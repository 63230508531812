// filters.js
const FiltersPlugin = {
    install: function(Vue, options) {

        // display the size of a file in human readable format
        Vue.filter('size', function(value) {

            if (value > 1048576) {
                return Math.round(value / 1048576) + ' MB';
            } else {
                // fix for non rounding toFixed
                let exact = value / 1048576;
                return (Math.round(exact * 100) / 100).toFixed(1) + ' MB';
            }
        });

        // capitalize a term
        Vue.filter('capitalize', function(text) {

            if (!text) {
                return null;
            }

            if (text == '') {
                return '';
            }

            return text[0].toUpperCase() + text.slice(1);
        });

    }
};

export default FiltersPlugin;