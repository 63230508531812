<template>
    <v-card
    
    class="product-card"
    :class="itemProduct.service_name"
    @click="showServiceDialog(itemProduct)"
    elevation="6"
    >
        <!-- <v-img
        :src="getImage"
        height="300px"
        class="align-top"
        gradient="to top, rgba(0,0,0,.01), rgba(0,0,0,.5)"
        > -->

            <v-chip
            class="product-card-label"
            :color="itemProduct.deleted_at !== null ? 'red': 'green'"
            text-color="white"
            >
            <v-avatar left>
                <v-icon>mdi-{{ itemProduct.deleted_at !== null ? 'close': 'check' }}</v-icon>
            </v-avatar>
             {{ itemProduct.deleted_at !== null ? 'Disabled': 'Active' }}
            </v-chip>

            <div class="product-card-wrapper">
                <v-card-title
                    class="product-card__title headline"
                    v-text="itemProduct.service_name"
                ></v-card-title>
                <v-card-subtitle class="price" >{{itemProduct.price + " " + itemProduct.currency}}</v-card-subtitle>
            </div>
            <v-btn
                class="btn btn-see-more"
                rounded
                @click="showServiceDialog(itemProduct)"
            >
            <!-- {{ $t('product.see_more') }} -->
            Edit
                <!-- <v-icon>mdi-arrow-right</v-icon> -->

            </v-btn>
        <!-- </v-img> -->
        <div 
        class="product-card__wrapper d-flex flex-no-wrap justify-space-between"
        v-show="false"
        >

        </div>
    </v-card>
</template>

<script>
// import { mapActions } from 'vuex'
import mixinShared from '@/mixins/Shared'
import { bus } from '../../main'

export default {
    mixins: [mixinShared],
    props: {
        itemProduct : {
            type : Object,
            required : false
        },
        design : {
            type : Boolean,
            required : false,
        },
        
    },
    data: () => ({

    }),
    methods: {
        showServiceDialog(service){
            console.log('service',service)
            bus.$emit('showServiceDialog',service);
        }
        // ...mapActions({
        //    singleView: 'shop/setSingleView',
        // }),     

    },
    computed: {
    }
}
</script>

<style scoped lang="scss">
.list-products__wrapper-parallax,
.list-products__wrapper-parallax .row,
.list-products__wrapper-parallax .row .col {
    height: 100%;
}

.list-products__container {
    padding: 2rem 0;
    height: 90%;
    margin-top: 5%;
    border-radius: 15px;
    background: #ffffff;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
}

.list-products__container .list-products__avatar{
    /* box-shadow: 0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.4); */
    border: 5px solid #009688;
}

.product-card {
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: .5rem;
    .product-card-label {
        position: absolute;
        right: .5rem;
        top: .5rem;
    }
    .product-card-wrapper {
        background: rgba(0,0,0,.2);
        max-width: 85%;
        padding: 1rem;
        border-radius: 0 0 25px 0;
        .v-card__title,
        .v-card__subtitle {
            color: #ffffff;
            text-shadow: 0 1px 3px #000000;
            &.address {
                padding-bottom: 0;
            }
            &.city {
                padding-top: 0;
                text-transform: capitalize;
            }
        }
    }

    .btn-see-more {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        font-size: 120%!important;
        border: 3px solid #009688!important;
        background: #009688;
        color: #ffffff;
    }
}



</style>