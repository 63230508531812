<template>
    <section class="page page-map">
        <div
        dark
        elevation="4"
        >
            <v-container>
                <v-row dense>
                    <v-col cols="10">
                        <v-text-field 
                            hide-details
                            outlined 
                            label="search" 
                            name="search-text" 
                            v-model="searchValueFinal"
                            prepend-inner-icon="mdi-magnify" 
                            type="text"
                            required 
                            clearable
                        >
                        </v-text-field>
                    </v-col>

                    <v-col cols="2">
                        <v-btn 
                        icon 
                        color="primary" 
                        class="btn btn-primary"
                        tile 
                        fab 
                        @click="dialogAlert = !dialogAlert">
                            <v-icon>
                            mdi-map-marker-radius-outline
                            </v-icon>
                        </v-btn>

                    </v-col>
                </v-row>
            </v-container>
        </div>

        <div class="map-container" 
        :style="
        'height:'+ window.height + 'px; width:'+ window.width +'px'
        ">
            <l-map ref="myMap" 
            @ready="onMapReady()" 
            :zoom="zoom" 
            :center="center">

                <l-tile-layer 
                :url="url">
                </l-tile-layer>

                <l-marker 
                v-for="mark in markers"
                :key="mark.id" 
                :lat-lng="mark.latLng"
                @click="showDetails(mark)"
                >
                    <l-popup>
                        <div 
                        class="map--marker"
                        @click="goToItemDetails(mark.id)">
                            <p class="marker-info"
                            v-if="showMarkerInfo"
                            >
                                {{mark.title}} <br>
                                {{mark.street}} - {{ mark.city }}
                            </p>
                            <v-card
                                v-if="showMarkerCard"
                                >
                                    <div class="justify-space-between">
                                        <div>
                                        <v-card-title
                                            class="headline"
                                            v-text="selectedMerchant.title"
                                        ></v-card-title>

                                        <v-card-subtitle v-text="selectedMerchant.title"></v-card-subtitle>
                                        </div>

                                        <v-avatar
                                        class="ma-3"
                                        size="125"
                                        tile
                                        @click="goToItemDetails(selectedMerchant.id)"
                                        >
                                        <v-img :src="(selectedMerchant.cover) ? selectedMerchant.cover : 'https://cdn.vuetifyjs.com/images/cards/foster.jpg'"></v-img>
                                        </v-avatar>
                                    </div>
                                </v-card> 
                        </div>
                    </l-popup>
                </l-marker>
            </l-map>    
        </div>

        <div class="seller-container seller-bottom--fixed"
            v-if="!showMarkerCard"
        >
            <v-card
            :class="(selectedMerchant.active) ? 'visible' : 'hidden'"
            @click="goToItemDetails(selectedMerchant.id)"
            >
                <div class="d-flex flex-no-wrap justify-space-between">
                    <v-avatar
                    class="merchant-img"
                    size="100"
                    tile
                    >
                        <!-- <v-btn 
                        class="btn btn-see-more"
                        small
                        outlined
                        color="primary"
                        >See More
                        </v-btn> -->
                        <template v-if="selectedMerchant.image">
                            <v-img 
                            :src="selectedMerchant.image"></v-img>
                        </template>
                        <template v-else>
                            <v-icon
                            color="primary"
                            class="icon-logo-avatar"
                            >mdi-spa</v-icon>
                        </template>
                    </v-avatar>
                    <div>
                        <v-card-title
                            class="headline primary--text"
                            v-text="selectedMerchant.title"
                        ></v-card-title>

                        <v-card-subtitle>{{ selectedMerchant.street }} - {{ selectedMerchant.city  }}</v-card-subtitle>
                    </div>
                    <!-- <v-card-actions>
                        <v-btn 
                        class="btn btn-see-more primary"
                        small
                        rounded
                        block
                        >See More
                        </v-btn>
                    </v-card-actions> -->
                </div>
            </v-card> 
        </div>


        <v-dialog
            class="dialog-confirm-map"
            v-model="dialogAlert"
            persistent
            max-width="290"
        >
            <v-card>
                <v-card-title class="headline">
                Reset Location
                </v-card-title>
                <v-card-text>Do you want to reset your location ? If you want to proceed please click to Continue. This allow you to select a new location on the map.</v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="red darken-1"
                    text
                    @click="dialogAlert = !dialogAlert"
                >
                    Cancel
                </v-btn>
                <v-btn
                    color="green darken-1"
                    outlined
                    left
                    @click="goToLocationPicker()"
                >
                    Continue
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
    </section>
</template>

<script>
    import {
        mapGetters,
        mapState
    } from 'vuex'
    //import SellerPage from "./SellerPage";

    import {
        latLng
    } from "leaflet";
    import {
        LMap,
        LTileLayer,
        LMarker,
        LPopup
    } from 'vue2-leaflet';
    export default {
        data() {
            return {
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                zoom: 15,
                center: [47.313220, -1.319482],
                markers: [],
                // Show Marker with card inside instead of bottom card
                showMarkerCard: false,
                // Show name and description inside marker - default
                showMarkerInfo: true,
                // Open Toggle top menu
                showSearchBarTop: false,
                // Dialog Alert
                dialogAlert: false,

                customMarker : {image: 'https://t4.ftcdn.net/jpg/03/87/94/65/240_F_387946581_S0FgnTQx3GjmsfZpnYxMZhLbrJG9Rm2k.jpg'},
                window: {
                    height: 200,
                    width: 400,
                },
                selectedMerchant: {
                    active: false,
                    title: '',
                    description:'',
                    street: '',
                    cover: ''
                },
                searchValueFinal:''
            };
        },
        components: {
            LMap,
            LTileLayer,
            LMarker,
            LPopup
        },
        created() {
            // set windows width
            let w = window.innerWidth;
            // Set windows height minus bottom bar and top bar height
            //let h = window.innerHeight - 80 - 56;
            let h = window.innerHeight - 80;
            this.window.height = h
            this.window.width = w

        },
        mounted() {

            this.generateMarkers(this.getSearchResult);
        },
        computed: {
            ...mapGetters(["getCurrentLocation", "getSearchResult"]),
        },
        methods: {

            filterResults(search){
                    
                let temp = [];
                try {

                if (this.getSearchResult.length > 0) {
                    temp = this.getSearchResult.filter((item) => {

                    let inService = false;
                    let inSeller = false;
                    search = (search.toString()).toLowerCase();

                    let searchValues2 = (search.split(" ")).join("|");
                    let matchpattern = new RegExp(searchValues2, 'g');

                    console.log('item', item);
                    for (let serv of item.services) {
                        if ((serv.service_name.toLowerCase()).match(matchpattern)) {
                        inService = true;
                        } else if ((serv.service_decription.toLowerCase()).match(matchpattern)) {
                        inService = true;
                        }
                    }

                    if ((item.name.toLowerCase()).match(matchpattern)) {
                        inSeller = true;
                    } else if ((item.city.toLowerCase()).match(matchpattern)) {
                        inSeller = true;
                    } else if ((item.street.toLowerCase()).match(matchpattern)) {
                        inSeller = true;
                    } else if ((item.description.toLowerCase()).match(matchpattern)) {
                        inSeller = true;
                    }

                    return inService || inSeller;
                    });


                }

                } catch (error) {
                console.log(error);
                }

                this.generateMarkers(temp);
            },
            generateMarkers(results){
                let temp = [];
                console.log('mounted maps getSearchResult', results);

                // Get base url for images
                let baseUrlImg = localStorage.getItem("appURL");

                for (let seller of results) {
                    console.log('seller' + seller.name, seller.images);

                    temp.push({
                        active: true,
                        id: seller.id,
                        lat: seller.lat,
                        lng: seller.lng,
                        latLng: [seller.lat, seller.lng],
                        title: seller.name,
                        subtitle: seller.description,
                        street: seller.street,
                        city: seller.city,
                        image: (seller.images.length > 0) ? baseUrlImg + seller.images[0].image_url : '',
                        //images: seller.images[0],
                        selected: true, // this will trigger the callout upon update
                        onTap: marker => console.log("Marker tapped with title: '" + marker.title + "'"),
                        onCalloutTap: marker => this.goToItemDetails(marker.id)
                    })
                }
                console.log('mounted maps temp', temp);
                this.markers = temp;
            },
            goToLocationPicker() {
                console.log("goToLocationPicker home.vue");
                this.$router.push({
                    name: 'LocationPicker'
                });
            },
            onMapReady(args) {

                this.map = this.$refs.myMap.mapObject

                this.center = [
                    this.getCurrentLocation.lat,
                    this.getCurrentLocation.lng
                ];


            },

            showDetails(id) {
                console.log("showDetails", id)
                //let obj = this.getSearchResult.find(o => o.id === id);
                this.selectedMerchant = id
            },
            goToItemDetails(id) {
                console.log("goToItemDetails")
                let obj = this.getSearchResult.find(o => o.id === id);

                this.$router.push({
                    name: 'seller-page',
                    params: {
                        singleItem: obj,
                        seller_id: obj.id
                    }
                
                });

            }
        },
        watch: {
            searchValueFinal(val) {
                if (val !== '' && val !== undefined) {
                this.filterResults(val)
                }
            },
        }
    };
</script>

<style scoped lang="scss">
header{
    display: none!important;
    visibility: hidden!important;
}

.dialog-confirm-map {
    z-index: 9999;
}

.seller-container {
    &.seller-bottom--fixed {
        position: fixed;
        bottom: 79px;
        right: 0;
        left: 0;
        z-index: 9990;
    }

    .v-card {
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        &.hidden {
            height: 0;
            overflow: hidden;
        }
        &.active {
            height: 100%;
            overflow: visible;
        }        


        .d-flex {
            .icon-logo-avatar {
                font-size: 3rem;
            }
            div.v-avatar + div {
                width: calc(100% - 100px);
            }

            .v-card__title.headline {
                line-height:1.2;
                font-size: 120%!important;
                font-weight: bold;
            }
        }
        div.v-card__actions {
            // position: absolute;
            // bottom: 1rem;
            left: auto;
            // right: 1rem;
            max-width: 100px;
            padding: 0;
            button.btn.btn-see-more {
                height: 100%;
                border-radius: 0;
                -webkit-border-radius: 0;
                -moz-border-radius: 0;
            }
        }

    }

    // .v-card.active {
    //     height: 100%;
    //     overflow: visible;
    //     div.v-card__actions {
    //         position: absolute;
    //         bottom: 1rem;
    //         left: auto;
    //         right: 1rem;
    //         max-width: 100px;
    //         padding: 0;
    //     }
    // }


}


    // Custom styles
    // .add-button {
    //     height: 30;
    //     background-color: rgb(51, 51, 206);
    //     color: white;
    //     border-radius: 5;
    //     font-size: 20;
    //     font-weight: 600;
    // }

    // .card {
    //     background-color: #fff;
    //     color: #4d4d4d;
    //     margin: 15;
    // }

    // .card-layout {
    //     padding: 20;
    // }
</style>