import Vue from 'vue'
import Vuex from 'vuex'

// Shop-Card Functions
//import shops from './modules/shop'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        ServerURL: "https://api.wellbookings.com",
        sellerProfile: {},
        sellerToken: '',
        profile: {},
        favourities: [],
        userToken: '',
        location: {},
        searchResult: [],
        sellersBookings: {},
        clientBookings: [],
        clientCart: [],
        calenderIndex: 0,
        calendarDate: null,
        isMobile: false,
        userType: null, // 1 user - 2 merchant
        //singlePageSeller: {},
        // Shop and Cart
        item: {},
        items: [],
        singleBookingDetails: {}
        // cart: {
        //     currency: "",
        //     total: 0,
        //     items: [],
        //     limit: 1, // this is the max service that user can add
        //     working_hours: {},
        //     merchant_id : null,
        // }    
    },
    mutations: {
        init(state, data) {

            localStorage.setItem('appURL', "https://api.wellbookings.com");

            try {
                state.sellerProfile = JSON.parse(localStorage.getItem("sellerProfile", '{}'));
                state.sellersBookings = JSON.parse(localStorage.getItem("sellersBookings", '{}'));
                state.clientBookings = JSON.parse(localStorage.getItem("clientBookings", '[]'));
            } catch (err) {
                console.log('[seller could not be loaded 56]', err)
            }
            try {
                state.profile = JSON.parse(localStorage.getItem("profile", '{}'));
                state.location = JSON.parse(localStorage.getItem("location", '{}'));
                state.searchResult = JSON.parse(localStorage.getItem("searchResult", '{}'));
                state.calenderIndex = localStorage.getItem("calenderIndex", 0);
                state.favourities = JSON.parse(localStorage.getItem("favourities", '[]'));

            } catch (err) {
                console.log('[user could not be loaded 34]', err)
            }

            try {
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    state.isMobile = true;
                } else {
                    state.isMobile = false;
                }
            } catch (err) {
                console.log('[user could not be loaded 12]', err)
            }

            try {
                state.item = JSON.parse(localStorage.getItem("singleMerchant", '{}'));
                state.clientCart = JSON.parse(localStorage.getItem("updateCart", '{}'));
                state.singleBookingDetails = JSON.parse(localStorage.getItem("singleBookingDetails", '{}'));
            } catch (err) {
                console.log('Error dv', err)
            }

            console.log('init------------------------------');
            console.log('sellerProfile', state.sellerProfile);
            console.log('sellersBookings', state.sellersBookings);
            console.log('clientBookings', state.clientBookings);
            console.log('profile', state.profile);
            console.log('location', state.location);
            console.log('searchResult', state.searchResult);
            console.log('favourities', state.favourities);
        },
        sellerProfile(state, payload) {
            state.sellerProfile = payload;
            state.userType = 2;
            localStorage.setItem("sellerProfile", JSON.stringify(payload));
        },
        profile(state, payload) {
            state.profile = payload;
            state.userType = 1;
            localStorage.setItem("profile", JSON.stringify(payload));
        },
        location(state, payload) {
            state.location = payload;
            localStorage.setItem("location", JSON.stringify(payload));
        },
        searchResult(state, payload) {
            console.log("mutation payload", payload);
            state.searchResult = payload;
            localStorage.setItem("searchResult", JSON.stringify(payload));
        },
        logout(state) {
            state.profile = null;
            localStorage.setItem("profile", null);
            localStorage.setItem('token', '');
        },
        sellerLogout(state) {
            state.sellerProfile = null;
            localStorage.setItem("sellerProfile", null);
            localStorage.setItem('sellerToken', '');
        },
        addService(state, payload) {
            state.sellerProfile.seller.services.push({
                ...payload
            });
            localStorage.setItem("sellerProfile", JSON.stringify(state.sellerProfile));
        },
        updateService(state, payload) {
            state.sellerProfile.seller.services = state.sellerProfile.seller.services.filter(item => item.id !== payload.id);
            state.sellerProfile.seller.services.push({
                ...payload
            });
            localStorage.setItem("sellerProfile", JSON.stringify(state.sellerProfile));
            console.log('state.sellerProfile.seller.services', state.sellerProfile.seller.services);
        },
        removeService(state, payload) {

            state.sellerProfile.seller.services = state.sellerProfile.seller.services.filter(item => item.id !== payload.id);
            localStorage.setItem("sellerProfile", JSON.stringify(state.sellerProfile));
        },
        setSellersBookings(state, payload) {
            state.sellersBookings = [
                ...payload
            ];
            localStorage.setItem("sellersBookings", JSON.stringify(state.sellersBookings));
        },
        setClientBookings(state, payload) {
            state.clientBookings = [...payload];
            localStorage.setItem("clientBookings", JSON.stringify(state.clientBookings));
        },
        updateSellerInformation(state, payload) {
            state.sellerProfile.seller = {
                ...payload
            };
            localStorage.setItem("sellerProfile", JSON.stringify(state.sellerProfile));
        },
        updateSellerActivated(state, payload) {
            state.sellerProfile.seller.activated = payload.activated;
            localStorage.setItem("sellerProfile", JSON.stringify(state.sellerProfile));
        },
        updateUserInformation(state, payload) {
            state.profile = {
                ...payload
            };
            console.log("state.profile", state.profile)
            console.log("state.profile payload", payload)
            localStorage.setItem("sellerProfile", JSON.stringify(state.profile));
        },
        updateImages(state, payload) {
            state.sellerProfile.seller.images = [...payload];
            localStorage.setItem("sellerProfile", JSON.stringify(state.sellerProfile));
        },
        setCalenderIndex(state, payload) {
            state.calenderIndex = payload;
            localStorage.setItem("calenderIndex", payload);
        },

        updateCalendarDate(state, payload) {
            state.calendarDate = payload;
            localStorage.setItem("updateCalendarDate", payload);
        },
        updateCart(state, payload) {
            state.clientCart = payload;
            // localStorage.setItem("cartItem", payload)
            localStorage.setItem("updateCart", JSON.stringify(payload));
        },

        // Update Seller Single Page
        // updateSellerPage(state,payload) {
        //   state.singlePageSeller = payload;
        //   localStorage.setItem("singlePageSeller", payload)
        // }

        // updateCartTotal
        // updateCartTotalVal( state, basket_total) {
        //     state.cart.total = basket_total
        // },
        // // Update User Cart
        // updateCartItem(state, payload) {
        //     console.log("payload.currency", payload.currency)
        //     state.cart.items.push(payload)

        //     state.cart.currency = payload.currency
        //     state.cart.merchant_id = payload.seller_id
        //     state.cart.merchant_service_id = state.item.id
        //     state.cart.working_hours = JSON.parse(state.item.working_hours)

        //     localStorage.setItem("updateCart", JSON.stringify(payload));
        // },
        // Update Merchant list in store , based on search
        updateMerchantList(state, payload) {
            state.items = payload
            localStorage.setItem("merchantList", JSON.stringify(payload));
        },
        updateSingleMerchant(state, payload) {
            state.item = payload
            localStorage.setItem("singleMerchant", JSON.stringify(payload));
        },
        updateSinlgeBookingAccount(state, payload) {
            state.singleBookingDetails = payload
            localStorage.setItem("singleBookingDetails", JSON.stringify(payload));
        },

        updateSellerToken(state, payload) {
            console.log("updateSellerToken -- payload", payload);
            state.sellerToken = payload
            localStorage.setItem("sellerToken", payload);
        },

        addEmployee(state, payload) {
            console.log("addemployee -- payload", payload);
            state.sellerProfile.seller.users.push(payload);
            localStorage.setItem("sellerProfile", JSON.stringify(state.sellerProfile));
        },
        updateEmployee(state, payload) {
            console.log("updateEmployee -- payload", payload);
            let templist = state.sellerProfile.seller.users.filter((item) => item.id !== payload.id)
            templist.push(payload);
            state.sellerProfile.seller.users = templist;
            console.log("updateEmployee -- payload", state.sellerProfile.seller.users);

            localStorage.setItem("sellerProfile", JSON.stringify(state.sellerProfile));
        },

        addRemoveFavourite(state, payload) {
            console.log("addRemoveFavourite -- payload", payload);
            if (state.favourities === null) {
                state.favourities = [];
            }
            const found = state.favourities.find(item => item.id === payload.id);

            if (found) {
                //found remove it
                state.favourities = state.favourities.filter(item => item.id !== payload.id);
            } else {
                //not found add it
                state.favourities.push(payload);
            }
            localStorage.setItem("favourities", JSON.stringify(state.favourities));
        },
    },


    actions: {
        updateBookingCart({ commit }, payload) {
            console.log("updateBookingCart", payload)
            commit('updateCart', payload)
        },
        init(context) {
            context.commit("init");
        },
        setSellerProfile({
            commit,
            state
        }, payload) {
            commit('sellerProfile', payload);
        },
        setprofile({
            commit,
            state
        }, payload) {
            commit('profile', payload);
        },
        setLocation({
            commit,
            state
        }, payload) {

            console.log("store setLocation", payload)

            if (payload.useGeo === true) {

                fetch(state.ServerURL + '/api/client/search/geo', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify({
                        lng: payload.lng,
                        lat: payload.lat,
                        distance: 10
                    })
                }).then(response => {


                    console.log("store -- setLocation -- RESPONSE", response);

                    if (response.status === 200) {

                        response.json().then(result => {
                            console.log("store -- setLocation -- RESPONSE -- RESULT", result);
                            console.log("store -- setLocation -- RESPONSE -- LOCATION", payload);
                            commit('searchResult', result);
                            commit('location', payload);
                        });

                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 398');
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                    commit('searchResult', [...state.searchResult]);
                });
            } else {

                fetch(state.ServerURL + '/api/client/search/city', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify({
                        city: payload.city.label,
                    })
                }).then(response => {


                    console.log("store -- setLocation -- RESPONSE", response);

                    if (response.status === 200) {

                        response.json().then(result => {
                            console.log("store -- setLocation -- RESPONSE -- RESULT", result.success);
                            console.log("store -- setLocation -- RESPONSE -- LOCATION", payload);
                            commit('searchResult', result.success);
                            commit('location', payload);
                        });

                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 398');
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                    commit('searchResult', [...state.searchResult]);
                });
            }


            commit('location', payload);

        },
        searchLocation({
            commit,
            state
        }, payload) {
            console.log("store setLocation", payload)

            fetch(state.ServerURL + '/api/client/search/geo', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify({
                    lng: payload.lng,
                    lat: payload.lat,
                    distance: 10
                })
            }).then(response => {

                console.log(response);

                if (response.status === 200) {
                    response.json().then(result => {
                        commit('searchResult', result);
                    });
                } else if (response.status === 401) {
                    //failed logn
                    console.log('Error connecting to API server 398');
                } else {
                    // unkown error
                    console.log('Unexpected error with API server 398');
                }

            }).catch((error) => {
                console.log('[GPSLocation http request error]', error);
            });

        },
        searchService({
            commit,
            state
        }, payload) {
            console.log("store searchService", payload)

            fetch(state.ServerURL + '/api/client/search/seller/service/name', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify({
                    "name": payload
                })
            }).then(response => {

                console.log(response);

                if (response.status === 200) {
                    response.json().then(result => {
                        console.log("searchService", result.success)
                        commit('searchResult', result.success);
                    });
                } else if (response.status === 401) {
                    //failed logn
                    console.log('Error connecting to API server 398');
                } else {
                    // unkown error
                    console.log('Unexpected error with API server 398');
                }

            }).catch((error) => {
                console.log('[GPSLocation http request error]', error);
            });

        },
        searchByCity({ commit, state }, payload) {
            let dataPayload = {
                city: payload
            }

            fetch(state.ServerURL + '/api/client/search/city', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify({
                    city: dataPayload.city,
                })
            }).then(response => {
                // let resp = JSON.parse(response)
                // console.log("searchByCity - Store - Response",resp);

                if (response.status === 200) {
                    response.json().then(result => {
                        console.log("searchByCity - Store - Response", result.success);

                        commit('searchResult', result.success);
                    });
                } else if (response.status === 401) {
                    //failed logn
                    console.log('Error connecting to API server 398');
                } else {
                    // unkown error
                    console.log('Unexpected error with API server 398');
                }

            }).catch((error) => {
                console.log('[GPSLocation http request error]', error);
            });
        },
        // Search By City and Country
        searchByCityCountry({ commit, state }, payload) {
            console.log("payload searchByCityCountry", payload)
                //return new Promise((resolve, reject) => {

            // Base Url of App
            //const baseUrl = localStorage.getItem('appURL');
            // Custom Url for Api Call
            //const searchCityCountryUrl = baseUrl + '/api/client/search/country/city';
            // Header and Token
            //const token = localStorage.getItem('token');
            // console.log("toke", token)
            // Header
            // const headers = {
            //     'Content-Type' : 'application/json',
            //     'Authorization' : 'Bearer '+ token,
            // }

            let dataPayload = {
                country: payload.country,
                city: payload.city
            }


            fetch(state.ServerURL + '/api/client/search/country/city', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify({
                    country: dataPayload.country,
                    city: dataPayload.city,
                })
            }).then(response => {

                console.log(response);

                if (response.status === 200) {
                    response.json().then(result => {
                        commit('searchResult', result.success);
                    });
                } else if (response.status === 401) {
                    //failed logn
                    console.log('Error connecting to API server 398');
                } else {
                    // unkown error
                    console.log('Unexpected error with API server 398');
                }

            }).catch((error) => {
                console.log('[GPSLocation http request error]', error);
            });

            // axios.post(searchCityCountryUrl, dataPayload, {
            //     headers : headers
            // })
            // .then(resp => {
            //     let respData = resp.data.success

            //     console.log("resp -store - addService", respData)
            //     commit('updateMerchantList', respData)
            //     resolve(respData)
            // })
            // .catch(err => {
            //     //commit('auth_error')
            //     reject(err)
            // })
            //})
        },
        logout({
            commit,
            state
        }) {
            commit('logout');
        },
        sellerLogout({
            commit,
            state
        }) {
            commit('sellerLogout');
        },
        addService({
            commit,
            state
        }, payload) {
            commit('addService', payload);
        },
        updateService({
            commit,
            state
        }, payload) {
            commit('updateService', payload);
        },
        setSellersBookings({
            commit,
            state
        }, payload) {
            commit('setSellersBookings', payload);
        },
        setClientBookings({
            commit,
            state
        }, payload) {
            commit('setClientBookings', payload);
        },
        updateSellerInformation({
            commit,
            state
        }, payload) {
            commit('updateSellerInformation', payload);
        },
        updateSellerActivated({
            commit,
            state
        }, payload) {
            commit('updateSellerActivated', payload);
        },
        updateUserInformation({
            commit,
            state
        }, payload) {
            commit('updateUserInformation', payload);
        },
        updateImages({
            commit,
            state
        }, payload) {
            commit('updateImages', payload);
        },
        setCalenderIndex({
            commit,
            state
        }, payload) {
            commit('setCalenderIndex', payload);
        },
        setCalenderDate({
            commit,
            state
        }, payload) {
            commit('updateCalendarDate', payload);
        },

        // Set Seller on Explore
        // setSellerPage({
        //   commit,
        //   state
        // }, payload) {
        //   console.log("store -- updateSellerPage ", payload)
        //   commit('updateSellerPage', payload);
        // }
        // Update Cart Item, total and Value
        // updateCart( {commit, dispatch}, payload) {
        //     commit('updateCartItem', payload)
        //     dispatch('updateCartTotal')
        // },
        // Update total prince on cart
        // updateCartTotal( {commit, state} ) {
        //     let basket_total = 0;
        //     state.cart.items.forEach(val => {
        //         basket_total += Number(val.price);
        //     //or if you pass float numbers , use parseFloat()
        //     });
        //     // console.log(basket_total);
        //     console.log("store", state.cart.items)
        //     commit('updateCartTotalVal', basket_total)
        // },

        setSingleView({ commit }, payload) {
            console.log("setSingleView action store", payload)
            commit('updateSingleMerchant', payload)
        },
        singleBookingInfo({
            commit
        }, payload) {
            commit('updateSinlgeBookingAccount', payload);
        },
        setSellerToken({
            commit
        }, payload) {
            console.log("styate /updateSellerToken", payload)
            commit('updateSellerToken', payload)
        },
        addEmployee({
            commit
        }, payload) {
            commit('addEmployee', payload)
        },
        addCustomer({
            commit,
            state
        }, payload) {
            console.log("store add customer", payload)
            const token = state.sellerToken;

            fetch(state.ServerURL + '/api/seller/customers/add', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify({
                    ...payload
                })
            }).then(response => {

                console.log(response);

                if (response.status === 200) {
                    response.json().then(result => {
                        console.log("searchService", result.success)
                            //commit('searchResult', result.success);
                    });
                } else if (response.status === 401) {
                    //failed logn
                    console.log('Error connecting to API server 398');
                } else {
                    // unkown error
                    console.log('Unexpected error with API server 398');
                }

            }).catch((error) => {
                console.log('[Addcustomer http request error]', error);
            });
        },
        updateEmployee({
            commit
        }, payload) {
            commit('updateEmployee', payload)
        },
        addRemoveFavourite({
            commit,
            state
        }, payload) {
            console.log('add fav');
            commit('addRemoveFavourite', payload);
        },
        removeService({
            commit,
            state
        }, payload) {
            console.log('remove service');
            commit('removeService', payload);
        },
    },
    getters: {
        getProfileData: (state) => state.profile,
        getSellerProfileData: (state) => state.sellerProfile,
        getSellersBookings: (state) => state.sellersBookings,
        getClientBookings: (state) => state.clientBookings,
        getCurrentLocation: (state) => state.location,
        getSearchResult: (state) => state.searchResult,
        getCalenderIndex: (state) => state.calenderIndex,
        getCalenderDate: (state) => state.calendarDate,
        isLoggedIn: (state) => state.profile === null ? false : true,
        isMobile: (state) => state.isMobile,
        isSellerLoggedIn: (state) => state.sellerProfile === null ? false : true,

        // Shop
        getSingleItem: (state) => state.item,
        getCart: (state) => state.clientCart,
        getListItems: (state) => state.items,
        getSingleBooking: (state) => state.singleBookingDetails === {} ? {} : state.singleBookingDetails,
        getSellerToken: (state) => state.sellerToken,
        getFavourities: (state) => state.favourities,
        getPromoted: (state) => (state.searchResult !== null ? (state.searchResult).filter((item) => item.promoted === 1) : {})
    },
    modules: {
        //shop: shops
    }
})