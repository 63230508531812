<template lang="html">

    <!-- <div class="slider-container">

        <div class="scrollmenu">

            <slot name="content"></slot>

        </div>

    </div> -->

    <v-slide-group
      class="pa-4 pr-0 pl-0"
      active-class="success"
      show-arrows
    >

    <slot name="content"></slot>
      
    </v-slide-group>

</template>

<script>
    import {
        mapGetters,
        mapState
    } from 'vuex'


    export default {
        data() {
            return {
            };
        },
        mounted() {

        },
        computed: {
        },
        methods: {


        },
    }
</script>

<style lang="scss" scoped>
    // Start custom common variables
    // End custom common variables

    .nextbtn {
            right: -33px;
        width: 35px;
        height: 41px;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        top: 30%;
        box-shadow: 0 2px 11px 0 rgb(26 26 26 / 25%);
    }
    .scrollmenu {
        background: #ebebeb;
        text-align: center;
        
    }

    .slider-container {
    }

    .slider-item {

        display: inline-block;
        color: black;
        background-color: white;
        border-width: 1px;
        border-color: slategray;
        margin: 5px;
        padding: 5px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 12px;
        cursor:pointer;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .selected {
        background-color: teal;
        
    }

    .v-slide-group__next, .v-slide-group__prev {
        align-items: center;
        display: flex;
        flex: 0 1 52px;
        justify-content: center;
        min-width: 52px;
        border-radius: 50%;
        background: #66beb5;
        height: 50px;
        width: 50px;
        top: 130px;
        position: relative;
        color: white;
        box-shadow: 0 2px 11px 0 rgb(26 26 26 / 25%);
    }

    // Custom styles
</style>