<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <section class="page page-account">
                    <!-- <div class="page-account--top">{{ getProfileData }}</div> -->
                    <div class="page--main">
                        <v-row>
                            <!-- Simple USer -->
                            <v-col 
                                lg="6"
                                md="6"
                                sm="6" 
                                cols="12"
                            >
                                <v-card
                                    class="card-account card-user"
                                    color="teal"
                                    
                                >
                                    <div class="d-flex--flex-no-wrap justify-space-between">
                                        <div>
                                            <v-card-title
                                            class="pb-8"
                                            >
                                                <v-icon class="mr-2 white pa-2 black--text" x-large >mdi-account</v-icon>
                                                <span
                                                >{{ ( isLoggedIn ? getProfileData.name : 'Guest ') }}</span>
                                                
                                                <!-- <v-chip
                                                small
                                                class="ma-2"
                                                color="green"
                                                outlined
                                                >
                                                User
                                                </v-chip> -->

                                            </v-card-title>

                                            <v-card-subtitle v-if="!isLoggedIn">
                                              {{ 'Login to your ' + this.site_name + ' account to book appointment or Register a new account' }}
                                            </v-card-subtitle>

                                            <v-list v-if="isLoggedIn">
                                                <v-list-item @click="goToProfile()">
                                                    <v-list-item-icon>
                                                        <v-icon>mdi-account-circle</v-icon>
                                                    </v-list-item-icon>                                                    
                                                    <v-list-item-content>
                                                        <v-list-item-title>Profile</v-list-item-title>
                                                        <v-list-item-subtitle>View and update your personal information</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <!-- List item -->
                                                <v-list-item @click="goToBookings()">
                                                    <v-list-item-icon>
                                                        <v-icon>mdi-book</v-icon>
                                                    </v-list-item-icon>                                                    
                                                    <v-list-item-content>
                                                        <v-list-item-title>My Bookings</v-list-item-title>
                                                        <v-list-item-subtitle>View your upcoming appointments</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <!-- List item -->
                                                <v-list-item @click="goToMarket()">
                                                    <v-list-item-icon>
                                                        <v-icon>mdi-heart</v-icon>
                                                    </v-list-item-icon>                                                    
                                                    <v-list-item-content>
                                                        <v-list-item-title>Favourites</v-list-item-title>
                                                        <v-list-item-subtitle>Your favourite stores</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <!-- List item -->
                                                <v-list-item>
                                                    <v-list-item-icon>
                                                        <v-icon>mdi-wallet</v-icon>
                                                    </v-list-item-icon>                                                    
                                                    <v-list-item-content>
                                                        <v-list-item-title>Wallet</v-list-item-title>
                                                        <v-list-item-subtitle>Balance : 0 Eur</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <!-- List item -->
                                            </v-list>

                                            <v-card-actions>
                                                <template v-if="!isLoggedIn">
                                                    <v-btn
                                                        class="ml-2 mt-0 pa-5"
                                                        color=""
                                                        rounded
                                                        @click="goToLogin(true)"
                                                    >
                                                        Login
                                                    </v-btn>

                                                    <v-btn
                                                        class="ml-2 mt-0 pa-5"
                                                        outlined
                                                        rounded
                                                        @click="goToLogin(false)"
                                                        color="primary"
                                                    >
                                                        Register
                                                    </v-btn> 
                                                </template>
                                                <template v-else>
                                                    <v-btn
                                                        @click="Logout()" 
                                                        class="ml-2 mt-0 pa-5"
                                                        rounded
                                                    >
                                                        Logout
                                                    </v-btn>
                                                </template>
                                            </v-card-actions>
                                        </div>

                                        <!-- <v-avatar
                                            class="ma-3"
                                            size="100"
                                            tile
                                        >
                                            <v-chip
                                            
                                            class="ma-2"
                                            color="primary"
                                            >
                                            Merchant
                                            </v-chip>
                                            <v-icon  x-large>mdi-account-tie</v-icon>
                                        </v-avatar> -->
                                    </div>
                                </v-card>
                            </v-col>                            
                            <!-- Merchant -->
                            <v-col 
                                lg="6"
                                md="6"
                                sm="6" 
                                cols="12"
                            >
                                <v-card
                                    class="card-account card-merchant"
                                    color="teal"
                                    
                                >
                                    <div class="d-flex--flex-no-wrap justify-space-between">
                                        <div>
                                            <v-card-title
                                                class="pb-8"
                                            >
                                                <v-icon class="mr-2 white pa-2 black--text" x-large >mdi-store</v-icon>
                                                <span>{{( isSellerLoggedIn ? getSellerProfileData.name : 'Merchant ')}}</span>
                                                
                                            </v-card-title>

                                            <v-card-subtitle v-if="!isSellerLoggedIn">
                                               {{'Login to your ' + this.site_name + ' seller account to check your appointments or Register a new account' }}
                                            </v-card-subtitle>

                                            <v-list v-if="isSellerLoggedIn">
                                                <v-list-item @click="goToSellerProfile" v-if="this.getSellerProfileData.admin">
                                                    <v-list-item-icon>
                                                        <v-icon>mdi-account-circle</v-icon>
                                                    </v-list-item-icon>                                                    
                                                    <v-list-item-content>
                                                        <v-list-item-title>Profile</v-list-item-title>
                                                        <v-list-item-subtitle>View and update your personal information.</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <!-- List item -->
                                                <v-list-item @click="goToSellerDashboard">
                                                    <v-list-item-icon>
                                                        <v-icon>mdi-book</v-icon>
                                                    </v-list-item-icon>                                                      
                                                    <v-list-item-content>
                                                        <v-list-item-title>My Appointments</v-list-item-title>
                                                        <v-list-item-subtitle>View your appointments.</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <!-- List item -->   
                                                <v-list-item @click="goToPreview" v-if="this.getSellerProfileData.admin">
                                                    <v-list-item-icon>
                                                        <v-icon>mdi-monitor</v-icon>
                                                    </v-list-item-icon>                                                    
                                                    <v-list-item-content>
                                                        <v-list-item-title>Preview</v-list-item-title>
                                                        <v-list-item-subtitle>Preview your store as your customer would see it.</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>       
                                                <v-list-item @click="goToGettingStarted" v-if="this.getSellerProfileData.admin">
                                                    <v-list-item-icon>
                                                        <v-icon>mdi-wizard-hat</v-icon>
                                                    </v-list-item-icon>                                                    
                                                    <v-list-item-content>
                                                        <v-list-item-title>Getting Started</v-list-item-title>
                                                        <v-list-item-subtitle>Lets help you get your account activated</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>                              

                                            </v-list>

                                            <v-card-actions>
                                                <template v-if="!isSellerLoggedIn">
                                                    <v-btn
                                                        class="ml-2 mt-0 pa-5"
                                                        color=""
                                                        rounded
                                                        @click="goToSellerLogin(true)"
                                                    >
                                                        Login
                                                    </v-btn>

                                                    <v-btn
                                                        class="ml-2 mt-0 pa-5"
                                                        outlined
                                                        rounded
                                                        @click="goToSellerLogin(false)"
                                                        color="primary"
                                                    >
                                                        Register
                                                    </v-btn>
                                                </template>
                                                <template v-else>
                                                    <v-btn
                                                        @click="SellerLogout()" 
                                                        class="ml-2 mt-0 pa-5"
                                                        rounded
                                                    >
                                                        Logout
                                                    </v-btn>
                                                </template>
                                            </v-card-actions>
                                        </div>

                                        <!-- <v-avatar
                                            class="ma-3"
                                            size="100"
                                            tile
                                        >
                                            <v-chip
                                            
                                            class="ma-2"
                                            color="primary"
                                            >
                                            Merchant
                                            </v-chip>
                                            <v-icon  x-large>mdi-account-tie</v-icon>
                                        </v-avatar> -->
                                    </div>
                                </v-card>
                            </v-col>

                        </v-row>

                        <v-divider class="ma-5"></v-divider>

                        <v-row>
                            <v-col 
                                lg="3"
                                md="3"
                                sm="4" 
                                cols="6"
                            >
                                <v-card class="custom-card">

                                    <v-card-title>
                                        <v-icon color="primary" class="mr-2">mdi-bell-ring</v-icon>
                                        Notifications
                                    </v-card-title>

                                    <v-card-subtitle>
                                        Please select if you want to receive notifications from {{ this.site_name }}
                                    </v-card-subtitle>

                                    <v-card-actions>
                                        <v-switch
                                        v-model="settings"
                                        inset
                                        :label="(settings) ? `enabled` : `disabled`"
                                        ></v-switch>
                                        <!-- <v-checkbox v-model="settings" ></v-checkbox>Notification {{ settings }} -->

                                    </v-card-actions>
                                </v-card>
                            </v-col>
                            <!-- End Col -->
                            <v-col 
                                lg="3"
                                md="3"
                                sm="4" 
                                cols="6"
                            >
                                <v-card class="custom-card">
                                    <v-card-title>
                                        <v-icon color="primary" class="mr-2">mdi-map-marker-radius-outline</v-icon>
                                        Location
                                    </v-card-title>

                                    <v-card-subtitle>
                                        {{ 'Longitude: ' + getCurrentLocation.lng }}<br>
                                        {{ 'Latitude: ' + getCurrentLocation.lat }}
                                    </v-card-subtitle>

                                    <v-card-actions>

                                    <v-btn 
                                        text 
                                        block 
                                        color="primary"
                                        @click="changeLocation"
                                    >
                                        <v-icon left>mdi-check-circle</v-icon>
                                        Reset
                                    </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                            <!-- End Col -->
                            <v-col 
                                lg="3"
                                md="3"
                                sm="4" 
                                cols="6"
                            >
                                <v-card class="custom-card">
                                    <v-card-title>
                                        <v-icon color="primary" class="mr-2">mdi-cellphone-arrow-down</v-icon>
                                        Color Mode
                                    </v-card-title>

                                    <v-card-subtitle>
                                        Click to switch to Light or Dark mode, choose your color.
                                    </v-card-subtitle>

                                    <v-card-actions>
                                        <v-tooltip v-if="!$vuetify.theme.dark" bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn 
                                            v-on="on" 
                                            color="primary" 
                                            text
                                            block
                                            @click="darkMode">
                                                <v-icon class="mr-5" color="dark">mdi-moon-waxing-crescent</v-icon>
                                                Dark Mode
                                            </v-btn>
                                            </template>
                                            <span>Dark Mode On</span>
                                        </v-tooltip>

                                        <v-tooltip v-else bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn 
                                            v-on="on" 
                                            color="primary" 
                                            text
                                            block
                                            @click="darkMode">
                                                <v-icon class="mr-5" color="yellow">mdi-white-balance-sunny</v-icon>
                                                Light Mode
                                            </v-btn>
                                            </template>
                                            <span>Dark Mode Off</span>
                                        </v-tooltip>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                            <!-- End Col -->   

                            <v-col 
                                lg="3"
                                md="3"
                                sm="4" 
                                cols="6"
                            >
                                <v-card class="custom-card">
                                    <v-card-title>
                                        <v-icon color="primary" class="mr-2">mdi-cellphone-arrow-down</v-icon>
                                        Update
                                    </v-card-title>

                                    <v-card-subtitle>
                                        Check if there are any update
                                    </v-card-subtitle>

                                    <v-card-actions>
                                        <v-btn 
                                            block 
                                            text 
                                            link 
                                            color="primary"
                                            @click="checkForUpdates()"
                                        >Update</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                            <!-- End Col -->                              

                            <v-col 
                                lg="3"
                                md="6"
                                sm="4" 
                                cols="6"
                            >
                                <v-card class="custom-card">
                                    <v-card-title>
                                        <v-icon color="primary" class="mr-2">mdi-face-agent</v-icon>
                                        Support
                                    </v-card-title>

                                    <v-card-subtitle>
                                        Get Help with an issue
                                    </v-card-subtitle>

                                    <v-card-actions>
                                        <v-btn 
                                            block 
                                            text 
                                            link 
                                            color="primary"
                                            @click="OpenSupport()"
                                        >Get Support</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                            <!-- End Col -->  

                            <v-col 
                                lg="3"
                                md="6"
                                sm="4" 
                                cols="6"
                            >
                                <v-card class="custom-card">
                                    <v-card-title>
                                        <v-icon color="primary" class="mr-2">mdi-share-variant-outline</v-icon>
                                        Share
                                    </v-card-title>

                                    <v-card-subtitle>
                                        Share the app with your friends                               
                                    </v-card-subtitle>

                                    <v-card-actions>
                                    <ShareNetwork
                                        network="facebook"
                                        url="https://api.wellbookings.com"
                                        title="Say hi to Wellbookings! A brand new, beauty treatment app"
                                        description="Get your beauty treatments done from a convinient app"
                                        quote="Beauty on demand everywhere"
                                        hashtags="wellbookings,beautyEverywhere"
                                    >
                                        <v-btn block text link color="primary">Share</v-btn>
                                    </ShareNetwork>  
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                            <!-- End Col -->  

                        </v-row>

                        <!-- <v-list flat subheader three-line>
                            <v-subheader>Settings</v-subheader>

                            <v-list-item-group v-model="settings" multiple active-class="">
                                <v-list-item>
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action>
                                            <v-checkbox :input-value="active"></v-checkbox>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                            <v-list-item-title>Notifications {{ settings }}</v-list-item-title>
                                            <v-list-item-subtitle>Notify me about updates the app or appointments</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                            </v-list-item-group>

                            <v-list-item @click="getLocation">
                                <v-list-item-content>
                                    <v-list-item-title>Location Reset</v-list-item-title>
                                    <v-list-item-subtitle>{{'Longitude: ' + getCurrentLocation.lng + ' Latitude: ' + getCurrentLocation.lat}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content style="color:grey;">
                                    <v-list-item-title>Support</v-list-item-title>
                                    <v-list-item-subtitle>Get Help with an issue</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content style="color:grey;">
                                    <v-list-item-title>Update</v-list-item-title>
                                    <v-list-item-subtitle>Check for new versions of the App</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>Dark Mode</v-list-item-title>
                                    <v-tooltip v-if="!$vuetify.theme.dark" bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn 
                                        v-on="on" 
                                        color="primary" 
                                        @click="darkMode">
                                            <v-icon class="mr-5" color="yellow">mdi-moon-waxing-crescent</v-icon>
                                            Dark Mode
                                        </v-btn>
                                        </template>
                                        <span>Dark Mode On</span>
                                    </v-tooltip>

                                    <v-tooltip v-else bottom>
                                        <template v-slot:activator="{ on }">
                                        <v-btn 
                                        v-on="on" 
                                        color="primary" 
                                        @click="darkMode">
                                            <v-icon class="mr-5" color="yellow">mdi-white-balance-sunny</v-icon>
                                            Light Mode
                                        </v-btn>
                                        </template>
                                        <span>Dark Mode Off</span>
                                    </v-tooltip>
                                </v-list-item-content>
                            </v-list-item>

                            
                        </v-list>

                        <v-divider class="ma-5"></v-divider>

                        <v-card>
                            <v-list subheader three-line style="background-color:teal;">
                                <v-subheader>Share</v-subheader>

                                <div>

                                    <ShareNetwork
                                        network="facebook"
                                        url="https://api.wellbookings.com"
                                        title="Say hi to Wellbookings! A brand new, beauty treatment app"
                                        description="Get your beauty treatments done from a convinient app"
                                        quote="Beauty on demand everywhere"
                                        hashtags="wellbookings,beautyEverywhere"
                                    >
                                        <v-list-item >
                                            <v-list-item-content>
                                                <v-list-item-title>Share</v-list-item-title>
                                                <v-list-item-subtitle>Share the app with your friends</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </ShareNetwork>
                                </div>


                            </v-list>
                        </v-card> -->

                    </div>
                </section>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
    import mixinShared from '@/mixins/Shared'

    import {
        mapGetters,
        mapState
    } from 'vuex'

    import { bus } from '../main'

    export default {
        mixins: [mixinShared],
        data() {
            return {
                // Set to true to show location box and reset btn
                showLocation: false,
                location: {},
                clientBookings: {},
                search: '',
                // /settings:[],
                settings: false,
            };
        },
        components: {

        },
        created() {
            //request gps location
        },
        mounted() {

        },

        methods: {
            checkForUpdates() {
                localStorage.clear();
                alert('you already have the newest version');
                
            },
            // Dark Mode Switcher
            darkMode() {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            },
            goToGettingStarted(){
                this.$router.push({
                    name: 'seller-getting-started',
                    params: {
                        singleItem: this.getSellerProfileData.seller
                    }
                });
            },
            goToPreview(){
                this.$router.push({
                    name: 'seller-page',
                    params: {
                        singleItem: this.getSellerProfileData.seller,
                        seller_id: this.getSellerProfileData.seller.id
                    }
                });
            },

            goToMarket() {
                this.$router.push({
                    name: 'market'
                });
            },
            // Get Location 

            changeLocation() {
                bus.$emit('showLocationDialog',true);
            },
            OpenSupport() {
                bus.$emit('showSupportDialog',true);
            },
            getLocation() {

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {

                        console.log('[Home - location]', position);
                        this.location.lat = position.coords.latitude;
                        this.location.lng = position.coords.longitude;
                        this.location.speed = position.coords.speed;
                        this.location.timestamp = position.timestamp;

                        this.$store.dispatch("setLocation", this.location);
                    });
                } else {
                    console.log("Geolocation is not supported by this browser.");
                }


            },
            LocationPicker() {
                console.log('dd vv ff aa');
            }
        },
        computed: {
            ...mapGetters([
                "getCurrentLocation",
                "isLoggedIn",
                "isSellerLoggedIn",
                "getProfileData",
                "getSellerProfileData",
                "getClientBookings"
            ])
        },
        watch: {
            location(val) {
                this.$store.dispatch("setLocation", val);
            },
        }
    };
</script>

<style lang="scss">
    .card-account {
        .v-card__title {
            i {
                border-radius: 2px 0 10px 0;
                -webkit-border-radius: 2px 0 10px 0;
                -moz-border-radius: 2px 0 10px 0;
            }
            .v-chip {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }

    .custom-card {
        .v-card__subtitle {
            min-height: 81px;
        }

        .v-card__actions {
            text-align:center;
            .v-input--selection-controls {
                margin-top: 0;
                padding-top: 0;
                margin: 0 auto;
                .v-input__slot {
                    margin-bottom: 0;
                }
            }
        }

        .v-card__actions {
            a {
                margin: 0 auto;
                width: 100%;
                display: block;
                text-decoration: none;
            }
        }
    }
    .page.page-account {
        align-items: center;
        justify-items: center;
        flex-direction: column;

        .page--top-container {
            text-align: center;
            margin: 2rem auto;

            h1.page--title {
                .v-avatar.account--avatar {
                    margin-right: .5rem;

                    i.v-icon {
                        border: 2px solid;
                        // border-color: rgba(0,0,0,0.3);
                        background: #ebebeb;
                        font-size: 30px;

                    }
                }

                strong {
                    color: var(--v-primary-base);
                }
            }
        }

        .page--main {
            .page--form {
                min-width: 380px;
                max-width: 800px;
                margin: 0 auto;
            }

            .page--no-booking {
                padding: 3rem 0;
                text-align: center;

                .page--description {}
            }
        }
    }
</style>