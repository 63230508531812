<template>
    <div class="bookings-container">
        
            <template v-if="loading">

                <v-sheet
                    class="pa-3"
                >
                    <v-skeleton-loader
                    v-for="(item, i) in 5"
                    :key="i"
                    class="mx-auto"
                    max-width="300"
                    type="list-item-three-line"
                    ></v-skeleton-loader>
                </v-sheet>
            </template>



            <template v-if="!loading">


                <v-select
                v-model="bookingsFilter"
                :items="filters"
                label="Select"
                hint="Select filter"
                persistent-hint
                @change="changeFilter"
                ></v-select>

                <v-list
                    :subheader="false"
                    :three-line="showDescription"
                    :two-line="! showDescription"
                    elevation="10"
                    style="margin-bottom:100px;"
                    >
                    <!-- <v-subheader inset> Your Bookings</v-subheader> -->

                        <v-card
                            v-for="(item, i) in bookings"
                            :key="i"
                        >
                            <template>
   

                                    <v-card  @click="goToBookingDetail(item)">
                                        <v-card-title class="primary--text" v-text="(item.service?item.service.service_name:'Block')"></v-card-title>

                                        <v-card-subtitle 
                                        v-if="showDescription"
                                        v-text="(item.service?item.service.service_decription:'Block')">
                                        </v-card-subtitle>

                                        <v-card-text>
                                        <strong>From : </strong>{{ formatDate(item.booking_from) }} <br/>
                                        <strong>To : </strong>  {{ formatDate(item.booking_to) }} <br/>
                                        <strong>Employee : </strong>{{ item.seller_user.name }} <br/>
                                        <strong>Note : </strong>{{ item.note }}
                                        </v-card-text>
                                    </v-card>

                                    <v-card-actions>

                                        <template v-if="item.status === null">
                                            <!-- <v-chip
                                            class="product-card-label"
                                            text-color="white"
                                            >
                                            <v-avatar left>
                                                <v-icon>mdi-{{ item.prdouct_icon !== null ? 'close': 'check' }}</v-icon>
                                                
                                            </v-avatar>
                                                                                            <v-btn 
                                                                                    small
                                                                                    color="green"
                                                                                    @click="bookingStatus(item.id,'approve')" 
                                                                                    class="btn btn-primary">
                                                                                Accept</v-btn>
                                            {{ itemProduct.deleted_at !== null ? 'Disabled': 'Active' }} 
                                            </v-chip> -->
                                            <div class="btn-container">
                                                <v-btn 
                                                    
                                                    color="green"
                                                    @click="bookingStatus(item.id,'approve')" 
                                                    class="btn btn-primary">
                                                    <v-icon class="mr-1">mdi-check</v-icon>
                                                Accept</v-btn>
                                                <v-btn 
                                                    outlined
                                                    
                                                    color="red"
                                                    @click="bookingStatus(item.id,'reject')" 
                                                    class="btn btn-primary">
                                                    <v-icon class="mr-1">mdi-close</v-icon>
                                                Reject</v-btn>
                                            </div>

                                        </template>
                                        <template v-if="item.status === 'Approved'">
                                            <div class="btn-container">
                                                <v-btn 
                                                    color="green"                                                
                                                    @click="bookingStatus(item.id,'complete')" 
                                                    class="btn btn-primary"
                                                >
                                                <v-icon class="mr-1">mdi-check</v-icon>
                                                Complete
                                                </v-btn>

                                                <v-btn 
                                                    outlined
                                                    
                                                    color="red"
                                                    @click="bookingStatus(item.id,'cancel')" 
                                                    class="btn btn-primary">
                                                <v-icon class="mr-1">mdi-close</v-icon>
                                                Cancel</v-btn>
                                            </div>

                                        </template>


                                        <!-- <v-btn
                                        @click="goToBookingDetail(item)"
                                        icon>
                                            <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                                        </v-btn> -->
                                    </v-card-actions>
                            </template>
                        </v-card>
                        
                </v-list>
                <template v-if="! bookings.length > 0">
                    <v-container>
                        <v-row 
                        justify="center"
                        dense>
                            <v-col
                            class="text-center"
                                cols="12">
                                <v-icon 
                                class="px-5"
                                x-large>mdi-alert-outline</v-icon>
                                <h2 class="pa-5">Sorry, no bookings</h2>
                                <div class="btn-hcontainer">
                                    <v-btn
                                    link
                                    to="/"
                                    x-large
                                    
                                    class="btn btn-browse"
                                    color="primary">

                                        Go To Homepage
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
            </template>
    </div>

</template>


<script>
    var moment = require("moment");

    export default {
        props: {
            bookingsProp: {
                type: Array,
                required: true,
                default() {
                    return []
                }
            },
            loading: {
                type: Boolean,
                required: false
            }
        },
        data() {
            return {
                // loading: false,
                showAvatar: false,
                showDescription: true,
                bookings:{},
                bookingsFilter:["Today"],
                filters:["Today","Up coming","Passed bookings"]
            };
        },
        mounted() {
            //this.loading = false
            console.log('this.bookingsProp',this.bookingsProp);
            this.bookings = this.bookingsProp;
        },
        methods: {
            goToBookingDetail(item){
                this.$emit('goToBookingDetail',{event:{
                    booking:item
                }});
            },
            formatDate(dateString){
                return moment(dateString).format('MMM DD - YYYY');
            },
            changeFilter(){
                this.bookings = [];

                let tempArray = this.bookingsProp;
                let today = new Date();

                if(this.bookingsFilter.includes("Today")){
                    this.bookings = tempArray.filter((item) => moment(item.booking_from).isSame(moment(), 'day'));
                }else if(this.bookingsFilter.includes("Up coming")){
                    this.bookings = tempArray.filter((item) => moment().isBefore(item.booking_from, 'day'));
                }else if(this.bookingsFilter.includes("Passed bookings")){
                    this.bookings = tempArray.filter((item) => moment().isAfter(item.booking_from, 'day'));
                }

            },
            bookingStatus(bookingid, status) {

                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');
                //console.log("sellerToken", sellerToken);

                fetch(appURL + '/api/seller/booking/' + bookingid + '/' +
                        status, {
                    method: 'POST',
                    headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + sellerToken
                    }
                }).then(response => {


                    console.log(response);

                    if (response.status === 200) {
                        response.json().then(result => {
                            console.log('result', result);
                            //this.getBookings();
                        });
                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                    } else if (response.status === 422) {
                        //failed logn
                        alert('Not Authenticated');
                    } else {
                    // unkown error
                    console.log('Unexpected error with API server 398');
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                        this.processing = false;
                        alert(error)
                });

            },
        },
    }
</script>

<style lang="scss">
    // End custom common variables
    .bookings-container {
        .v-list {
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            padding: .25rem 0;
            margin-bottom: 1rem;
            // max-height: 500px;
            // overflow-y: auto;
            &.v-list--two-line {}

            .v-card {
                border-bottom: 1px solid rgba(0,0,0,.12);
                &:last-child {
                    border-bottom: 0;
                }
                .v-card__content {
                    .v-card__title {
                        font-weight: 500;
                    }
                }
            }

            &:empty {
                padding: 0;
            }
        }
    }

    .btn-container {
        // display: flex;
        // flex-direction: row-reverse;
        .btn {
            // order: 2;
            display: block;
            margin: 1rem;
        }
    }
    // Custom styles
</style>