import Vue from 'vue';
import Router from 'vue-router';
import store from '../store/index.js'

import CookiePolicy from '../views/Policy/CookiePolicy.vue';
import Disclaimer from '../views/Policy/Disclaimer.vue';
import PrivacyPolicy from '../views/Policy/PrivacyPolicy.vue';
import TermsAndConditions from '../views/Policy/TermsAndConditions.vue';


import Home from '../views/Home.vue';
import Update from '../views/Update.vue';
import Maps from '../views/Map.vue';
import LocationPicker from '../views/LocationPicker.vue';
import Explore from '../views/Explore.vue';
import Options from '../views/Options.vue';

import Market from '../views/Market.vue';
import SellerPage from '../views/SellerPage.vue';
import Bookings from '../views/Bookings.vue';
import Account from '../views/Account.vue';
import Login from '../views/Login.vue';
import UserProfile from '../views/User/Profile.vue';
import BookingsDetails from '../views/User/BookingsDetails.vue';
import BookingReview from '../views/User/BookingReview.vue';

import SellerLogin from '../views/Seller/Login.vue';
import SellerProfile from '../views/Seller/Profile.vue';
import SellerGettingStarted from '../views/Seller/GettingStartedWizard.vue';

import AddEmployee from '../views/Seller/AddEmployee.vue';
import SellerAddUpdateService from '../views/Seller/AddService.vue';
import SellerDashBoard from '../views/Seller/SellerDashboard.vue';
import SellerServices from '../views/Seller/Services.vue';
import SellerBookings from '../views/Seller/Bookings.vue';
import SellerReports from '../views/Seller/SellerReports.vue';
import SellerAddBooking from '../views/Seller/SellerAddBooking.vue';
import SellerBooking from '../views/Seller/SellerBooking.vue';


import MakeBooking from '../views/MakeBooking.vue';
import CheckoutConfirmation from '../views/CheckoutConfirmation.vue';

Vue.use(Router);

// export default new Router({
//   mode: 'history',
//   base: process.env.BASE_URL,
//  routes: [

const routes = [{
            path: '/CookiePolicy',
            name: 'CookiePolicy',
            component: CookiePolicy,
            props: true,
            meta: {
                requiresAuth: false
            }
        }, {
            path: '/Disclaimer',
            name: 'Disclaimer',
            component: Disclaimer,
            props: true,
            meta: {
                requiresAuth: false
            }
        }, {
            path: '/PrivacyPolicy',
            name: 'PrivacyPolicy',
            component: PrivacyPolicy,
            props: true,
            meta: {
                requiresAuth: false
            }
        }, {
            path: '/TermsAndConditions',
            name: 'TermsAndConditions',
            component: TermsAndConditions,
            props: true,
            meta: {
                requiresAuth: false
            }
        }, {
            path: '/',
            name: 'home',
            component: Home,
            props: true,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/update',
            name: 'update',
            component: Update,
            props: true,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/map',
            name: 'map',
            component: Maps,
            props: true,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/LocationPicker',
            name: 'LocationPicker',
            component: LocationPicker,
            props: true,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/explore',
            name: 'explore',
            component: Explore,
            props: true,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/options',
            name: 'options',
            component: Options,
            props: true,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/seller-page/:seller_id',
            name: 'seller-page',
            component: SellerPage,
            props: true,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/bookings',
            name: 'bookings',
            component: Bookings,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/account',
            name: 'account',
            component: Account,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            props: true,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/user-profile',
            name: 'user-profile',
            component: UserProfile,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/booking-details',
            name: 'booking-details',
            component: BookingsDetails,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/booking-review',
            name: 'booking-review',
            component: BookingReview,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/seller-login',
            name: 'seller-login',
            component: SellerLogin,
            props: true,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/seller-profile',
            name: 'seller-profile',
            component: SellerProfile,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/seller-getting-started',
            name: 'seller-getting-started',
            component: SellerGettingStarted,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/add-employee',
            name: 'add-employee',
            component: AddEmployee,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/seller-add-update-service',
            name: 'seller-add-update-service',
            component: SellerAddUpdateService,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/seller-dashboard',
            name: 'seller-dashboard',
            component: SellerDashBoard,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: 'seller-add-booking',
            name: 'seller-add-booking',
            component: SellerAddBooking,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/seller-reports',
            name: 'seller-reports',
            component: SellerReports,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/seller-booking',
            name: 'seller-booking',
            component: SellerBooking,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/seller-services',
            name: 'seller-services',
            component: SellerServices,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/seller-bookings',
            name: 'seller-bookings',
            component: SellerBookings,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/make-booking',
            name: 'make-booking',
            component: MakeBooking,
            props: true,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/market',
            name: 'market',
            component: Market,
            props: true,
            meta: {
                requiresAuth: false
            }
        },
        {
            path: '/order-confirmation',
            name: 'order-confirmation',
            component: CheckoutConfirmation,
            props: true,
            meta: {
                requiresAuth: true
            }
        }
    ]
    //})

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // console.log("beforeEach ROUTER", to.matched.meta.requiresAuth)
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['isLoggedIn'] || store.getters['isSellerLoggedIn']) {
            console.log("beforeEach logged in getProfileData", store.getters['getProfileData'])
            console.log("beforeEach logged in user", store.getters['isLoggedIn'])
            console.log("beforeEach logged in merchant", store.getters['isSellerLoggedIn'])

            //console.log("store.user.getters.isLoggedIn", store.getters['user/isLoggedIn'])
            next()
            return
        }
        next('/login')
    } else {
        //console.log("store.user.getters.isLoggedIn", store.getters['user/isLoggedIn'])

        next()
    }
})

export default router