<template>
    <div class="page-reports page-seller-reports">
        <v-container>
            <h1>Download Report</h1>
            <v-row>
                <v-col cols="12">
                    <MerchantReportsCmp
                        :itemShowReportsTable="true"
                        :itemReports="this.itemListReports"
                    ></MerchantReportsCmp>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
    import mixinShared from '@/mixins/Shared'

    import MerchantReportsCmp from '@/components/widgets/MerchantReports.vue';
    //var moment = require("moment");
    // import VueHtml2pdf from 'vue-html2pdf'

    import {
        mapGetters,
        mapState
    } from 'vuex'

    export default {
        mixins: [mixinShared],
        data() {
            return {
                itemListReports: {},
            };
        },
        components: {
           MerchantReportsCmp
        },
        computed: {
            ...mapGetters([
                "getCurrentLocation",
                "isLoggedIn",
                "isSellerLoggedIn",
                "getProfileData",
                "getSellerProfileData",
                "getSellersBookings",
                "isMobile"
            ]),
        },
        mounted() {

        },
        methods: {
        },
    }
</script>

<style lang="scss" scoped>
</style>