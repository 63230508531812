const categories = [
    {
        translationKey:'category_hair',
        name:'Hair',
        icon:'hair',
        class:'hair',
        classless:'',
        searchValue:'hair',
        subCategory:{

        }
    },
    {
        translationKey:'category_nails',
        name:'Nails',
        icon:'nails',
        class:'nails',
        classless:'',
        searchValue:'nail',
        subCategory:{

        }
    },
    {
        translationKey:'category_pedicure',
        name:'Pedicure',
        icon:'pedicure',
        class:'pedicure',
        classless:'',
        searchValue:'pedicure',
        subCategory:{

        }
    },
    {
        translationKey:'category_body',
        name:'Body',
        icon:'body',
        class:'body',
        classless:'',
        searchValue:'body',
        subCategory:{

        }
    },
    {
        translationKey:'category_waxing',
        name:'Waxing',
        icon:'waxing',
        class:'waxing',
        classless:'',
        searchValue:'waxing',
        subCategory:{

        }
    },
    {
        translationKey:'category_gel_nails',
        name:'Gel Nails',
        icon:'gel_nails',
        class:'gel_nails',
        classless:'',
        searchValue:'gel nails',
        subCategory:{

        }
    },
    {
        translationKey:'category_spa',
        name:'SPA',
        icon:'spa',
        class:'spa',
        classless:'',
        searchValue:'spa',
        subCategory:{

        }
    },
    {
        translationKey:'category_massage',
        name:'Massage',
        icon:'massage',
        class:'massage',
        classless:'',
        searchValue:'massage',
        subCategory:{

        }
    }
];

export default categories;
