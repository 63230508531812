<template>

  <v-bottom-navigation app fixed grow v-model="activeItem" color="primary" class="bottom-navigation">
    <v-btn value="home" :to="{ path: '/'}">
      <span v-show="showText">Home</span>
      <v-icon>mdi-home-outline</v-icon>
    </v-btn>

    <!--v-btn value="map" :to="{ name: 'map'}">
      <span v-show="showText">Map</span>
      <v-icon>mdi-map-marker-radius-outline</v-icon>
    </v-btn-->

    <v-btn value="explore" :to="{ name: 'explore'}">
      <span v-show="showText">Explore</span>

            <v-img
            max-height="50"
            max-width="50"
            src="/img/icons/manifest-icon-192.maskable.png"
            ></v-img>
      <!--v-icon>mdi-spa</v-icon-->
    </v-btn>

    <!--v-btn value="market" :to="{ name: 'market'}">
      <span v-show="showText">Favourites</span>
      <v-icon>mdi-heart</v-icon>
    </v-btn-->
   
    <v-btn value="options" :to="{ name: 'options'}">
      <span v-show="showText">Options</span>
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
  import {
    mapGetters
  } from 'vuex'

  export default {
    name: 'BottomNav',
    data() {
      return {
        showText: false,
        activeItem: 'top'
      }
    },
    computed: {
      ...mapGetters(["isLoggedIn", "isSellerLoggedIn"])
    },
    methods: {
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      doNothing() {}

    },
  }
</script>

<style lang="scss" scoped>
  .v-bottom-navigation.bottom-navigation {
    min-height: 80px;
    height: 80px !important;
    box-shadow: 0 0px 22px 0 rgba(0, 0, 0, 0.4);

    .v-btn {
      i {
        font-size: 30px;

        &.mdi-home-outline {
          font-size: 35px;
        }

        &.mdi-spa {

          font-size: 40px;
          border: 4px solid rgba(0, 0, 0, 0.2);
          border-top: 0;
          border-radius: 50%;
          padding: .5rem;
        }
      }

      &.v-btn--active {
        i {
          &.mdi-spa {
            border: 4px solid;
            border-top: 0;
          }
        }
      }
    }

    .v-btn:not(.v-btn--active) {
      color: rgba(0, 0, 0, 0.5);
    }
  }
</style>