<template>
        <v-container>
            <v-row>
                <v-col cols="12">
                    <section class="page page-account">
                            <div class="page--top-container">
                            </div>
                            <div class="page--main">

                                <template v-if="sellersBookings.length">
                                    
                                    <p class="page--description text-center">Your last Booking on wellbookings</p>

                                    <BookingsListMerchant
                                    :bookingsProp="getSellersBookings"
                                    :loading="bookingLoading"
                                    ></BookingsListMerchant>
<!-- 
                                    <div class="Profile-btn"
                                        v-if="showLocation"
                                    >

                                        <div>Location</div>
                                        <template v-if="getCurrentLocation">
                                        <div>{{'Longitude: ' + getCurrentLocation.lng + ' Latitude: ' + getCurrentLocation.lat}}</div>
                                        </template>
                                            <v-btn @click="getLocation" class="btn btn-primary">Reset
                                            </v-btn>
                                    </div> -->

                                </template>
                                <template v-else>
                                    <div class="page--no-booking">
                                        <p class="page--description">No booking</p>

                                        <v-btn 
                                            text
                                            @click="getBookings()"
                                            outlined
                                            class="btn btn-browse"
                                            color="primary"
                                        >Refresh bookings
                                        </v-btn> 
                                    </div>
                                </template>

                            </div>

                    </section>
                </v-col>
            </v-row>
        </v-container>
</template>

<script>
    import mixinShared from '@/mixins/Shared'
    import BookingsListMerchant from '@/components/widgets/BookingsListMerchant.vue'

    import {
        mapGetters,
        mapState
    } from 'vuex'

    const httpModule = require("http");

    export default {
        mixins: [mixinShared],
        data() {
            return {
                sellersBookings: [],
                showLocation: true,
                bookingLoading : true
            };
        },
        components: {
            BookingsListMerchant
        },        
        computed: {
            ...mapGetters([
                "getCurrentLocation", 
                "isLoggedIn", 
                "isSellerLoggedIn", 
                "getProfileData",
                "getSellerProfileData",
                "getSellersBookings"
            ]),
            getServices() {
                console.log('getSellerProfileData', this.getSellerProfileData.seller.services);
                return this.getSellerProfileData.seller.services
            }
        },
        mounted() {
            console.log("SellerDashboard --- mounted")
            if (!this.isSellerLoggedIn) {
                this.goToSellerLogin(true);
            } else {
                this.getBookings();
                this.getSellersBookings
            }                
        },
        methods: {
            getBookings() {

                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');

                fetch(appURL + '/api/seller/bookings', {
                    method: 'POST',
                    headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + sellerToken
                    }
                    // body: JSON.stringify({
                    //     ...this.service
                    // })
                }).then(response => {


                    console.log(response);

                    if (response.status === 200) {

                    response.json().then(result => {
                            console.log('result', result);

                            this.sellersBookings = result.success;
                            this.$store.dispatch('setSellersBookings', result.success);
                             this.bookingLoading = false

                    });
                    
                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                    } else if (response.status === 422) {
                        //failed logn
                        alert('Not Authenticated');
                    } else {
                    // unkown error
                    console.log('Unexpected error with API server 398');
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                        this.processing = false;
                        alert(error)
                });

            },
        },
    }
</script>

<style lang="scss" scoped>
    // End custom common variables

    // Custom styles
    .products-list-container {
        border-top: 1px solid rgba(0,0,0,0.2);
        padding: 1rem 0;
        .products-list-top {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: space-between;
            padding: 1rem;
            .page--subtitle {
                font-weight: bold;
            }
            .btn {
                font-size: 120%!important;
                font-weight: bold;
            }
        }
    }
</style>