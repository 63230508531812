<template>
    <div class="notification-box">
        <template v-if="messageVisible">
            <v-alert 
            :type="(messageType) ? messageType : 'success'"
            dismissible
            border="top"
            colored-border
            text
            elevation="12">
            {{ messageText }}
            </v-alert>
        </template>
    </div>
</template>

<script>
    import mixinGeneral from '@/mixins/Shared'

    export default {
        mixins: [mixinGeneral],
        props: {
            messageText: {
                //type: String,
                required: false,
            },
            messageType: {
                //type: String,
                required: false,
            },
            messageVisible: {
                type: Boolean,
                required: false,
            }
        },
        data() {
            return {
            }
        },
        mounted() {}
    }

</script>