<template>
    <div class="page-favourites">
        <v-container>
            <h1 class="page-title">
                Favourites
            </h1>
            <v-row v-if="getFavourities.length" dense>
                <v-col 
                cols="12" 
                sm="12" 
                md="6" 
                lg="4" 
                v-for="fav in getFavourities"
                :key="fav.id"
                @click="goToItemDetails(fav)">
                    <ProductCardFavourite :item="fav" :showServices="false"></ProductCardFavourite>
                </v-col>
                <!-- {{ this.searchResult }} -->
            </v-row>  
            <v-row v-else>              
                    <!-- <v-col cols="12">
                        <label>Favourities</label>
                        <div 
                        v-for="fav in getFavourities" 
                        :key="fav.id" 
                        class="sellerFav" 
                        @click="goToItemDetails(fav)">{{fav.name}}</div>
                    </v-col> -->
                    <v-col cols="12">
                        <div class="page--no-results">
                        <v-icon x-large color="primary">mdi-alert-decagram-outline</v-icon>
                        <p class="page--subtitle page--no-services">
                            Sorry, no favourites in your list. 
                        </p>
                        <div class="btn-container">
                            <v-btn 
                            @click="goToExplore()" 
                            class="btn btn-primary" 
                            outlined
                            x-large
                            color="primary"
                            >
                                Go to Explore
                            </v-btn>
                        </div>
                        </div>
                    </v-col>                    
                </v-row>
        </v-container>
    </div>
</template>

<script>
    import mixinShared from '@/mixins/Shared'
    import ProductCardFavourite from '../components/products/ProductCardFavourite.vue';

    import {
        mapGetters,
        mapState
    } from 'vuex'

    export default {
        mixins: [mixinShared],
        data() {
            return {
            };
        },
        components: {
            ProductCardFavourite
        },        
        computed: {
            ...mapGetters(["getFavourities"]),
        },
        mounted() {              
        },
        methods: {
            goToItemDetails(obj) {
                console.log("goToItemDetails")

                this.$router.push({
                    name: 'seller-page',
                    params: {
                        singleItem: obj,
                        seller_id: obj.id
                    }
                });

            }
        },
    }
</script>

<style lang="scss" scoped>
.sellerFav{
    margin:10px;
    background: purple;
    color:white;
}
</style>