<template>
    <div>
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>
            <v-row>
            <v-col cols="12" xs="8" sm="8">
                <v-row>
                    <v-col cols="12">
                        <Calender @selectedDate="dateSelected" />

                        <!-- <v-select outlined filled  required v-model="booking.seller_user_id" :items="employees" item-text="value"
                            item-value="key" label="Select employee" persistent-hint></v-select> -->

                        <v-list subheader>
                            <v-subheader>Employee</v-subheader>

                            <v-list-item-group
                                v-model="SelectedSellerUserIndex"
                                color="primary"
                            >
                                <v-list-item v-for="employee in employees" :key="employee.email" v-show="employee.active===1">
                                    <v-list-item-avatar >
                                        <v-img v-if="employee.avatar !== null" :alt="`${employee.name} avatar`"
                                            :src="'https://api.wellbookings.com/storage/sellerProfileImages/'+employee.avatar">
                                        </v-img>
                                        <v-img v-else :alt="`${employee.name} avatar`"
                                            src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png">
                                        </v-img>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title v-text="employee.name.slice(0, 20)"></v-list-item-title>
                                        <v-list-item-subtitle>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>


                                </v-list-item>
                            </v-list-item-group>

                        </v-list>


                        <v-list subheader>
                            <v-subheader>Services</v-subheader>

                            <v-list-item-group
                                v-model="SelectedServiceIndex"
                                color="primary"
                            >
                                <v-list-item v-for="serv in getServices" :key="serv.id">
                                    <v-list-item-avatar >
                                        <div style="height:60px;width:10px;background:teal;"></div>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title v-text="serv.service_name.slice(0, 25)"></v-list-item-title>
                                        <v-list-item-subtitle v-text="serv.service_decription.slice(0, 200)">
                                        </v-list-item-subtitle>
                                        <div style="font-weight:bold;font-size: 15px;">{{serv.price}}{{serv.currency}}</div>
                                    </v-list-item-content>


                                </v-list-item>
                            </v-list-item-group>

                        </v-list>

<!-- 
                        <v-select outlined filled  required v-model="SelectedService" :items="getServices" item-text="service_name"
                            item-value="id" label="Select service" persistent-hint return-object @change="checkForavailability"></v-select> -->

                    </v-col>
                </v-row>
                <v-row>


                    <v-col cols="12" xs="12" sm="12" v-if="!customTime">
                        <v-radio-group v-model="booking.time" row>

                            <span v-for="avail in availability" :key="avail.start">
                                <v-radio :label="avail.start" color="teal darken-3"
                                    :value="avail.start" :disabled="!avail.available">
                                </v-radio>

                            </span>

                        </v-radio-group>
                    </v-col>

                            <v-switch
                            v-model="customTime"
                            label="Use a Custom Time"
                            ></v-switch>



                    <v-col cols="12" v-if="customTime">

                        <label>Time from :</label>
                        <v-dialog ref="timedialog1" v-model="timeModal1" :return-value.sync="timeFrom" persistent
                            width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field outlined filled  v-model="timeFrom" label="Time from"
                                    prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on">
                                </v-text-field>
                            </template>
                            <v-time-picker v-if="timeModal1" v-model="timeFrom" full-width format="24hr" :min="getWorkHours.start">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="timeModal1 = false">
                                    Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.timedialog1.save(timeFrom)">
                                    OK
                                </v-btn>
                            </v-time-picker>
                        </v-dialog>


                        <label>Time To :</label>
                        <v-dialog ref="timedialog2" v-model="timeModal2" :return-value.sync="timeTo" persistent
                            width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field outlined filled  v-model="timeTo" label="Time to"
                                    prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on" style="">
                                </v-text-field>
                            </template>
                            <v-time-picker v-if="timeModal2" v-model="timeTo" full-width format="24hr" :min="timeFrom">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="timeModal2 = false">
                                    Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.timedialog2.save(timeTo)">
                                    OK
                                </v-btn>
                            </v-time-picker>
                        </v-dialog>



                    </v-col>
                </v-row>
                <v-row>

                    <v-switch
                    v-model="block_booking"
                    label="Block Timeframe"
                    ></v-switch>

                </v-row>
                <v-row>

                    <v-switch
                    v-model="booking.come_to_me"
                    label="On site Booking"
                    ></v-switch>

                        <v-col cols="12" v-if="booking.come_to_me">

                            <v-col cols="12" xs="12" sm="12" class="py-0">
                                <p class="page--description">Address to meet</p>
                                <div class="note-container">
                                    <v-textarea outlined height="100" class="input--big-icon"
                                        v-model="booking.additional.meetupLocation"
                                        prepend-icon="mdi-note-outline" label="Address to meet">
                                    </v-textarea>
                                </div>
                            </v-col>
                                <v-col 
                                    cols="12"
                                    xs="12"
                                    sm="12"
                                    md="12"
                                    lg="12">
                                        Please tap on map to select a location.
                                    <v-row id="mapContainerDetectWidthHeight">
                                        <LocationPicker
                                        :lat="getSellerProfileData.lat"
                                        :lng="getSellerProfileData.lng"
                                        @setLocation="setGPSLocation"
                                        ></LocationPicker>
                                    </v-row>
                                </v-col>

                        </v-col>

                </v-row>

            </v-col>
            <v-col cols="12" xs="4" sm="4">
                <v-row>
                    <v-col cols="12">
                        <SellerMyCustomers
                        @CustomerSelected="CustomerSelected"
                        ></SellerMyCustomers>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field v-model="booking.invoice.total" 
                        outlined filled color="deep-purple" label="Price">
                        </v-text-field>
                    </v-col>


                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-textarea
                        outlined 
                        filled
                        v-model="booking.note" 
                        name="note" 
                        label="Note" 
                        value="" 
                        hint="Note"
                        ></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-btn @click="createBooking()" class="btn btn-primary btn-submit" x-large block
                            color="primary">
                            Add Booking
                        </v-btn>

                        <!-- <v-btn @click="goBack" class="btn btn-primary btn-back" x-large block outlined color="primary">
                            Back
                        </v-btn> -->
                    </v-col>
                </v-row>

            </v-col>
            </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
    import mixinShared from '@/mixins/Shared'
    import Calender from '@/components/Calender.vue';
    import SellerMyCustomers from './SellerMyCustomers.vue';
    import LocationPicker from "@/components/LocationPicker"
    var moment = require("moment");

    import {
        mapGetters,
        mapState
    } from 'vuex'



    export default {
        mixins: [mixinShared],
        data() {
            return {

                timeModal1: false,
                timeModal2: false,
                valid: true,
                employees: [],
                dateFrom: moment().format('YYYY-MM-DD'),
                timeFrom: '',
                timeTo: '',
                customTime:false,
                blockBooking:false,

                availability: [],
                SelectedServiceIndex:null,
                SelectedSellerUserIndex:null,
                SelectedService:{},
                booking: {
                    come_to_me:false,
                    seller_service_id:null,
                    time: null,
                    block_booking: 0,
                    seller_id: '',
                    seller_user_id: '',
                    booking_from: '',
                    booking_to: '',
                    note: '',
                    status: 1,
                    additional: {
                        block_booking: {
                            clientName: '',
                            clientEmail: '',
                            clientPhone: ''
                        }
                    },
                    invoice: {
                        total: 0
                    }
                }
            };
        },
        components: {
            Calender,
            SellerMyCustomers,
            LocationPicker,
        },
        computed: {
            ...mapGetters([
                "getCurrentLocation",
                "isLoggedIn",
                "isSellerLoggedIn",
                "getProfileData",
                "getSellerProfileData",
                "getSellersBookings",
                "isMobile"
            ]),
            getServices() {
                console.log('getSellerProfileData', this.getSellerProfileData.seller.services);
                return this.getSellerProfileData.seller.services
            },
            getWorkHours() {
                return this.getSellerProfileData.seller.working_hours;
            }
        },
        watch:{
            SelectedServiceIndex(){

                this.SelectedService = this.getServices[this.SelectedServiceIndex];
                this.booking.seller_service_id = this.SelectedService.id;
                this.booking.invoice.total = this.SelectedService.price;
                this.checkForavailability();
            },
            SelectedSellerUserIndex(){
                this.booking.seller_user_id = this.employees[this.SelectedSellerUserIndex].id;

                this.checkForavailability();
            }
        },
        mounted() {
            this.booking.seller_id = this.getSellerProfileData.seller.id;
            this.users = this.getSellerProfileData.seller.users;
            this.employees = this.users;

        },
        methods: {

            setGPSLocation(location){
                this.booking.additional.meetuplat = location.lat;
                this.booking.additional.meetuplng = location.lng;
            },
            CustomerSelected(item) {
                this.booking.additional.block_booking.clientName = item.name;
                this.booking.additional.block_booking.clientPhone = item.phone;
                this.booking.additional.block_booking.clientEmail = item.email;
            },
            getAvailability() {
                console.log('getting availability');
                    console.log('this.booking.seller_user_id',this.booking.seller_user_id);
                    console.log('this.booking.seller_service_id',this.booking.seller_service_id);
                    console.log('this.booking.seller_id',this.booking.seller_id);

                var appURL = localStorage.getItem('appURL');
                var token = localStorage.getItem('sellerToken');

                if(this.booking.seller_user_id >0 && this.booking.seller_service_id  >0 && this.booking.seller_id  >0){


                    //get availability times
                    fetch(appURL + '/api/seller/booking/getAvailabilityTimeSlotes', {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + token
                        },
                        body: JSON.stringify({
                            seller_user_id: this.booking.seller_user_id,
                            seller_service_id: this.booking.seller_service_id,
                            seller_id: this.booking.seller_id,
                            additional: this.booking.additional,
                            invoice: {
                                total: this.SelectedService.price,
                                currency: this.SelectedService.currency
                            },
                            date: this.booking.date
                        })
                    }).then(response => {
                        console.log("availability RESULT", response);

                        if (response.status === 200) {
                            response.json().then(result => {
                                console.log('result', result.success);
                                this.availability = result.success;
                            });

                        } else {
                            // unkown error
                            //alert('please login again !!');
                            this.goToLogin(true,true);
                            this.$emit('closeDialog', true);
                            console.log('Unexpected error with API server 398');
                        }

                    }).catch((error) => {
                        console.log('[GPSLocation http request error]', error);
                        this.processing = false;
                        alert(error)
                    });


                }


            },
            checkForavailability(){
                this.getAvailability();
            },
            selectDate(val) {
                this.booking.date = moment(val).format('YYYY-MM-DD');
                //this.selectedDate = val;
                this.getAvailability();
            },
            createBooking() {


                if(this.customTime){
                    this.booking.booking_from = this.dateFrom + ' ' + this.timeFrom;
                    this.booking.booking_to = this.dateFrom + ' ' + this.timeTo;
                }else{
                    var timestring = this.dateFrom + ' ' + this.booking.time
                    this.booking.booking_from = timestring;
                    this.booking.booking_to = moment(timestring).add(this.SelectedService.time_interval, 'm').format('YYYY-MM-DD HH:mm');
                }

                this.booking.come_to_me = (this.booking.come_to_me?1:0);
                this.booking.block_booking = (this.blockBooking?1:0);

                var appURL = localStorage.getItem('appURL');
                var token = localStorage.getItem('sellerToken');


                console.log('bookingobj', JSON.stringify(this.booking));

                fetch(appURL + '/api/seller/booking/add', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + token
                    },
                    body: JSON.stringify({
                        ...this.booking,
                        invoice: {...this.booking.invoice},
                        additional:{...this.booking.additional}
                    })
                }).then(response => {
                    console.log("booking RESULT", response);

                    let emptyCart = {}

                    if (response.status === 200) {
                        console.log('booking is created !');
                        this.$router.go(-1);
                        this.goToSuccess(true)

                        response.json().then(result => {
                            console.log('result', result);

                        });

                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                    } else if (response.statusCode === 422) {
                        //failed logn
                        alert(
                            'The following error happened Username or email already taken or password length is to short.'
                        );
                        console.log('------------------------');
                        console.log(response);
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 398');
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                    this.processing = false;
                    alert(error)
                });
            },
            dateSelected(date) {
                this.dateFrom = moment(date).format('YYYY-MM-DD');
            }

        },
    }
</script>

<style scoped>
.v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 16px;
    background-color: #e1f3f1;
    border-radius: 10px;
    padding: 3px;
    margin: 3px;
}
.v-radio--is-disabled {
    pointer-events: none;
    cursor: default;
    background-color: gray!important;
}
.v-input--selection-controls__input .v-icon {
    color:white!important;
}
.v-application .teal--text.text--darken-3 {
    color: #ffffff !important;
    caret-color: #ffffff !important;
}
.v-radio > .v-label {
    color: white!important;
}
</style>