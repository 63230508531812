<template>
    <!-- <v-container>
        <v-row>
            <v-col cols="12"> -->
                <section class="page page-account page-account-seller">


                    <ServiceDialog></ServiceDialog>
                    <EmployeeDialog></EmployeeDialog>

                    <!-- <div class="page--top-container"> -->
                        <AlertMessage 
                            :messageVisible="messageDetails.visible" 
                            :messageText="messageDetails.text"
                            :messageType="messageDetails.type"
                        ></AlertMessage>
                    <!-- </div>                     -->
                    <div class="page--main">
                        <!-- {{ seller.working_hours }} -->
                        <v-tabs 
                        v-model="tab"
                        background-color="teal"
                        centered
                        dark
                        icons-and-text
                        >
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#tab-0">
                                Information
                                <v-icon>mdi-account-circle</v-icon>
                            </v-tab>

                            <v-tab href="#tab-1">
                                Images
                                <v-icon>mdi-camera</v-icon>
                            </v-tab>

                            <v-tab href="#tab-2">
                                Hours
                                <v-icon>mdi-clock-outline</v-icon>
                            </v-tab>

                            <v-tab href="#tab-3">
                                Employees
                                <v-icon>mdi-account-multiple</v-icon>
                            </v-tab>

                            <v-tab href="#tab-4">
                                Services
                                <v-icon>mdi-room-service-outline</v-icon>
                            </v-tab>

                            <v-tab href="#tab-5">
                                Payment
                                <v-icon>mdi-credit-card-outline</v-icon>
                            </v-tab>

                            <!-- <v-tab href="#tab-5">
                                Account
                                <v-icon>mdi-account-check-outline</v-icon>
                            </v-tab> -->

                        </v-tabs>

                        <v-tabs-items class="pt-5" v-model="tab">
                            <v-tab-item :value="'tab-0'">
                                <div class="page--form">
                                    <v-container>
                                        <v-row>
                                            <v-col 
                                                cols="12"
                                                xs="12"
                                                sm="12"
                                                md="6"
                                                lg="6">
                                                <v-row dense>
                                                    <v-col 
                                                        cols="12"
                                                        xs="12"
                                                        sm="12"
                                                        md="6"
                                                        lg="6"
                                                    >
                                                        <v-text-field outlined height="50" class="input--big-icon" id="username"
                                                            label="Store name" v-model="seller.name"
                                                            name="account-username" prepend-icon="mdi-account-outline"
                                                            type="text"></v-text-field>
                                                    </v-col>
                                                    <!-- Enc Col -->
                                                    <v-col 
                                                        cols="12"
                                                        xs="12"
                                                        sm="12"
                                                        md="6"
                                                        lg="6"
                                                    >
                                                        <v-text-field outlined height="50" class="input--big-icon" id="email"
                                                            label="Email" v-model="seller.email" name="account-email"
                                                            prepend-icon="mdi-email-outline" type="text"></v-text-field>
                                                    </v-col>
                                                    <!-- Enc Col -->
                                                    <v-col cols="12">
                                                        <v-text-field outlined height="50" class="input--big-icon" id="street"
                                                            label="Street" v-model="seller.street" name="account-street"
                                                            prepend-icon="mdi-domain" type="text"></v-text-field>
                                                    </v-col>
                                                    <!-- Enc Col -->
                                                    <v-col cols="12">
                                                        <v-text-field outlined height="50" class="input--big-icon" id="city"
                                                            label="City" v-model="seller.city" name="account-city"
                                                            prepend-icon="mdi-domain" type="text"></v-text-field>
                                                    </v-col>
                                                    <!-- Enc Col -->
                                                    <v-col cols="12">
                                                        <v-text-field outlined height="50" class="input--big-icon" id="country"
                                                            label="Country" v-model="seller.country" name="account-country"
                                                            prepend-icon="mdi-domain" type="text"></v-text-field>
                                                    </v-col>
                                                    <!-- Enc Col -->

                                                    <v-col 
                                                        cols="12"
                                                        xs="12"
                                                        sm="12"
                                                        md="6"
                                                        lg="6"
                                                    >
                                                        <v-text-field outlined height="50" class="input--big-icon" id="zip_code"
                                                            label="Zip Code" v-model="seller.zip_code" name="account-zip_code"
                                                            prepend-icon="mdi-domain" type="text"></v-text-field>
                                                    </v-col>
                                                    <!-- Enc Col -->
                                                    <v-col 
                                                        cols="12"
                                                        xs="12"
                                                        sm="12"
                                                        md="6"
                                                        lg="6"
                                                    >
                                                        <v-text-field outlined height="50" class="input--big-icon" id="phone"
                                                            label="Phone" v-model="seller.phone" name="account-phone"
                                                            prepend-icon="mdi-phone-outline" type="text"></v-text-field>
                                                    </v-col>
                                                    <!-- Enc Col -->                                        

                                                    <v-col cols="12">
                                                        <v-textarea outlined height="150" class="input--big-icon"
                                                            id="description" label="Description" v-model="seller.description"
                                                            name="account-description" prepend-icon="mdi-pen"></v-textarea>
                                                    </v-col>

                                                    <!-- <v-col cols="12">
                                                        <div class="btn-container">
                                                            <v-btn @click="setCurrentLocationSeller" class="btn btn-primary btn-submit"
                                                                x-large block color="primary">
                                                                Update Information
                                                            </v-btn>

                                                        </div>
                                                    </v-col> -->
                                                </v-row>
                                            </v-col>
                                            <v-col 
                                                cols="12"
                                                xs="12"
                                                sm="12"
                                                md="6"
                                                lg="6">
                                                <v-row id="mapContainerDetectWidthHeight">
                                                    Please tap on map to select a location.
                                                    <LocationPicker
                                                    v-if="loaded"
                                                    :lat="seller.lat"
                                                    :lng="seller.lng"
                                                    @setLocation="setGPSLocation"
                                                    ></LocationPicker>
                                                </v-row>
                                            </v-col>


                                            <v-col cols="12">
                                                <div class="btn-container">
                                                    <v-btn @click="UpdateSeller()" class="btn btn-primary btn-submit"
                                                        x-large block color="primary">
                                                        Save Information
                                                    </v-btn>

                                                    <!-- <v-btn @click="setCurrentLocationSeller"
                                                        class="btn btn-primary btn-update-location" outlined x-large
                                                        block color="primary">
                                                        Use current Location
                                                    </v-btn> -->

                                                </div>
                                            </v-col>
                                        </v-row>

                                        <v-divider class="ma-5"></v-divider>

                                        <v-row>
                                            <v-col 
                                                cols="12"
                                                xs="12"
                                                sm="12"
                                                md="6"
                                                lg="6"
                                                class="align-center"
                                            >

                                                <v-card class="custom-card" outlined elevation="12">
                                                    <v-card-title>
                                                        <v-icon color="primary" class="mr-2">mdi-account-outline</v-icon>
                                                        Account Activation
                                                    </v-card-title>

                                                    <v-card-subtitle>
                                                        {{ (getActivated === 1) ? 'Your account is active.' : 'Your account is not active.' }} 
                                                    </v-card-subtitle>

                                                    <v-card-actions>

                                                        <v-btn 
                                                            @click="ActivateAccount()" 
                                                            block
                                                            text
                                                            :color="(getActivated === 0) ? 'green' : 'red'">
                                                            <v-icon class="mr-2">mdi-{{ (getActivated === 0) ? 'check' : 'close' }}</v-icon>
                                                            {{ (getActivated === 0) ? 'Activate Account' : 'Deactivate Account' }}
                                                        </v-btn> 
                                                    </v-card-actions>
                                                </v-card>
                                               
                                                <!-- <p v-else>
                                                    Your account is not active.
                                                </p> -->
                                                <!-- <v-switch
                                                @change="ActivateAccount()"
                                                v-model="getActivated"
                                                :label="`Switch 1: ${getActivated.toString()}`"
                                                ></v-switch> -->

                                                <!-- <v-btn @click="ActivateAccount()" v-if="getActivated === 0" class="btn btn-primary btn-submit"
                                                    x-large block color="primary">
                                                    Activate Account
                                                </v-btn> -->
                                            </v-col>
                                            <!-- Enc Col -->

                                            <v-col 
                                                cols="12"
                                                xs="12"
                                                sm="12"
                                                md="6"
                                                lg="6"
                                                class="center-align align-center"
                                            >
                                                <v-card class="custom-card" outlined elevation="12">
                                                    <v-card-title>
                                                        <v-icon color="primary" class="mr-2">mdi-map-marker-radius-outline</v-icon>
                                                        Your current Location
                                                    </v-card-title>

                                                    <v-card-subtitle>
                                                        {{ 'Longitude: ' + getCurrentLocation.lng }}<br>
                                                        {{ 'Latitude: ' + getCurrentLocation.lat }}
                                                    </v-card-subtitle>

                                                    <v-card-actions>

                                                    <v-btn 
                                                        text 
                                                        block 
                                                        color="primary"
                                                        @click="setCurrentLocationSeller"
                                                    >
                                                        <v-icon left>mdi-check-circle</v-icon>
                                                        Use Current Location
                                                    </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-col>
                                            <!-- Enc Col -->    

                                        </v-row>
                                    </v-container>
                                </div>
                            </v-tab-item>
                            <!-- End Tab Account -->

                            <v-tab-item :value="'tab-1'">
                                <div class="page-form-upload">
                                    <div v-if="uploadProgress > 0">Uploading :</div>
                                    <v-container>
                                        <v-row dense>
                                            <v-col 
                                                cols="12"
                                                xs="12"
                                                sm="9"
                                                md="9"
                                                lg="9"
                                            >
                                                <v-file-input
                                                    v-model="files" 
                                                    accept="image/*" 
                                                    label="File input"
                                                    prepend-icon="mdi-camera" 
                                                    @blur="onFileChange" 
                                                    show-size>
                                                </v-file-input>

                                            </v-col>
                                            <!-- Enc Col -->
                                            <v-col 
                                                cols="12"
                                                xs="12"
                                                sm="3"
                                                md="3"
                                                lg="3"
                                            >                                            
                                                    <v-btn 
                                                        @click="selectImage" 
                                                        class="btn btn-upload"                                                        
                                                        color="primary"
                                                        x-large
                                                        block
                                                        >
                                                        <v-icon class="mr-2">mdi-progress-upload</v-icon>
                                                        Upload
                                                    </v-btn>
                                                    <!-- <v-btn @click="takePicture" class="btn btn-primary">Take with Camera
                                                    </v-btn> -->
                                            </v-col>
                                            <!-- Enc Col -->
                                            <v-col 
                                                cols="12"
                                                xs="12"
                                                sm="12"
                                                md="12"
                                                lg="12"
                                            >   
                                                    <!-- <input type="file" accept="image/*" capture="camera" /> -->
                                                    <v-btn 
                                                    @click="RefreshImages" 
                                                    class="btn btn-primary btn-refresh"
                                                    
                                                    text
                                                    color="primary">
                                                        <v-icon class="mr-2" color="primary">mdi-refresh</v-icon>
                                                        Refresh Image List
                                                    </v-btn>

                                            </v-col>
                                            <!-- Enc Col -->
                                            <v-col cols="12" v-if="seller.images.length > 0">
                                                <v-container fluid>
                                                    <v-row dense>
                                                        <v-col 
                                                        cols="6"
                                                        sm="4"
                                                        md="3"
                                                        lg="2"
                                                        
                                                        v-for="(image, index) in seller.images"
                                                            :key="'sellerImages-'+index">
                                                            <v-card>
                                                                <!-- <v-img 
                                                                    height="100"
                                                                    :src="this.site_domain + image.image_url" /> -->
                                                                <v-img 
                                                                    :alt="seller.name + '_'+ index"
                                                                    height="100"
                                                                    :src="'https://api.wellbookings.com'+image.image_url" 
                                                                >
                                                                    <v-btn 
                                                                        icon
                                                                        color="red" 
                                                                        
                                                                        @click="confirmDelete(image.id)"
                                                                        class="btn btn-delete pa-0">
                                                                        <v-icon>mdi-close</v-icon>
                                                                        
                                                                    </v-btn>  
                                                                </v-img>                                                              
                                                                <!-- <v-card-actions>
                                                                    <v-btn color="red" outlined @click="DeleteImage(image.id)"
                                                                        class="btn btn-primary">
                                                                        <v-icon class="mr-1">mdi-close</v-icon>
                                                                        Delete
                                                                    </v-btn>
                                                                </v-card-actions> -->
                                                            </v-card>
                                                        </v-col>
                                                        <!-- Enc Col -->
                                                    </v-row>                                                    

                                                </v-container>
                                            </v-col>
                                            <!-- Enc Col -->
                                            <!-- <v-col cols="12">
                                                <div class="btn-container">
                                                    <v-btn @click="selectImage" class="btn btn-primary btn-submit"
                                                        x-large block color="primary">Upload Selected Photo
                                                    </v-btn> -->
                                                    <!-- <v-btn @click="takePicture" class="btn btn-primary">Take with Camera
                                                    </v-btn> -->

                                                    <!-- <input type="file" accept="image/*" capture="camera" /> -->
                                                    <!-- <v-btn @click="RefreshImages" class="btn btn-primary btn-refresh"
                                                        x-large block outlined color="primary">
                                                        Refresh
                                                    </v-btn>
                                                </div>
                                            </v-col> -->
                                            <!-- Enc Col -->
                                        </v-row>
                                    </v-container>
                                </div>
                            </v-tab-item>
                            <!-- End Tab Images -->
                            <v-tab-item :value="'tab-2'">
                                <div class="page--form">
                                    <v-container>
                                        <v-row dense>
                                            <v-col 
                                                cols="12"
                                                sm="12"
                                                md="6"
                                                lg="4"
                                            >
                                                <!-- <v-select
                                                outlined
                                                height="50"
                                                class="input--big-icon"                                    
                                                :items="timeSelect"
                                                v-model="seller.working_hours.start"
                                                prepend-icon="mdi-clock-outline"
                                                label="Select Start Time"
                                                :rules="[rules_select.required]"
                                                ></v-select> -->
                                                <v-select outlined height="50" class="input--big-icon"
                                                    :items="timeSelect" v-model="seller.working_hours.start"
                                                    prepend-icon="mdi-clock-outline" label="Select Start Time"
                                                    :rules="[rules_select.required]"></v-select>
                                            </v-col>
                                            <!-- Enc Col -->
                                            <v-col 
                                                cols="12"
                                                sm="12"
                                                md="6"
                                                lg="4"
                                            >
                                                <v-select outlined height="50" class="input--big-icon"
                                                    v-model="seller.working_hours.end" :items="timeSelect"
                                                    prepend-icon="mdi-clock-outline" label="Select End Time"
                                                    :rules="[rules_select.required]"></v-select>
                                            </v-col>
                                            <!-- Enc Col -->

                                            <v-col 
                                                cols="12"
                                                sm="12"
                                                md="6"
                                                lg="4"
                                            >
                                                <v-select outlined height="50" class="input--big-icon"
                                                    v-model="seller.working_hours.break_start" :items="timeSelect"
                                                    prepend-icon="mdi-clock-outline" label="Select Break Start Time"
                                                    :rules="[rules_select.required]"></v-select>
                                            </v-col>
                                            <!-- Enc Col -->
                                            <v-col 
                                                cols="12"
                                                sm="12"
                                                md="6"
                                                lg="4"
                                            >
                                                <v-select outlined height="50" class="input--big-icon"
                                                    v-model="seller.working_hours.break_end" :items="timeSelect"
                                                    prepend-icon="mdi-clock-outline" label="Select Break End Time"
                                                    :rules="[rules_select.required]"></v-select>
                                            </v-col>
                                            <!-- Enc Col -->
                                            <v-col 
                                                cols="12"
                                                sm="12"
                                                md="6"
                                                lg="4"
                                            >
                                                <v-select chips multiple outlined height="50" class="input--big-icon"
                                                    v-model="seller.working_hours.Offdays" :items="weekDays"
                                                    prepend-icon="mdi-clock-outline" label="Closed Days"
                                                    :rules="[rules_select.required]"></v-select>
                                            </v-col>
                                            <!-- Enc Col -->
                                            <v-col cols="12">
                                                <div class="btn-container">
                                                    <v-btn @click="UpdateSeller" class="btn btn-primary btn-submit"
                                                        x-large block color="primary">
                                                        Update Information
                                                    </v-btn>
                                                    <!-- <v-btn 
                                                @click="setCurrentLocationSeller" 
                                                class="btn btn-primary btn-update-location"
                                                outlined
                                                x-large
                                                block
                                                color="primary"
                                                >
                                                Use current Location
                                                </v-btn>                                 -->

                                                </div>

                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </div>
                            </v-tab-item>
                            <v-tab-item :value="'tab-3'">
                                <div class="page--form">
                                    <v-container>
                                        <v-row dense>
                                            <v-col 
                                                cols="12"
                                                sm="12"
                                                md="12"
                                                lg="12"
                                            >
                                                <v-list subheader>
                                                    <v-subheader>Users</v-subheader>

                                                    <v-list-item 
                                                        v-for="user in this.getSellerProfileData.seller.users" 
                                                        :key="user.email" 
                                                        @click="showEmployeeDialog(user)">
                                                        <v-list-item-avatar>
                                                            <v-img v-if="user.avatar !== null" :alt="`${user.name} avatar`"
                                                                :src="'https://api.wellbookings.com/storage/sellerProfileImages/'+user.avatar">
                                                            </v-img>
                                                            <v-img v-else :alt="`${user.name} avatar`"
                                                                src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png">
                                                            </v-img>
                                                        </v-list-item-avatar>

                                                        <v-list-item-content>
                                                            <v-list-item-title v-text="user.name"></v-list-item-title>
                                                            <v-list-item-subtitle v-text="user.email">
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>

                                                        <v-list-item-action>
                                                            <v-btn
                                                                block
                                                                small
                                                                outlined
                                                                color="primary"
                                                                class="btn"
                                                                @click="showEmployeeDialog(user)">
                                                                Edit
                                                            </v-btn>
                                                        </v-list-item-action>


                                                    </v-list-item>
                                                </v-list>
                                            </v-col>

                                            <v-col cols="12">
                                                <div class="btn-container">
                                                    <v-btn 
                                                        @click="showEmployeeDialog()" 
                                                        class="btn btn-primary btn-submit"
                                                        x-large 
                                                        color="primary">
                                                        Add Employee
                                                    </v-btn>
                                                </div>
                                            </v-col>

                                        </v-row>
                                    </v-container>
                                </div>
                            </v-tab-item>
                            <v-tab-item :value="'tab-4'">
                                <div class="page--form">
                                    <v-container>
                                        <v-row dense>


                                            <v-col cols="12">
                                                <section class="page page-account page-merchant-services">
                                                    <!-- <div class="page-account--top">{{ getProfileData }}</div> -->
                                                    <div class="page--top-container">
                                                    </div>
                                                    <div class="page--main">
                                                        <div class="products-list-container">
                                                            <div class="products-list-top">
                                                                <v-container fluid>
                                                                    <v-row dense>
                                                                        <v-col 
                                                                        class="pa-0"
                                                                        cols="6">
                                                                            <h4 class="page--subtitle">Service List</h4>
                                                                        </v-col>
                                                                        <v-col 
                                                                        class="pa-0 text-right"
                                                                        cols="6">
                                                                            <v-btn 
                                                                                text 
                                                                                small
                                                                                @click="showServiceDialog({})" 
                                                                                outlined
                                                                                class="btn btn-browse" 
                                                                                color="primary"
                                                                            >
                                                                                Add Service
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </div>
                                                            <template v-if="getServices.length">
                                                                <v-container fluid>
                                                                    <v-row dense>
                                                                        <v-col cols="12" xs="12" sm="6" md="6" xl="4"
                                                                            v-for="(item, index) in getServices"
                                                                            :key="item + '--' + index">
                                                                            <ProductsListMerchant :itemProduct="item">
                                                                            </ProductsListMerchant>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-container>
                                                            </template>
                                                        </div>

                                                    </div>

                                                </section>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </div>
                            </v-tab-item>
                            <v-tab-item :value="'tab-5'">
                                <div class="page--form">
                                    <v-container>
                                        <v-row dense>
                                            <v-col 
                                                cols="12"
                                                sm="12"
                                                md="6"
                                                lg="4"
                                            >
                                                <v-checkbox
                                                    v-model="seller.payment_methods.accept_card"
                                                    label="Card payment"
                                                ></v-checkbox>
                                                <v-checkbox
                                                    v-model="seller.payment_methods.accept_cash"
                                                    label="Cash"
                                                ></v-checkbox>
                                                <v-checkbox
                                                    v-model="seller.payment_methods.accept_coinbase"
                                                    label="Coinbase"
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="12">
                                                <div class="btn-container">
                                                    <v-btn @click="UpdateSeller" class="btn btn-primary btn-submit"
                                                        x-large block color="primary">
                                                        Update Information
                                                    </v-btn>
                                                </div>

                                            </v-col>

                                        </v-row>
                                    </v-container>
                                </div>
                            </v-tab-item>
                            <!-- <v-tab-item :value="'tab-5'">
                                <div class="page--form">
                                    <v-container>
                                        <v-row dense>
                                            <v-col cols="12">

                                            <p v-if="getActivated === 1">
                                                Your account is active.
                                            </p>
                                            <p v-else>
                                                Your account is not active.
                                            </p>

                                                    <v-btn @click="ActivateAccount()" v-if="getActivated === 0" class="btn btn-primary btn-submit"
                                                        x-large block color="primary">
                                                        Activate Account
                                                    </v-btn>
                                                    <v-btn @click="ActivateAccount()" v-else class="btn btn-primary btn-submit"
                                                        x-large block color="primary">
                                                        Deactivate Account
                                                    </v-btn>
                                                
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </div>
                            </v-tab-item> -->
                            <!-- End Tab Working TImes -->
                        </v-tabs-items>

                    </div>
                    <!-- End Container Main -->

                    <v-dialog
                        class="dialog-delete-img"
                        v-model="modalDelete"
                        persistent
                        max-width="290"
                    >
                        <v-card>
                            <v-card-title class="headline">
                            Confirm Action
                            </v-card-title>
                            <v-card-text>Do you want to delete this image ?</v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="red darken-1"
                                text
                                @click="modalDelete = !modalDelete"
                            >
                                No
                            </v-btn>
                            <v-btn
                                color="green darken-1"
                                outlined
                                left
                                @click="deleteImage()"
                            >
                                Yes
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>                    

                </section>
            <!-- </v-col>
        </v-row>
    </v-container> -->
</template>

<script>
    import {
        mapGetters,
        mapState
    } from 'vuex'
    // import SellerDashboard from "./SellerDashboard";

    import mixinShared from '@/mixins/Shared'
    import ProductsListMerchant from '@/components/products/ProductsListMerchant.vue'
    import AlertMessage from "@/components/widgets/AlertMessage"
    import LocationPicker from "@/components/LocationPicker"
    import ServiceDialog from '@/components/ServiceDialog.vue';
    import EmployeeDialog from '@/components/EmployeeDialog.vue';

    var moment = require("moment");
    import { bus } from '../../main'


    export default {
        mixins: [mixinShared],
        data() {
            return {
                seller: {},
                processing: false,
                img: '',
                uploadProgress: 0,
                files: [],
                tab: null,
                // Array start for time select
                timeSelect: [],
                weekDays: [],
                working_hours: {
                    start: "06:00",
                    end: "",
                    break_start: "",
                    break_end: "",
                    Offdays: []
                },
                payment_methods:{
                    accept_card: false,
                    accept_cash: false,
                    accept_coinbase: false,
                },
                users: [],
                // Message info 
                messageDetails: {
                    visible: false,
                    type: "",
                    text: ""
                },
                // Modal Delete Img 
                modalDelete: false,
                imageDelete: null,
                loaded:false,      
            };
        },
        mounted() {
            setTimeout(() => {
                this.loaded=true;
            }, 500);
        },
        components: {
            AlertMessage,
            ProductsListMerchant,
            LocationPicker,
            ServiceDialog,
            EmployeeDialog
        },
        created() {
            this.arrayTime()
            this.weekArray()

            console.log('this.getSellerProfileData.seller', this.getSellerProfileData.seller);

            this.users = this.getSellerProfileData.seller.users;

            this.seller = {
                ...this.getSellerProfileData.seller
            };

            if (this.seller.working_hours === null) {
                //console.log("this.seller null", this.seller)
                this.seller.working_hours = this.working_hours
            }
            if (this.seller.working_hours.length) {
                //console.log("this.seller haswork hours", this.seller)
                this.seller.working_hours = JSON.parse(this.seller.working_hours)

            }

            if (this.seller.payment_methods === null) {
                //console.log("this.seller null", this.seller)
                this.seller.payment_methods = this.payment_methods
            }
            if (this.seller.payment_methods.length) {
                //console.log("this.seller haswork hours", this.seller)
                this.seller.payment_methods = JSON.parse(this.seller.payment_methods)

            }

        },
        computed: {
            ...mapGetters(["getCurrentLocation", "isLoggedIn", "isSellerLoggedIn", "getProfileData",
                "getSellerProfileData"
            ]),
            getServices() {
                console.log('getSellerProfileData.seller  services', this.getSellerProfileData.seller.services);
                return this.getSellerProfileData.seller.services
            },
            getActivated() {
                return this.seller.activated;
            }

        },
        methods: {


            showEmployeeDialog(employee){
                console.log('employee',employee)
                bus.$emit('showEmployeeDialog',employee);
            },
            showServiceDialog(service){
                console.log('service',service)
                bus.$emit('showServiceDialog',service);
            },
            setGPSLocation(location){
                console.log('setGPSLocation location',location)
                console.log("...this.seller", this.seller);
                this.seller.lat = location.lat;
                this.seller.lng = location.lng;
            },
            EditEmployee(user) {

                console.log("editemployee profile.vue", user);
                this.$router.push({
                    name: 'add-employee',
                    params: {
                        employeeProp: user
                    }
                });
            },
            AddEmployee() {
                console.log("addemployee profile.vue");
                this.$router.push({
                    name: 'add-employee'
                });
            },
            existTime(value) {
                if (value.length && value != null) {
                    return value
                }
                return ''

            },

            weekArray() {
                let weekArray = moment.weekdays()
                console.log("weekArray", weekArray)
                this.weekDays = weekArray
            },

            // Create Array of 24 time with 30 min range
            arrayTime() {
                const items = [];
                new Array(24).fill().forEach((acc, index) => {
                    items.push(moment({
                        hour: index
                    }).format('HH:mm'));
                    items.push(moment({
                        hour: index,
                        minute: 30
                    }).format('HH:mm'));
                })

                this.timeSelect = items;
            },

            onFileChange(e) {
                console.log("onFileChange", e.target.files);
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.files = files[0]

                console.log("files", files[0]);
            },

            takePicture() {
            },
            ActivateAccount() {
                this.processing = true;

                if (this.isSellerLoggedIn) {
                    console.log("this.isSellerLoggedIn", this.isSellerLoggedIn);
                    if (this.seller.id) {
                        //update existing
                        console.log("this.isSellerLoggedIn", this.seller.id);

                        var appURL = localStorage.getItem('appURL');
                        var sellerToken = localStorage.getItem('sellerToken');

                        console.log("(this.seller.activated===0?1:0)", (this.seller.activated === 0?1:0));

                        fetch(appURL + '/api/seller/AddUpdateSellerInformation', {
                            method: 'POST',
                            headers: {
                                "Content-Type": "application/json",
                                "Accept": "application/json",
                                "Authorization": "Bearer " + sellerToken
                            },
                            //credentials: "same-origin",
                            body: JSON.stringify({
                                //...this.seller
                                activated: (this.seller.activated === 0?1:0),

                            })
                        }).then(response => {

                            console.log("response UpdateSeller", response.body)

                            if (response.status === 200) {

                                response.json().then(result => {
                                    console.log('result', result.success);

                                    this.$store.dispatch('updateSellerActivated', result.success.activated);
                                    this.seller.activated = (this.seller.activated === 0?1:0);
                                });

                                

                            } else if (response.status === 401) {
                                //failed logn
                                console.log('Error connecting to API server 398');
                            } else if (response.status === 422) {
                                //failed logn
                                alert('Not Authenticated');
                            } else {
                                // unkown error
                                console.log('Unexpected error with API server 398');
                            }

                        }).catch((error) => {
                            console.log('[GPSLocation http request error]', error);
                            this.processing = false;
                            alert(error)
                        });


                    } else {

                        console.log('else seller', this.seller);
                    }

                }
            },
            selectImage() {

                const formData = new FormData()
                formData.append('image', this.files)

                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');

                fetch(appURL + '/api/seller/images', {
                    method: 'POST',
                    headers: {
                        "Authorization": "Bearer " + sellerToken
                    },
                    body: formData
                }).then(response => {

                    console.log(response);


                    if (response.status === 200) {

                        response.json().then(result => {
                            console.log('result', result.success);

                            // Message Info
                            let info = {
                                visible: true,
                                text: "Success, image uploaded!",
                                type:"success"
                            }
                            this.messageBoxCustom(info)  

                            //alert('image uploaded');
                            this.RefreshImages();
                        });

                    } else if (response.status === 401) {

                        // Message Info
                        let info = {
                            visible: true,
                            text: "Error connecting to API server 398",
                            type:"error"
                        }
                        this.messageBoxCustom(info) 

                    } else if (response.status === 422) {
                        // Message Info
                        let info = {
                            visible: true,
                            text: "Not Authenticated",
                            type:"error"
                        }
                        this.messageBoxCustom(info)  

                       
                    } else {
                        // Message Info
                        let info = {
                            visible: true,
                            text: "Unexpected error with API server 398",
                            type:"error"
                        }
                        this.messageBoxCustom(info)                         
                    }

                }).catch((error) => {
                    //console.log('[GPSLocation http request error]', error);
                    this.processing = false;
                    // Message Info
                    let info = {
                        visible: true,
                        text: "[GPSLocation http request error]" + error,
                        type:"error"
                    }
                    this.messageBoxCustom(info)                     
                    //alert(error)
                });
            },

            RefreshImages() {

                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');
                fetch(appURL + '/api/seller/images/all', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + sellerToken
                    },
                    body: JSON.stringify({
                        "seller_id": this.getSellerProfileData.seller.id
                    })
                }).then(response => {
                    console.log("RefreshImages", response);
                    if (response.status === 200) {

                        response.json().then(result => {
                            console.log('RefreshImages result', result);
                            this.seller.images = result.success.images;
                            this.$store.dispatch('updateImages', result.success.images);

                        });

                    } else if (response.status === 401) {

                        // Message Info
                        let info = {
                            visible: true,
                            text: "Error connecting to API server 398",
                            type:"error"
                        }
                        this.messageBoxCustom(info) 

                    } else if (response.status === 422) {
                        // Message Info
                        let info = {
                            visible: true,
                            text: "Not Authenticated",
                            type:"error"
                        }
                        this.messageBoxCustom(info)  

                       
                    } else {
                        // Message Info
                        let info = {
                            visible: true,
                            text: "Unexpected error with API server 398",
                            type:"error"
                        }
                        this.messageBoxCustom(info)                         
                    }

                }).catch((error) => {
                    //console.log('[GPSLocation http request error]', error);
                    this.processing = false;
                    // Message Info
                    let info = {
                        visible: true,
                        text: "[GPSLocation http request error]" + error,
                        type:"error"
                    }
                    this.messageBoxCustom(info)                     
                    //alert(error)
                });

            },
            confirmDelete(id) {
                this.modalDelete = true
                this.imageDelete = id
            },
            deleteImage(id) {
                let imgId = (id) ? id : this.imageDelete;

                console.log("deleteImage", id);
                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');

                fetch(appURL + '/api/seller/images/' + imgId +
                    '/delete', {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + sellerToken
                        },
                        body: JSON.stringify({
                            ...this.service
                        })
                    }).then(response => {


                    console.log(response);

                    if (response.status === 200) {

                        this.RefreshImages();
                        this.modalDelete = false;

                        // Message Info
                        let info = {
                            visible: true,
                            text: "Image deleted",
                            type:"success"
                        }
                        this.messageBoxCustom(info) 

                    } else if (response.status === 401) {

                        // Message Info
                        let info = {
                            visible: true,
                            text: "Error connecting to API server 398",
                            type:"error"
                        }
                        this.messageBoxCustom(info) 

                    } else if (response.status === 422) {
                        // Message Info
                        let info = {
                            visible: true,
                            text: "Not Authenticated",
                            type:"error"
                        }
                        this.messageBoxCustom(info)  

                       
                    } else {
                        // Message Info
                        let info = {
                            visible: true,
                            text: "Unexpected error with API server 398",
                            type:"error"
                        }
                        this.messageBoxCustom(info)                         
                    }

                }).catch((error) => {
                    //console.log('[GPSLocation http request error]', error);
                    this.processing = false;
                    // Message Info
                    let info = {
                        visible: true,
                        text: "[GPSLocation http request error]" + error,
                        type:"error"
                    }
                    this.messageBoxCustom(info)                     
                    //alert(error)
                });

            },
            setCurrentLocationSeller() {
                this.seller.lat = this.getCurrentLocation.lat;
                this.seller.lng = this.getCurrentLocation.lng;
                this.UpdateSeller();
            },
            UpdateSeller() {
                this.processing = true;

                if (this.isSellerLoggedIn) {
                    console.log("this.isSellerLoggedIn", this.isSellerLoggedIn);
                    if (this.seller.id) {
                        //update existing
                        console.log("this.isSellerLoggedIn", this.seller.id);

                        var appURL = localStorage.getItem('appURL');
                        var sellerToken = localStorage.getItem('sellerToken');

                        console.log("...this.seller", this.seller);

                        let descr = (this.seller.description) ? this.seller.description : '';
                        let daysClosed = (this.seller.working_hours) ? this.seller.working_hours : this.working_hours;

                        console.log("daysClosed", daysClosed)
                        console.log("this.seller.working_hours", this.seller.working_hours)

                        fetch(appURL + '/api/seller/AddUpdateSellerInformation', {
                            method: 'POST',
                            headers: {
                                "Content-Type": "application/json",
                                "Accept": "application/json",
                                "Authorization": "Bearer " + sellerToken
                            },
                            //credentials: "same-origin",
                            body: JSON.stringify({
                                //...this.seller
                                name: this.seller.name,
                                email: this.seller.email,
                                phone: this.seller.phone,
                                country: this.seller.country,
                                city: this.seller.city,
                                street: this.seller.street,
                                zip_code: this.seller.zip_code,
                                description: descr,
                                lat: this.seller.lat,
                                lng: this.seller.lng,
                                payment_methods: JSON.stringify({
                                    accept_card: this.seller.payment_methods.accept_card,
                                    accept_cash: this.seller.payment_methods.accept_cash,
                                    accept_coinbase: this.seller.payment_methods.accept_coinbase
                                }),
                                working_hours: JSON.stringify({
                                    //daysClosed
                                    start: this.seller.working_hours.start,
                                    end: this.seller.working_hours.end,
                                    break_start: this.seller.working_hours.break_start,
                                    break_end: this.seller.working_hours.break_end,
                                    Offdays: this.seller.working_hours.Offdays
                                })

                            })
                        }).then(response => {

                            console.log("response UpdateSeller", response.body)

                            if (response.status === 200) {

                                this.$store.dispatch('updateSellerInformation', this.seller);

                                console.log('updateSellerInformation', this.seller);
                                //this.$router.go(-1)
                                let info = {
                                    visible: true,
                                    text: "Account Update",
                                    type:"success"
                                }
                                this.messageBoxCustom(info)

                    } else if (response.status === 401) {

                        // Message Info
                        let info = {
                            visible: true,
                            text: "Error connecting to API server 398",
                            type:"error"
                        }
                        this.messageBoxCustom(info) 

                    } else if (response.status === 422) {
                        // Message Info
                        let info = {
                            visible: true,
                            text: "Not Authenticated",
                            type:"error"
                        }
                        this.messageBoxCustom(info)  

                       
                    } else {
                        // Message Info
                        let info = {
                            visible: true,
                            text: "Unexpected error with API server 398",
                            type:"error"
                        }
                        this.messageBoxCustom(info)                         
                    }

                }).catch((error) => {
                    //console.log('[GPSLocation http request error]', error);
                    this.processing = false;
                    // Message Info
                    let info = {
                        visible: true,
                        text: "[GPSLocation http request error]" + error,
                        type:"error"
                    }
                    this.messageBoxCustom(info)                     
                    //alert(error)
                });


                    } else {

                        console.log('else seller', this.seller);
                    }

                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    // Start custom common variables
    // End custom common variables
</style>