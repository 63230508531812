<template>
    <div>
        <v-container>
            <v-card v-if="ShowCustomerCard">
                <v-card-title>
                {{customer.name}}
                <v-spacer></v-spacer>
                </v-card-title>

                <v-card-text>
                Email : {{customer.email}}<br/>
                Phone : {{customer.phone}}
                </v-card-text>


                <v-card-actions>
                        <v-btn @click="ShowCustomerCard = false" class="btn btn-primary btn-submit" x-large outlined
                            color="primary">
                            Back
                        </v-btn>
                </v-card-actions>
            </v-card>

            <v-card v-show="!ShowCustomerCard">
                <v-card-title>
                Customers
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
                </v-card-title>

                <v-data-table
                :headers="headers"
                :items="customers"
                :search="search"
                @click:row="customerSelected"
                :footer-props="{
                refreshIcon: 'mdi-refresh'
                }"
                ></v-data-table>

                <v-card-actions>


                        <v-btn @click="getMyCustomers" class="btn btn-primary btn-submit" x-large outlined
                            color="primary">
                            Refresh
                        </v-btn>
                        <v-btn @click="showAddCusotmer" class="btn btn-primary btn-submit" x-large
                            color="primary">
                            Add Customer
                        </v-btn>
                </v-card-actions>
            </v-card>

        </v-container>
    </div>
</template>

<script>
    import mixinShared from '@/mixins/Shared'


    import { bus } from '../../main'

    import {
        mapGetters,
        mapState
    } from 'vuex'



    export default {
        mixins: [mixinShared],
        data () {
        return {
            ShowCustomerCard:false,
            search: '',
            headers: [
            {
                text: 'Name',
                align: 'start',
                value: 'name',
            },
            { text: 'Email', value: 'email' },
            { text: 'Phone', value: 'phone' },
            { text: 'notes', value: 'notes' }
            ],
            customers: [],
            customer: {}
        }
        },
        components: {
        },
        computed: {
            ...mapGetters([
                "isMobile"
            ])
        },
        created() {
            this.getMyCustomers();
        },
        methods: {
            viewCusotmerCard() {
                this.ShowCustomerCard = true;
            },
            customerSelected(item) {
                this.customer = item;
                this.viewCusotmerCard();
                this.$emit('CustomerSelected', item)
            },
            showAddCusotmer() {

                bus.$emit('showCustomerDialog',true);
            },
            flatenMyCustomers(customerArray){

                this.customers = customerArray.map((customer) =>{

                    if(customer.user_id !== null){
                        customer.name = customer.customer.name;
                        customer.email = customer.customer.email;
                        customer.phone = customer.customer.phone;
                    }
                    return customer;
                }
                );
            },
            getMyCustomers() {

                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');
                //console.log("sellerToken", sellerToken);

                fetch(appURL + '/api/seller/customers/get' +
                    status, {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + sellerToken
                        }
                    }).then(response => {
                    console.log(response);

                    if (response.status === 200) {
                        response.json().then(result => {
                            console.log('result', result);
                            this.flatenMyCustomers(result.success)
                        });

                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                    } else if (response.status === 422) {
                        //failed logn
                        alert('Not Authenticated');
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 398');
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                    this.processing = false;
                    alert(error)
                });

            }
        },
    }
</script>

<style lang="scss" scoped>
</style>