<template>
    <div class="chat-container chat-seller">
        <v-container>

            <chat-window :current-user-id="currentUserId" :rooms="rooms" :messages="messages" :single-room="true"
                :room-id="1" :show-emojis="true" :show-audio="false" :show-add-room="false" :show-files="false"
                :link-options="{ disabled: true, target: '_blank', rel: null }" :loading-rooms="false"
                :rooms-loaded="true" :messages-loaded="messagesLoaded" @fetch-messages="getMessages"
                @send-message="sendMessage" />

        </v-container>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapState
    } from 'vuex'
    var moment = require("moment");

    import ChatWindow from 'vue-advanced-chat'
    import 'vue-advanced-chat/dist/vue-advanced-chat.css'


    export default {
        components: {
            ChatWindow
        },
        props: {
            booking_id: {
                type: Number,
                require: true
            },
            current_chat: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        data() {
            return {
                rooms: [{
                    roomId: 1,
                    roomName: 'Room 1',
                    avatar: 'assets/imgs/people.png',
                    unreadCount: 4,
                    index: 3,
                    lastMessage: {
                        content: 'Last message received',
                        senderId: 1234,
                        username: 'John Doe',
                        timestamp: '10:20',
                        saved: true,
                        distributed: false,
                        seen: false,
                        new: true
                    },
                    users: [{
                            _id: 201,
                            username: 'John Doe',
                            avatar: 'assets/imgs/doe.png',
                            status: {
                                state: 'online',
                                lastChanged: 'today, 14:30'
                            }
                        },
                        {
                            _id: 201,
                            username: 'John Snow',
                            avatar: 'assets/imgs/snow.png',
                            status: {
                                state: 'offline',
                                lastChanged: '14 July, 20:00'
                            }
                        }
                    ],
                    typingUsers: [4321]
                }],
                messages: [],
                messagesLoaded:false,
                currentUserId: 1234,
                currentRoom: [],
                messagesWs: []
            };
        },
        mounted() {

            // window.Echo.channel('channel-hello')
            //     .listen('hello', (e) => {
            //         console.log(e)
            //     })
            this.connect();

        },
        computed: {
            ...mapGetters(["getCalenderIndex"]),
        },
        methods: {

            connect() {
                if (this.currentRoom.id) {
                    let vm = this;
                    this.getMessages();
                    window.Echo.private('room-1')
                        .listen('.message.new', e => {
                            console.log('here');
                            vm.getMessages();
                            console.log(e);
                        });
                }
            },
            disconnect(room) {
                window.Echo.leave('chat.' + room.id);
            },
            getMessages() {


                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');
                this.messagesLoaded = false;

                fetch(appURL + '/api/seller/chat/' + this.rooms[0].roomId, {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + sellerToken
                    }
                }).then(response => {
                    console.log('response------------');
                    console.log(response);

                    if (response.status === 200) {

                        response.json().then(result => {
                            console.log('getMessages result', result);
                            this.messages = result;
                            this.messagesLoaded = true;

                        });

                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                    } else if (response.status === 422) {
                        //failed logn
                        alert('Not Authenticated');
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 398');
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                    this.processing = false;
                    alert(error)
                });
            },
            sendMessage(message) {
                // if(message.content == ' '){
                //     return;
                // }

                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');

                console.log('this.rooms[0].roomId',this.rooms[0].roomId);

                fetch(appURL + '/api/seller/chat/' + this.rooms[0].roomId, {
                    method: 'post',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + sellerToken
                    },
                    body: JSON.stringify({
                        message: message.content
                    })
                }).then(response => {
                    console.log('response------------');
                    console.log(response);

                    if (response.status === 200) {

                        response.json().then(result => {
                            console.log('set new message result', result);
                            //this.messagesWs = response.data;
                            this.messages.push(result);

                        });

                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                    } else if (response.status === 422) {
                        //failed logn
                        alert('Not Authenticated');
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 398');
                    }

                }).catch((error) => {
                    console.log('[ http request error]', error);
                    this.processing = false;
                    alert(error)
                });
            },


        },

    }
</script>

<style lang="scss" scoped>

</style>