<template>
    <div>
        <v-container>



            <chat-window :current-user-id="currentUserId" :rooms="rooms" :messages="messages"
                :show-emojis="true" :show-audio="false" :show-add-room="false" :show-files="false"
                :link-options="{ disabled: true, target: '_blank', rel: null }" :loading-rooms="loadingRooms"
                :rooms-loaded="roomsLoaded" :messages-loaded="messagesLoaded" @fetch-messages="connectGetMessages"
                @send-message="sendMessage" />

        </v-container>
    </div>
</template>

<script>
   import {
        mapGetters,
        mapState
    } from 'vuex'
    var moment = require("moment");

    import ChatWindow from 'vue-advanced-chat'
    import 'vue-advanced-chat/dist/vue-advanced-chat.css'


    export default {
        components: {
            ChatWindow
        },
        data() {

            return {
                rooms: [],
                messages: [],
                messagesLoaded:false,
                currentUserId: 'seller-201',
                currentRoom: {},
                messagesWs: [],
                loadingRooms:true,
                roomsLoaded:false
            };
        },
        mounted() {

            // window.Echo.channel('channel-hello')
            //     .listen('hello', (e) => {
            //         console.log(e)
            //     })
            //this.connect();

            this.currentUserId = 201;
            this.getRooms();

        },
        computed: {
            ...mapGetters(["getCalenderIndex"]),
        },

        methods: {
            connectGetMessages({ room = {}, options = {} }) {

                console.log('room',room)
                console.log('options',options)

                // if (options.reset) {
                //     this.resetMessages()
                //     this.roomId = room.roomId
                // }
                if (room) {
                    this.currentRoom = room;
                    this.connect();

                    //this.getMessages();
                }
            },

            connect() {
                if (this.currentRoom.roomId) {
                    console.log('-------connecting');
                    let vm = this;
                    this.getMessages();
                    window.Echo.channel('booking-'+this.currentRoom.roomId)
                        .listen('.server.created', e => {
                            console.log('here');
                            // let tmpMessages = this.messages;
                            // tmpMessages.push({...e.message});
                            this.messages.push({...e.message});

                            //vm.getMessages();
                            console.log(e);
                        });
                }
            },
            disconnect(room) {
                window.Echo.leave('booking-' + room.roomId);
            },
            getRooms() {


                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');
                this.messagesLoaded = false;

                fetch(appURL + '/api/seller/chat/', {
                    method: 'post',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + sellerToken
                    },
                    body: JSON.stringify({
                        referenceType:'booking',
                    })
                }).then(response => {
                    console.log('response------------');
                    console.log(response);

                    if (response.status === 200) {

                        response.json().then(result => {
                            console.log('rooms result', result);

                            this.rooms = result;
                            this.currentRoom = result[0];

                            this.loadingRooms=false
                            this.roomsLoaded=true;

                        });

                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 434');
                    } else if (response.status === 422) {
                        //failed logn
                        alert('Not Authenticated');
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 4343');
                    }

                }).catch((error) => {
                    console.log('[http request error]', error);
                    this.processing = false;
                    alert(error)
                });
            },
            getMessages(){


                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');
                this.messagesLoaded = false;

                fetch(appURL + '/api/seller/chat/'+this.currentRoom.roomId, {
                    method: 'get',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + sellerToken
                    }
                }).then(response => {
                    console.log('response------------');
                    console.log(response);

                    if (response.status === 200) {

                        response.json().then(result => {
                            console.log('getMessages result', result);
                            this.messages = result;
                            this.messagesLoaded = true;

                        });

                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 45645');
                    } else if (response.status === 422) {
                        //failed logn
                        alert('Not Authenticated');
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 6565');
                    }

                }).catch((error) => {
                    console.log('[http request error]', error);
                    this.processing = false;
                    alert(error)
                });
            },
            sendMessage(message) {
                // if(message.content == ' '){
                //     return;
                // }

                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');

                console.log('this.rooms[0].roomId', this.currentRoom.roomId);

                fetch(appURL + '/api/seller/chat/' +this.currentRoom.roomId, {
                    method: 'post',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + sellerToken
                    },
                    body: JSON.stringify({
                        message: message.content
                    })
                }).then(response => {
                    console.log('response------------');
                    console.log(response);

                    if (response.status === 200) {

                        response.json().then(result => {
                            console.log('set new message result', result);

                            //new meesages will come from websocket
                            //this.messages.push(result);

                        });

                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 3445');
                    } else if (response.status === 422) {
                        //failed logn
                        alert('Not Authenticated');
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 344');
                    }

                }).catch((error) => {
                    console.log('[ http request error]', error);
                    this.processing = false;
                    alert(error)
                });
            },


        },
        watch: {
            currentRoom(val,oldVal) {
                if(oldVal.roomId){
                    this.disconnect(oldVal);
                }
                this.connect();
            }
        },
        deactivated() {
            console.log('deactivated');
            console.log('deactivated - DISCONNECTED!!');
            this.disconnect(this.currentRoom);
        },
        beforeDestroy() {
            console.log('beforeDestroy');
            console.log('beforeDestroy - DISCONNECTED!!');
            this.disconnect(this.currentRoom);
        },
        destroyed() {
            console.log('destroyed - DISCONNECTED!!');
            this.disconnect(this.currentRoom);
        }
    }
</script>

<style lang="scss" scoped>
</style>