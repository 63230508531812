<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <section class="page page-account page-account-bookings">
                    <div class="page--top-container">
<!-- {{ clientBookings }} {{ sellerBookings}} -->
                    </div>
                    <div class="page--main">
                        <p class="page--description">Your last Booking on</p>
                        <template 
                        v-if="isLoggedIn">  
                            <BookingsList 
                            :bookings="clientBookings"
                            :loading="bookingLoading"
                            
                            ></BookingsList>
                        </template>
                        <template v-if="isSellerLoggedIn">
                            <BookingsListMerchant
                            :bookingsProp="getSellersBookings"
                            :loading="bookingLoading"
                            ></BookingsListMerchant>

                        </template>

                    </div>

                    <!-- <div actionBarHidden="true">

                            <div>

                                <div class="scrollmenu">

                                        <div class="options-card" @click="getBookings()" v-if="isLoggedIn">
                                            Refresh bookings
                                        </div>

                                </div>

                                <div>
                                    <div v-for="item in clientBookings" :key="item">

                                        <div v-if="item.service !== null" style="color:black;" class="booking-card">
                                            <div v-if="isLoggedIn">

                                                <div>
                                                    {{item.service.service_name}}
                                                    {{item.service.service_decription}}
                                                </div>
                                                <div >

                                                    {{item.booking_from}}
                                                    {{item.booking_to}}
                                                    {{item.note}}
                                                    {{item.status}}
                                                    <v-btn class="btn btn-primary" v-if="item.status !== 'Approved'">Edit
                                                    </v-btn>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div> -->

                </section>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {
        mapGetters,
        mapState
    } from 'vuex'
    import BookingsList from '../components/widgets/BookingsList.vue'

    export default {
        data() {
            return {
                showAvatar: false,
                showDescription: false,
                clientBookings: [],
                sellerBookings: [],
                bookingLoading : true
            };
        },
        components: {
            BookingsList
        },
        mounted() {
            console.log('Booking mounted', )

            if (this.isLoggedIn) {
                console.log('isLoggedIn', this.isLoggedIn)

                this.getBookings();
            }
            if (this.isSellerLoggedIn) {
                console.log('isSellerLoggedIn', this.isSellerLoggedIn)
                this.sellerBookings = this.getSellersBookings
            }
        },
        computed: {
            ...mapGetters(["getCurrentLocation", "isLoggedIn", "isSellerLoggedIn", "getProfileData",
                "getSellerProfileData",
                "getSellersBookings"
            ])
        },
        methods: {
            getBookings() {

                var appURL = localStorage.getItem('appURL', "https://api.wellbookings.com");
                var token = localStorage.getItem('token');

                //get list of sellers bookings
                fetch(appURL + '/api/client/booking/all', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + token
                    }
                }).then(response => {


                    console.log(response);

                    if (response.status === 200) {

                    response.json().then(result => {
                            console.log('result', result);

                            this.clientBookings = result.success;
                            this.$store.dispatch('setClientBookings', result.success);
                            this.bookingLoading = false

                    });
                    
                    } else if (response.status === 401) {
                    //failed logn
                    console.log('Error connecting to API server 398');
                            this.clientBookings = this.getSellersBookings;
                    } else {
                    // unkown error
                    console.log('Unexpected error with API server 398');
                            this.clientBookings = this.getSellersBookings;
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                        this.processing = false;
                        alert(error)
                });

            }
        },
    }
</script>

<style lang="scss" scoped>
    // End custom common variables


    .service-card {
        color: black;
        background-color: cadetblue;
        border-width: 1px;
        border-color: slategray;
        margin: 10px;
        padding: 10px;
        font-size: 18px;
        border-radius: 10px;
        height: 100px;
        width: 200px;
    }

    .options-card {
        color: black;
        background-color: white;
        border-width: 1px;
        border-color: slategray;
        margin: 5px;
        padding: 5px;
        font-size: 12px;
        border-radius: 10px;
    }

    .booking-card {
        color: black;
        background-color: white;
        border-width: 1px;
        border-color: slategray;
        margin: 5px;
        padding: 5px;
        font-size: 12px;
        border-radius: 10px;
        width: 100%;
    }

    // Custom styles
</style>