<template>
    <section class="header-nav">
        <v-app-bar app fixed>
            <v-container class="pa-0 fill-height">

                <v-img max-height="50" max-width="50" src="/img/icons/manifest-icon-192.maskable.png"></v-img>
                <v-toolbar-title class="logo mr-15" @click="goToHome">

                    <!--v-icon color="primary">mdi-spa</v-icon-->
                    wellbookings
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <a class="pr-5" @click="goToHome()" v-show="showHome">
                    <v-icon left>mdi-home-outline</v-icon>
                    <span class="primary--text">Home</span>
                </a>

                <!--a class="pr-5"  @click="goToMap()">
                    <v-icon left>mdi-map-marker-radius-outline</v-icon>
                    <span
                    class="primary--text"  
                    >Map</span>
                </a-->

                <a class="pr-5" @click="goToExplore()">
                    <span class="primary--text">Explore</span>
                </a>

                <!--a class="pr-5"  @click="goToMarket()"
                v-if="this.isLoggedIn || this.isSellerLoggedIn" 
                >
                    <v-icon left>mdi-heart</v-icon>
                    <span
                    class="primary--text"
                    >Market</span>
                </a-->

                <template v-if="this.isLoggedIn || this.isSellerLoggedIn">

                    <a class="pr-2" @click="goToOptions()">
                        <span class="primary--text">Options</span>
                        <v-icon left>mdi-dots-vertical</v-icon>
                    </a>
                </template>
                <template v-else>

                    <a class="pr-2" @click="goToOptions()">
                        <v-icon left>mdi-account-circle-outline</v-icon>
                        <span class="primary--text">Login / Register</span>
                    </a>

                </template>

                <v-btn @click="installapp()" v-show="installButton">
                    Install
                </v-btn>
            </v-container>

        </v-app-bar>
    </section>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    export default {
        props: {
            installButton: {
                type: Boolean,
                default: false
            },
            deferredPrompt: {
                type: Object,
                default () {
                    return {}
                }
            }
        },
        name: 'TopToolbar',
        data() {
            return {
                showHome: false, // Show home button in menu
                showMenu: false
            }
        },
        computed: {
            ...mapGetters(["isLoggedIn", "isSellerLoggedIn"])
        },
        methods: {
            installapp() {
                // Show the prompt
                this.deferredPrompt.prompt();

                // Wait for the user to respond to the prompt
                this.deferredPrompt.userChoice.then(choiceResult => {
                    if (choiceResult.outcome === "accepted") {
                        console.log("PWA setup accepted");

                        this.installButton = false;
                    } else {
                        console.log("PWA setup rejected");

                        this.installButton = false;
                    }
                    this.deferredPrompt = null;
                });
            },
            toggleMenu() {
                this.showMenu = !this.showMenu;
            },

            goToHome() {
                this.$router.push({
                    name: 'home'
                });
            },
            goToMap() {
                this.$router.push({
                    name: 'map'
                });
            },
            goToExplore() {
                this.$router.push({
                    name: 'explore',
                });
            },
            // FROM ACCOUNT PAGE
            goToDashboard() {
                this.$router.push({
                    name: 'account'
                });
            },
            goToMarket() {
                this.$router.push({
                    name: 'market'
                });
            },
            goToOptions() {
                this.$router.push({
                    name: 'options'
                });
            },

            goToLogin(loginRegister) {
                this.$router.push({
                    name: 'login',
                    params: {
                        isLoggingIn: loginRegister
                    }
                });

            },
            Logout() {
                this.$store.dispatch('logout');
                this.$router.push({
                    name: 'home'
                });
            },
            SellerLogout() {
                this.$store.dispatch('sellerLogout');
                this.$router.push({
                    name: 'home'
                });
            },
            goToSellerLogin(loginRegister) {
                this.$router.push({
                    name: 'seller-login',
                    params: {
                        isLoggingIn: loginRegister
                    }
                });
            },
            goToSellerProfile() {
                this.$router.push({
                    name: 'seller-profile'
                });
            },

            goToSellerAddUpdateService() {
                this.$router.push({
                    name: 'seller-add-update-service'
                });
            },

            goToSellerService() {
                this.$router.push({
                    name: 'seller-services',
                    // params: {
                    //     isLoggingIn: loginRegister
                    // }
                });
            },

            goToBookings() {

                this.$router.push({
                    name: 'bookings'
                });
            },

            goToSellerMakeBookings() {

                this.$router.push({
                    name: 'seller-bookings'
                });
            },

            goToProfile() {
                this.$router.push({
                    name: 'user-profile'
                });
            },
            goToSellerDashboard() {
                this.$router.push({
                    name: 'seller-dashboard'
                });
            },
        },
    }
</script>

<style lang="scss" scoped>
    .v-toolbar.v-app-bar {
        transition: all 0.3 ease-in-out;
        -webkit-transition: all 0.3 ease-in-out;
        -moz-transition: all 0.3 ease-in-out;

        // &.v-app-bar--fixed.v-app-bar--is-scrolled.white {
        //     width: 50px;
        //     background: transparent!important;
        //     box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 0%), 0px 1px 10px 0px rgb(0 0 0 / 0%);
        //     transition: all 0.3 ease-in-out;
        //     -webkit-transition: all 0.3 ease-in-out;
        //     -moz-transition: all 0.3 ease-in-out;
        //     .logo {
        //         display: none;
        //     }
        //     .v-btn {
        //         background: #fff;
        //         transition: all 0.3 ease-in-out;
        //         -webkit-transition: all 0.3 ease-in-out;
        //         -moz-transition: all 0.3 ease-in-out;
        //         box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
        //     }
        // }
        .logo {
            cursor: pointer;
        }
    }
</style>