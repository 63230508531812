<template>
    <div class="bookings-container">
        
            <template v-if="loading">

                <v-sheet
                    class="pa-3"
                >
                    <v-skeleton-loader
                    v-for="(item, i) in 5"
                    :key="i"
                    class="mx-auto"
                    max-width="300"
                    type="list-item-three-line"
                    ></v-skeleton-loader>
                </v-sheet>
            </template>
            <template v-if="!loading">
                
                <v-list
                    :subheader="false"
                    :three-line="showDescription"
                    :two-line="! showDescription"
                    elevation="10"
                    reverse
                    >
                    <!-- <v-subheader inset> Your Bookings</v-subheader> -->

                        <v-list-item
                            v-for="(item, i) in bookings || reverse"
                            :key="i"
                            @click="goToBookingDetail(item)"
                        >
                            <template v-if="item.service !== null">
                                    <v-list-item-avatar
                                        v-if="showAvatar"
                                    >
                                        <v-icon
                                            class="grey lighten-1"
                                            dark
                                        >
                                            mdi-folder
                                        </v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                    <v-list-item-title v-text="item.service.service_name"></v-list-item-title>

                                    <v-list-item-subtitle 
                                    v-if="showDescription"
                                    v-text="item.service.service_decription"></v-list-item-subtitle>
                                    <v-list-item-subtitle> {{ item.booking_from }} </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <div v-if="item.status !== 'Approved'">
                                            {{item.status}}
                                        </div>
                                        <v-btn
                                        @click="goToBookingDetail(item)"
                                        icon>
                                            <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                            </template>
                        </v-list-item>
                </v-list>
                <template v-if="! bookings.length > 0">
                    <v-container>
                        <v-row 
                        justify="center"
                        dense>
                            <v-col
                            class="text-center"
                                cols="12">
                                <v-icon 
                                class="px-5"
                                x-large>mdi-alert-outline</v-icon>
                                <h2 class="pa-5">Sorry, no bookings</h2>
                                <div class="btn-hcontainer">
                                    <v-btn
                                    link
                                    to="/"
                                    x-large
                                    
                                    class="btn btn-browse"
                                    color="primary">

                                        Go To Homepage
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </template>
            </template>
    </div>

</template>


<script>

    export default {
        props: {
            bookings: {
                type: Array,
                required: true,
                default() {
                    return []
                }
            },
            loading: {
                type: Boolean,
                required: false
            }
        },
        data() {
            return {
                // loading: false,
                showAvatar: false,
                showDescription: false,
            };
        },
        mounted() {
            //this.loading = false
        },
        methods: {
            goToBookingDetail(item) {
                console.log("goToBookingDetail", item);
                this.$store.dispatch("singleBookingInfo", item)

                this.$router.push({
                    name: 'booking-details',
                    params: {
                        itemBooking: item
                    }
                });
            },
            // getBookings() {

            //     var appURL = localStorage.getItem('appURL', "https://api.wellbookings.com");
            //     var token = localStorage.getItem('token');

            //     //get list of sellers bookings
            //     fetch(appURL + '/api/client/booking/all', {
            //         method: 'POST',
            //         headers: {
            //             "Content-Type": "application/json",
            //             "Accept": "application/json",
            //             "Authorization": "Bearer " + token
            //         }
            //     }).then(response => {


            //         console.log(response);

            //         if (response.status === 200) {

            //         response.json().then(result => {
            //                 console.log('result', result);

            //                 this.clientBookings = result.success;
            //                 this.$store.dispatch('setClientBookings', result.success);

            //         });
                    
            //         } else if (response.status === 401) {
            //         //failed logn
            //         console.log('Error connecting to API server 398');
            //                 this.clientBookings = this.getSellersBookings;
            //         } else {
            //         // unkown error
            //         console.log('Unexpected error with API server 398');
            //                 this.clientBookings = this.getSellersBookings;
            //         }

            //     }).catch((error) => {
            //         console.log('[GPSLocation http request error]', error);
            //             this.processing = false;
            //             alert(error)
            //     });

            // }
        },
    }
</script>

<style lang="scss">
    // End custom common variables
    .bookings-container {
        .v-list {
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            padding: .25rem 0;
            margin-bottom: 1rem;
            // max-height: 500px;
            // overflow-y: auto;
            &.v-list--two-line {}

            .v-list-item {
                border-bottom: 1px solid rgba(0,0,0,.12);
                &:last-child {
                    border-bottom: 0;
                }
                .v-list-item__content {
                    .v-list-item__title {
                        font-weight: 500;
                    }
                }
            }
        }
    }

    // Custom styles
</style>