<template>
    
      <div class="scrollmenu"
      :class="[{ 'horizontal' : horizontal }, { 'hide-icons' : hideIcons }]"
      >
          <div  v-show="showAllResult" class="options-card categories-col" >
              <button 
                  class="btn-categories all" 
                  :class="{ 'active' : activeMenu == 'all'}"
                  @click="getAllResults()"
              >
                  <span>
                      <span class="icon icon-all"></span>
                      <span class="name">All</span>
                  </span>
              </button>
          </div>

          <div v-show="showFavorites" class="options-card categories-col">
              <button 
                  class="btn-categories favorites" 
              >
                  <span>
                      <span class="icon icon-favorites"></span>
                      <span class="name">Favorites</span>
                  </span>
              </button>          
          </div>

          <div 
          class="options-card categories-col" 
          v-for="(item, index) in categories" 
          :key="'exploreOptions-'+index"
          >
              <button 
                  class="btn-categories" 
                  :class="[ item.name , { 'active' : activeMenu == item.name}]"
                  @click="explore(item.searchValue)"
              >
                  <span>
                      <span class="icon"
                        :class="[ item.name , { active : activeMenu == item.name}]"
                      ></span>
                      <span class="name">{{item.name}}</span>
                  </span>
              </button>
          </div>

      </div>
</template>

<script>
// import { mapActions } from 'vuex'

export default {
    props: {
        categories : {
            type: Array,
            required: true
        },
        horizontal : {
            type: Boolean,
            required: false
        },
        hideIcons : {
            type: Boolean,
            required: false
        }
    },
    data: () => ({
        //Categories: categories,
        showAllResult: true,
        showFavorites: false,
        searchResult: [],
        SearchCity: '',
        activeMenu: 'all'

    }),
    methods: {
        explore(item){
            this.activeMenu = item;
            console.log("explore-item -clkick", item)
            this.$emit('explore',item);
        },
        // ...mapActions({
        //    singleView: 'shop/setSingleView',
        // }), 
        getAllResults() {
            console.log('dfs d fdsfdsf dfd f');
            this.$emit('getAllResults');
        }, 
      filterResults(search) {

        let temp = [];

  // console.log("fulterResukts", search)
  //       try {

  //         if(this.getSearchResult.length > 0){

  //           temp = this.getSearchResult.filter((item) => {

  //             console.log('item',item);
  //             for (let serv of item.services) {
  //               console.log('search', search)
  //               console.log('serv', serv)

  //               let tempname = (serv.service_name?serv.service_name.toLowerCase():'');
  //               let tempdecription = (serv.service_decription?serv.service_decription.toLowerCase():'');

  //               if (tempname.includes(search.toLowerCase())) {
  //                 return true;
  //               } else if (tempdecription.includes(search.toLowerCase())) {
  //                 return true;
  //               }

  //             }

  //             return false;
  //           });

  //         }

  //       } catch (error) {
  //         console.log(error);
  //       }

  //       this.searchResult = temp;
      },         
    },

    computed: {

    }
}
</script>

<style lang="scss">
.listmenu,
.scrollmenu {
    
    .categories-col {
        text-align: center;
        justify-content: center;
        outline: none;
        &.options-card {
            background: transparent;
            display: inline-block;
            outline: 0;
            border: 0;
            margin: 0 auto .5rem auto;
            padding: 0;
            &:focus,
            &:active {
                outline: none!important;
                border: 0;
            }
            outline: none!important;
        }
        button.btn-categories{
            background: transparent;
            background-color: transparent!important;
            border-radius: 10px!important;
            outline: none;
            // width: 100px;
            // height: 100px;
            min-width: 80px;
            min-height: 80px;
            margin: .25rem auto;
            position: relative;
            box-shadow: 0 0 0 transparent;
            transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            > span {
                display: block;
                .icon {
                    background-color:  #ffffff;
                    background-image: url("~@/assets/icons-b.png");
                    background-position: 0px 0px;
                    display: block;
                    width: 80px;
                    height: 80px;
                    border: 3px solid transparent;
                    border-radius: 15px;
                    margin: 0 auto;
                    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
                    // position: absolute;
                    // top: 0;
                    // left: 0;
                    // right: 0;
                    &:hover {
                        border: 3px solid #009688;
                    }
                    &.Hair {
                        background-position: -5px -1px;
                    }
                    &.Nails {
                        background-position: -165px -5px;
                    } 
                    &.Pedicure {
                        background-position: -82px -240px;
                    } 
                    &.Body {
                        background-position: -325px -160px;
                    } 
                    &.Waxing {
                        background-position: -165px -240px;
                    } 
                    &.Gel.Nails {
                        background-position: -242px -5px;
                    } 
                    &.SPA,
                    &.Spa {
                        background-position: -245px -245px;
                    } 
                    &.Massage {
                        background-position: -325px -320px;
                    } 
                                                                                                                                          
                }
                .name {
                    width: 100%;
                    display: block;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: .5px;
                    padding: .25rem 0;
                }
            }

            &:hover {
                .name {
                    color:  #009688;
                }
            }

            &.active {
                .name {
                    color:  #009688;
                    box-shadow: inset 0 0 -2px 2px red;
                }
            }
        }
            &.active {
                .name {
                    color:  #009688;
                    box-shadow: inset 0 0 -2px 2px red;
                }
            }
    }

    &.horizontal {
        .options-card {
            margin: 0 auto;
        }
        button.btn-categories{
            // width: 100px;
            // height: 100px;
            padding: .25rem;

            min-width: 80px;
            min-height: 80px;
            margin: 0 auto;
            position: relative;
            box-shadow: 0 0 0 transparent;
            transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            > span {
                display: block;
                .icon {
                    background-color:  #ffffff;
                    background-image: url("~@/assets/icons-b.png");
                    background-position: 0px 0px;
                    display: block;
                    width: 80px;
                    height: 80px;
                    border: 3px solid transparent;
                    border-radius: 15px;
                    margin: 0 auto;
                    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
                    // position: absolute;
                    // top: 0;
                    // left: 0;
                    // right: 0;
                    &:hover {
                        border: 3px solid #009688;
                    }
                    &.Hair {
                        background-position: -5px -1px;
                    }
                    &.Nails {
                        background-position: -165px -5px;
                    } 
                    &.Pedicure {
                        background-position: -82px -240px;
                    } 
                    &.Body {
                        background-position: -325px -160px;
                    } 
                    &.Waxing {
                        background-position: -165px -240px;
                    } 
                    &.Gel.Nails {
                        background-position: -242px -5px;
                    } 
                    &.SPA,
                    &.Spa {
                        background-position: -245px -245px;
                    } 
                    &.Massage {
                        background-position: -325px -320px;
                    } 
                                                                                                                                        
                }
                .name {
                    width: 100%;
                    display: block;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: .5px;
                    padding: .25rem 0 0 0;
                }
            }

            &:hover {
                .name {
                    color:  #009688;
                }
            }
        }
    }

    &.hide-icons {
        .categories-col {
            button.btn-categories{

                min-height: 51px;
                > span {
                    display: block;
                    .icon {
                        display: none;                                                                                        
                    }
                    .name {
                        width: 100%;
                        display: block;
                        font-size: 14px;
                        font-weight: 600;
                        letter-spacing: .5px;
                        padding: .25rem 0;
                    }
                }

                &:hover {
                    .name {
                        color:  #009688;
                    }
                }
            }

        }        
    }  
}

::-webkit-scrollbar{
    height: 4px;
    width: 4px;
    background: gray;
}
::-webkit-scrollbar-thumb:horizontal{
    background:  #333;
    border-radius: 10px;
    padding: 2px;
}

.v-application.theme--dark {
  .scrollmenu {
    background-color: rgba(255,255,255,.1);
  }
}


</style>