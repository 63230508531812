<template>
<div>

  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Charge
          </th>
          <th class="text-left">
            Price
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(key,value) in getcharges" :key="value">
          
          <td v-if="value !== 'total'">{{ value }}</td>
          <td v-if="value !== 'total'">{{ key }}</td>

        </tr>
      </tbody>
    </template>
  </v-simple-table>

  <h3 style="text-align:right;text-weight:bold;">
  Total : {{invoiceObj.total}}
  </h3>
  <h3 style="text-align:right;text-weight:bold;">
  Payment method : {{invoiceObj.paymentmethod}}
  </h3>
  <h3 style="text-align:right;text-weight:bold;">
  Paid : {{invoiceObj.paid}}
  </h3>
</div>
</template>

<script>
    import {
        mapGetters,
        mapState
    } from 'vuex'

    export default {
        props:{
            invoiceObj: {
                required:true
            },
        },
        data() {
            return {
            };
        },
        created() {
        
        },
        mounted() {

        },
        computed: {

            getcharges(){
                let temp = {};

                for(const [key, value] of Object.entries(this.invoiceObj)){
                    console.log(key)
                    console.log(value)

                    if(key !== 'paymentmethod' && key !== 'paid' && key !== 'total'){
                        temp[key] = value;
                    }

                }

                return temp
            }
        },
        methods: {
 
        }
    };
</script>

<style lang="scss">

</style>