<template>
        <v-dialog 
        v-model="dialog" 
        style="z-index: 9991;"
        scrollable max-width="600px"
        :fullscreen="isMobile"
        :hide-overlay="isMobile"
        transition="dialog-bottom-transition"
        >
            <v-card tile>
            <v-toolbar
                flat
                dark
                color="primary"
            >
                <v-btn
                icon
                dark
                @click="dialog = false"
                >
                <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Service</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            
            <AddSerice
            v-if="dialog"
            :item="service"
            @done="dialog = false"
            ></AddSerice>
 
            </v-card>
        </v-dialog>
</template>

<script>
    import {
        mapGetters,
        mapState
    } from 'vuex'

    import mixinShared from '@/mixins/Shared'
    import { bus } from '../main'
    import AddSerice from '@/views/Seller/AddService'

    export default {
        data() {
            return {
                dialog: false,
                service:{}
            }
        },

        components: {
            AddSerice
        },

        created() {
            bus.$on('showServiceDialog', (data) => {
                this.dialog = true;
                this.service = data;
            })
        },
        mounted() {

        },
        computed: {
            ...mapGetters([
                "isMobile"
            ])
        },
        methods: {
            isEmpty(obj){
                return obj && Object.keys(obj).length === 0 && obj.constructor === Object
            }
        },
    }
</script>
<style>
.v-dialog__content--active{
    z-index: 9991!important;
}
.v-overlay--active{
    z-index: 9991!important;
}
</style>