<template>
    <section class="page page-map">
        <v-snackbar
        :timeout="10000"
        :value="showDialogInfo"
        absolute
        class="notification-message"
        right
        text
        dark
        bottom
        >
        Please tap on map to select a location, and click to "select location" button to confirm.
              <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="showDialogInfo = false"
        >
          Close
        </v-btn>
      </template>
        </v-snackbar>

        <div class="map-container" :style="
        'height:'+ window.height + 'px; width:'+ window.width +'px'
        ">
            <l-map ref="myMap" @ready="onMapReady()" :zoom="zoom" :center="center" @click="clicked">
                <l-tile-layer :url="url">
                </l-tile-layer>
                    <v-geosearch 
                    :class="[{'visible': this.showAddressBox }]"
                    :options="geosearchOptions" 
                    ></v-geosearch>
                    <!-- /IMPORTANT PART HERE -->

                <l-marker v-for="mark in markers" :key="mark.id" :lat-lng="mark.latLng" @click="showDetails(mark)">

                </l-marker>
            </l-map>
        </div>

        <div class="map-info-container">

            <!-- <p class="text">Please tap on map to select a location, and confirm below.</p> -->
            <v-btn 
                @click="setLocation()" 
                class="btn btn-primary btn-submit" 
                x-large
                color="primary"
            >
                Select Location
                <v-icon medium right>
                    mdi-check
                </v-icon>
            </v-btn>

            <v-btn 
                @click="showAddressBox = !showAddressBox" 
                class="btn btn-primary btn-search ml-5" 
                fab
                outlined
                color="primary"
            >
                <v-icon>
                    mdi-map-search-outline
                </v-icon>
            </v-btn>
        </div>
    </section>
</template>

<script>
    import {
        mapGetters,
        mapState
    } from 'vuex'
    //import SellerPage from "./SellerPage";

    import {
        latLng
    } from "leaflet";
    import {
        LMap,
        LTileLayer,
        LMarker
    } from 'vue2-leaflet';


    import { OpenStreetMapProvider } from 'leaflet-geosearch';
    import VGeosearch from 'vue2-leaflet-geosearch';

    export default {
        data() {
            return {
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                zoom: 15,
                center: [47.313220, -1.319482],
                markers: [],
                // Show Marker with card inside instead of bottom card
                showMarkerCard: false,
                // Show name and description inside marker - default
                showMarkerInfo: true,
                // Dialog Information tap
                showDialogInfo: true,
                // Show Address box
                showAddressBox: true,

                customMarker: {
                    image: 'https://t4.ftcdn.net/jpg/03/87/94/65/240_F_387946581_S0FgnTQx3GjmsfZpnYxMZhLbrJG9Rm2k.jpg'
                },
                window: {
                    height: 200,
                    width: 400,
                },
                selectedMerchant: {
                    active: false,
                    title: '',
                    description: '',
                    street: '',
                    cover: ''
                },
                citySearch: '',
                geosearchOptions: { // Important part Here
                    provider: new OpenStreetMapProvider(),
                },
            };
        },
        components: {
            LMap,
            LTileLayer,
            LMarker,
            VGeosearch
        },
        created() {
            // set windows width
            let w = window.innerWidth;
            // Set windows height minus bottom bar and top bar height
            let h = window.innerHeight - 80;

            this.window.height = h
            this.window.width = w

        },
        mounted() {
        },
        computed: {
            ...mapGetters(["getCurrentLocation", "getSearchResult", "isMobile"]),
        },
        methods: {
            setLocation(){
                if(this.markers.length){

                    this.$store.dispatch("setLocation", this.markers[0]).then(()=>{

                    this.$router.go(-1);
                    });


                }else{
                    alert('Please tap the map to select a location');
                }
            },
            clicked(item){
                console.log('clicked',item);
                let temp = [];

                temp.push({
                    active: true,
                    id: 0,
                    lat: item.latlng.lat,
                    lng: item.latlng.lng,
                    latLng: item.latlng
                });
                this.markers = temp;
                
            },
            onMapReady(args) {

                this.map = this.$refs.myMap.mapObject

                this.center = [
                    this.getCurrentLocation.lat,
                    this.getCurrentLocation.lng
                ];

            },

            showDetails(id) {
                console.log("showDetails", id)
                //let obj = this.getSearchResult.find(o => o.id === id);
                this.selectedMerchant = id
            },
            goToItemDetails(id) {
                console.log("goToItemDetails")
                let obj = this.getSearchResult.find(o => o.id === id);

                this.$router.push({
                    name: 'seller-page',
                    params: {
                        singleItem: obj,
                        seller_id: obj.id
                    }
                });

            }
        }
    };
</script>

<style lang="scss">
// .geosearch{
//     width: 100%;
//     background: white;
//     font-size: 18px;
// }
.geosearch > form > div > div{
    // color:white;
    // background: #009688;
    // margin:5px;
    // cursor: pointer;
}
.geosearch > form > .reset{
    // display:inline-block;
    // background: #009688;
    // color:white;
}

.leaflet-control-container {
    z-index: 9999;
    .leaflet-top.leaflet-left {
        left: 0;
        right: 0;
        top: 0;
        display: flex;
        .leaflet-control-zoom {
            display:block;
            height: 60px;
        }
        .geosearch {
            background: #ffffff;
            //height: 45px;
            font-size: 18px;
            width: 80%;
            width: calc(100% - 65px);
            > form {
                // padding: .5rem;
                padding: .75rem 0 0 .5rem;
                .glass  {
                    width: calc(100% - 30px);
                    display:inline-block;
                }
                a.reset {
                    display:inline-block;
                }
                // div {
                //     div {
                //         color:white;
                //         background: #009688;
                //         margin:5px;
                //         cursor: pointer;
                //     }
                // }
                .results {
                    background: #ffffff;
                    color: #333333;
                    max-height: 250px;
                    overflow-y: scroll;
                    >div {
                        color: #333333;
                        border-bottom: 1px solid rgba(0,0,0,.5);
                    }
                }
            }
            a.leaflet-bar-part {
                width: 0;
                height: 0;
                line-height: 0;
                display: none
            }
        }
    }
}

.visible ~ .leaflet-control-container {
    .leaflet-top.leaflet-left {
        .geosearch {
            display: none;
            visibility: hidden;
        }
    }
}
</style>


<style scoped lang="scss">

    header {
        display: none !important;
        visibility: hidden !important;
    }

    .notification-message {
        z-index: 9991;
    }

    .map-info-container {
        background: transparent;
        text-align: center;
        z-index: 999;
        position: absolute;
        left: 0;
        right: 0;
        bottom: .5rem;
    }

    
</style>