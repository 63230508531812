<template>
    <!-- <v-container fluid>
        <v-row dense>
            <v-col cols="12"> -->
                <section class="page page-account page-dashboard">

                    <CustomerDialog></CustomerDialog>
                    <!-- <div class="page-account--top">{{ getProfileData }}</div> -->
                    <div class="page--main">
                        <v-tabs
                        v-model="tab"
                        background-color="teal"
                        centered
                        dark
                        icons-and-text
                        >
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#tab-1">
                                Calendar
                                <v-icon>mdi-book</v-icon>
                            </v-tab>

                            <v-tab href="#tab-2">
                                BookingsList
                                <v-icon>mdi-book</v-icon>
                            </v-tab>

                            <v-tab href="#tab-3">
                                Add Booking
                                <v-icon>mdi-book-plus</v-icon>
                            </v-tab>

                            <v-tab href="#tab-4">
                                My customers
                                <v-icon>mdi-account-multiple</v-icon>
                            </v-tab>

                            <v-tab href="#tab-5">
                                Customer support
                                <v-icon>mdi-message</v-icon>
                            </v-tab>

                            <v-tab href="#tab-6">
                                Reports
                                <v-icon>mdi-file-chart</v-icon>
                            </v-tab>
                            
                            </v-tabs>

                        <v-tabs-items v-model="tab">

                            <v-tab-item
                                eager
                                key="1"
                                :value="'tab-1'"
                            >

                                <v-container>

                                    <v-card v-if="showSelectedBooking">
                                        <v-toolbar color="teal">
                                            <v-btn @click="showSelectedBooking = false" icon >
                                            <v-icon>arrow_back</v-icon>
                                            </v-btn>
                                            <v-toolbar-title>Booking</v-toolbar-title>
                                            <v-spacer></v-spacer>

                                        </v-toolbar>

                                        <SellerBooking
                                        v-if="showSelectedBooking"
                                        :booking="selectedBooking"
                                        @bookingStatusChanged="bookingStatusChanged"
                                        ></SellerBooking>


                                        <!-- <v-card-actions>
                                                <v-btn @click="showSelectedBooking = false" class="btn btn-primary btn-submit" x-large outlined
                                                    color="primary">
                                                    Back
                                                </v-btn>
                                        </v-card-actions> -->
                                    </v-card>

                                </v-container>
                                <v-container fluid>
                                    <div v-show="!showSelectedBooking" class="dashbord-main-container botton-container">
                                        <v-row>
                                            <v-col
                                                cols="12"
                                                sm="12"
                                                md="12"
                                                lg="12">
                                                <!-- <v-select
                                                v-model="filteredCategories"
                                                :items="categories"
                                                label="Select"
                                                multiple
                                                hint="Select employees"
                                                persistent-hint
                                                ></v-select> -->

                                                <v-sheet height="64">
                                                    <v-toolbar flat>
                                                        <!-- <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                                                            Today
                                                        </v-btn> -->
                                                        <v-btn outlined class="mr-4" color="grey darken-2" @click="setTodayView">
                                                            Today
                                                        </v-btn>
                                                        <v-btn outlined class="mr-4" color="grey darken-2" @click="setWeekView">
                                                            Week
                                                        </v-btn>
                                                        <v-btn fab text small color="grey darken-2" @click="prev">
                                                            <v-icon small>
                                                                mdi-chevron-left
                                                            </v-icon>
                                                        </v-btn>
                                                        <v-btn fab text small color="grey darken-2" @click="next">
                                                            <v-icon small>
                                                                mdi-chevron-right
                                                            </v-icon>
                                                        </v-btn>
                                                        <v-toolbar-title v-if="$refs.calendar">
                                                            {{ $refs.calendar.title }}
                                                        </v-toolbar-title>
                                                        
                                                        <v-spacer></v-spacer>

                                                        <v-select
                                                        v-if="!showCalendarEmployer"
                                                        v-model="filteredCategories"
                                                        :items="categories"
                                                        multiple
                                                        ></v-select>
                                                    </v-toolbar>
                                                </v-sheet>
                                                <v-sheet>
                                                    <v-calendar 
                                                    ref="calendar" 
                                                    v-model="focus" 
                                                    color="primary" 
                                                    :type="viewType"
                                                    category-show-all 
                                                    :categories="filteredCategories" 
                                                    :events="events"
                                                    :event-color="getEventColor" 
                                                    @click:event="showBookingDetail" 
                                                    @change="calenderChange"
                                                    :key="calendarKey"
                                                    >
                                                        <!--template #category>
                                                            <p>{{category}}</p>
                                                        </template-->
                                                    </v-calendar>
                                                        
                                                </v-sheet>
                                            </v-col>
                                            <!-- End Col Left -->
                                            <!-- <v-col
                                                cols="12"
                                                sm="12"
                                                md="4"
                                                lg="3">
                                                <v-select
                                                v-if="!showCalendarEmployer"
                                                v-model="filteredCategories"
                                                :items="categories"
                                                label="Select employees"
                                                multiple
                                                hint="You will see appointment for each employees"
                                                persistent-hint
                                                ></v-select>
                                                <template v-if="sellersBookings.length">

                                                    <p class="page--description text-center">Your last Booking on wellbookings</p>

                                                    <BookingsListMerchant :bookingsProp="getSellersBookings" :loading="bookingLoading" @goToBookingDetail="showBookingDetail">
                                                    </BookingsListMerchant>

                                                    <div class="Profile-btn" v-if="showLocation">

                                                        <div>Location</div>
                                                        <template v-if="getCurrentLocation">
                                                            <div>
                                                                {{'Longitude: ' + getCurrentLocation.lng + ' Latitude: ' + getCurrentLocation.lat}}
                                                            </div>
                                                        </template>
                                                        <v-btn @click="getLocation" class="btn btn-primary">Reset
                                                        </v-btn>
                                                    </div>

                                                </template>
                                                <template v-else>
                                                    <div class="page--no-booking">
                                                        <p class="page--description">No booking</p>

                                                        <v-btn text @click="getBookings()" outlined class="btn btn-browse" color="primary">
                                                            Refresh bookings
                                                        </v-btn>
                                                    </div>
                                                </template>
                                            </v-col> -->
                                        </v-row>
                                    </div>
                                </v-container>
                            </v-tab-item>

                            <v-tab-item
                                key="2"
                                :value="'tab-2'"
                            >
                                <v-container fluid>
                                    <div class="dashbord-main-container botton-container">
                                        <SellerBookingsList></SellerBookingsList>
                                    </div>
                                </v-container>
                            </v-tab-item>

                            <v-tab-item
                                key="3"
                                :value="'tab-3'"
                            >
                                <v-container fluid>
                                    <div class="dashbord-main-container botton-container">
                                        <SellerAddBooking></SellerAddBooking>
                                    </div>
                                </v-container>
                            </v-tab-item>

                            <v-tab-item
                                key="4"
                                :value="'tab-4'"
                            >
                                <v-container fluid>
                                    <div class="dashbord-main-container botton-container">
                                        <SellerMyCustomers></SellerMyCustomers>
                                    </div>
                                </v-container>
                            </v-tab-item>

                            <v-tab-item
                                key="5"
                                :value="'tab-5'"
                            >
                                <v-container fluid>
                                    <div class="dashbord-main-container botton-container">
                                        <SellerSupport></SellerSupport>
                                    </div>
                                </v-container>
                            </v-tab-item>

                            <v-tab-item
                                key="6"
                                :value="'tab-6'"
                            >
                                <v-container fluid>
                                    <div class="dashbord-main-container botton-container">
                                        <SellerReports></SellerReports>
                                    </div>
                                </v-container>
                            </v-tab-item>

                        </v-tabs-items>
                    </div>

                </section>
            <!-- </v-col>
        </v-row>
    </v-container> -->
</template>

<script>
    import mixinShared from '@/mixins/Shared'
    //import BookingsListMerchant from '@/components/widgets/BookingsListMerchant.vue'
    import SellerAddBooking from '@/views/Seller/SellerAddBooking.vue'
    import SellerBooking from '@/views/Seller/SellerBooking.vue'
    import SellerReports from '@/views/Seller/SellerReports.vue'
    import SellerMyCustomers from '@/views/Seller/SellerMyCustomers.vue'
    import SellerBookingsList from '@/views/Seller/SellerBookingsList.vue'
    import SellerSupport from '@/views/Seller/SellerSupport.vue'
    import CustomerDialog from '@/components/CustomerDialog.vue';

    import {
        mapGetters,
        mapState
    } from 'vuex'


    export default {
        mixins: [mixinShared],
        data() {
            return {
                tab: null,
                showCalendarEmployer: false,
                sellersBookings: [],
                sellerServices: [],
                showLocation: false,
                bookingLoading: false,

                focus: '',
                events: [],
                colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
                names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
                categories: [],
                filteredCategories: [],
                users: [],
                selectedBooking:{},
                showSelectedBooking:false,
                viewType:'category',
                calendarKey:2
            };
        },
        components: {
            //BookingsListMerchant,
            SellerAddBooking,
            SellerReports,
            SellerMyCustomers,
            CustomerDialog,
            SellerBookingsList,
            SellerBooking,
            SellerSupport
        },
        computed: {
            ...mapGetters([
                "getCurrentLocation",
                "isLoggedIn",
                "isSellerLoggedIn",
                "getProfileData",
                "getSellerProfileData",
                "getSellersBookings",
                "isMobile"
            ]),
            getServices() {
                console.log('getSellerProfileData', this.getSellerProfileData.seller.services);
                return this.getSellerProfileData.seller.services
            },
            getWorkHours() {
                return JSON.parse(this.getSellerProfileData.seller.working_hours);
            }
        },
        mounted() {
            this.users = this.getSellerProfileData.seller.users;

            for (const user of this.users) {
                this.categories.push(user.name);
            }

            if(this.isMobile){
                this.filteredCategories.push(this.categories[0]);
            }else{
                this.filteredCategories = this.categories;
            }

            console.log("SellerDashboard --- mounted")
            if (!this.isSellerLoggedIn) {
                this.goToSellerLogin(true);
            } else {
                this.getBookings();
                this.getSellersBookings
            }
            this.$refs.calendar.checkChange();
        },
        methods: {
            setTodayView() {
                this.viewType = 'category';
            },
            setWeekView() {
                this.viewType = 'week';
            },
            goToProfile() {

                this.$router.push({
                    name: 'seller-profile'
                });
            },
            goToAddBooking() {

                this.$router.push({
                    name: 'seller-add-booking'
                });
            },
            showBookingDetail(item) {
                console.log("goToBookingDetail", item);

                this.selectedBooking = item.event.booking;
                this.showSelectedBooking = true;
            },
            goToBookingDetail(item) {
                console.log("goToBookingDetail", item);

                this.$router.push({
                    name: 'seller-booking',
                    params: {
                        booking: item.event.booking
                    }
                });
            },
            getEventColor(event) {
                return event.color
            },
            setToday() {
                this.focus = ''
            },
            prev() {
                this.$refs.calendar.prev()
            },
            next() {
                this.$refs.calendar.next()
            },
            rnd(a, b) {
                return Math.floor((b - a + 1) * Math.random()) + a
            },
            calenderChange() {

            },
            getInternalBookings(){

                this.$router.push({
                    name: 'seller-add-booking'
                });
            },
            getReport(){

                this.$router.push({
                    name: 'seller-reports'
                });
            },
            getBookingColor(booking) {

                if(booking.block_booking === 1){

                     return 'grey';

                }else if(booking.status === 'Approved'){

                     return 'green';

                }else if(booking.status === 'Completed'){

                     return 'green';

                }else if(booking.status === 'Rejected' || booking.status === 'Canceled'){

                     return 'red';

                }else{
                    return 'teal';
                    //return this.colors[this.rnd(0, this.colors.length - 1)];
                }
            },
            bookingStatusChanged(status){
                console.log('status',status);

                let objIndex =  this.events.findIndex((obj => obj.booking.id === this.selectedBooking.id));
                this.events[objIndex].booking.status = status;
                this.events = [...this.events];

                console.log(' this.events[objIndex].booking.status', this.events[objIndex].booking.status);
                this.calendarKey = this.calendarKey+1;

            },
            getBookings() {

                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');
                //sellerToken = sellerToken.replace(/['"]+/g, '');

                console.log("sellerToken", sellerToken);

                fetch(appURL + '/api/seller/bookings', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + sellerToken
                    }
                    // body: JSON.stringify({
                    //     ...this.service
                    // })
                }).then(response => {
                    console.log('response------------');
                    console.log(response);

                    if (response.status === 200) {

                        response.json().then(result => {
                            console.log('result', result);

                            this.sellersBookings = result.success;
                            this.$store.dispatch('setSellersBookings', result.success);

                            for (let booking of this.sellersBookings) {

                                console.log('booking', booking);
                                const first = new Date(booking.booking_from)
                                const second = new Date(booking.booking_to)
                                const employee = this.users.filter(item => item.id === booking
                                    .seller_user_id)[0]

                                this.events.push({
                                    name: (booking.service?booking.service.service_name:'Block'),
                                    start: first,
                                    end: second,
                                    color: this.getBookingColor(booking),
                                    timed: true,
                                    category: employee.name,
                                    booking:booking
                                })
                            }

                            console.log('this.events', this.events)

                        });

                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                    } else if (response.status === 422) {
                        //failed logn
                        alert('Not Authenticated');
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 398');
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                    this.processing = false;
                    alert(error)
                });

            },

        },
    }
</script>

<style lang="scss" scoped>
    // End custom common variables
    .page-dashboard {
        .dashboard-top-container {
            border-bottom: 1px solid rgba(255,255,255,0.15);
            padding: .5rem 0;
            margin-bottom: .5rem;
        }
    }
    // Custom styles
    .products-list-container {
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        padding: 1rem 0;

        .products-list-top {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: space-between;
            padding: 1rem;

            .page--subtitle {
                font-weight: bold;
            }

            .btn {
                font-size: 120% !important;
                font-weight: bold;
            }
        }
    }
</style>