<template>
        <v-dialog 
        v-model="dialog" 
        style="z-index: 9991;"
        scrollable max-width="300px"
        :fullscreen="isMobile"
        :hide-overlay="isMobile"
        transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card-title @click="getGPSLocation()" style="cursor:pointer">
                <v-icon
                large
                color="teal darken-2"
                >
                mdi-crosshairs-gps
                </v-icon>
                Current Location
                </v-card-title>

                <!-- <v-divider></v-divider>

                <v-card-title @click="selectLocation()">
                <v-icon
                large
                color="teal darken-2"
                >
                mdi-map
                </v-icon>
                Select on Map
                </v-card-title> -->

                <v-divider></v-divider>

                <v-card-title>
                <v-icon
                large
                color="teal darken-2"
                >
                mdi-earth
                </v-icon>
                Select Country
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                    <v-radio-group v-model="selectedCountry" column>
                        <v-radio v-for="country in location" :key="country.value" :label="country.label" :value="country"></v-radio>
                    </v-radio-group>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-title v-if="!isEmpty(selectedCountry)" style="cursor:pointer">
                <v-icon
                large
                color="teal darken-2"
                >
                mdi-city
                </v-icon>
                Select City
                </v-card-title>

                <v-divider v-if="!isEmpty(selectedCountry)"></v-divider>

                <v-card-text v-if="!isEmpty(selectedCountry)">
                    <v-radio-group v-model="selectedCity" column>
                        <v-radio v-for="city in selectedCountry.cities" :key="city.value" :label="city.label" :value="city" @click="setCountryCity()"></v-radio>
                    </v-radio-group>
                </v-card-text>
                <!-- <v-divider></v-divider>
                <v-card-actions>
                    <v-btn
                        class="ml-2 mt-0"
                        color="primary"
                        rounded
                        small
                        @click="setCountryCity()"
                    >
                        Done
                    </v-btn>
                </v-card-actions> -->
            </v-card>
        </v-dialog>
</template>

<script>
    import {
        mapGetters,
        mapState
    } from 'vuex'

    import mixinShared from '@/mixins/Shared'
    import { bus } from '../main'

    export default {
        data() {
            return {
                dialogm1: '',
                dialog: false,
                selectedCountry:{},
                selectedCity:{},
                location: [{
                        label: "Malta",
                        value: "malta",
                        cities: [{
                                label: "Sliema",
                                value: "sliema",
                                latitude:35.912842,
                                longitude:14.501440

                            },
                            {
                                label: "St Julians",
                                value: "stjulians",
                                latitude:35.913761,
                                longitude:14.489910
                            },
                            {
                                label: "Attard",
                                value: "attard",
                                latitude:35.892110,
                                longitude:14.440947
                            }
                        ]
                    },
                    {
                        label: "United Kingdom",
                        value: "united_kingdom",
                        cities: [{
                                label: "London",
                                value: "london",
                                latitude:51.507567,
                                longitude:-0.125989
                            }
                        ]
                    },

                    {
                        label: "Saint Lucia",
                        value: "saint_lucia",
                        cities: [{
                                label: "Vieux Fort",
                                value: "vieux_fort",
                                latitude:13.749640,
                                longitude:-60.970429
                            }
                        ]
                    },
                    {
                        label: "The Netherlands",
                        value: "the_netherlands",
                        cities: [{
                                label: "Amsterdam",
                                value: "amsterdam",
                                latitude:52.376962,
                                longitude:4.898450
                            },{
                                label: "Den Haag",
                                value: "den_haag",
                                latitude:52.078135,
                                longitude:4.312916
                            }
                        ]
                    }
                ]
            }
        },
        created() {
            this.dialog = this.isEmpty(this.getCurrentLocation);

            bus.$on('showLocationDialog', (data) => {
                this.dialog = true;
            })
        },
        mounted() {

        },
        computed: {
            ...mapGetters([
                "isMobile",
                "getCurrentLocation"
            ])
        },
        methods: {
            isEmpty(obj){
                return obj && Object.keys(obj).length === 0 && obj.constructor === Object
            },
            selectLocation(){

            },
            getGPSLocation(){
                //request gps location
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(this.getLocation);
                } else {
                    console.log("Geolocation is not supported by this browser.");
                }

                this.dialog = false;
            },
            getLocation(position) {

                //if geo is set
                if(position.coords.latitude){

                    console.log('[Home - location]', position);
                    let finalLocation = {
                      useGeo:true,
                      lat:position.coords.latitude,
                      lng:position.coords.longitude,
                      speed:position.coords.speed,
                      timestamp:position.timestamp
                    }


                    this.$store.dispatch("setLocation", finalLocation);

                }else{
                    console.log('[no geo]');

                    fetch( localStorage.getItem('appURL') + '/api/client/search/whereami', {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        }
                    }).then(response => {
                
                        console.log(response);
                
                        if (response.status === 200) {
                            response.json().then(result => {

                            console.log('[Home - location]', result.success);

                            this.location = {
                                useGeo:true,
                                lat:position.coords.latitude,
                                lng:position.coords.longitude,
                                speed:position.coords.speed,
                                timestamp:position.timestamp
                            }

                            this.$store.dispatch("setLocation", this.location);

                            });
                        }  else {
                            // unkown error
                            console.log('[cant get location]');
                        }
                
                    }).catch((error) => {
                        console.log('[GPSLocation http request error]', error);
                    });

                }


                this.dialog = false;

            },

            setCountryCity(){
                    let finalLocation = {
                      useGeo:false,
                      lat:this.selectedCity.latitude,
                      lng:this.selectedCity.longitude,
                      country:this.selectedCountry,
                      city:this.selectedCity
                    }


                    this.$store.dispatch("setLocation", finalLocation);
                    this.dialog = false;
            }
        },
    }
</script>
<style>
.v-dialog__content--active{
    z-index: 9991!important;
}
.v-overlay--active{
    z-index: 9991!important;
}
</style>