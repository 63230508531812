<template>
    <v-container class="fill-height">
    <v-layout column class="fill-height">
            <template v-if="SelectedService" app>

        <v-flex
          class="flex overflow-auto"
        >
                        <v-stepper v-model="checkoutStep">
                            <!-- STEP 1 - BOOKING HEAD-->
                            <!-- <v-stepper-step :complete="checkoutStep > 1" step="1">
                                Employee
                                <small>{{ SelectedSeller.name }}</small>
                            </v-stepper-step> -->

                            <!-- STEP 1 - BOOKING -->
                            <v-stepper-content step="1">
                                <div class="booking-calendar">
                                    <v-form ref="booking-form" v-model="booking.valid">
                                        <v-container class="pa-0 pt-5" fluid>
                                            <v-row justify="center">
                                                <v-col cols="12" xs="12" sm="12">
                                                    <!-- {{ selectedDate}} -->
                                                    <!-- <v-radio-group v-model="booking.seller_user_id" @change="getAvailability()"
                                                        column>

                                                        <span v-for="employee in employees" :key="employee.email">
                                                            <v-radio :label="employee.name" color="orange darken-3"
                                                                :value="employee.id">
                                                            </v-radio>

                                                        </span>

                                                    </v-radio-group> -->

                                                    <v-list subheader>
                                                        <v-subheader>Employee</v-subheader>

                                                        <v-list-item-group
                                                            v-model="listGroupSelectedEmployee"
                                                            color="primary"
                                                        >
                                                            <v-list-item v-for="employee in employees" :key="employee.email" v-show="employee.active===1" @click="employeeSelect(employee)">
                                                                <v-list-item-avatar >
                                                                    <v-img v-if="employee.avatar !== null" :alt="`${employee.name} avatar`"
                                                                        :src="'https://api.wellbookings.com/storage/sellerProfileImages/'+employee.avatar">
                                                                    </v-img>
                                                                    <v-img v-else :alt="`${employee.name} avatar`"
                                                                        src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png">
                                                                    </v-img>
                                                                </v-list-item-avatar>

                                                                <v-list-item-content>
                                                                    <v-list-item-title v-text="employee.name.slice(0, 20)"></v-list-item-title>
                                                                    <v-list-item-subtitle>
                                                                    </v-list-item-subtitle>
                                                                </v-list-item-content>


                                                            </v-list-item>
                                                        </v-list-item-group>

                                                    </v-list>


                                                </v-col>

                                            </v-row>
                                            <!-- <v-text-field class="textfield" v-model="note" /> -->
                                        </v-container>
                                    </v-form>
                                </div>
                                <template>

                                    <v-btn color="primary" @click="checkoutStep = 2"
                                        :disabled="booking.seller_user_id === null">
                                        Continue
                                    </v-btn>

                                </template>

                            </v-stepper-content>
                            <!-- END BOOKING STEP 1 -->

                            <!-- STEP 1 - BOOKING HEAD-->
                            <!-- <v-stepper-step :complete="checkoutStep > 2" step="2">
                                Date and Time
                                <small>{{ SelectedSeller.name }}</small>
                            </v-stepper-step> -->

                            <!-- STEP 1 - BOOKING -->
                            <v-stepper-content step="2">
                                <div class="booking-calendar">
                                    <v-form ref="booking-form" v-model="booking.valid">
                                        <v-container class="pa-0 pt-5" style="">
                                            <v-row justify="center">

                                                <v-col cols="12" xs="12" sm="12">
                                                    <Calender @selectedDate="selectDate"></Calender>
                                                </v-col>
                                            </v-row>
                                            <v-row justify="center">
                                                <v-col cols="12" xs="12" sm="12">
                                                    <v-radio-group v-model="booking.time" row>

                                                        <span v-for="avail in availability" :key="avail.start">
                                                            <v-radio :label="avail.start" color="teal darken-3"
                                                                :value="avail.start" :disabled="!avail.available">
                                                            </v-radio>

                                                        </span>

                                                    </v-radio-group>
                                                </v-col>


                                                <v-col cols="12">
                                                    <p class="page--description">
                                                        <strong>Time : </strong>{{ SelectedService.time_interval }}mins<br>
                                                        <strong>Total : </strong>{{ SelectedService.price }}
                                                        {{ SelectedService.currency }}
                                                    </p>
                                                </v-col>
                                            </v-row>
                                            <!-- <v-text-field class="textfield" v-model="note" /> -->
                                        </v-container>
                                    </v-form>
                                </div>
                                <template>
                                    <v-btn @click="checkoutStep = 1" text>
                                        Back
                                    </v-btn>

                                    <v-btn color="primary" @click="checkoutStep = 3"  :disabled="booking.time === null">
                                        Continue
                                    </v-btn>
                                </template>

                            </v-stepper-content>
                            <!-- END BOOKING STEP 1 -->


                            <!-- STEP 2 - BOOKING USER INFO -->
                            <!-- <v-stepper-step :complete="checkoutStep > 3" step="3">
                                Add your Data
                            </v-stepper-step> -->

                            <v-stepper-content step="3">
                                <div class="booking-user-details">
                                    <v-container class="pa-0 pt-5" fluid>
                                        <v-row class="pa-0" dense>
                                            <v-col cols="12">

                                                <v-text-field dense outlined height="50" class="input--big-icon" id="name"
                                                    label="Name" v-model="getProfileData.name" name="booking-name"
                                                    prepend-icon="mdi-account-outline" type="text"></v-text-field>
                                            </v-col>

                                            <v-col cols="12">

                                                <v-text-field dense outlined height="50" class="input--big-icon" id="email"
                                                    label="Email" ref="email" v-model="getProfileData.email"
                                                    name="booking-email" prepend-icon="mdi-email-outline" type="text">
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12">

                                                <v-text-field dense outlined height="50" class="input--big-icon" id="country"
                                                    label="Country" ref="country" v-model="getProfileData.country"
                                                    name="booking-country" prepend-icon="mdi-domain" type="text"></v-text-field>
                                            </v-col>
                                            <v-col cols="12">

                                                <v-text-field dense outlined height="50" class="input--big-icon" id="city"
                                                    label="City" ref="city" v-model="getProfileData.city" name="booking-city"
                                                    prepend-icon="mdi-domain" type="text"></v-text-field>
                                            </v-col>
                                            <v-col cols="12">

                                                <v-text-field dense outlined height="50" class="input--big-icon" id="state"
                                                    label="State" ref="state" v-model="getProfileData.state"
                                                    name="booking-state" prepend-icon="mdi-domain" type="text"></v-text-field>
                                            </v-col>
                                            <v-col cols="12">

                                                <v-text-field dense outlined height="50" class="input--big-icon" id="postalcode"
                                                    label="Postal Code" ref="postalcode" v-model="getProfileData.postalcode"
                                                    name="booking-postalcode" prepend-icon="mdi-domain" type="text">
                                                </v-text-field>
                                            </v-col>

                                            <v-col cols="12" v-if="SelectedService.allow_come_to_me">

                                                <v-checkbox v-model="booking.come_to_me" label="Come to me" color="red"
                                                    value="red" hide-details :disabled="SelectedService.come_to_me_only === 1 || SelectedService.come_to_me_only? true : false"></v-checkbox>

                                                <v-col cols="12" xs="12" sm="12" class="py-0" v-if="booking.come_to_me">
                                                    <p class="page--description">Address to meet</p>
                                                    <div class="note-container">
                                                        <v-textarea outlined height="150" class="input--big-icon"
                                                            v-model="booking.additional.meetupLocation"
                                                            prepend-icon="mdi-note-outline" label="Address to meet">
                                                        </v-textarea>
                                                    </div>
                                                </v-col>
                                                    <v-col 
                                                        cols="12"
                                                        xs="12"
                                                        sm="12"
                                                        md="12"
                                                        lg="12">
                                                        <v-row id="mapContainerDetectWidthHeight">
                                                            Please tap on map to select a location.
                                                            <LocationPicker
                                                            :lat="SelectedSeller.lat"
                                                            :lng="SelectedSeller.lng"
                                                            @setLocation="setGPSLocation"
                                                            ></LocationPicker>
                                                        </v-row>
                                                    </v-col>

                                            </v-col>

                                            <v-col cols="12" xs="12" sm="12" class="py-0">
                                                <v-textarea outlined height="150" class="input--big-icon" v-model="booking.note"
                                                    prepend-icon="mdi-note-outline" label="Note for seller"></v-textarea>
                                            </v-col>

                                            <v-col cols="12">

                                                <v-btn @click="checkoutStep = 2" text>
                                                    Back
                                                </v-btn>
                                                <v-btn color="primary" @click="checkoutStep = 4">
                                                    Continue
                                                </v-btn>
                                            </v-col>

                                        </v-row>

                                    </v-container>
                                    <!-- End Container -->
                                </div>
                            </v-stepper-content>
                            <!-- END STEP 2 - USER INFO -->

                            <!-- STEP 3 - BOOKING CC -->
                            <!-- <v-stepper-step :complete="checkoutStep > 4" step="4">
                                Complete &amp; Pay
                            </v-stepper-step> -->

                            <v-stepper-content step="4">
                                <div class="booking-cc-details">
                                    <h4 class="page--subtitle">
                                        <span class="primary--text">{{ SelectedService.service_name }} </span><br>
                                        <small>{{ SelectedSeller.name }}</small>

                                        <v-list-item v-if="selectedEmployeeObj">
                                            <v-list-item-avatar >
                                                <v-img v-if="selectedEmployeeObj.avatar !== null" :alt="`${selectedEmployeeObj.name} avatar`"
                                                    :src="'https://api.wellbookings.com/storage/sellerProfileImages/'+selectedEmployeeObj.avatar">
                                                </v-img>
                                                <v-img v-else :alt="`${selectedEmployeeObj.name} avatar`"
                                                    src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png">
                                                </v-img>
                                            </v-list-item-avatar>

                                            <v-list-item-content>
                                                <v-list-item-title v-text="selectedEmployeeObj.name.slice(0, 20)"></v-list-item-title>
                                                <v-list-item-subtitle>
                                                </v-list-item-subtitle>
                                            </v-list-item-content>

                                        </v-list-item>
                                    </h4>
                                    <!-- <CreditCardView id="creditCard" ref="ccview"></CreditCardView> -->


                                    <p class="page--description mt-5">
                                        <v-icon color="primary">mdi-clock-outline</v-icon>
                                        {{ this.booking.date }} at {{ this.booking.time}}

                                    </p>

                                    <p class="page--description">
                                        Pay for your treatment in advance so that you won't have to handle cash at the venue

                                    </p>



                                    <h5 class="page--subtitle primary--text">Credit Card Data</h5>
                                    <v-radio-group v-model="paymentMethod">
                                        <v-radio
                                            v-for="item in availablePaymentMethods"
                                            :key="item.name"
                                            :label="`${item.display}`"
                                            :value="`${item.name}`"
                                        ></v-radio>
                                    </v-radio-group>
                                    <v-form ref="cc-form" v-model="creditcard.valid" v-if="paymentMethod === 'accept_card'">
                                        <v-container class="pa-0 pt-5" fluid>
                                            <v-row class="pa-0" dense>
                                                <v-col cols="12">

                                                    <v-text-field outlined height="50" class="input--big-icon" id="cc-name"
                                                        label="Holder Name" v-model="creditcard.name" name="cc-name"
                                                        :rules="[rules_cc.required]" prepend-icon="mdi-account-outline"
                                                        type="text">
                                                    </v-text-field>
                                                </v-col>

                                                <v-col cols="12">

                                                    <v-text-field outlined height="50" class="input--big-icon" id="cc-number"
                                                        label="Card Number" ref="cc-number" v-model="creditcard.number"
                                                        :rules="[rules_cc.ccnumber]" name="cc-number"
                                                        prepend-icon="mdi-credit-card-outline" type="number" counter>
                                                    </v-text-field>
                                                </v-col>
                                                <!-- End Col -->

                                                <v-col cols="6" sm="6" md="12">
                                                    <v-text-field outlined height="50" class="input--big-icon" id="cc-expdate"
                                                        label="Expiration Date" ref="cc-expdate" v-model="creditcard.exp"
                                                        name="cc-expdate" counter="4"
                                                        prepend-icon="mdi-credit-card-clock-outline" :rules="[rules_cc.ccexp]"
                                                        type="number"></v-text-field>
                                                </v-col>
                                                <!-- End Col -->
                                                <v-col cols="6" sm="6" md="12">
                                                    <v-text-field outlined height="50" class="input--big-icon" id="cc-cvc"
                                                        label="CVC" counter="3" ref="cc-cvc" v-model="creditcard.cvc"
                                                        :rules="[rules_cc.cvc]" name="cc-cvc"
                                                        prepend-icon="mdi-credit-card-sync-outline" type="number">
                                                    </v-text-field>
                                                </v-col>
                                                <!-- End Col -->

                                                <v-col cols="12">
                                                    <p class="page--description">
                                                        <strong class="primary--text">Total :
                                                        </strong>{{ SelectedService.price }}
                                                        {{ SelectedService.currency }}
                                                    </p>
                                                </v-col>
                                                <!-- End Col -->


                                                <v-col cols="12">
                                                    <div class="btn-container">
                                                        <v-btn @click="checkoutStep = 3" text>
                                                            Back
                                                        </v-btn>
                                                        <!-- End Btn -->

                                                        <v-btn class="btn-booking ml-5" color="primary" @click="Book"
                                                            :disabled="!creditcard.valid">
                                                            Pay &amp; Confirm
                                                        </v-btn>

                                                        <!-- End Btn -->
                                                        <!-- <v-btn 
                                                        @click="Book" 
                                                        :disabled="!creditcard.valid"
                                                        class="btn btn-primary">Book
                                                        </v-btn> -->
                                                    </div>
                                                </v-col>


                                                <v-col cols="12">
                                                    <label class="label--privacy">By continuing you agree with our <a
                                                            href="#">Booking Terms</a></label>

                                                </v-col>

                                            </v-row>

                                        </v-container>
                                        <!-- End Container -->
                                    </v-form>


                                    <v-row v-if="paymentMethod === 'accept_cash'">
                                        <v-col cols="12">
                                            <div class="btn-container">

                                                <v-col cols="12">
                                                    <p class="page--description" v-if="booking.come_to_me">
                                                        <strong class="primary--text">Come to me :
                                                        </strong>{{ SelectedService.come_to_me_additional_fee }}
                                                        {{ SelectedService.currency }}
                                                    </p>
                                                    <p class="page--description"  v-if="!booking.come_to_me">
                                                        <strong class="primary--text">Total :
                                                        </strong>{{ SelectedService.price }}
                                                        {{ SelectedService.currency }}
                                                    </p>
                                                    <p class="page--description"  v-if="booking.come_to_me">
                                                        <strong class="primary--text">Service Price :
                                                        </strong>{{ SelectedService.price }}
                                                        {{ SelectedService.currency }}
                                                    </p>
                                                    <p class="page--description"  v-if="booking.come_to_me">
                                                        <strong class="primary--text">Total :
                                                        </strong>{{ SelectedService.price + SelectedService.come_to_me_additional_fee }}
                                                        {{ SelectedService.currency }}
                                                    </p>
                                                </v-col>

                                                <v-btn @click="checkoutStep = 3" text>
                                                    Back
                                                </v-btn>
                                                <!-- End Btn -->

                                                <v-btn class="btn-booking ml-5" color="primary" @click="Book">
                                                    Pay &amp; Confirm
                                                </v-btn>

                                                <!-- End Btn -->
                                                <!-- <v-btn 
                                                @click="Book" 
                                                :disabled="!creditcard.valid"
                                                class="btn btn-primary">Book
                                                </v-btn> -->
                                            </div>
                                        </v-col>


                                        <v-col cols="12">
                                            <label class="label--privacy">By continuing you agree with our <a
                                                    href="#">Booking Terms</a></label>
                                        </v-col>

                                    </v-row>

                                </div>
                            </v-stepper-content>
                            <!-- END STEP 3 - USER INFO -->

                        </v-stepper>
        </v-flex>


                        
        <!-- <v-flex class="white--text blue flex shrink darken-3">   
            <v-card-text>
              Footer
            </v-card-text>
        </v-flex> -->

            </template>
            <template v-else>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <div class="empty-cart page--top-container text-center">
                                <v-icon color="primary" class="icon-logo">mdi-shopping-outline</v-icon>

                                <h1 class="page--title">Empty Cart</h1>
                                <p class="page--description">
                                    No services on your cart. Start now!

                                </p>
                                <v-btn link text to="/explore" x-large outlined class="btn btn-browse" color="primary">
                                    Explore
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
    </v-layout>
    </v-container>
</template>

<script>
    import {
        mapGetters,
        mapState
    } from 'vuex'

    import mixinShared from '@/mixins/Shared'

    var moment = require("moment");
    import Calender from "../components/Calender";
    import LocationPicker from "@/components/LocationPicker"
    // import TimePicker from "../components/TimePicker";
    // import CalendarBooking from "../components/widgets/CalendarBooking";

    export default {
        mixins: [mixinShared],
        props: {
            sellerService: {
                type: Object,
                //         empty: true
                //     };
                // }
            },
            sellerInfo: {
                type: Object,
                // default () {
                //     return {
                //         empty: true
                //     };
                // }
            },
        },
        components: {
            // CalendarBooking,
            Calender,
            LocationPicker,
            // TimePicker
        },
        data() {
            return {
                // 
                SelectedService: {},
                SelectedSeller: {},
                // Step init
                checkoutStep: 1,
                // Skip the 2nd step for add user information
                skipAccountData: false,
                // Show/hide note textarea
                showNote: false,
                // Booking Info
                booking: {
                    valid: false,
                    time: null,
                    date: moment().format('YYYY-MM-DD'),
                    note: '',
                    seller_user_id: null,
                    come_to_me: false,
                    additional: {
                        meetupLocation: '',
                        meetuplat:0,
                        meetuplng:0,
                    },
                    invoice:{

                    }
                },
                // CC Form
                creditcard: {
                    valid: false,
                    name: '',
                    number: null,
                    exp: null,
                    cvc: null,
                },
                paymentMethod:{

                },
                // serviceA: {},
                // merchantA: {},
                // picker: new Date().toISOString().substr(0, 10),
                // selectedDate: moment().format('YYYY-MM-DD'),
                // selectedTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                //note: null,
                stripeObj: null,
                availability: [],
                employees: [],
                listGroupSelectedEmployee:{},
                selectedEmployeeObj:null
            };
        },

        mounted() {
            // If user is not logged in
            if (!this.isLoggedIn && !this.isSellerLoggedIn) {
                this.goToLogin(true,true);
                this.$emit('closeDialog', true);
            }
            // If params value are empty redirect to explore
            if (!this.SelectedService || !this.SelectedSeller) {
                //this.goToExplore()
                this.$emit('closeDialog', true);
            }

            this.getAvailability();

            console.log('this.sellerInfo', this.sellerInfo);
            this.employees = this.sellerInfo.users;

            this.booking.come_to_me = this.SelectedService.come_to_me_only;
        },
        created() {
            if (this.sellerService == undefined || this.sellerInfo == undefined) {
                this.SelectedService = this.getCart.service
                this.SelectedSeller = this.getCart.seller
            } else {
                this.SelectedService = this.sellerService
                this.SelectedSeller = this.sellerInfo
            }
        },
        computed: {
            availablePaymentMethods() {
                let arr = [];

                let temppaymethods = {};

                try{
                    temppaymethods = JSON.parse(this.sellerInfo.payment_methods);
                }catch(e){
                    temppaymethods = this.sellerInfo.payment_methods;
                }

                for (let [key, value] of Object.entries(temppaymethods)) {
                    console.log('paymentMethods-value',value);
                    console.log('paymentMethods-key',key);
                    if(value){
                        arr.push({"name":key,"active":value,'display':key.replace('accept_','')})
                    }
                }
                return arr;
            },
            ...mapGetters([
                "getCurrentLocation",
                "isLoggedIn",
                "isSellerLoggedIn",
                "getProfileData",
                "getCart"
            ])
        },
        methods: {

            setGPSLocation(location){
                this.booking.additional.meetuplat = location.lat;
                this.booking.additional.meetuplng = location.lng;
            },
            employeeSelect(emplyee){
                this.selectedEmployeeObj = emplyee;
                this.booking.time = null;
                this.booking.seller_user_id = emplyee.id;
                this.getAvailability();
            },
            getAvailability() {
                console.log('getting availability');

                var appURL = localStorage.getItem('appURL');
                var token = localStorage.getItem('token');

                //get availability times
                fetch(appURL + '/api/client/booking/getAvailabilityTimeSlotes', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + token
                    },
                    body: JSON.stringify({
                        seller_user_id: this.booking.seller_user_id,
                        seller_service_id: this.SelectedService.id,
                        seller_id: this.SelectedSeller.id,
                        additional: this.booking.additional,
                        invoice: {
                            total: this.SelectedService.price,
                            currency: this.SelectedService.currency
                        },
                        date: this.booking.date
                    })
                }).then(response => {
                    console.log("availability RESULT", response);

                    if (response.status === 200) {
                        response.json().then(result => {
                            console.log('result', result.success);
                            this.availability = result.success;
                        });

                    } else {
                        // unkown error
                        //alert('please login again !!');
                        this.goToLogin(true,true);
                        this.$emit('closeDialog', true);
                        console.log('Unexpected error with API server 398');
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                    this.processing = false;
                    alert(error)
                });
            },

            submitPayment() {

            },
            selectDate(val) {
                this.booking.date = moment(val).format('YYYY-MM-DD');
                //this.selectedDate = val;
                this.getAvailability();
            },

            Book() {
                var appURL = localStorage.getItem('appURL');
                var token = localStorage.getItem('token');

                console.log('TimeOBJ', this.booking.date);
                console.log('TimeOBJ', this.booking.time);

                //var timestring = moment(this.booking.date).format('YYYY-MM-DD') + ' ' + moment(this.booking.time).format('HH:mm');
                //var startdate = moment(this.booking.date);

                var timestring = this.booking.date + ' ' + this.booking.time

                console.log('timestring', timestring);
                //console.log('startdate', startdate);

                let interval
                // Interval - remove letter from string
                if (this.SelectedService.interval) {
                    interval = this.SelectedService.interval.replace(/\D/g, '')
                    interval = parseInt(interval)
                } else {
                    interval = 1
                }

                console.log("interval", interval)

                let totalObj = {};
                let total = this.SelectedService.price;
                totalObj.total = total;
                totalObj.service_fee = this.SelectedService.price;
                totalObj.paymentmethod = 'cash';
                totalObj.paid = false;
                if(this.booking.come_to_me){
                    total = total + this.SelectedService.come_to_me_additional_fee;
                    totalObj.total = total;
                    totalObj.come_to_me_additional_fee = this.SelectedService.come_to_me_additional_fee;
                }


                console.log('bookingobj', JSON.stringify({
                    "seller_service_id": this.SelectedService.id,
                    "seller_user_id": this.booking.seller_user_id,
                    "seller_id": this.SelectedSeller.id,
                    "user_id": this.getProfileData.id,
                    "booking_from": this.booking.date + " " + this.booking.time,
                    "booking_to": moment(timestring).add(interval, 'hours').format('YYYY-MM-DD HH:mm'),
                    "note": this.booking.note,
                    "come_to_me": this.booking.come_to_me,
                    "location_lng": this.SelectedSeller.lng,
                    "location_lat": this.SelectedSeller.lat,
                    "additional": {
                        "meetupLocation": ''
                    },
                    "invoice": {
                        "total": total
                    }

                }));

                fetch(appURL + '/api/client/booking/add', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + token
                    },
                    body: JSON.stringify({
                        seller_service_id: this.SelectedService.id,
                        seller_user_id: this.booking.seller_user_id,
                        seller_id: this.SelectedSeller.id,
                        user_id: this.getProfileData.id,
                        booking_from: timestring,
                        booking_to: moment(timestring).add(interval, 'hours').format('YYYY-MM-DD HH:mm'),
                        note: this.booking.note,
                        location_lng: this.SelectedSeller.lng,
                        location_lat: this.SelectedSeller.lat,
                        come_to_me: this.booking.come_to_me,
                        invoice: {...totalObj},
                        additional:this.booking.additional
                    })
                }).then(response => {
                    console.log("booking RESULT", response);

                    let emptyCart = {}
                    this.$store.dispatch("updateBookingCart", emptyCart)

                    if (response.status === 200) {
                        console.log('booking is created !');
                        // this.$router.go(-1);
                        this.goToSuccess(true);
                        this.$emit('closeDialog', true);

                        response.json().then(result => {
                            console.log('result', result);

                        });

                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                    } else if (response.statusCode === 422) {
                        //failed logn
                        alert(
                            'The following error happened Username or email already taken or password length is to short.'
                        );
                        console.log('------------------------');
                        console.log(response);
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 398');
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                    this.processing = false;
                    alert(error)
                });
            }

        },
        watch: {

            booking: function (val) {
                this.fullName = val + ' ' + this.lastName
            },

        },
    }
</script>

<style scoped>
    .v-input--radio-group.v-input--radio-group--row .v-radio {
        margin-right: 16px;
        background-color: #e1f3f1;
        border-radius: 10px;
        padding: 3px;
        margin: 3px;
    }
    .v-radio--is-disabled {
        pointer-events: none;
        cursor: default;
        background-color: gray!important;
    }
    .v-input--selection-controls__input .v-icon {
        color:white!important;
    }
    .v-application .teal--text.text--darken-3 {
        color: #ffffff !important;
        caret-color: #ffffff !important;
    }
    .v-radio > .v-label {
        color: white!important;
    }
    .empty-cart {
        padding-top: 30%
    }

    .primary--text {
        font-weight: bold;
    }

    .btn-container {
        text-align: center;
        margin-bottom: .5rem;
    }

    .label--privacy {
        display: block;
        text-align: center;
    }

    .v-radio {
        margin: 5px;
    }
</style>