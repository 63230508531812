<template>
    <v-card
    :color="item.color || 'transparent'"
    class="product-card"
    :class="item.service_name"
    @click="setSingleItem(item)"
    elevation="6"
    >
        <v-img
        :src="getImage"
        height="300px"
        class="align-top"
        gradient="to top, rgba(0,0,0,.01), rgba(0,0,0,.5)"
        >
            <div class="product-card-wrapper">
                <v-card-title
                    class="product-card__title headline"
                    v-text="item.name"
                ></v-card-title>
                <v-card-subtitle class="address" v-text="item.street"></v-card-subtitle>
                <v-card-subtitle class="city" v-text="item.city"></v-card-subtitle>
            </div>
            <v-btn
                class="btn btn-see-more"
                outlined
                rounded
                @click="setSingleItem(item)"
            >
            <!-- {{ $t('product.see_more') }} -->
            See More
                <!-- <v-icon>mdi-arrow-right</v-icon> -->

            </v-btn>
        </v-img>
        <div 
        class="product-card__wrapper d-flex flex-no-wrap justify-space-between"
        v-show="false"
        >

            <div class="product-card__right-container">
                <!-- <v-card-title
                    class="product-card__title headline"
                    v-text="item.name"
                ></v-card-title> -->
                <!-- Card Title -->

                <!-- <div class="product-card__ratings">
                    <v-rating
                        v-model="item.ratings.like"
                        background-color="white"
                        color="yellow accent-4"
                        dense
                        half-increments
                        hover
                        size="18"
                    ></v-rating>
                </div> -->

                <!-- <v-card-subtitle v-text="item.street"></v-card-subtitle> -->
                <!-- <v-card-subtitle v-text="item.ratings.like"></v-card-subtitle> -->
                <div class="product-card__services" 
                v-if="showServices && item.services.length">
                    <ul class="product-card__services services-list horizontal">
                        <li 
                            v-for="service in item.services" 
                            :key="service.id"
                            class="services-list__item"
                        >
                            <v-chip
                                pill
                            >
                                <v-avatar left>
                                    <div class="service-list__item-avatar" 
                                    :class="service.service_name"></div>
                                     <v-img :src="service.service_icon || 'https://cdn.vuetifyjs.com/images/john.png'"></v-img> 
                                     <div :class="service.service_icon"></div>
                                </v-avatar>
                                {{ service.service_name }}
                                {{ service.price }} {{ service.currency}}
                            </v-chip>                    
                        </li>
                    </ul>
                </div>

                <v-card-actions
                v-show="false"
                >
                    <v-btn
                        class="ml-2 mt-5"
                        outlined
                        rounded
                        @click="setSingleItem(item)"
                    >
                    <!-- <v-btn
                        class="ml-2 mt-5"
                        outlined
                        rounded
                        link
                        href="/single"
                        @click="setSingleItem(item)"
                    > -->
                        <!-- <v-icon>mdi-arrow-right</v-icon> -->
                    <!-- {{ $t('product.see_more') }} -->
                    See More
                    </v-btn>
                </v-card-actions>
            </div>

        </div>
    </v-card>
</template>

<script>
// import { mapActions } from 'vuex'
import mixinShared from '@/mixins/Shared'

export default {
    mixins: [mixinShared],
    props: {
        // temp
        // num : {
        //     type : Number,
        //     required : false
        // },
        item : {
            type : Object,
            required : false
        },
        design : {
            type : Boolean,
            required : false,
        },
        // This will display the services chips on list
        showServices : {
            type : Boolean,
            required : false,
        }
        
    },
    data: () => ({

    }),
    methods: {
        // ...mapActions({
        //    singleView: 'shop/setSingleView',
        // }),     
        setSingleItem(data) {
         console.log("setSingleItem", data)
            // let data = {
            //     email: this.login.email,
            //     password : this.login.password,
            // }
            //if( this.isFormValid ) {
                this.$store.dispatch('setSingleView',data)
                .then( () => { 
                    let messageInfo = {
                        msg : "welcome",
                        type : "success"
                    }

                    this.$emit('showMsg', messageInfo)
                    console.log("data",data)

                    // this.$router.push('/seller-page') 
                    this.$router.push({
                        name: 'seller-page',
                        params: {
                            singleItem: data,
                            seller_id: data.id
                        }
                    });
                } )
                .catch( (e) => { 
                    let messageInfo = {
                        msg : "error",
                        type : "error"
                    }
                    console.log("error", e)
                    
                    this.$emit('showMsg', messageInfo)
                //this.$toasted.error('wrong email or password', { duration: 3000 }) 
                })
            //}
        },
    },
    // mounted() {
    //     this.item = this.itemData
    // },
    computed: {
    }
}
</script>

<style scoped lang="scss">
.list-products__wrapper-parallax,
.list-products__wrapper-parallax .row,
.list-products__wrapper-parallax .row .col {
    height: 100%;
}

.list-products__container {
    padding: 2rem 0;
    height: 90%;
    margin-top: 5%;
    border-radius: 15px;
    background: #ffffff;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
}

.list-products__container .list-products__avatar{
    /* box-shadow: 0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.4); */
    border: 5px solid #009688;
}

.product-card {
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: .5rem;
    .product-card-wrapper {
        background: rgba(0,0,0,.2);
        max-width: 85%;
        padding: 1rem;
        border-radius: 0 0 25px 0;
        .v-card__title,
        .v-card__subtitle {
            color: #ffffff;
            text-shadow: 0 1px 3px #000000;
            &.address {
                padding-bottom: 0;
            }
            &.city {
                padding-top: 0;
                text-transform: capitalize;
            }
        }
    }

    .btn-see-more {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        font-size: 120%!important;
        border: 3px solid #009688!important;
        background: #009688;
        color: #ffffff;
    }
}



</style>