<template>
    <!-- <v-container>
        <v-row>
            <v-col cols="12"> -->
                <section class="page page-account page-merchant-services">
                    <!-- <div class="page-account--top">{{ getProfileData }}</div> -->
                        <div class="page--top-container">
                        </div>
                        <div class="page--main">
                                <div class="products-list-container">
                                    <div class="products-list-top">
                                    <h4 class="page--subtitle">Service List</h4>
                                    <v-btn 
                                        
                                        text
                                        @click="AddService({})"
                                        outlined
                                        class="btn btn-browse"
                                        color="primary"
                                    >Add Service
                                    </v-btn> 
                                    </div>
                                    <template v-if="getServices.length">
                                        <v-container fluid>
                                            <v-row dense>
                                                <v-col 
                                                    cols="12"
                                                    sm="12"
                                                    md="6"
                                                    xl="4"
                                                    v-for="(item, index) in getServices" 
                                                    :key="sellerServices + '--' + index"
                                                    >
                                                        <ProductsListMerchant 
                                                        :itemProduct="item"
                                                        ></ProductsListMerchant>  
                                                </v-col> 
                                            </v-row>
                                        </v-container>
                                    </template>
                                </div>

                        </div>

                </section>
            <!-- </v-col>
        </v-row>
    </v-container> -->

</template>

<script>
    import mixinShared from '@/mixins/Shared'
    import ProductsListMerchant from '@/components/products/ProductsListMerchant.vue'

    import {
        mapGetters,
        mapState
    } from 'vuex'


    export default {
        mixins: [mixinShared],
        data() {
            return {
                sellerServices: [],
                bookingLoading : false
            };
        },
        components: {
            ProductsListMerchant,
        },        
        computed: {
            ...mapGetters([
                "getCurrentLocation", 
                "isLoggedIn", 
                "isSellerLoggedIn", 
                "getProfileData",
                "getSellerProfileData",
                "getSellersBookings"
            ]),
            getServices() {
                console.log('getSellerProfileData', this.getSellerProfileData.seller.services);
                return this.getSellerProfileData.seller.services
            }
        },
        mounted() {
            console.log("Seller Services --- mounted")
            if (!this.isSellerLoggedIn) {
                this.goToSellerLogin(true);
            } else {
                //this.getBookings();
                this.getSellersBookings
            }                
        },
        methods: {
            // getBookings() {

            //     var appURL = localStorage.getItem('appURL');
            //     var sellerToken = localStorage.getItem('sellerToken');
            //     fetch(appURL + '/api/seller/bookings', {
            //         method: 'POST',
            //         headers: {
            //                 "Content-Type": "application/json",
            //                 "Accept": "application/json",
            //                 "Authorization": "Bearer " + sellerToken
            //         }
            //         // body: JSON.stringify({
            //         //     ...this.service
            //         // })
            //     }).then(response => {


            //         console.log(response);

            //         if (response.status === 200) {

            //         response.json().then(result => {
            //                 console.log('result', result);

            //                 this.sellersBookings = result.success;
            //                 this.$store.dispatch('setSellersBookings', result.success);


            //         });
                    
            //         } else if (response.status === 401) {
            //             //failed logn
            //             console.log('Error connecting to API server 398');
            //         } else if (response.status === 422) {
            //             //failed logn
            //             alert('Not Authenticated');
            //         } else {
            //         // unkown error
            //         console.log('Unexpected error with API server 398');
            //         }

            //     }).catch((error) => {
            //         console.log('[GPSLocation http request error]', error);
            //             this.processing = false;
            //             alert(error)
            //     });

            // },
        },
    }
</script>

<style lang="scss" scoped>
    // End custom common variables

    // Custom styles
    .products-list-container {
        // border-top: 1px solid rgba(0,0,0,0.2);
        // padding: 1rem 0;
        .products-list-top {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: space-between;
            padding: 1rem;
            .page--subtitle {
                font-weight: bold;
            }
            .btn {
                font-size: 120%!important;
                font-weight: bold;
            }
        }
    }
</style>