<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <section class="page page-account">
                    <!-- <div class="page-account--top">{{ getProfileData }}</div> -->
                        <div class="page--top-container">
                            <v-icon
                            color="primary"
                            class="icon-logo"
                            >mdi-spa</v-icon>

                            <h1 class="page--title"
                            >    <v-avatar
                                    class="account--avatar"
                                >
                                    <!-- <img
                                        v-if="getProfileData.cover"
                                        :src="getProfileData.cover"
                                        :alt="getProfileData.name"
                                    > 
                                    <v-icon 
                                    class="primary--text"
                                    v-if="!getProfileData.cover"
                                    >mdi-account-outline</v-icon>-->
                                     
                                    <v-icon 
                                    class="primary--text"
                                    >mdi-account-outline</v-icon>
                                </v-avatar>
                                Welcome <strong class="primary--text">{{ getProfileData.name }}</strong></h1>
                        </div>
                        <div class="page--main">
                            
                            <template v-if="getClientBookings">
                                
                                <p class="page--description text-center">Your last Booking on wellbookings</p>
                                <BookingsList 
                                :bookings="getClientBookings || []"
                                ></BookingsList>

                                <div class="Profile-btn"
                                    v-if="showLocation"
                                >

                                    <div>Location</div>
                                    <template v-if="getCurrentLocation">
                                    <div>{{'Longitude: ' + getCurrentLocation.lng + ' Latitude: ' + getCurrentLocation.lat}}</div>
                                    </template>
                                        <v-btn @click="getLocation" class="btn btn-primary">Reset
                                        </v-btn>
                                </div>

                            </template>
                            <template v-else>
                                <div class="page--no-booking">
                                    <p class="page--description">No booking</p>
                                    <v-btn 
                                        link
                                        text
                                        to="/explore"
                                        x-large
                                        outlined
                                        class="btn btn-browse"
                                        color="primary"
                                    >Start Now
                                    </v-btn> 
                                </div>
                            </template>
                        </div>
                    <!-- <div class="Profile-btn">{{ getProfileData }}

                        <div>{{'User Account : '+ (isLoggedIn?getProfileData.name : 'Guest ')}}</div>
                        <div>User details</div>

                        <div columns="*, *" rows="*" v-if="!isLoggedIn">

                            <v-btn @click="goToLogin(true)" class="btn btn-primary">Login
                            </v-btn>
                            <v-btn @click="goToLogin(false)" class="btn btn-primary">Register
                            </v-btn>

                        </div>
                        <div columns="*, *" rows="*" v-else>

                            <v-btn @click="goToProfile()" class="btn btn-primary">Profile
                            </v-btn>
                            <v-btn @click="Logout" class="btn btn-primary">Logout
                            </v-btn>

                        </div>


                    </div>

                    <div class="Profile-btn">

                        <div>{{'Seller : '+ (isSellerLoggedIn?getSellerProfileData.name : '')}}</div>
                        <div style="font-size:10;">Login / Register as a seller and provide services</div>

                        <div columns="*, *" rows="*" v-if="!isSellerLoggedIn">

                            <v-btn @click="goToSellerLogin(true)" class="btn btn-primary">Login
                            </v-btn>
                            <v-btn @click="goToSellerLogin(false)" class="btn btn-primary">Register
                            </v-btn>

                        </div>
                        <div columns="*,*" rows="*" v-else>

                            <v-btn @click="goToSellerDashboard" class="btn btn-primary">Seller Dashboard
                            </v-btn>
                            <v-btn @click="goToSellerProfile" class="btn btn-primary">Profile
                            </v-btn>
                            <v-btn @click="SellerLogout" class="btn btn-primary">Logout
                            </v-btn>

                        </div>


                    </div> -->

                    <!-- <div class="Profile-btn">

                        <div>Location</div>
                        <template v-if="getCurrentLocation">
                        <div>{{'Longitude: ' + getCurrentLocation.lng + ' Latitude: ' + getCurrentLocation.lat}}</div>
                        </template>
                            <v-btn @click="getLocation" class="btn btn-primary">Reset
                            </v-btn>
                    </div> -->



                </section>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
    import mixinShared from '@/mixins/Shared'
    import BookingsList from '../components/widgets/BookingsList.vue'

    import {
        mapGetters,
        mapState
    } from 'vuex'
    // import Login from "./Login";
    // import SellerLogin from "./Seller/Login";
    // import SellerProfile from "./Seller/Profile";
    // import Profile from "./User/Profile";


    export default {
        mixins: [mixinShared],
        data() {
            return {
                // Set to true to show location box and reset btn
                showLocation: false,
                location: {},
                clientBookings: {},
                search: ''
            };
        },
        components: {
            BookingsList
            // Home,
            // SellerProfile,
            // Profile
        },
        created() {
            //request gps location
        },
        mounted() {
            // if (this.isLoggedIn) {
            //     this.getBookings();
            // }
            if (!this.isLoggedIn) {
                this.goToLogin(true);
            } else {
                this.getLocation();
                //this.getBookings();
                //this.clientBookings = this.getClientBookings
            }               
        },

        methods: {
            getBookings() {
                console.log('getbooking')

                var appURL = localStorage.getItem('appURL');
                var token = localStorage.getItem('token');

                //get list of sellers bookings
                fetch(appURL + '/api/client/booking/all', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + token
                    }
                }).then(response => {
                    console.log("getBookings Account user",response);

                    if (response.status === 200) {

                    response.json().then(result => {
                            console.log('result', result);

                            this.clientBookings = result.success;
                            this.$store.dispatch('setClientBookings', result.success);

                    });
                    
                    } else if (response.status === 401) {
                    //failed logn
                    console.log('Error connecting to API server 398');
                            this.clientBookings = this.getSellersBookings;
                    } else {
                    // unkown error
                    console.log('Unexpected error with API server 398');
                            this.clientBookings = this.getSellersBookings;
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                        this.processing = false;
                        alert(error)
                });

            },
            getLocation() {

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {

                        console.log('[Home - location]', position);
                        this.location.lat = position.coords.latitude;
                        this.location.lng = position.coords.longitude;
                        this.location.speed = position.coords.speed;
                        this.location.timestamp = position.timestamp;

                        this.$store.dispatch("setLocation", this.location);
                    });
                } else {
                    console.log("Geolocation is not supported by this browser.");
                }


            }
        },
        computed: {
            ...mapGetters([
                "getCurrentLocation", 
                "isLoggedIn", 
                "isSellerLoggedIn", 
                "getProfileData",
                "getSellerProfileData",
                "getClientBookings"
            ])
        },
        watch: {
            // location(val) {
            //     this.$store.dispatch("setLocation", val);
            // },
        }
    };
</script>

<style lang="scss">
    .page.page-account {
        align-items: center;
        justify-items: center;
        flex-direction: column;
        .page--top-container {
            text-align: center;
            margin: 2rem auto;
            h1.page--title {
                .v-avatar.account--avatar {
                    margin-right: .5rem;
                    i.v-icon {
                        border: 2px solid;
                        // border-color: rgba(0,0,0,0.3);
                        background: #ebebeb;
                        font-size: 30px;
                    
                    }
                }
                strong {
                    color: var(--v-primary-base);
                }
            }
        }
        .page--main {
            .page--form {
                min-width: 380px;
                max-width: 800px;
                margin: 0 auto;
            }
            .page--no-booking {
                padding: 3rem 0;
                text-align: center;
                .page--description {
                }
            }
        }
    }
</style>