<template>
        <v-dialog 
        v-model="dialog" 
        style="z-index: 9991;"
        scrollable max-width="300px"
        :fullscreen="isMobile"
        :hide-overlay="isMobile"
        transition="dialog-bottom-transition"
        >
            <v-card tile>
            <v-toolbar
                flat
                dark
                color="primary"
            >
                <v-btn
                icon
                dark
                @click="dialog = false"
                >
                <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Customer</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                       <v-row class="fill-height pb-14">


                <v-row>
                    <v-col cols="12">
                        <v-text-field v-model="customer.name" filled label="Client Name"></v-text-field>
                        <v-text-field v-model="customer.phone" filled label="Phone number"></v-text-field>
                        <v-text-field v-model="customer.email" filled label="Email address" type="email"></v-text-field>
                        <v-textarea
                        filled
                        label="Notes"
                        v-model="customer.notes"
                        ></v-textarea>
                    </v-col>
                </v-row>

            </v-row>
            <v-row no-gutters>
                <v-col 
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                >
                    <div class="d-flex flex-row align-center">
                        <v-btn @click="addCustomer()">
                            <v-icon>mdi-plus</v-icon>Add Customer
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            </v-card-text>
 
            </v-card>
        </v-dialog>
</template>

<script>
    import {
        mapGetters,
        mapState
    } from 'vuex'

    import mixinShared from '@/mixins/Shared'
    import { bus } from '../main'

    export default {
        data() {
            return {
                dialog: false,
                customer: {
                    name:'',
                    phone:'',
                    email:'',
                    bookings_count:'',
                    notes:''
                }
            }
        },
        created() {
            bus.$on('showCustomerDialog', (data) => {
                this.dialog = true;
            })
        },
        mounted() {

        },
        computed: {
            ...mapGetters([
                "isMobile"
            ])
        },
        methods: {
            isEmpty(obj){
                return obj && Object.keys(obj).length === 0 && obj.constructor === Object
            },
            addCustomer(){
                this.$store.dispatch("addCustomer", this.customer);
                this.dialog = false;
            }
        },
    }
</script>
<style>
.v-dialog__content--active{
    z-index: 9991!important;
}
.v-overlay--active{
    z-index: 9991!important;
}
</style>