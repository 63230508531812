let moment = require("moment");
export default {
    data() {
        return {
            site_prot: 'https://',
            site_domain: 'https://api.wellbookings.com',
            site_name: 'Wellbookings',
            site_description: 'Beauty on demand everywhere',
            site_address: '33243, office 33c, Attard, Malta',
            rules_pass: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                passwordmatch: v => v == this.registration.password || 'Password must match',
                passwordmatchappentity: v => v == this.app_password || 'Password must match',
                passwordmatchapp: v => v == this.new_user_app.password || 'Password must match',
                //passwordmatch: () => (this.registration.password === this.registration.c_password) || 'E-mail must match',
                //emailMatch: () => ('The email and password you entered don\'t match'),
            },
            rules_email: {
                required: value => !!value || 'Required.',
                valid: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                //emailMatch: () => ('The email and password you entered don\'t match'),
            },
            rules_name: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                //valid: v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                //emailMatch: () => ('The email and password you entered don\'t match'),
            },
            rules_required: {
                required: value => !!value || 'Required.',
            },
            rules_cc: {
                required: value => !!value || 'Required',
                cvc: value => value && value.length == 3 || 'Require 3 numbers',
                ccnumber: value => value && value.length == 16 || 'Require 16 numbers',
                ccexp: value => value && value.length == 4 || 'Require 4 numbers'
            },
            rules_select: {
                required: value => !!value || 'Required',
            },
            rules_calendar: {
                required: value => !!value || 'Required',
            }
        }
    },
    methods: {

        isEmpty(obj) {
            return obj && Object.keys(obj).length === 0 && obj.constructor === Object
        },

        messageBoxCustom(data) {
            console.log("messageBox-----------", data)
            let messageText = data.text
            let messageType = data.type
            this.messageDetails.type = messageType
            this.messageDetails.text = messageText
            this.messageDetails.visible = data.visible

            //this.hideMessage();
            setTimeout(() => {
                console.log("hideMessage -----------setTimeout ");
                this.messageDetails.visible = false;
            }, 5000);
        },


        // Go to previous page
        goBack() {
            this.$router.go(-1)
        },
        // Redirect to Explore page
        goToExplore() {
            this.$router.push({
                name: 'explore',
            });
        },
        // Redirect to Login page
        goToLogin(loginRegister, goBackAfterLogin = false) {
            console.log("goToLogin Shared.js");
            this.$router.push({
                name: 'login',
                params: {
                    isLoggingIn: loginRegister,
                    goBack: goBackAfterLogin
                }
            });
        },

        goToSellerLogin(loginRegister) {

            this.$router.push({
                name: 'seller-login',
                params: {
                    isLoggingIn: loginRegister
                }
            });
        },
        goToSuccess(val) {
            // if true redirect to Order conf else to problem during order
            console.log("goToSuccess Shared.js");
            this.$router.push({
                name: 'order-confirmation',
                params: {
                    checkoutResult: val
                }
            });
        },

        goToAccount() {
            console.log("goToAccount Shared.js");
            this.$router.push({
                name: 'account'
            });
        },

        goToMerchantAccount() {
            console.log("goToMerchantAccount Shared.js");
            this.$router.push({
                name: 'seller-account'
            });
        },

        goToMerchantDashboard() {
            console.log("goToMerchantDashboard Shared.js");
            this.$router.push({
                name: 'seller-dashboard'
            });
        },

        goToReview(item) {
            console.log("goToReview", item);
            this.$router.push({
                name: 'booking-review',
                params: {
                    booking: item
                }
            });
        },

        AddService(item) {
            this.$router.push({
                name: 'seller-add-update-service',
                params: {
                    item: item
                }
            });
        },

        goToProfile() {
            console.log("goToProfile options.vue");
            this.$router.push({
                name: 'user-profile'
            });
        },
        goToSellerProfile() {
            console.log("goToSellerProfile options.vue");
            this.$router.push({
                name: 'seller-profile'
            });
        },
        goToSellerDashboard() {
            this.$router.push({
                name: 'seller-dashboard'
            });
        },
        goToBookings() {
            this.$router.push({
                name: 'bookings'
            });
        },
        Logout() {
            this.$store.dispatch('logout');
            this.$router.push({
                name: 'home'
            });
        },
        SellerLogout() {
            this.$store.dispatch('sellerLogout');
            this.$router.push({
                name: 'home'
            });
        },
        formatDate(date, formate) {
            return moment(date).format(formate);
        }

    },
    computed: {

        getImage() {
            let vm = this.item;

            if (vm.seller !== undefined && vm.seller.cover !== undefined) {
                return 'https://api.wellbookings.com' + vm.seller.cover.image_url;

            } else if (vm.seller !== undefined && vm.seller.images.length) {
                return 'https://api.wellbookings.com' + vm.seller.images[0].image_url;

            } else if (vm.cover !== undefined) {
                return 'https://api.wellbookings.com' + vm.cover.image_url;

            } else if (vm.images.length) {
                return 'https://api.wellbookings.com' + vm.images[0].image_url;

            } else {
                //console.log("@/assets/random_store_img/" + Math.floor(Math.random() * 20) + ".png");
                return require("@/assets/random_store_img/" + Math.floor(Math.random() * 20) + ".png");
                //return "https://picsum.photos/300/200?random=" + Math.floor(Math.random() * 101);
            }
        },

        // Multiple images in Merchant
        getImages() {
            let temp = [];
            let vm = this.item;
            //console.log('vm', vm);

            for (let index = 0; index < 5; index++) {
                if (vm.seller !== undefined && vm.seller.cover !== undefined) {
                    temp.push('https://api.wellbookings.com' + vm.seller.cover.image_url);

                } else if (vm.seller !== undefined && vm.seller.images[index] !== undefined) {
                    temp.push('https://api.wellbookings.com' + vm.seller.images[index].image_url);

                } else if (vm.cover !== undefined && vm.cover.length) {
                    temp.push('https://api.wellbookings.com' + vm.cover.image_url);

                } else if (vm.images[index] !== undefined && vm.images.length) {
                    temp.push('https://api.wellbookings.com' + vm.images[index].image_url);

                } else {
                    temp.push(require("@/assets/random_store_img/" + Math.floor(Math.random() * 21) + ".png"));
                }
            }

            return temp;
        },
    },
};