import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
    options: {
        customProperties: true
    },
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#009688',
                secondary: '#ebebeb',
                accent: '#ffffff',
                text: '#333333',
                error: '#b71c1c',
            },
            dark: {
                primary: '#009688',
                secondary: '#333333',
                accent: '#ffffff',
                text: '#ffffff',
                error: '#b71c1c',
            }
        }
    },
});