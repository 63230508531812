<template>
    <div>
        <v-container>
            <v-card v-if="ShowBookingCard">
                <v-toolbar color="teal">
                    <v-btn @click="ShowBookingCard = false" icon >
                    <v-icon>arrow_back</v-icon>
                    </v-btn>
                    <v-toolbar-title>Booking</v-toolbar-title>
                    <v-spacer></v-spacer>

                </v-toolbar>

                <SellerBooking
                v-if="ShowBookingCard"
                :booking="selectedBooking"
                @bookingStatusChanged="bookingStatusChanged"
                ></SellerBooking>


                <!-- v-card-actions>
                        <v-btn @click="ShowBookingCard = false" class="btn btn-primary btn-submit" x-large outlined
                            color="primary">
                            Back
                        </v-btn>
                </v-card-actions -->
            </v-card>

            <v-card v-show="!ShowBookingCard">
                <v-card-title>
                Bookings
                <v-spacer></v-spacer>
                <v-select
                    v-model="bookingsFilter"
                    :items="filters"
                    label="Filter"
                    @change="changeFilter"
                ></v-select>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
                </v-card-title>

                <v-data-table
                :headers="headers"
                :items="eventsFiltered"
                :search="search"
                @click:row="bookingSelected"
                :footer-props="{
                refreshIcon: 'mdi-refresh'
                }"
                >
                <template v-slot:[`item.action`]="{ item }">
                    <v-chip
                    :color="getBookingColor(item.booking)"
                    filter
                    >{{item.booking.status}}</v-chip>
                    
                        <!-- <div v-if="item.booking.status === null">
                            <div class="btn-container">
                                <v-btn 
                                    
                                    color="green"
                                    @click="bookingStatus(item.booking.id,'approve')" 
                                    class="btn btn-primary">
                                    <v-icon class="mr-1">mdi-check</v-icon>
                                Accept</v-btn>
                                <v-btn 
                                    outlined
                                    
                                    color="red"
                                    @click="bookingStatus(item.booking.id,'reject')" 
                                    class="btn btn-primary">
                                    <v-icon class="mr-1">mdi-close</v-icon>
                                Reject</v-btn>
                            </div>

                        </div>
                        <div v-if="item.booking.status === 'Approved'">
                            <div class="btn-container">
                                <v-btn 
                                    color="green"                                                
                                    @click="bookingStatus(item.booking.id,'complete')" 
                                    class="btn btn-primary"
                                >
                                <v-icon class="mr-1">mdi-check</v-icon>
                                Complete
                                </v-btn>

                                <v-btn 
                                    outlined
                                    color="red"
                                    @click="bookingStatus(item.booking.id,'cancel')" 
                                    class="btn btn-primary">
                                <v-icon class="mr-1">mdi-close</v-icon>
                                Cancel</v-btn>
                            </div>

                        </div>
                        <div v-if="item.booking.status === 'Rejected'">
                            <div class="btn-container">
                                <v-btn
                                    outlined
                                    disabled
                                    color="red"
                                    class="btn btn-primary">
                                <v-icon class="mr-1">mdi-close</v-icon>
                                Rejected</v-btn>
                            </div>

                        </div>
                        <div v-if="item.booking.status === 'Completed'">
                            <div class="btn-container">
                                <v-btn
                                    outlined
                                    disabled
                                    color="green"
                                    class="btn btn-primary">
                                <v-icon class="mr-1">mdi-close</v-icon>
                                Completed</v-btn>
                            </div>

                        </div>
                        <div v-if="item.booking.status === 'canceled'">
                            <div class="btn-container">
                                <v-btn
                                    outlined
                                    disabled
                                    color="red"
                                    class="btn btn-primary">
                                <v-icon class="mr-1">mdi-close</v-icon>
                                Canceled</v-btn>
                            </div>

                        </div> -->
                </template>
                </v-data-table>

                <v-card-actions>
                </v-card-actions>
            </v-card>

        </v-container>
    </div>
</template>

<script>
    import mixinShared from '@/mixins/Shared'
    import SellerBooking from '@/views/Seller/SellerBooking.vue'
    var moment = require("moment");


    import { bus } from '../../main'

    import {
        mapGetters,
        mapState
    } from 'vuex'



    export default {
        mixins: [mixinShared],
        data () {
        return {
            ShowBookingCard:false,
            search: '',
            headers: [
            {
                text: 'Action',
                align: 'start',
                value: 'action',
            },
            {
                text: 'Name',
                align: 'start',
                value: 'name',
            },
            { text: 'Start Date', value: 'start' },
            { text: 'End Date', value: 'end' },
            { text: 'Employee', value: 'category' },
            { text: 'Note', value: 'note' },
            { text: 'Price', value: 'price' },
            ],
            events: [],
            eventsFiltered: [],
            selectedBooking:{},
            users: [],
            bookingsFilter:["Today"],
            filters:["Today","Up coming","Passed bookings"]
        }
        },
        components: {
            SellerBooking
        },
        computed: {
            ...mapGetters([
                "isMobile",
                "getSellerProfileData"
            ])
        },
        mounted() {
            this.getBookings();
            this.users = this.getSellerProfileData.seller.users;
        },
        methods: {
            getBookingColor(booking) {

                if(booking.block_booking === 1){

                     return 'grey';

                }else if(booking.status === 'Approved'){

                     return 'green';

                }else if(booking.status === 'Completed'){

                     return 'green';

                }else if(booking.status === 'Rejected' || booking.status === 'Canceled'){

                     return 'red';

                }else{
                    return 'teal';
                    //return this.colors[this.rnd(0, this.colors.length - 1)];
                }
            },
            bookingStatus(bookingid, status) {

                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');
                //console.log("sellerToken", sellerToken);

                fetch(appURL + '/api/seller/booking/' + bookingid + '/' +
                        status, {
                    method: 'POST',
                    headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + sellerToken
                    }
                }).then(response => {


                    console.log(response);

                    if (response.status === 200) {
                        response.json().then(result => {
                            console.log('result', result);
                            //this.getBookings();
                            let objIndex =  this.events.findIndex((obj => obj.booking.id === bookingid));
                            this.events[objIndex].status = status;

                        });
                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                    } else if (response.status === 422) {
                        //failed logn
                        alert('Not Authenticated');
                    } else {
                    // unkown error
                    console.log('Unexpected error with API server 398');
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                        this.processing = false;
                        alert(error)
                });

            },
            bookingStatusChanged(status){

                let objIndex =  this.events.findIndex((obj => obj.booking.id === this.selectedBooking.id));
                this.events[objIndex].booking.status = status;
                 
            },
            changeFilter(){
                this.eventsFiltered = [];

                let tempArray = this.events;
                let today = new Date();

                if(this.bookingsFilter.includes("Today")){
                    this.eventsFiltered = tempArray.filter((item) => moment(item.booking.booking_from).isSame(moment(), 'day'));
                }else if(this.bookingsFilter.includes("Up coming")){
                    this.eventsFiltered = tempArray.filter((item) => moment().isBefore(item.booking.booking_from, 'day'));
                }else if(this.bookingsFilter.includes("Passed bookings")){
                    this.eventsFiltered = tempArray.filter((item) => moment().isAfter(item.booking.booking_from, 'day'));
                }

            },
            viewBookingCard() {
                this.ShowBookingCard = true;
            },
            bookingSelected(item) {
                this.selectedBooking = item.booking;
                this.viewBookingCard();
                this.$emit('CustomerSelected', item)
            },

            getBookings() {

                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');
                //sellerToken = sellerToken.replace(/['"]+/g, '');

                console.log("sellerToken", sellerToken);

                fetch(appURL + '/api/seller/bookings', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + sellerToken
                    }
                    // body: JSON.stringify({
                    //     ...this.service
                    // })
                }).then(response => {
                    console.log('response------------');
                    console.log(response);

                    if (response.status === 200) {

                        response.json().then(result => {
                            console.log('result', result);

                            this.sellersBookings = result.success;
                            this.$store.dispatch('setSellersBookings', result.success);

                            for (let booking of this.sellersBookings) {

                                const employee = this.users.filter(item => item.id === booking
                                    .seller_user_id)[0]

                                this.events.push({
                                    name: (booking.service?booking.service.service_name:'Block'),
                                    start: booking.booking_from,
                                    end: booking.booking_to,
                                    timed: true,
                                    category: employee.name,
                                    note:booking.note,
                                    price:(booking.invoice?(JSON.parse(booking.invoice)).total : ''),
                                    booking:booking
                                })

                                this.changeFilter();
                            }

                            console.log('this.events', this.events)

                        });

                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                    } else if (response.status === 422) {
                        //failed logn
                        alert('Not Authenticated');
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 398');
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                    this.processing = false;
                    alert(error)
                });

            },
        },
    }
</script>

<style lang="scss" scoped>
</style>