const currencies = [
    {
        translationKey:'currency_eur',
        name:'eur',
        icon:'fa-euro-sign',
        class:'eur',
        classless:'',
        searchValue:'eur'
    },
    {
        translationKey:'currency_usd',
        name:'usd',
        icon:'fa-dollar-sign',
        class:'usd',
        classless:'',
        searchValue:'usd'
    },
    {
        translationKey:'currency_gbp',
        name:'gdp',
        icon:'fa-pound-sign',
        class:'gdp',
        classless:'',
        searchValue:'gbp'
    }
];

export default currencies;
