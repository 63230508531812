<template>
    <div>
        <v-container>
            <v-row>
                <v-col cols="12" md="8">
                    <v-card elevation="0">

                        <v-card-text>
                            <v-row>
                                <v-col  md="6" cols="12">
                                    <div class="date-card">
                                        <div style="display:inline-block; margin-right:20px;">
                                            <div style="margin-bottom:10px;">{{formatDate(booking.booking_from,'MMMM')}}</div>
                                            <div style="margin-bottom:10px;font-weight:bold;font-size: 25px;text-align: center;">{{formatDate(booking.booking_from,'DD')}}</div>
                                            <div style="text-align: center;">{{formatDate(booking.booking_from,'dddd')}}</div>
                                        </div>
                                        <div style="display:inline-block;">
                                            <div style="margin-top:12px;font-weight:bold;font-size: 25px;">{{formatDate(booking.booking_from,'hh:mm a')}}</div>
                                        </div>
                                    </div>
                                </v-col>
                                <v-col  md="6" cols="12">
                                    <div class="date-card">
                                        <div style="display:inline-block; margin-right:20px;">
                                            <div style="margin-bottom:10px;">{{formatDate(booking.booking_to,'MMMM')}}</div>
                                            <div style="margin-bottom:10px;font-weight:bold;font-size: 25px;text-align: center;">{{formatDate(booking.booking_to,'DD')}}</div>
                                            <div style="text-align: center;">{{formatDate(booking.booking_to,'dddd')}}</div>
                                        </div>
                                        <div style="display:inline-block;">
                                            <div style="margin-top:12px;font-weight:bold;font-size: 25px;">{{formatDate(booking.booking_to,'hh:mm a')}}</div>
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    
                    </v-card>
                    <v-card elevation="0">
                        <v-card-title>Service</v-card-title>
                        <v-card-text>
                        <div>
                            <div style="display:inline-block; width:10px;height:60px;background:teal;border-radius:10px;margin-right: 20px;">
                            </div>
                            <div style="display:inline-block;">
                                
                                
                                <div style="font-weight:bold;font-size: 25px;">{{booking.service.service_name}}</div>
                                <div>{{booking.service.service_decription}}</div>
                                <div style="font-weight:bold;font-size: 15px;">{{booking.service.price}}{{booking.service.currency}}</div>

                            </div>
                        </div>
                        </v-card-text>
                    </v-card>
                    <v-card elevation="0">
                        <v-card-title>Employee</v-card-title>
                        <v-card-text>

                            <v-list-item v-if="booking.seller_user">
                                <v-list-item-avatar >
                                    <v-img v-if="booking.seller_user.avatar !== null" :alt="`${booking.seller_user.name} avatar`"
                                        :src="'https://api.wellbookings.com/storage/sellerProfileImages/'+booking.seller_user.avatar">
                                    </v-img>
                                    <v-img v-else :alt="`${booking.seller_user.name} avatar`"
                                        src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png">
                                    </v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-text="booking.seller_user.name.slice(0, 25)"></v-list-item-title>
                                    <v-list-item-subtitle>
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                            </v-list-item>

                        </v-card-text>
                    </v-card>
                    <v-card elevation="0" v-if="booking.user">
                        <v-card-title>Client</v-card-title>
                        <v-card-text>

                            <v-list-item v-if="booking.user">
                                <v-list-item-avatar >
                                    <v-img :alt="`${booking.user.name} avatar`"
                                        src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png">
                                    </v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-text="booking.user.name.slice(0, 25)"></v-list-item-title>
                                    <v-list-item-subtitle>
                                    {{booking.user.email}}<br>{{booking.user.phone}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                            </v-list-item>

                        <div>Note : {{booking.note}}</div>
                        </v-card-text>
                    </v-card>
                    <v-card elevation="0" v-if="additional.block_booking">
                        <v-card-title>Client</v-card-title>

                        <v-card-text>
                            <v-list-item v-if="additional.block_booking">
                                <v-list-item-avatar >
                                    <v-img :alt="`${additional.block_booking.clientName} avatar`"
                                        src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png">
                                    </v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-text="additional.block_booking.clientName.slice(0, 25)"></v-list-item-title>
                                    <v-list-item-subtitle>
                                    {{additional.block_booking.clientEmail}}<br>{{additional.block_booking.clientPhone}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>

                            </v-list-item>

                        <div>Note : {{booking.note}}</div>
                        </v-card-text>
                    </v-card>
                    <v-card elevation="0" v-if="invoice!=={}">
                        <v-card-title>Invoice</v-card-title>
                        <v-card-text>
                        <invoice
                        :invoiceObj="invoice"
                        ></invoice>
                        </v-card-text>
                    
                    </v-card>
                    <v-card elevation="0" v-if="booking.come_to_me===1">
                        <v-card-title>Meetup Location</v-card-title>
                        <v-card-text>
                        <div>Location : {{additional.meetupLocation}}</div>
                        <div id="mapContainerDetectWidthHeight3">
                            <LocationPicker
                            v-if="loaded"
                            :lat="parseFloat(additional.meetuplat)"
                            :lng="parseFloat(additional.meetuplng)"
                            :disableClick="true"
                            :parentDiv="'mapContainerDetectWidthHeight3'"
                            ></LocationPicker>
                        </div>
                        </v-card-text>

                    
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">

                    <template v-if="booking.status === null">

                        <div class="btn-container">
                            <v-btn color="green" @click="bookingStatus(booking.id,'approve')" class="btn btn-primary">
                                <v-icon class="mr-1">mdi-check</v-icon>
                                Accept
                            </v-btn>
                            <v-btn outlined color="red" @click="bookingStatus(booking.id,'reject')"
                                class="btn btn-primary">
                                <v-icon class="mr-1">mdi-close</v-icon>
                                Reject
                            </v-btn>
                        </div>

                    </template>
                    <template v-if="booking.status === 'Approved'">
                        <div class="btn-container">
                            <v-btn color="green" @click="bookingStatus(booking.id,'complete')" class="btn btn-primary">
                                <v-icon class="mr-1">mdi-check</v-icon>
                                Complete
                            </v-btn>

                            <v-btn outlined color="red" @click="bookingStatus(booking.id,'cancel')"
                                class="btn btn-primary">
                                <v-icon class="mr-1">mdi-close</v-icon>
                                Cancel
                            </v-btn>
                        </div>

                    </template>
                    <div v-if="booking.status === 'Rejected'">
                        <div class="btn-container">
                            <v-btn
                                outlined
                                disabled
                                color="red"
                                class="btn btn-primary">
                            <v-icon class="mr-1">mdi-close</v-icon>
                            Rejected</v-btn>
                        </div>

                    </div>
                    <div v-if="booking.status === 'Completed'">
                        <div class="btn-container">
                            <v-btn
                                outlined
                                disabled
                                color="green"
                                class="btn btn-primary">
                            <v-icon class="mr-1">mdi-close</v-icon>
                            Completed</v-btn>
                        </div>

                    </div>
                    <div v-if="booking.status === 'Canceled'">
                        <div class="btn-container">
                            <v-btn
                                outlined
                                disabled
                                color="red"
                                class="btn btn-primary">
                            <v-icon class="mr-1">mdi-close</v-icon>
                            Canceled</v-btn>
                        </div>

                    </div>

                    <BookingChatSeller style="margin-top:30px;"
                    :booking_id="booking.id"
                    :current_chat="messaging"
                    ></BookingChatSeller>
                </v-col>
            </v-row>
        </v-container>

    </div>
</template>

<script>
    import mixinShared from '@/mixins/Shared'
    import BookingChatSeller from '@/components/BookingChatSeller.vue'
    import LocationPicker from "@/components/LocationPicker"
    import Invoice from "@/components/Invoice"

    import {
        mapGetters,
        mapState
    } from 'vuex'


    export default {
        mixins: [mixinShared],
        props: {
            booking: {
                type: Object,
                required:true
            }
        },
        data() {
            return {
                invoice:{},
                messaging:[],
                additional:{},
                loaded:false
            };
        },
        components: {
            BookingChatSeller,
            LocationPicker,
            Invoice
        },
        computed: {
            ...mapGetters([
                "getCurrentLocation",
                "isLoggedIn",
                "isSellerLoggedIn",
                "getProfileData",
                "getSellerProfileData",
                "getSellersBookings",
                "isMobile"
            ]),
            getServices() {
                console.log('getSellerProfileData', this.getSellerProfileData.seller.services);
                return this.getSellerProfileData.seller.services
            },
            getWorkHours() {
                return JSON.parse(this.getSellerProfileData.seller.working_hours);
            }
        },
        mounted() {
            this.invoice = JSON.parse(this.booking.invoice);
            this.messaging = JSON.parse(this.booking.messaging);
            this.additional = JSON.parse(this.booking.additional);

            setTimeout(() => {
                this.loaded=true;
            }, 500);

        },
        methods: {

            bookingStatus(bookingid, status) {

                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');
                //console.log("sellerToken", sellerToken);

                fetch(appURL + '/api/seller/booking/' + bookingid + '/' +
                    status, {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + sellerToken
                        }
                    }).then(response => {


                    console.log(response);

                    if (response.status === 200) {
                        response.json().then(result => {
                            console.log('results', result);
                            this.booking.status = result.success.status;
                            this.$emit('bookingStatusChanged',result.success.status);
                            //this.getBookings();
                        });
                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                    } else if (response.status === 422) {
                        //failed logn
                        alert('Not Authenticated');
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 398');
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                    this.processing = false;
                    alert(error)
                });

            }

        },
    }
</script>

<style lang="scss" scoped>

.date-card {
    color: white;
    background-color: teal;
    border-width: 1px;
    border-color: slategray;
    margin: 5px;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 7px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.infoBox{
    border:1px solid grey;
    margin:10px;
    padding:10px;
}

</style>