<template>
    <v-list 
    two-line>
        <v-card
            v-for="(service, index) in itemServices || reverse"  
            :key="'itemDetailsServices-'+index"
            class="mx-auto mt-2" elevation="1">

        <v-list-item
            class="single-service"
            :class="[service.service_icon, { 'visible' : (service.service_icon === 'hair' ) }]"
        >
            <!-- <v-list-item-avatar>
            <v-icon
                :class="[service.iconClass]"
                v-text="service.icon"
            ></v-icon>
            </v-list-item-avatar> -->

            <v-list-item-content>
                <v-list-item-title style="font-weight:bold;" v-text="service.service_name"></v-list-item-title>
                <v-list-item-subtitle v-text="service.service_decription"></v-list-item-subtitle>
                {{service.time_interval+' mins'}}
            </v-list-item-content>

            <v-list-item-action>
            <!-- <v-btn icon>
                <v-icon color="grey lighten-1">mdi-information</v-icon>
            </v-btn> -->
                <p class="service--price">{{ service.price }} {{ service.currency }}</p>
            
                <v-btn 
                    @click="BookService(service)" 
                    small
                    color="primary"
                    class="btn btn-primary"
                >
                    Book
                </v-btn>
            </v-list-item-action>
        </v-list-item>
        </v-card>

    </v-list>
</template>

<script>
// import { mapActions } from 'vuex'
import mixinShared from '@/mixins/Shared'

export default {
    mixins: [mixinShared],
    props: {
        itemServices : {
            type : Array,
            required : false
        },
        design : {
            type : Boolean,
            required : false,
        }
        
    },
    data: () => ({

    }),
    methods: {
        BookService(service) {
            console.log('service.seller', service)
            this.$emit('bookItem', service)
        }
    },
    // mounted() {
    //     this.item = this.itemData
    // },
    computed: {

    }
}
</script>

<style scoped lang="scss">
.list-products__wrapper-parallax,
.list-products__wrapper-parallax .row,
.list-products__wrapper-parallax .row .col {
    height: 100%;
}

.list-products__container {
    padding: 2rem 0;
    height: 90%;
    margin-top: 5%;
    border-radius: 15px;
    background: #ffffff;
    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
}

.list-products__container .list-products__avatar{
    /* box-shadow: 0 5px 5px -3px rgba(0,0,0,.2),0 8px 10px 1px rgba(0,0,0,.4); */
    border: 5px solid #009688;
}

.product-card {
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: .5rem;
    .product-card-wrapper {
        background: rgba(0,0,0,.2);
        max-width: 85%;
        padding: 1rem;
        border-radius: 0 0 25px 0;
        .v-card__title,
        .v-card__subtitle {
            color: #ffffff;
            text-shadow: 0 1px 3px #000000;
            &.address {
                padding-bottom: 0;
            }
            &.city {
                padding-top: 0;
                text-transform: capitalize;
            }
        }
    }

    .btn-see-more {
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        font-size: 120%!important;
        border: 3px solid #009688!important;
        background: #009688;
        color: #ffffff;
    }
}



</style>