<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <!-- <div actionBarHidden="true"> -->
                    <section class="page page-login">
                        <div class="page--top-container">
                            <v-icon
                            color="primary"
                            class="icon-logo"
                            >mdi-spa</v-icon>

                            <h1 class="page--title"
                            >Login</h1>
                            <p class="page--description">
                                Please login to your account

                            </p>

                            <AlertMessage 
                                :messageVisible="messageDetails.visible"
                                :messageText="messageDetails.text"
                                :messageType="messageDetails.type"
                            ></AlertMessage>

                        </div>
                        <div class="page--main">
                            <div class="page--form">
                                <v-container>
                                <!-- <v-img class="logo" src="/images/logo.png" /> -->
                                <v-row dense>
                                    <!-- {{ forgotPass}} - lg in {{ isLoggingIn }} -->
                                    <v-col 
                                    cols="12">

                                        <v-text-field
                                        hide-details
                                        outlined
                                        height="50"
                                        class="input--big-icon"
                                        v-show="!isLoggingIn && !forgotPass"
                                        id="username"
                                        label="Username"
                                        v-model="user.name"
                                        name="registration-username"
                                        prepend-icon="mdi-account-outline"
                                        type="text"
                                        
                                        ></v-text-field>
                                    </v-col>                                     
                                    <v-col 
                                    cols="12">
                                        <v-text-field
                                            hide-details
                                            outlined
                                            height="50"
                                            class="input--big-icon"
                                            v-show="!isLoggingIn || isLoggingIn || forgotPass"
                                            id="email"
                                            label="Email"
                                            name="login-reg-email"
                                            v-model="user.email"
                                            prepend-icon="mdi-email-outline"
                                            type="text"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col 
                                    cols="12">

                                        <v-text-field
                                            hide-details
                                            outlined
                                            height="50"
                                            class="input--big-icon"
                                            v-show="!isLoggingIn || isLoggingIn && !forgotPass"                                       
                                            id="password"
                                            label="Password"
                                            v-model="user.password"
                                            name="login-password"
                                            prepend-icon="mdi-lock"
                                            type="password"
                                            
                                        ></v-text-field>
                                        <p 
                                        v-show="isLoggingIn" 
                                        class="forgot mt-5"
                                        @click="forgotPassword()"
                                        >{{ !forgotPass ? 'Forgot your password? ' : 'Back to Login'}}</p>
                                    </v-col>
                                    <v-col 
                                    cols="12">

                                        <v-text-field
                                            hide-details
                                            outlined
                                            height="50"
                                            v-show="!isLoggingIn && !forgotPass"
                                            class="input--big-icon"
                                            id="confirm_password"
                                            label="Repeat Password"
                                            v-model="user.confirm_password"
                                            name="login-conf-password"
                                            prepend-icon="mdi-lock"
                                            type="password"
                                            
                                        ></v-text-field>
                                    </v-col>                                                                       
                                
                                    <v-col 
                                    cols="12">
                                        <v-btn 
                                        @click="submit" 
                                        class="mt-5 btn btn-primary btn-login btn-register"
                                        :outlined="!isLoggingIn"
                                        x-large
                                        block
                                        color="primary"
                                        >
                                            {{ isLoggingIn ? 'Submit' : 'Sign Up'}}
                                        </v-btn>
                                    </v-col>

                                    <v-col 
                                    cols="12">
                                        <v-btn 
                                        link
                                        @click="loginFacebook()" 
                                        class="mt-2 btn btn-facebook"
                                        x-large
                                        block
                                        dark
                                        >
                                            Login with Facebook
                                            <v-icon 
                                            right 
                                            large 
                                            dark
                                            class="ml-5">mdi-facebook
                                            </v-icon>
                                        </v-btn>
                                        <!-- <a class="btn" href="https://api.wellbookings.com/auth/facebook/redirect"
                                            style="background: #3B5499; color: #ffffff; padding: 10px; width: 100%; text-align: center; display: block; border-radius:3px;">
                                            Login with Facebook
                                        </a> -->
                                    </v-col>

                                </v-row>
                                </v-container>
                            </div>
                            <!-- End Form -->

                            <p class="page--text-login page--text mt-5" @click="toggleForm">
                                {{ isLoggingIn ? 'Don’t have an account? ' : 'Back to Login'}}<strong>{{ isLoggingIn ? 'Sign up' : '' }}</strong>
                            </p>


                            <p class="page--text-login page--text mt-5" @click="goToSellerLogin(true)">
                                Are you a merchant ?<strong>Go to Merchant login page</strong>
                            </p>                            

                            <!-- <p class="page--text-login page--text ma-0" @click="changeAPI">
                                    <span>Change the default API server </span>
                                    <span class="bold">Change</span>
                            </p> -->
                        </div>
                    </section>
                <!-- </div> -->
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import mixinShared from '@/mixins/Shared'

    //import * as http from "http";
    import AlertMessage from "@/components/widgets/AlertMessage"
    import {
        mapGetters,
        mapState
    } from 'vuex'

    export default {
        mixins: [mixinShared],
        props: {
            isLoggingIn: {
                type: Boolean,
                default: true
            },
            goBack: {
                type: Boolean,
                default: false
            }
        },
        components:{
            AlertMessage
        },
        data() {
            return {
                processing: false,
                // Message info 
                messageDetails: {
                    visible: false,
                    type: "",
                    text: ""
                },
                // Form
                user: {
                    name: "",
                    email: "",
                    password: "",
                    confirm_password: ""
                },
                Token:"",
                // Forgot Pass
                forgotPass: false
            };
        },
        computed: {
            ...mapGetters([
                "isLoggedIn", 
                "isSellerLoggedIn", 
                // "getProfileData",
                // "getSellerProfileData"
            ])
        }, 
        beforeDestroy () {
          window.removeEventListener('message', this.onMessage)
        },       
        created() {
            console.log('this is a test app : created');
        },
        mounted() {

            // console.log('this is a test app : mounted');
            // setTimeout(() => {
            //     this.onStart();
            // }, 1000);\
            // If user is not logged in
            if (this.isLoggedIn || !this.isSellerLoggedIn) {
                    this.goToAccount();
            }

            window.addEventListener('message', this.onMessage, false)

        },
        methods: {

          // This method call the function to launch the popup and makes the request to the controller. 
            loginFacebook() {

                var appURL = localStorage.getItem('appURL');
                console.log("login", appURL)

                const newWindow = openWindow('', 'message')

                fetch(appURL+'/auth/facebook/redirect', {
                    method: 'GET',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    }
                }).then(response => {


                    console.log(response);

                    if (response.status === 200) {

                        response.text().then(result => {
                                console.log('result', result);
                                newWindow.location.href = result;
                        });
                    }else{
                        console.log('fb login error !!');
                    }
                }).catch( (error) => {
                    console.error(error);
                });

              },
              // This method save the new token and username
              onMessage (e) {

                console.log('facebook login',e);

                // if (e.origin !== window.origin || !e.data.data.token) {
                //   return
                // }
                //localStorage.setItem('user',e.data.name)
                //localStorage.setItem('jwt',e.data.token)

                //this.$router.go('/board')

                console.log('result', e.data.data.user);

                localStorage.setItem('token',e.data.data.token);
                this.$store.dispatch('setprofile',e.data.data.user);
                // Error Message Info
                let info = {
                    visible: true,
                    text: "Welcome, you are logged in!",
                    type:"success"
                }
                this.messageBoxCustom(info)  

                console.log('navigating.....')

                setTimeout( ()=> { 
                    //this.$router.go(-1)
                    if(this.goBack){
                        this.$router.go(-1)
                    }else{
                        this.goToAccount();
                    }
                }, 2000);


              },

            forgotPassword() {
                this.isLoggingIn = true
                this.forgotPass = !this.forgotPass
            },
            // goToSellerLogin(loginRegister) {

            //     this.$router.push({
            //         name: 'seller-login',
            //         params: {
            //             isLoggingIn: loginRegister
            //         }
            //     });
            // },

            toggleForm() {
                this.isLoggingIn = !this.isLoggingIn
                this.forgotPass = false
            },

            submit() {

                this.processing = true;
                if (this.isLoggingIn && !this.forgotPass) {
                    if (!this.user.email || !this.user.password) {
                        let info = {
                            visible: true,
                            text: "Please provide both an email address and password.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)
                        return;
                    }              
                         
                    //console.log('Login')
                    this.login();
                }
                else if( this.isLoggingIn && this.forgotPass ) {
                    if (!this.user.email ) {
                        let info = {
                            visible: true,
                            text: "Please provide an email to reset.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)
                        return;
                    }

                    console.log('Forgot')
                    this.recoverPassword()
                    // } else {
                    //     this.login();
                    // }
                    //this.login();
                } else {
                    this.register();
                }

            },

            login() {
                var appURL = localStorage.getItem('appURL');
                console.log("login", appURL)

                fetch(appURL+'/api/login', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify({
                        email: this.user.email,
                        password: this.user.password
                    })
                }).then(response => {


                    console.log(response);

                    if (response.status === 200) {

                    response.json().then(result => {
                            console.log('result', result.success.user);

                            localStorage.setItem('token',result.success.token);
                            this.$store.dispatch('setprofile',result.success.user);
                            // Error Message Info
                            let info = {
                                visible: true,
                                text: "Welcome, you are logged in!",
                                type:"success"
                            }
                            this.messageBoxCustom(info)  

                            console.log('navigating.....')

                            setTimeout( ()=> { 
                                //this.$router.go(-1)
                                if(this.goBack){
                                    this.$router.go(-1)
                                }else{
                                    this.goToAccount();
                                }
                            }, 2000);

                    });
                    
                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                        // Error Message Info
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)                          
                    } else if (response.status === 422) {
                        //failed logn
                        //alert('Not Authenticated');
                        // Error Message Info
                        let info = {
                            visible: true,
                            text: "Not Authenticated, invalid login data",
                            type:"error"
                        }
                        this.messageBoxCustom(info)  
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 398');
                        // Error Message Info
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)                          
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                        this.processing = false;
                        //alert(error)
                        // Error Message Info
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)    
                });


            },

            register() {
                if (this.user.password != this.user.confirm_password) {

                    this.processing = false;
                    //alert("Your passwords do not match.");
                    let info = {
                        visible: true,
                        text: "Your passwords do not match.",
                        type:"error"
                    }
                    this.messageBoxCustom(info)

                    return;

                } else if(!this.user.name || !this.user.email){

                    this.processing = false;
                    //alert('all fields are required.');
                    let info = {
                        visible: true,
                        text: "All fields are required.",
                        type:"error"
                    }
                    this.messageBoxCustom(info)

                    return;
                }

                // var appURL = localStorage.setItem('appURL',"https://api.wellbookings.com");
                var appURL = localStorage.getItem('appURL');

                console.log("register", appURL)



                fetch(appURL+'/api/register', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify({
                        name: this.user.name,
                        username: this.user.username,
                        email: this.user.email,
                        password: this.user.password,
                        confirm_password: this.user.confirm_password
                    })
                }).then(response => {


                    console.log(response);

                    if (response.status === 200) {

                    response.json().then(result => {
                        console.log('result', result);

                        let info = {
                            visible: true,
                            text: "Registration Completed",
                            type:"success"
                        }
                        this.messageBoxCustom(info)  

                        this.login();

                    });
                    
                    } else if (response.status === 401) {
                    //failed logn
                        console.log('Error connecting to API server 398');
                        // Error Message Info
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)                    
                    } else {
                    // unkown error
                        console.log('Unexpected error with API server 398');
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                        this.processing = false;
                        //alert(error)
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)
                });

            },

            recoverPassword() {
                if(!this.user.email){

                    this.processing = false;
                    //alert('all fields are required.');
                    let info = {
                        visible: true,
                        text: "All fields are required.",
                        type:"error"
                    }
                    this.messageBoxCustom(info)

                    return;
                }

                // var appURL = localStorage.setItem('appURL',"https://api.wellbookings.com");
                var appURL = localStorage.getItem('appURL');

                fetch(appURL + '/api/reset-password', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify({
                        email: this.user.email,
                    })
                }).then(response => {

                    console.log(response);

                    if (response.status === 200) {

                    response.json().then(result => {
                        console.log('result', result);

                        let info = {
                            visible: true,
                            text: "Password Reset Confirm",
                            type:"success"
                        }
                        this.messageBoxCustom(info)  

                        this.login();

                    });
                    
                    } else if (response.status === 401) {
                    //failed logn
                        console.log('Error connecting to API server 398');
                        // Error Message Info
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)                    
                    } else {
                    // unkown error
                        console.log('Unexpected error with API server 398');
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                        this.processing = false;
                        //alert(error)
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)
                });

            },


            // onStart(){
            // },

            changeAPI(){

            },
            // focusPassword() {
            //     // this.$refs.password.nativeView.focus();
            // },
            // focusConfirmPassword() {
            //     if (!this.isLoggingIn) {
            //         // this.$refs.confirm_password.nativeView.focus();
            //     }
            // },
            // goHome(){
            //     console.log('navigating.....')
            //     // this.$navigateTo(Home, { clearHistory: true });
            // }


        }
    };


    // The popup is launched.

    function openWindow(url, title, options = {}) {
      if (typeof url === 'object') {
        options = url
        url = ''
      }

      options = { url, title, width: 600, height: 720, ...options }

      const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screen.left
      const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screen.top
      const width = window.innerWidth || document.documentElement.clientWidth || window.screen.width
      const height = window.innerHeight || document.documentElement.clientHeight || window.screen.height

      options.left = ((width / 2) - (options.width / 2)) + dualScreenLeft
      options.top = ((height / 2) - (options.height / 2)) + dualScreenTop

      const optionsStr = Object.keys(options).reduce((acc, key) => {
        acc.push(`${key}=${options[key]}`)
        return acc
      }, []).join(',')

      const newWindow = window.open(url, title, optionsStr)

      if (window.focus) {
        newWindow.focus()
      }

      return newWindow
    }

</script>

<style lang="scss">
    .page.page-login {
        align-items: center;
        justify-items: center;
        flex-direction: column;
        .page--top-container {
            text-align: center;
            margin: 0rem auto;
            h1.page--tite {
            }
        }
        .page--main {
            .page--form {
                // min-width: 380px;
                max-width: 800px;
                margin: 0 auto;
                .v-text-field.v-text-field--enclosed {
                    .v-input__slot {
                        //background: #ebebeb;
                        border-radius: 15px;
                        -webkit-border-radius: 15px;
                        -moz-border-radius: 15px;
                    }
                    .v-text-field__details {
                        margin-bottom: 0;
                    }
                }
            }
            p.forgot {
                cursor: pointer;
                font-weight: bold;
                text-align: right;
                margin-bottom: 0;
            }

            p.page--text-login {
                cursor: pointer;
                strong {
                    
                    margin-left: .25rem;
                    // color: var(--v-primary-base)!important;
                }
            }
        }
    }

</style>
