<template>
    <v-dialog v-model="dialog" style="z-index: 9991;" scrollable max-width="400px" :fullscreen="isMobile"
        :hide-overlay="isMobile" transition="dialog-bottom-transition">
        <v-card tile>
            <v-toolbar flat dark color="primary">
                <v-btn icon dark @click="disconnect()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{chatName}}</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-row class="fill-height" align="start" style="overflow:auto;background-color:#104d40;">

                <chat-window :current-user-id="currentUserId" :rooms="rooms" :messages="messages" :single-room="true"
                    :room-id="currentRoom.roomId" :show-emojis="true" :show-audio="false" :show-add-room="false" :show-files="false"
                    :link-options="{ disabled: true, target: '_blank', rel: null }" :loading-rooms="loadingRooms"
                    :rooms-loaded="roomsLoaded" :messages-loaded="messagesLoaded" @fetch-messages="connectGetMessages"
                    @send-message="sendMessage" />

            </v-row>

        </v-card>

    </v-dialog>
</template>

<script>
    import {
        mapGetters,
        mapState
    } from 'vuex'
    var moment = require("moment");

    import ChatWindow from 'vue-advanced-chat'
    import 'vue-advanced-chat/dist/vue-advanced-chat.css'

    import mixinShared from '@/mixins/Shared'
    import {
        bus
    } from '../main'

    export default {
        components: {
            ChatWindow
        },
        data() {
            return {
                chat: [],
                msg: null,
                dialog: false,
                rooms: [],
                messages: [],
                currentUserId: "user-202",
                currentRoom: {},
                
                fromUserId:null,
                toUserId:null,
                referenceType:null,
                referenceId:null,
                chatName:'',
                loadingRooms:true,
                roomsLoaded:false,
                messagesLoaded:false,
            };
        },
        created() {
        },
        mounted() {
            bus.$on('showSupportDialog', (data) => {
                this.dialog = true;
                console.log('dialogdata',data);

                this.currentUserId = 'user-'+data.toUserId;

                this.fromUserId = data.fromUserId;
                this.toUserId = data.toUserId;
                this.referenceType = data.referenceType;
                this.referenceId = data.referenceId;
                this.chatName = data.chatName;

                this.getRoomdetails();
            })

        },
        computed: {
            ...mapGetters(["isMobile"])
        },

        methods: {
            connectGetMessages({ room = {}, options = {} }) {

                if (room) {
                    this.currentRoom = room;
                    //this.connect();

                    //this.getMessages();
                }
            },

            connect() {
                if (this.currentRoom.roomId) {
                    console.log('-------connecting');
                    let vm = this;
                    this.getMessages();
                    window.Echo.channel('booking-'+this.currentRoom.roomId)
                        .listen('.server.created', e => {
                            console.log('here');
                            this.messages.push({...e.message});
                            //vm.getMessages();
                            console.log(e);
                        });
                }
            },
            disconnect() {
                console.log('Method - DISCONNECTED!!');
                window.Echo.leave('booking-' + this.currentRoom.roomId);
                this.dialog = false;
            },
            getRoomdetails() {


                var appURL = localStorage.getItem('appURL');
                var token = localStorage.getItem('token');
                this.messagesLoaded = false;

                fetch(appURL + '/api/client/chat/roomdetail', {
                    method: 'post',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + token
                    },
                    body: JSON.stringify({
                        fromUserId:this.fromUserId,
                        toUserId:this.toUserId,
                        referenceType:this.referenceType,
                        referenceId:this.referenceId,
                        chatName:this.chatName,
                    })
                }).then(response => {
                    if (response.status === 200) {

                        response.json().then(result => {
                            console.log('roomdetails result', result);


                            this.rooms = [{...result}];
                            this.currentRoom = result;

                            this.loadingRooms=false
                            this.roomsLoaded=true;

                        }).then(result => {

                            this.connect();

                        });

                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 434');
                    } else if (response.status === 422) {
                        //failed logn
                        alert('Not Authenticated');
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 4343');
                    }

                }).catch((error) => {
                    console.log('[http request error]', error);
                    this.processing = false;
                    alert(error)
                });
            },
            getMessages() {

                var appURL = localStorage.getItem('appURL');
                var token = localStorage.getItem('token');
                this.messagesLoaded = false;

                fetch(appURL + '/api/client/chat/'+this.currentRoom.roomId, {
                    method: 'get',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + token
                    }
                }).then(response => {

                    if (response.status === 200) {

                        response.json().then(result => {
                            console.log('getMessages result', result);
                            this.messages = result;
                            this.messagesLoaded = true;

                        });

                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 45645');
                    } else if (response.status === 422) {
                        //failed logn
                        alert('Not Authenticated');
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 6565');
                    }

                }).catch((error) => {
                    console.log('[http request error]', error);
                    this.processing = false;
                    alert(error)
                });
            },
            sendMessage(message) {
                // if(message.content == ' '){
                //     return;
                // }

                var appURL = localStorage.getItem('appURL');
                var token = localStorage.getItem('token');

                console.log('this.rooms[0].roomId', this.currentRoom.roomId);

                fetch(appURL + '/api/client/chat/' +this.currentRoom.roomId, {
                    method: 'post',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + token
                    },
                    body: JSON.stringify({
                        message: message.content
                    })
                }).then(response => {

                    if (response.status === 200) {

                        response.json().then(result => {
                            console.log('set new message result', result);

                            //new meesages will come from websocket
                            //this.messages.push(result);

                        });

                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 3445');
                    } else if (response.status === 422) {
                        //failed logn
                        alert('Not Authenticated');
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 344');
                    }

                }).catch((error) => {
                    console.log('[ http request error]', error);
                    this.processing = false;
                    alert(error)
                });
            },


        },
        deactivated() {
            console.log('deactivated');
            console.log('deactivated - DISCONNECTED!!');
            this.disconnect();
        },
        beforeDestroy() {
            console.log('beforeDestroy');
            console.log('beforeDestroy - DISCONNECTED!!');
            this.disconnect();
        },
        destroyed() {
            console.log('destroyed - DISCONNECTED!!');
            this.disconnect();
        }
    }
</script>
<style>
    .v-dialog__content--active {
        z-index: 9991 !important;
    }

    .v-overlay--active {
        z-index: 9991 !important;
    }
</style>