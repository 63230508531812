<!-- Default Base Component Layout - Starting Scratch -->

<template>
    <div class="reports reports-component"
    :class="[{ 'custom-component' : (design === '' ) }]"
    >
        <v-container fluid>
            <v-row dense>
                <v-col cols="12">
                    <div class="reports-options">
                        <!-- <label>Date:</label> -->
                        <!-- <v-menu ref="menu" 
                        v-model="menu" 
                        :close-on-content-click="false" 
                        :return-value.sync="dateSelected"
                            transition="scale-transition" offset-y min-width="auto"> -->
                        <v-dialog
                            ref="dialog"
                            v-model="menu"
                            :return-value.sync="dateSelected"
                            persistent
                            width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field 
                                v-model="dateSelected" 
                                label="Select Date" 
                                prepend-icon="mdi-calendar"
                                readonly 
                                v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker 
                                v-model="dateSelected" 
                                no-title 
                                persistent
                                scrollable
                                :min="dateMin"
                                :max="dateToday" 
                                type="month"
                                color="primary"
                                >
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false">
                                    Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.dialog.save(dateSelected)">
                                    OK
                                </v-btn>
                            </v-date-picker>
                        <!-- </v-menu> -->
                        </v-dialog>
                        <div class="btn-container"
                        v-show="!loading"
                        >
                            <v-btn 
                            @click="generatePreview(dateSelected)" 
                            class="btn btn-primary btn-generate"
                            x-large block 
                            color="primary">Generate Preview
                            </v-btn>
                        </div>
                        <!-- End Btn Container -->
                        <div 
                        class="loading-container"
                        v-show="loading">
                            <div class="loding-wrapper">
                                <p> {{ progress }} %</p>
                                <!-- <v-progress-circular
                                :rotate="-90"
                                :size="100"
                                :width="15"
                                :value="progress"
                                color="primary"
                                >
                                {{ progress }}
                                </v-progress-circular> -->

                                <v-progress-linear :value="progress"></v-progress-linear>
                            </div>
                        </div>
                        <!--End Loading -->

                    </div>
                </v-col>
                <v-col cols="12">
                    <div class="html-pdf"
                        v-show="itemShowReportsTable"
                    >
                        <vue-html2pdf
                            :show-layout="false"
                            :float-layout="false"
                            :enable-download="true"
                            :preview-modal="false"
                            :paginate-elements-by-height="1400"
                            :filename="this.site_name + '-Reports-' + this.dateSelectedStamp"
                            :pdf-quality="2"
                            :manual-pagination="false"
                            pdf-format="a4"

                            @progress="onProgress($event)"
                            @hasStartedGeneration="hasStartedGeneration()"
                            ref="html2Pdf"
                        >
                            <section slot="pdf-content" class="A4style pdf-page-content">
                                <!-- PDF Content Here -->
                                <div class="pdf-top-page">
                                    <h1>{{ this.site_name}}</h1>
                                </div>
                                <div class="pdf-main-content">
                                    <v-simple-table
                                        class="pdf-table"
                                    >
                                        <template v-slot:default>
                                        <thead>
                                            <tr>
                                            <th class="text-left">
                                                Employee
                                            </th>
                                            <th class="text-left">
                                                Service
                                            </th>
                                            <th class="text-left">
                                                Internal
                                            </th>
                                            <th class="text-left">
                                                Booking from
                                            </th>
                                            <th class="text-left">
                                                Booking to
                                            </th>
                                            <th class="text-left">
                                                Client name
                                            </th>
                                            <th class="text-left">
                                                Client email
                                            </th>
                                            <th class="text-left">
                                                Note
                                            </th>
                                            <th class="text-left">
                                                Final Price
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                            v-for="item in bookingsForReport"
                                            :key="item.id"
                                            >
                                            <td>{{ item.seller_user.name }}</td>
                                            <td>{{ item.service.service_name }}</td>
                                            <td>{{ (item.block_booking === 1 ? 'Block date' : 'Wellbookings App') }}</td>
                                            <td>{{ formatDate(item.booking_from,'YYYY-MM-DD HH:mm') }}</td>
                                            <td>{{ formatDate(item.booking_to,'YYYY-MM-DD HH:mm') }}</td>
                                            <td>{{ extractClientName(item) }}</td>
                                            <td>{{ extractClientEmail(item) }}</td>
                                            <td>{{ item.note }}</td>
                                            <td>{{ ( item.invoice===null ? '' : (JSON.parse(item.invoice)).total )}}</td>
                                            </tr>
                                        </tbody>
                                        </template>
                                    </v-simple-table>

                                </div>
                                <div class="pdf-footer-content">
                                    <!-- Site title -->
                                    <p>{{ this.site_name }}, {{ this.site_address }}<p/>
                                </div>
                            </section>
                        </vue-html2pdf>
                        <!-- End Pdf -->
                    </div>
                </v-col>
            </v-row>

        </v-container>

    </div>
    
</template>

<script>
    import mixinShared from '@/mixins/Shared'
    var moment = require("moment");
    import VueHtml2pdf from 'vue-html2pdf'

    export default {
        // Default shared functions
        mixins: [mixinShared],
        props: {
            itemReports : {
                type : Object,
                required : false
            },
            itemShowReportsTable : {
                type: Boolean,
                required: false,
            },
            design : {
                type : Boolean,
                required : false,
            },       
        },
        components: {
            VueHtml2pdf
        },
        data() {
            return {
                dateMin: moment().subtract(6, 'months').format('YYYY-MM'),
                dateToday: moment().format('YYYY-MM'),
                dateSelected: moment().format('YYYY-MM'),
                dateSelectedStamp: moment().format('YYYY-MM-DD HH:mm'),
                menu: false,
                bookingsForReport: this.itemReports || {},
                loading: false,
                progress: 0
            }
        },
        computed: {
        },
        mounted() {},
        methods: {
            extractClientName(item){
                
                if(item.user){
                    return (item.user.name?item.user.name:'');
                }else{
                    let tmp = JSON.parse(item.additional);
                    return (tmp.block_booking.clientName?tmp.block_booking.clientName:'');
                }
            },
            extractClientEmail(item){
                
                if(item.user){
                    return (item.user.email?item.user.email:'');
                }else{
                    let tmp = JSON.parse(item.additional);
                    return (tmp.block_booking.clientEmail?tmp.block_booking.clientEmail:'');
                }
                

            },
            onProgress(item){
                console.log(item);
                this.loading = true;
                this.progress = item;

                if(item == 100) {
                    this.loading = false;
                    this.progress = 0; 
                }
            },
            hasStartedGeneration(){
            },
            // hasGeneratedPdf(item){
            //     console.log("hasGenerated", item);
            //     this.loading = false;
            //     this.progress = 0;
            // },
            // generatePreview(date){
            //     this.loading = true;
            //     console.log("date",date);
            //     this.$emit('generateReports', date)

            // },
            // generatePdfAction() {

            //     this.$refs.html2Pdf.generatePdf();
            // }
            generatePreview(date){
                this.loading = true;

                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');

                fetch(appURL + '/api/seller/bookings/month', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + sellerToken
                    },
                    body: JSON.stringify({
                        date: date
                    })
                }).then(response => {
                    if (response.status === 200) {
                        response.json().then(result => {
                            console.log('result',result.success);

                            this.bookingsForReport = result.success;

                            setTimeout(() => {
                                this.$refs.html2Pdf.generatePdf();
                                this.loading = false;
                            }, 3000);
                        });
                    
                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                    } else if (response.status === 422) {
                        //failed logn
                        alert('Not Authenticated');
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 398');
                    }

                }).catch((error) => {
                    console.log('[report generation error]', error);
                    this.processing = false;
                    alert(error)
                });

            }
        },
    }
</script>


<style lang="scss" scoped>
    div:not(.theme--dark) .pdf-page-content{
        // background-color: white;
        margin-top:30px;
        // color: #000;
        padding: 1rem;
    }
</style>