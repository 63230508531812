<template>
    <v-container>

        <v-row dense>
            <v-col md="4" cols="12" v-if="loaded">

                <div class="page--main main">
                    <div class="slider-container">
                        <div class="page--cover anim-cover">

                            <v-img class="card-img slider-img--big" :lazy-src="require('../../assets/_bk-a.jpg')"
                                v-if="!sliderImg" :src="getImage" />

                            <v-img class="card-img slider-img--big" :lazy-src="require('../../assets/_bk-a.jpg')"
                                v-if="sliderImg" :src="sliderImg" />

                        </div>

                        <div class="scrollmenu slider-container--thubnails">
                            <div v-for="(image, index) in getImages" class="slider--thubnail card-img-thumb-wrapper"
                                :key="'itemIimage-'+index" @click="updateSlider(image)" @touch="updateSlider(image)">
                                <v-img class="slider-img--thumbnail card-img-thumb"
                                    :lazy-src="require('../../assets/_bk-a.jpg')" :src="image" />
                            </div>
                        </div>
                    </div>
                    <!-- End Slider Container -->

                    <div class="main-container">
                        <v-row>
                            <v-col cols="12" sm="12" md="12" xl="12">
                                <div class="seller-top--container">
                                    <!-- {{ item}} -->
                                    <h1 class="page--title primary--text">{{singleBooking.seller.name}}</h1>
                                    <p class="page--description"><strong>{{ singleBooking.seller.street }}</strong>, <br>
                                        {{ singleBooking.seller.city }}</p>
                                    <p>{{ singleBooking.seller.description }}</p>
                                    <p class="open--hours">
                                        <v-chip outlined :color="(openToday.open) ? 'green' : 'red'">
                                            <v-icon :color="(openToday.open) ? 'green' : 'red'" left>
                                                mdi-{{ openToday.open ? 'check' : 'close' }}
                                            </v-icon>
                                            Today
                                            {{ openToday.open ? 'Open from ' + openToday.start + ' to ' + openToday.end : 'Closed' }}
                                        </v-chip>
                                    </p>

                                    <div>
                                        <!-- <div v-if="calculateRatings.length"> -->
                                            <div class="text-left">
                                                <v-rating 
                                                class="my-2"
                                                readonly
                                                size="18"
                                                color="teal"
                                                half-increments
                                                v-model="singleBooking.seller.rating"
                                                ></v-rating>
                                            </div>
                                    </div>

                                    <div class="btn-container">
                                        <v-btn text x-large outlined block @click="cancelBooking(singleBooking.id)"
                                            class="btn btn-browse" color="primary">
                                            Cancel Booking
                                        </v-btn>
                                        <v-btn v-if="leaveRatingActive" block @click="goToReview(singleBooking)"
                                            x-large class="btn btn-browse" color="primary">

                                            Leave a Review
                                        </v-btn>
                                        <v-btn  block @click="OpenSupport(singleBooking)"
                                            x-large class="btn btn-browse" color="primary">

                                            Contact Seller
                                        </v-btn>
                                        <v-btn  block @click="GotoSeller(singleBooking)"
                                            x-large class="btn btn-browse" color="primary">

                                            Go To Sellers Page
                                        </v-btn>

                                    </div>
                                </div>

                            </v-col>
                        </v-row>
                    </div>
                </div>

                <!-- <SingleView
                    :itemData="item"
                    /> -->
            </v-col>
            <v-col md="8" cols="12">
                <div class="page--main main">
                    <div class="main-container">
                        <v-row>
                            <v-col cols="12" sm="12" md="12" xl="12">
                                <div id="services" class="seller-services--container">

                                    <v-card elevation="0" class="mx-auto" style="overflow:hidden;">

                                        <v-card-title>
                                        Reservation - {{singleBooking.id}}
                                        </v-card-title>

                                        <v-card-text>
                                            <v-row>
                                                <v-col  md="6" cols="12">
                                                    <div class="date-card">
                                                        <div style="display:inline-block; margin-right:20px;">
                                                            <div style="margin-bottom:10px;text-align: center;">{{formatDate(singleBooking.booking_from,'MMMM')}}</div>
                                                            <div style="margin-bottom:10px;font-weight:bold;font-size: 25px;text-align: center;">{{formatDate(singleBooking.booking_from,'DD')}}</div>
                                                            <div style="text-align: center;">{{formatDate(singleBooking.booking_from,'dddd')}}</div>
                                                        </div>
                                                        <div style="display:inline-block;">
                                                            <div style="margin-top:12px;font-weight:bold;font-size: 25px;">{{formatDate(singleBooking.booking_from,'hh:mm a')}}</div>
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col  md="6" cols="12">
                                                    <div class="date-card">
                                                        <div style="display:inline-block; margin-right:20px;">
                                                            <div style="margin-bottom:10px;text-align: center;">{{formatDate(singleBooking.booking_to,'MMMM')}}</div>
                                                            <div style="margin-bottom:10px;font-weight:bold;font-size: 25px;text-align: center;">{{formatDate(singleBooking.booking_to,'DD')}}</div>
                                                            <div style="text-align: center;">{{formatDate(singleBooking.booking_to,'dddd')}}</div>
                                                        </div>
                                                        <div style="display:inline-block;">
                                                            <div style="margin-top:12px;font-weight:bold;font-size: 25px;">{{formatDate(singleBooking.booking_to,'hh:mm a')}}</div>
                                                        </div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>

                                        <v-card-title>
                                        Service
                                        </v-card-title>
                                        <v-card-text>
                                                    <div>
                                                        <div style="display:inline-block; width:10px;height:60px;background:teal;border-radius:10px;margin-right: 20px;">
                                                        </div>
                                                        <div style="display:inline-block;">
                                                            
                                                            
                                                            <div style="font-weight:bold;font-size: 25px;">{{singleBooking.service.service_name}}</div>
                                                            <div>{{singleBooking.service.service_decription}}</div>
                                                            <div style="font-weight:bold;font-size: 15px;">{{singleBooking.service.price}}{{singleBooking.service.currency}}</div>

                                                        </div>
                                                    </div>

                                        </v-card-text>

                                        <v-card-title>
                                        Emplyee
                                        </v-card-title>
                                        <v-card-text>

                                            <v-list-item v-if="singleBooking.seller_user">
                                                <v-list-item-avatar >
                                                    <v-img v-if="singleBooking.seller_user.avatar !== null" :alt="`${singleBooking.seller_user.name} avatar`"
                                                        :src="'https://api.wellbookings.com/storage/sellerProfileImages/'+singleBooking.seller_user.avatar">
                                                    </v-img>
                                                    <v-img v-else :alt="`${singleBooking.seller_user.name} avatar`"
                                                        src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png">
                                                    </v-img>
                                                </v-list-item-avatar>

                                                <v-list-item-content>
                                                    <v-list-item-title v-text="singleBooking.seller_user.name.slice(0, 25)"></v-list-item-title>
                                                    <v-list-item-subtitle>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>

                                            </v-list-item>


                                        </v-card-text>


                                    </v-card>
                                    <v-card elevation="0" v-if="singleBooking.invoice!=={}">
                                        <v-card-title>Invoice</v-card-title>
                                        <v-card-text>
                                        <invoice
                                        :invoiceObj="invoice"
                                        ></invoice>
                                        </v-card-text>                                    
                                    </v-card>



                                    <v-card elevation="0" v-if="singleBooking.come_to_me===1">
                                        <v-card-title>Meetup Location</v-card-title>
                                        <v-card-text>
                                        <div>Location : {{additional.meetupLocation}}</div>
                                        <div id="mapContainerDetectWidthHeight3">
                                            <LocationPicker
                                            v-if="loaded"
                                            :lat="additional.meetuplat"
                                            :lng="additional.meetuplng"
                                            :disableClick="true"
                                            :parentDiv="'mapContainerDetectWidthHeight3'"
                                            ></LocationPicker>
                                        </div>
                                        </v-card-text>
                                    </v-card>


                                </div>
                            </v-col>


                            <v-col cols="12" sm="12" md="12" xl="12" v-if="!singleBooking.come_to_me===1">
                                <div class="seller-map--container">
                                    <h3 class="page--subtitle primary--text text-center">Store Location</h3>
                                    <v-card class="mx-auto" elevation="8" style="overflow:hidden;">

                                        <v-card-text>
                                            {{ singleBooking.seller.street }}, <br> {{ singleBooking.seller.city }}, {{ singleBooking.seller.country }}
                                        </v-card-text>

                                        <v-row id="mapContainerDetectWidthHeight2">
                                            <LocationPicker v-if="loaded" :lat="parseFloat(singleBooking.seller.lat)"
                                                :lng="parseFloat(singleBooking.seller.lng)" :disableClick="true"
                                                :parentDiv="'mapContainerDetectWidthHeight2'"></LocationPicker>
                                        </v-row>
                                    </v-card>
                                </div>
                            </v-col>
                            <!-- End Col -->



                            <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            xl="12"
                            v-if="!leaveRatingActive"
                            >
                                <div class="seller-ratings--container">

                                        <v-card
                                         elevation="0"
                                            class="mx-auto"
                                        >
                                            <v-card-title>
                                            Review
                                            </v-card-title>
                                            <v-card-text>
                                            {{singleBooking.rating.comment}}

                                            <div class="text-right">
                                                <v-rating
                                                :value="parseFloat(singleBooking.rating.rating)"
                                                color="teal"
                                                half-increments
                                                background-color="grey darken-1"
                                                empty-icon="$ratingFull"
                                                readonly
                                                ></v-rating>
                                            </div>
                                            </v-card-text>
                                        </v-card>
                                    
                                </div>
                            </v-col> 
                            <!-- End Col -->   





                        </v-row>
                    </div>
                    <!-- End Main Container -->
                </div>

                <!-- <SingleView
                    :itemData="item"
                    /> -->
            </v-col>
        </v-row>


    </v-container>
</template>

<script>
    let moment = require("moment");

    import {
        mapGetters,
        mapState
    } from 'vuex'

    import {
        bus
    } from '../../main'
    import mixinShared from '@/mixins/Shared'
    import LocationPicker from "@/components/LocationPicker"
    import Invoice from "@/components/Invoice"

    export default {
        mixins: [mixinShared],
        components: {
            LocationPicker,
            Invoice
        },
        props: {
            itemBooking: {
                type: Object,
                required: false,
                // default() {
                //     return {}
                // }
            }
        },
        data() {
            return {
                singleBooking: this.itemBooking || this.getSingleBooking,
                showAvatar: false,
                showDescription: false,
                leaveRatingActive: true,
                clientBookings: [],
                images:[],
                sliderImg: "",
                invoice:{},
                additional:{},
                loaded:false
            };
        },
        computed: {
            ...mapGetters([
                "isLoggedIn",
                "isSellerLoggedIn",
                "getProfileData",
                "getSellerProfileData",
                "getSingleBooking"
            ]),

            openToday() {
                // Check if exist otherwise give an error
                if(this.singleBooking.seller.working_hours) {
                    // Parse the string
                    let openHours = {};
                    try {
                        openHours = JSON.parse(this.singleBooking.seller.working_hours)
                    } catch (e) {
                        openHours = this.singleBooking.seller.working_hours
                    }

                    // Today Moment Date
                    let todayDate = moment()
                    // Moment full date
                    let fullDate = moment(todayDate, "YYYY-MM-DD HH:mm:ss")
                    // Get day DDD in moment 
                    let dayStr = fullDate.format('ddd')

                    //let Offdays = ["Wen","Tue","Sat"]
                    // Check the Off days in array, if true is close days
                    //let filterCloseDay = openHours.Offdays.filter(d => d !== day);
                    let filterCloseDay = openHours.Offdays.filter(d => d === dayStr);
                    // Return value
                    let isClosed = (filterCloseDay.length) ? false : true

                    // New Object with open value
                    const openHoursUpd = {
                        ...openHours,
                        open : isClosed
                    }

                    //console.log("openHoursUpd", openHoursUpd)
                    return openHoursUpd

                }

                return []

            },

            getImage() {
                let vm = this.singleBooking;

                if (vm.seller !== undefined && vm.seller.cover !== undefined) {
                    return 'https://api.wellbookings.com' + vm.seller.cover.image_url;

                } else if (vm.seller !== undefined && vm.seller.images.length) {
                    return 'https://api.wellbookings.com' + vm.seller.images[0].image_url;

                } else if (vm.cover !== undefined) {
                    return 'https://api.wellbookings.com' + vm.cover.image_url;

                } else if (vm.images.length) {
                    return 'https://api.wellbookings.com' + vm.images[0].image_url;

                } else {
                    //console.log("@/assets/random_store_img/" + Math.floor(Math.random() * 20) + ".png");
                    return require("@/assets/random_store_img/" + Math.floor(Math.random() * 20) + ".png");
                    //return "https://picsum.photos/300/200?random=" + Math.floor(Math.random() * 101);
                }
            },

            getImages() {
                let temp = [];
                let vm = this.singleBooking;
                //console.log('vm', vm);

                for (let index = 0; index < 5; index++) {
                    if (vm.seller !== undefined && vm.seller.cover !== undefined) {
                        temp.push('https://api.wellbookings.com' + vm.seller.cover.image_url);

                    } else if (vm.seller !== undefined && vm.seller.images[index] !== undefined) {
                        temp.push('https://api.wellbookings.com' + vm.seller.images[index].image_url);

                    } else if (vm.cover !== undefined && vm.cover.length) {
                        temp.push('https://api.wellbookings.com' + vm.cover.image_url);

                    } else if (vm.seller.images[index] !== undefined && vm.seller.images.length) {
                        temp.push('https://api.wellbookings.com' + vm.seller.images[index].image_url);

                    } else {
                        temp.push(require("@/assets/random_store_img/" + Math.floor(Math.random() * 21) + ".png"));
                    }
                }

                return temp;
            }

        },
        methods: {
            GotoSeller(singleBooking){

                    this.$router.push({
                        name: 'seller-page',
                        params: {
                            singleItem: null,
                            seller_id: singleBooking.seller.id
                        }
                    });

            },
            OpenSupport() {
                bus.$emit('showSupportDialog', {
                    fromUserId: this.singleBooking.user_id,
                    toUserId: this.singleBooking.seller_id,
                    referenceType: 'booking',
                    referenceId: this.singleBooking.id,
                    chatName: 'Support-' + this.singleBooking.service.service_name,
                });
            },
            cancelBooking(id) {
                var appURL = localStorage.getItem('appURL');
                var token = localStorage.getItem('token');

                //get list of sellers bookings
                fetch(appURL + '/api/client/booking/' + id + '/update', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + token
                    },
                    body: JSON.stringify({
                        status: "canceled"
                    })
                }).then(response => {


                    console.log(response);

                    if (response.status === 200) {

                        response.json().then(result => {
                            console.log('result', result);

                            // this.clientBookings = result.success;
                            // this.$store.dispatch('setClientBookings', result.success);

                        });

                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                        // this.clientBookings = this.getSellersBookings;
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 398');
                        // this.clientBookings = this.getSellersBookings;
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                    this.processing = false;
                    alert(error)
                });
            },
            // getBookings() {

            //     var appURL = localStorage.getItem('appURL', "");
            //     var token = localStorage.getItem('token');

            //     //get list of sellers bookings
            //     fetch(appURL + '/api/client/booking/all', {
            //         method: 'POST',
            //         headers: {
            //             "Content-Type": "application/json",
            //             "Accept": "application/json",
            //             "Authorization": "Bearer " + token
            //         }
            //     }).then(response => {


            //         console.log(response);

            //         if (response.status === 200) {

            //         response.json().then(result => {
            //                 console.log('result', result);

            //                 this.clientBookings = result.success;
            //                 this.$store.dispatch('setClientBookings', result.success);

            //         });

            //         } else if (response.status === 401) {
            //         //failed logn
            //         console.log('Error connecting to API server 398');
            //                 this.clientBookings = this.getSellersBookings;
            //         } else {
            //         // unkown error
            //         console.log('Unexpected error with API server 398');
            //                 this.clientBookings = this.getSellersBookings;
            //         }

            //     }).catch((error) => {
            //         console.log('[GPSLocation http request error]', error);
            //             this.processing = false;
            //             alert(error)
            //     });

            // }
        },
        mounted() {
            console.log('this.singleBooking', this.singleBooking);
            // if (this.isLoggedIn) {
            //     this.getBookings();
            // }
            console.log("BookingDetails --- mounted")
            if (!this.isLoggedIn) {
                this.goToLogin(true,true);
            }
            // If params value are empty redirect to explore
            if (!this.itemBooking) {
                this.singleBooking = this.getSingleBooking
                console.log('this.singleBooking', this.singleBooking);
                // this.goToAccount()
            }


            this.invoice = JSON.parse(this.singleBooking.invoice);
            this.additional = JSON.parse(this.singleBooking.additional);

            if(this.singleBooking.rating !== undefined && this.singleBooking.rating !== null){
               this.leaveRatingActive = false; 
            }

            this.loaded = true;

        },

    }
</script>

<style lang="scss">
    // End custom common variables
    .date-card {
        color: white;
        background-color: teal;
        border-width: 1px;
        border-color: slategray;
        margin: 5px;
        padding: 5px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 12px;
        cursor: pointer;
        border-radius: 7px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .slider-container {
        .page--cover {
            border-radius: 5px;
            overflow: hidden;
        }
        .slider-container--thubnails {
            // display: flex;
            margin: .5rem auto;
            // padding-bottom: .5rem;
            .slider--thubnail {
                display: inline-block;
                width: calc(25% - 5px);
                background-color: #ebebeb;
                vertical-align: center;
                border-radius: 10px;
                margin-right: 5px;

                img {
                    padding: .25rem;
                    overflow: hidden;
                    border-radius: 50px;
                    margin: .25rem;
                }
            }
        }
    }
    .bookings-container--single {
        padding: 1rem;
    }

    // Custom styles
</style>