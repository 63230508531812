<template>

    <div class="calender-container">

        <div class="scrollmenu">

            <div class="date-card" v-for="(date,index) in dateArray" :key="'calenderOption-'+index"
                @click="selectedDate(date,index)" :class="getCalenderIndex === index? 'selected' :''">
                <div style="margin-bottom:10px;">{{formatDate(date,'MMM')}}</div>
                <div style="margin-bottom:10px;font-weight:bold;font-size: 18px;">{{formatDate(date,'DD')}}</div>
                <div>{{formatDate(date,'ddd')}}</div>

            </div>
            <div class="date-card"
                @click="AddMoreDates()">
                <div style="padding-top: 65px;font-weight:bold">More</div>

            </div>

        </div>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapState
    } from 'vuex'
    var moment = require("moment");


    export default {
        data() {
            return {
                dateArray: [],
                selected: null,
                lastindex:0
            };
        },
        mounted() {

            //create the date array list
            this.dateArray.push(moment().format('YYYY-MM-DD HH:mm:ss'));

            for (let index = 1; index < 8; index++) {

                this.dateArray.push(moment(this.dateArray[0]).add(index, 'days').format('YYYY-MM-DD HH:mm:ss'));

            }
            this.lastindex = 8;
            this.selected = this.getCalenderIndex;

        },
        computed: {
            ...mapGetters(["getCalenderIndex"]),
        },
        methods: {
            AddMoreDates(){

                for (let index = this.lastindex; index < this.lastindex+8; index++) {

                    this.dateArray.push(moment(this.dateArray[this.dateArray.length]).add(index, 'days').format('YYYY-MM-DD HH:mm:ss'));

                }
                this.lastindex = this.lastindex+8;
                this.selected = this.getCalenderIndex;
            },
            selectedDate(date, index) {
                console.log('index', index)
                this.selected = index;
                this.$emit('selectedDate', date)
                this.$store.dispatch('setCalenderIndex', this.selected);
            },
            formatDate(date, formate) {
                return moment(date).format(formate);
            }

        },
    }
</script>

<style lang="scss" scoped>
    // Start custom common variables
    // End custom common variables

    .scrollmenu {
        background: #ebebeb;
        text-align: center;
        
    }

    .calender-container {
    }
    .date-card {

        display: inline-block;
        color: white;
        background-color: teal;
        border-width: 1px;
        border-color: slategray;
        margin: 5px;
        padding: 5px;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 12px;
        cursor:pointer;
        border-radius: 7px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .selected {
        background-color: white;
        color: black;
        
    }

    // Custom styles
</style>