<template>
  <div>

    <div style="background-color:teal;">
      <v-container >
        <v-row dense>

          <v-col cols="12">
            <v-text-field class="search-field" hide-details filled label="search" name="search-text" v-model="searchValueFinal"
              prepend-inner-icon="mdi-magnify" type="text" required clearable>
            </v-text-field>
          </v-col>

        </v-row>
        <v-row no-gutters v-if="!getCurrentLocation.useGeo">
          <v-col class="search-field" cols="6">
            <v-text-field class="search-field" label="Country" :value="getCurrentLocation.country.label" @click="changeLocation()" filled
              prepend-inner-icon="mdi-earth" hide-details readonly></v-text-field>
          </v-col>
          <v-col class="search-field" cols="6">
            <v-text-field class="search-field" label="City" :value="getCurrentLocation.city.label" @click="changeLocation()" filled
              prepend-inner-icon="mdi-city" hide-details readonly></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters v-else>
          <v-col cols="12">
            <v-text-field class="search-field" label="GEO" value="Current Location" @click="changeLocation()" filled hide-details readonly>
            </v-text-field>
          </v-col>
        </v-row>

        <!-- <v-row dense>

                <v-col cols="12">

                  <v-btn 
                  @click="changeLocation()" 
                  text 
                  small 
                  block 
                  color="primary"
                  class="btn-avanced"
                  v-if="!isEmpty(getCurrentLocation)"
                  >
                      {{ getCurrentLocation.useGeo ? 'Current location' : getCurrentLocation.country.label+' - '+getCurrentLocation.city.label }}
                  </v-btn>

                </v-col>

            </v-row> -->
      </v-container>
    </div>

    <template v-if="options.showDate && options.showCalendar">
      <Calender />
    </template>

    <div class="categories-top-scroll">

      <CategoriesMenu :categories="Categories" :horizontal="true" :hideIcons="true" @explore="filterResults"
        @getAllResults="getAllResults">
      </CategoriesMenu>

    </div>

    <!-- <div row="2" width="100%"> -->
    <!-- {{ searchResult}} -->
    <v-container>

      <v-btn class="btn btn-see-more" text v-if="options.showCalendar" @click="options.showDate = !options.showDate">
        <!-- {{ $t('product.see_more') }} -->
        Show Date
        <v-icon>mdi-plus</v-icon>

      </v-btn>
      <v-row v-if="searchResult.length" dense>
        <v-col cols="12" sm="12" md="6" lg="4" ref="listView" v-for="(merchant, index) in searchResult"
          :key="'exploreItem-'+index">

          <ProductCard :item="merchant" :showServices="false"></ProductCard>

        </v-col>
        <!-- {{ this.searchResult }} -->
      </v-row>
      <v-row v-if="! searchResult.length" dense>
        <v-col cols="12">
          <div class="page--no-results">
            <v-icon x-large color="primary">mdi-alert-decagram-outline</v-icon>
            <p class="page--subtitle page--no-services">
              Sorry, no services in that area!
            </p>
          </div>
        </v-col>
      </v-row>


    </v-container>
    <!-- </div> -->

    <v-dialog class="dialog-confirm-map" v-model="dialogAlert" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          Reset Location
        </v-card-title>
        <v-card-text>Do you want to reset your location ? If you want to proceed please click to Continue. This allow
          you to select a new location on the map.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialogAlert = !dialogAlert">
            Cancel
          </v-btn>
          <v-btn color="green darken-1" outlined left @click="goToLocationPicker()">
            Continue
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div style="position:fixed;bottom: 12vh;right: 30px;">
      <v-fab-transition>
        <v-btn key="mdi-map" color="teal" fab large dark class="v-btn--example" @click="goToMap()">
          <v-icon>mdi-map</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
  </div>
</template>

<script>
  import {
    mapGetters,
    mapState
  } from 'vuex'

  import Categories from "../mixins/Categories";
  import Calender from "../components/Calender.vue";
  // import ExploreItem from '../components/ExploreItem.vue';
  import ProductCard from '../components/products/ProductCard.vue';
  import CategoriesMenu from '../components/widgets/CategoriesMenu.vue';

  import mixinShared from '@/mixins/Shared'
  import {
    bus
  } from '../main'


  export default {
    mixins: [mixinShared],
    props: ["searchValue"],
    components: {
      // ExploreItem,
      ProductCard,
      Calender,
      CategoriesMenu
    },
    data() {
      return {
        options: {
          showCalendar: false,
          showDate: false,
        },
        Categories: Categories,
        searchResult: [],
        SearchCity: '',
        show: false,
        searchValueFinal: '',
        // Open Toggle top menu
        // Dialog Alert
        dialogAlert: false,
      };
    },
    computed: {
      ...mapGetters(["getSearchResult", "getCurrentLocation"])
    },
    filters: {
      dollars: num => `£${num / 100}`
    },
    created() {
      this.$store.dispatch("setLocation", this.getCurrentLocation);

      //this.searchResult = this.getSearchResult;
    },
    mounted() {
      console.log("Explore - mounted - out", this.searchValue);

      this.searchValueFinal = this.searchValue;

      if (this.searchValueFinal !== '' && this.searchValueFinal !== undefined) {
        console.log("in search --- ")
        this.filterResults(this.searchValueFinal)
      }
    },
    methods: {

      goToMap() {
        this.$router.push({
          name: 'map'
        });
      },
      changeLocation() {
        bus.$emit('showLocationDialog', true);
      },
      goToLocationPicker() {
        console.log("goToLocationPicker home.vue");
        this.$router.push({
          name: 'LocationPicker'
        });
      },
      filterResults(search) {

        let temp = [];
        try {

          if (this.getSearchResult.length > 0) {
            temp = this.getSearchResult.filter((item) => {

              let inService = false;
              let inSeller = false;
              search = (search.toString()).toLowerCase();

              let searchValues2 = (search.split(" ")).join("|");
              let matchpattern = new RegExp(searchValues2, 'g');

              //console.log('item', item);
              for (let serv of item.services) {
                if ((serv.service_name.toLowerCase()).match(matchpattern)) {
                  inService = true;
                } else if ((serv.service_decription.toLowerCase()).match(matchpattern)) {
                  inService = true;
                }
              }

              if ((item.name.toLowerCase()).match(matchpattern)) {
                inSeller = true;
              } else if ((item.city.toLowerCase()).match(matchpattern)) {
                inSeller = true;
              } else if ((item.street.toLowerCase()).match(matchpattern)) {
                inSeller = true;
              } else if ((item.description.toLowerCase()).match(matchpattern)) {
                inSeller = true;
              }

              return inService || inSeller;
            });


          }

        } catch (error) {
          console.log(error);
        }

        this.searchResult = temp;
      },
      getAllResults() {
        this.searchResult = this.getSearchResult;
      },
    },
    watch: {
      getSearchResult: {
        handler: function (after, before) {
          this.searchResult = after;
          console.log('we are here !!!!S');
          if (this.searchValueFinal !== '' && this.searchValueFinal !== undefined) {
            this.filterResults(this.searchValueFinal)
          }
        },
        deep: true
      },
      searchValue(val) {

        this.searchValueFinal = this.searchValue;

        if (val !== '' && val !== undefined) {
          this.filterResults(this.searchValueFinal)
        }
      },
      searchValueFinal(val) {
        if (val !== '' && val !== undefined) {
          this.filterResults(val)
        }
      },
    }
  };
</script>

<style scoped lang="scss">
  .page--no-results {
    padding: 3rem 0;

    i {
      margin: 0 auto;
      display: block;
      font-size: 64px !important;
      text-align: center;
    }

    // End custom common variables
    .page--no-services {
      text-align: center;
      display: block;
      margin: 1rem auto;
    }
  }
  .search-field{
  background-color: white!important;
  }

  // Custom styles
  .add-button {
    height: 30px;
    background-color: rgb(51, 51, 206);
    color: white;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 600px;
  }

  .card {
    background-color: #fff;
    color: #4d4d4d;
    margin: 15px;
  }

  .card-layout {
    padding: 20px;
  }

  .scrollmenu {
    background: #ebebeb;
    text-align: center;

    .options-card {
      color: black;
      display: inline-block;
      background-color: transparent;
      margin: 0;
      padding: 0;
      font-size: 12px;
      border-radius: 10px;
      transform: scale(.8);
      margin-right: -20px;
      outline: 0;
    }
  }


  // .top-option-container {
  //     position: absolute;
  //     background-color: #fff;
  //     top: 0;
  //     right: 0;
  //     left: 0;
  //     height: 0;
  //     z-index: 9991;
  //     transition: all 0.3s ease-in-out;
  //     .container {
  //         opacity: 0;
  //         z-index: -1;
  //         visibility: hidden;
  //         transition: all 0.2s ease-in-out;
  //     }
  //     &.visible {
  //         height: 70px;
  //         top: 0;
  //         z-index: 9991;
  //         transition: all 0.3s ease-in-out;
  //         .container {
  //             opacity: 1;
  //             z-index: 9991;
  //             visibility: visible;
  //             transition: all 0.5s ease-in-out;
  //         }
  //     }

  //     .search-map-toggle {
  //         //background-color: #fff;
  //         position: absolute;
  //         bottom: -55px;
  //         left: 0;
  //     }
  // }

  .btn-container {
    .btn {
      margin: 0 auto;
    }
  }
</style>