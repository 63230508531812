<template>
    <v-container 
    fluid
    >
    <v-row dense>
    <v-col cols="12">

    <v-progress-circular
      :size="70"
      :width="7"
      color="green"
      indeterminate
    ></v-progress-circular>

    </v-col>
    </v-row>
    </v-container>
</template>

<script>

    export default {
        data() {
            return {
            };
        },
        created() {},
        mounted() {
            const names = [''];
            caches.keys().then((names) => {
                for (let name of names)
                    caches.delete(name);
            }).then(()=>{

                this.$router.push({
                    name: 'home'
                });
            });
        }
    };
</script>
