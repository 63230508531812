<template>
    <v-container>
        <v-row>
            <v-col cols="12"
            >
                <section class="page page-account page-account-rating">
                    <div class="page--top-container">
                            <h1 class="page--title primary--text">
                                {{ booking.service.service_name }}
                            </h1>
                            <p class="page--description">
                                <!-- {{ booking.booking_from }} -->
                                Leave a rating for this service.
                            </p>                        
                    </div>
                    <div class="page--main">
<!-- {{booking}} -->
                        <div class="bookings-container--single">
                            <v-form
                                ref="rating-form"
                                v-model="rating.valid"
                            >                                
                                <v-container 
                                class="pa-0 pt-5"
                                fluid>
                                    <v-row 
                                    class="pa-0"
                                    dense>
                                        <v-col 
                                        cols="12">
                                            <v-input :value="rating.value" :rules="[rules_required.required]">
                                                <v-rating 
                                                size="35"
                                                background-color="warning lighten-1"
                                                
                                                class="ratings-container ratings-container--center"
                                                v-model="rating.rating"
                                                :readonly="existing"
                                                ></v-rating>
                                            </v-input>
                                        </v-col>                                    
                                        <v-col 
                                        cols="12">

                                                <v-textarea
                                                outlined
                                                height="150"
                                                class="input--big-icon"
                                                id="rating-description"
                                                label="Write here your rating"
                                                v-model="rating.comment"
                                                name="rating-description"
                                                :rules="[rules_cc.required]"
                                                prepend-icon="mdi-pen"
                                                type="text"
                                                half-increments
                                                :disabled="existing"
                                                ></v-textarea>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>

                            <!-- <p class="page--description">{{ booking.service.service_decription }}</p> -->
                                <div class="btn-container" v-if="!existing">
                                    <v-btn
                                    text
                                    x-large
                                    outlined
                                    block
                                    @click="addrating()"
                                    class="btn btn-rating"
                                    color="primary"
                                    >
                                        Confirm rating
                                    </v-btn>

                                    <v-btn
                                    text
                                    x-large
                                    block
                                    @click="goBack"
                                    class="btn btn-back"
                                    color="primary">
                                        Back
                                    </v-btn>
                                </div> 
                        </div>
                    </div>   
                </section>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import mixinShared from '@/mixins/Shared'

    import {
        mapGetters,
        mapState
    } from 'vuex'

    export default {
        mixins: [mixinShared],
        props: {
            booking:{
                type: Object,
                required: true
            }
        },
        data() {
            return {
                rating: {
                    valid: false,
                    user_id:0,
                    seller_id:0,
                    booking_id:0,
                    rating:0,
                    comment:'',
                    status:1,
                },
                existing:false
            };
        },  
        mounted() {
            console.log("BookingDetails --- mounted")
            // If user is not logged in
            if (!this.isLoggedIn) {
                this.goToLogin(true);
            }
            // If params value are empty redirect to explore
            if(!this.booking) {
                console.log("goToAccount Bokking ratings", this.booking);
                this.goToAccount()
            }else{

                if(this.booking.rating !== undefined && this.booking.rating !== null){
                    this.rating = {valid: true,...this.booking.rating};
                    this.existing=true;
                }

            }
        },       
        computed: {
            ...mapGetters([
                "isLoggedIn", 
                "isSellerLoggedIn", 
                // "getProfileData",
                // "getSellerProfileData"
            ])
        },
        methods: {
            addrating() {
                if(this.existing === false){

                    this.rating.user_id = this.booking.user_id;
                    this.rating.seller_id = this.booking.seller_id;
                    this.rating.booking_id = this.booking.id;

                }

                var appURL = localStorage.getItem('appURL');
                var token = localStorage.getItem('token');

                //get list of sellers bookings
                fetch(appURL + '/api/client/booking/rating/add', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + token
                    },
                    body: JSON.stringify({...this.rating})
                }).then(response => {


                    console.log(response);

                    if (response.status === 200) {

                    response.json().then(result => {
                            console.log('result', result);
                            this.existing = true;

                            this.goToBookings();

                            // this.clientBookings = result.success;
                            // this.$store.dispatch('setClientBookings', result.success);

                    });
                    
                    } else if (response.status === 401) {
                    //failed logn
                    console.log('Error connecting to API server 398');
                            // this.clientBookings = this.getSellersBookings;
                    } else {
                    // unkown error
                    console.log('Unexpected error with API server 398');
                            // this.clientBookings = this.getSellersBookings;
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                        this.processing = false;
                        alert(error)
                });
            },

        },        
    }
</script>

<style lang="scss">
    // End custom common variables
    
    .page-account-rating {
        padding: 0;
    }
    .ratings-container--center {
        text-align: center;
        margin: 0 auto;
    }

    // Custom styles
</style>