<template>
  <v-app>

    <TopToolbar 
    v-if="!isMobile"
    :installButton="installButton" 
    :deferredPrompt="deferredPrompt"
    ></TopToolbar>

    <LocationDialog></LocationDialog>
    <SupportDialog></SupportDialog>
    <MakeBookingDialog></MakeBookingDialog>

    <v-main>
      <router-view></router-view>
    </v-main>

    <BottomNav v-if="isMobile"></BottomNav>

    <Footer v-if="!isMobile"></Footer>


  </v-app>
</template>

<script>
  import TopToolbar from './components/TopToolbar.vue';
  import BottomNav from './components/BottomNav.vue';
  import Footer from './components/Footer.vue';
  import LocationDialog from './components/LocationDialog.vue';
  import SupportDialog from './components/SupportDialog.vue';
  import MakeBookingDialog from './components/MakeBookingDialog.vue';
  
  import mixinShared from './mixins/Shared'
  import { bus } from './main'

  import {
      mapGetters,
      mapState
  } from 'vuex'

  export default {
    mixins: [mixinShared],
    components: {
      BottomNav,
      TopToolbar,
      Footer,
      LocationDialog,
      SupportDialog,
      MakeBookingDialog
    },
    mounted() {

      if(this.getCurrentLocation === null || this.getCurrentLocation.useGeo === undefined){
        console.log('Here Here');
        bus.$emit('showLocationDialog',true);
      }

      // window.addEventListener("beforeinstallprompt", e => {
      //   console.log("App beforeinstallprompt fired");
      //   // Prevent Chrome 76 and earlier from automatically showing a prompt
      //   e.preventDefault();
      //   // Stash the event so it can be triggered later.
      //   this.deferredPrompt = e;

      //   this.installButton = true;

      // });
    },
    created(){
    },
    data() {
      return {
        activeItem: 'top',
        deferredPrompt: {},
        installButton: false,
        location:{}
      }
    },
    methods: {
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      doNothing() {},


    },
    computed: {
        ...mapGetters([
            "isMobile",
            "getCurrentLocation"
        ])
    },
  }
</script>

<style lang="scss">
  @media (min-width: 1904px){
    .container {
        max-width: 1200px;
    }
  }
  /* global styles */
  html {
    --scrollbarBG: #CFD8DC;
    --thumbBG: #009688;
  }
  body::-webkit-scrollbar {
    width: 11px;
  }
  body {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }
  body::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }

  .row:empty {
    padding: 0;
    margin: 0;
  }

  .container:empty {
    padding: 0;
    margin: 0;
  }

  .v-text-field--outlined.br-20,
  .br-20 {
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
  }

  .v-text-field--outlined.br-50,
  .br-50 {
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
  }
  
  // .container {
  //     width: 1170px;
  // }
  
  .header-nav,
  .v-bottom-navigation.bottom-navigation {
      z-index: 9990;
  }

  .v-main {
    overflow: hidden;
  }

  .btn {
    font-size: 110%!important;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -o-border-radius: 50px;
    &.v-btn--outlined {
      border: 3px solid;
      border-color: var(--v-primary-base)!important;
      font-weight: 400;
    }
    &.v-size--small {
      font-size: 100%!important;
      text-transform: capitalize;
    }
  }

  .btn.btn-facebook {
    background-color: #3B5499!important;
  }

  div.btn-container {
    max-width: 720px;
    margin: 0 auto 1rem auto;
    text-align: center;
    // padding: 1rem;
    .btn {
      text-align: center;
      display: inline-flex;
      margin: 1rem auto 0 auto!important;
      max-width: 350px;
    }
  }

  .v-text-field.v-text-field--enclosed {
    .v-input__slot {
        //background: #ebebeb;
    }
    .v-text-field__details {
        margin-bottom: 0;
    }
  }

  .page--title {
    font-size: 180%;
    color: var(--v-primary-base);
    line-height: 1.1;
    &.inverted {
      //color: #ffffff;
    }
  }

  .page--subtitle {
    font-size: 130%;
    font-weight: 500;
    color: var(--v-primary-base);
      &.inverted {
        //color: #ffffff;
      }
  }

  .page--description {
    font-size: 120%;
  }

  .page--text {
    font-size: 120%;
    text-align: center;
    strong {
      color: var(--v-primary-base)!important;
    }
  }

  .input--big-icon {
    i.mdi {
      font-size: 180%;
    }

    .v-input__slot {
        fieldset {
          border-color: rgba(0,0,0,.15);
          border-radius: 15px;
       }
    }

  }

  .scrollmenu {
    /* background-color: #333; */
    overflow: auto;
    white-space: nowrap;
  }

  .options-card {
    display: inline-block;
    //color: black;
    background-color: white;
    border-width: 1px;
    border-color: slategray;
    margin: 5px;
    padding: 5px;
    font-size: 12px;
    border-radius: 10px;
  }

  .icon-logo {
    color: var(--v-primary-base)!important;
    font-size: 8rem!important;
    border: .5rem solid var(--v-primary-base)!important;
    border-radius: 100%;
  }

  
  .top-option-container {
    position: absolute;
    //background-color: var(--v-primary-base) !important;
    background-color: #fff;
    top: 0;
    right: 0;
    left: 0;
    height: 0;
    z-index: 9991;
    transition: all 0.3s ease-in-out;
    .container {
      opacity: 0;
      z-index: -1;
      visibility: hidden;
      transition: all 0.2s ease-in-out;
    }
    &.visible {
      height: 80px;
      top: 0;
      z-index: 9991;
      transition: all 0.3s ease-in-out;
      .container {
        opacity: 1;
        z-index: 9991;
        visibility: visible;
        transition: all 0.5s ease-in-out;
      }
    }

    .search-map-toggle {
      //background-color: #fff;
      position: absolute;
      bottom: -55px;
      right: 0;
      &.left-align {
        left: 0;
      }
    }
  }

  .v-application.theme--dark {
    .top-option-container {
      background-color: #333333;
    }
  }

  .v-input {
    &.theme--dark {
      .v-input__slot fieldset {
        border-color: rgba(255,255,255,.2);
      }
    }
  }
</style>