<template>

  <footer
    dark
    padless
    flat
    tile
    class="teal darken-4 lighten-1 white--text text-center"
  >


  <div class="footer-above">
    <v-container>
      <v-row dense>

        <v-col
          cols="6"
          sm="4"
          md="4"
          lg="4" 
          class="footer-col"
          style="text-align:left;"
        >
          <h5>Location</h5>
          <p>malta
            <br>Trig mathew kamp 30 fl10 slm8967,<br> Attard</p>
        </v-col>
        <v-col
          cols="6"
          sm="4"
          md="4"
          lg="4" 
          class="footer-col"
        >
          <h5>Around the Web</h5>
          <ul class="list-inline">
            <!-- some social networks -->
          </ul>

          <h5>Try it out! It’s FREE and always will be!</h5>
          <p>
            No more phone calls or endless messages
            Receive SMS confirmation fast
            Over 200 restaurants to choose from
            Cancel booking anytime (no cancellation fees)
            Unparalleled customer service</p>
        </v-col>
        <v-col
          cols="6"
          sm="4"
          md="4"
          lg="4" 
          class="footer-col"
          style="text-align:right;"
        >
        <router-link to="/CookiePolicy">Cookie Policy</router-link><br>
        <router-link to="/Disclaimer">Disclaimer</router-link><br>
        <router-link to="/PrivacyPolicy">Privacy Policy</router-link><br>
        <router-link to="/TermsAndConditions">Terms & Conditions</router-link><br>

        </v-col>
      </v-row>
    </v-container>
  </div>


  <div class="footer-below">
    <v-container>
      <v-row dense>

        <v-col
          cols="6"
          sm="4"
          md="4"
          lg="4" 
          class="footer-col"
        >
          Copyright {{ this.footerYear }} &copy; Author {{ this.site_name }}
        </v-col>
      </v-row>
    </v-container>
  </div>

  <cookie-law theme="dark-lime"></cookie-law>

</footer>


</template>

<script>

    import CookieLaw from 'vue-cookie-law'
    import mixinShared from '@/mixins/Shared'
    var moment = require("moment");

    export default {
      components: { CookieLaw },
      mixins: [mixinShared],
      data() {
          return {
             footerYear: moment().format('YYYY')
          };
      },
    }
</script>

<style lang="scss" scoped>

</style>