<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <!-- <div actionBarHidden="true"> -->
                <section class="page page-add-employee">
                    <div class="page--top-container">
                        <!-- <v-icon v-if="user.avatar === ''" color="primary" class="icon-logo">mdi-spa</v-icon>
                        <v-img v-else height="100" :src="'https://api.wellbookings.com/storage/sellerProfileImages/'+user.avatar" />

                        <h1 class="page--title">Add Employee</h1>
                        <p class="page--description">
                            Add / Edit Employee For Seller account
                        </p> -->

                        <AlertMessage 
                            :messageVisible="messageDetails.visible" 
                            :messageText="messageDetails.text"
                            :messageType="messageDetails.type"
                        ></AlertMessage>
                    </div>
                    <div class="page--main">
                        <div class="page--form">
                            <v-container fluid>
                                <v-row >
                                    <v-col 
                                    cols="12" 
                                    sm="12"
                                    md="6"
                                    lg="6"
                                    v-if="this.user.id !== undefined">
                                        <v-card>
                                            <v-card-title class="headline primary--text">
                                            Set Avatar
                                            </v-card-title>
                                            <v-card-subtitle>
                                                <!-- {{ this.user.id }}
                                                {{ this.user.image.name }} -->
                                                <v-file-input 
                                                    v-model="user.image"  
                                                    accept="image/*" 
                                                    label="File input"
                                                    prepend-icon="mdi-camera" 
                                                    @blur="onFileChange" 
                                                    show-size>
                                                </v-file-input>   
                                            </v-card-subtitle>  
                                            <v-card-actions>                                       
                                                <v-btn 
                                                    @click="uploadProfilePhoto()" 
                                                    class="btn btn-upload"
                                                    x-large 
                                                    outlined
                                                    color="primary">
                                                    Upload Profile photo
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-col>
                                    <!-- End Col -->
                                <!-- </v-card> -->
                                    <v-col 
                                    cols="12" 
                                    sm="12"
                                    md="6"
                                    lg="6"
                                    v-if="this.user.id !== undefined">
                                        <v-card >
                                            <v-card-title class="headline primary--text">
                                            Sync
                                            </v-card-title>
                                            <v-card-subtitle>
                                                <v-text-field 
                                                label="Export Ical Link"
                                                :value="personalIcalLink"
                                                readonly></v-text-field>

                                                <v-text-field 
                                                v-model="user.ical_link" 
                                                label="Import Ical Link"></v-text-field>
                                            </v-card-subtitle>
                                            <v-card-actions>
                                                <v-btn 
                                                    @click="SaveIcalLink()" 
                                                    class="btn btn-save"
                                                    outlined
                                                    x-large
                                                    color="primary">
                                                    Save Ical Link
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-col>
                                    <!-- End Col -->
                                    <v-col 
                                    cols="12" 
                                    sm="12"
                                    md="12"
                                    lg="12"
                                    >                                    
                                        <v-card>
                                            <v-container fluid>
                                                <v-card-title class="headline primary--text">Account</v-card-title>
                                                <v-row>
                                                    <v-col 
                                                        cols="12"
                                                        sm="12"
                                                        md="6"
                                                        lg="6"
                                                    >

                                                        <v-text-field 
                                                            outlined height="50" 
                                                            class="input--big-icon"
                                                            id="username" 
                                                            label="Username"
                                                            v-model="user.name" 
                                                            name="registration-username"
                                                            prepend-icon="mdi-account-outline" 
                                                            type="text"></v-text-field>
                                                    </v-col>
                                                    <v-col 
                                                        cols="12"
                                                        sm="12"
                                                        md="6"
                                                        lg="6"
                                                    >
                                                        <v-text-field 
                                                            outlined 
                                                            height="50" 
                                                            class="input--big-icon"
                                                            id="email" label="Email"
                                                            name="login-reg-email" 
                                                            v-model="user.email" 
                                                            prepend-icon="mdi-email-outline"
                                                            type="text"
                                                        ></v-text-field>
                                                    </v-col>

                                                    <!-- <div v-show="user.changePassword"> -->
                                                        <v-col 
                                                            cols="12"
                                                            sm="12"
                                                            md="6"
                                                            lg="6"
                                                            v-show="user.changePassword"
                                                        >

                                                            <v-text-field 
                                                                outlined 
                                                                height="50" 
                                                                class="input--big-icon"
                                                                id="password"
                                                                label="Password" 
                                                                v-model="user.password" 
                                                                name="login-password"
                                                                prepend-icon="mdi-lock" 
                                                                type="password"></v-text-field>

                                                            <p v-show="isLoggingIn" class="forgot" @click="forgotPassword()">
                                                                {{ !forgotPass ? 'Forgot your password? ' : 'Back to Login'}}</p>
                                                        </v-col>

                                                        <v-col 
                                                            cols="12"
                                                            sm="12"
                                                            md="6"
                                                            lg="6"
                                                            v-show="user.changePassword"
                                                        >
                                                            <v-text-field 
                                                                outlined 
                                                                height="50" 
                                                                class="input--big-icon"
                                                                id="confirm_password"
                                                                label="Repeat Password" 
                                                                v-model="user.confirm_password"
                                                                name="login-conf-password" 
                                                                prepend-icon="mdi-lock" 
                                                                type="password">
                                                            </v-text-field>
                                                        </v-col>
                                                    <!-- </div> -->

                                                    <v-col 
                                                        cols="12"
                                                        sm="12"
                                                        md="4"
                                                        lg="4"
                                                        class="py-0"
                                                    >
                                                        <v-checkbox
                                                            v-model="user.admin"
                                                            label="Admin account"
                                                            color="teal"
                                                            hide-details
                                                        ></v-checkbox>
                                                    </v-col>

                                                    <v-col 
                                                        cols="12"
                                                        sm="12"
                                                        md="4"
                                                        lg="4"
                                                        class="py-0"
                                                    >
                                                        <v-checkbox
                                                            v-model="user.active"
                                                            label="Account Active"
                                                            color="teal"
                                                            hide-details
                                                        ></v-checkbox>
                                                    </v-col>


                                                    <v-col 
                                                        cols="12"
                                                        sm="12"
                                                        md="4"
                                                        lg="4"
                                                        class="py-0"
                                                    >
                                                        <v-checkbox
                                                            v-model="user.changePassword"
                                                            label="Set Password"
                                                            color="teal"
                                                            hide-details
                                                            :readonly="this.user.id === undefined"
                                                        ></v-checkbox>
                                                    </v-col>                                        
                    
                                                    <v-col cols="12"
                                                    class="py-0"
                                                    >
                                                        <v-btn 
                                                            @click="submit" 
                                                            class="btn btn-primary btn-login btn-register"
                                                            :outlined="!isLoggingIn" 
                                                            x-large 
                                                            color="primary">
                                                            {{ this.user.id !== undefined ? 'Save' : 'Add Employee'}}
                                                        </v-btn>
                                                    </v-col>

                                                    <!-- <div v-show="isLoggingIn" @click="forgotPassword()">Forgot your password?</div> -->
                                                </v-row>

                                            </v-container>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>
                        <!-- End Form -->

                    </div>
                </section>
                <!-- </div> -->
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    //import * as http from "http";
    import mixinShared from '@/mixins/Shared'
    import AlertMessage from "@/components/widgets/AlertMessage"

    import {
        mapGetters,
        mapState
    } from 'vuex'

    export default {
        mixins: [mixinShared],

        props: {
            employeeProp: {
                type: Object,
                default: () =>{
                    return {};
                }
            }
            
        },
        components: {
            AlertMessage
        },
        data() {
            return {
                isLoggingIn: false,
                processing: false,
                // Message info 
                messageDetails: {
                    visible: false,
                    type: "",
                    text: ""
                },
                // Form               
                user: {
                    avatar: "",
                    name: "",
                    email: "",
                    active: true,
                    admin:false,
                    changePassword: false, 
                    password: "",
                    confirm_password: "",
                    image: [],
                    ical_link:''
                },
                
                personalIcalLink:'',
                Token: "",
                // Forgot Pass
                forgotPass: false
            };
        },
        created() {
        },
        mounted() {
            setTimeout(() => {
                this.onStart();
            }, 1000);

            //merge the props and the user object into a single object
            this.user = {
                ...this.user,
                ...this.employeeProp
            };

            if(this.user.id !== undefined){
                this.user.changePassword = false;
            }else{
                this.user.changePassword = true;
            }

            var appURL = localStorage.getItem('appURL');
            this.personalIcalLink = window.btoa( (+this.user.id * 98752486134) + '');
            this.personalIcalLink = appURL+'/ical/'+ this.personalIcalLink +'/export';
            //test = base64_decode('MS4zMTU2OTg3NzQzNTk5RSsxNw==');
            console.log('this.user',this.user);

        },
        computed: {
            ...mapGetters([
                "isLoggedIn",
                "isSellerLoggedIn",
                "getSellerProfileData"
                // "getProfileData",
                // "getSellerProfileData"
            ])
        },
        methods: {
            forgotPassword() {
                this.isLoggingIn = true
                this.forgotPass = !this.forgotPass
            },
            onStart() {},

            changeAPI() {

            },
            toggleForm() {
                this.isLoggingIn = !this.isLoggingIn
                this.forgotPass = false
            },

            submit() {
                this.processing = true;
                this.register();
            },

            onFileChange(e) {
                var files = e.target.files || e.dataTransfer.files;
                console.log("onFileChange", files);

                if (!files.length)
                    return;
                this.user.image = files[0]

                console.log("user.image", files[0]);
            },

            uploadProfilePhoto(){

                const formData = new FormData();
                formData.append('image', this.user.image);
                formData.append('seller_id', this.user.id);

                // console.log("uploadProfilePhoto formData", this.user.image)
                // console.log("uploadProfilePhoto formData", this.user.id)

                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');

                fetch(appURL + '/api/seller/user/profile/employee/avatar', {
                    method: 'POST',
                    headers: {
                        "Authorization": "Bearer " + sellerToken
                    },
                    body: formData
                }).then(response => {

                    console.log(response);
                    if (response.status === 200) {

                        response.json().then(result => {
                            console.log("result", result)
                            this.$store.dispatch("updateEmployee", result);
                            this.user.avatar = result.avatar;

                            // Message Info
                            let info = {
                                visible: true,
                                text: "Success, employee updated!",
                                type:"success"
                            }
                            this.messageBoxCustom(info)  

                            setTimeout( ()=> { 
                                //this.$router.go(-1);

                                this.$emit('done',true);
                               // this.goToAccount()
                            }, 2000);

                        
                        });

                    } else if (response.status === 401) {

                        // Message Info
                        let info = {
                            visible: true,
                            text: "Error connecting to API server 398",
                            type:"error"
                        }
                        this.messageBoxCustom(info) 

                    } else if (response.status === 422) {
                        // Message Info
                        let info = {
                            visible: true,
                            text: "Not Authenticated",
                            type:"error"
                        }
                        this.messageBoxCustom(info)  

                       
                    } else {
                        // Message Info
                        let info = {
                            visible: true,
                            text: "Unexpected error with API server 398",
                            type:"error"
                        }
                        this.messageBoxCustom(info)                         
                    }

                }).catch((error) => {
                    //console.log('[GPSLocation http request error]', error);
                    this.processing = false;
                    // Message Info
                    let info = {
                        visible: true,
                        text: "[GPSLocation http request error]" + error,
                        type:"error"
                    }
                    this.messageBoxCustom(info)                     
                    //alert(error)
                });
            },

            register() {
                if ((this.user.id === undefined) && (this.user.password != this.user.confirm_password)) {

                    this.processing = false;
                    //alert("Your passwords do not match.");
                    let info = {
                        visible: true,
                        text: "Your passwords do not match.",
                        type: "error"
                    }
                    this.messageBoxCustom(info)

                    return;

                } else if (!this.user.name || !this.user.email) {

                    this.processing = false;
                    //alert('all fields are required.');
                    let info = {
                        visible: true,
                        text: "All fields are required.",
                        type: "error"
                    }
                    this.messageBoxCustom(info)

                    return;
                }

                console.log('this.user',this.user);

                var appURL = localStorage.getItem('appURL');

                console.log("register func data register", this.user );

                fetch(appURL + '/api/seller/register', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify({
                        ...this.user,
                        seller_id: this.getSellerProfileData.seller.id
                    })
                }).then(response => {
                    console.log(response);
                    if (response.status === 200) {

                        response.json().then(result => {
                            console.log('result', result);

                            let info = {
                                visible: true,
                                text: "Registration Completed",
                                type: "success"
                            }
                            this.messageBoxCustom(info);
                            if((this.user.id !== undefined)){

                                this.$store.dispatch("updateEmployee", result.success);
                            }else{

                                this.$store.dispatch("addEmployee", result.success);
                            }
                            setTimeout( ()=> { 
                                //this.goBack();
                                this.$emit('done',true);
                                
                               // this.goToAccount()
                            }, 2000);

                            //this.goBack();

                        });

                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 398');
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type: "error"
                        }
                        this.messageBoxCustom(info)
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                    this.processing = false;
                    //alert(error)
                    let info = {
                        visible: true,
                        text: "Something went wrong, try again.",
                        type: "error"
                    }
                    this.messageBoxCustom(info)
                });

            },



        }
    };
</script>

<style scoped>
    .row--dense>.col,
    .row--dense>[class*=col-] {
        padding: 0;
    }

    .page {
        align-items: center;
        flex-direction: column;
    }

    .form {
        margin-left: 30px;
        margin-right: 30px;
        flex-grow: 2px;
        vertical-align: middle;
    }

    .logo {
        margin-bottom: 12px;
        height: 90px;
        font-weight: bold;
    }

    .header {
        /* horizontal-align: center; */
        font-size: 25px;
        font-weight: 600px;
        margin-bottom: 70px;
        text-align: center;
        color: #D51A1A;
    }

    .input-field {
        margin-bottom: 25px;
    }

    .input {
        font-size: 18px;
        placeholder-color: #A8A8A8;
    }

    .input:disabled {
        background-color: white;
        opacity: 0.5px;
    }

    .btn-primary {
        margin: 30px 5px 15px 5px;
    }

    .login-label {
        horizontal-align: center;
        color: #A8A8A8;
        font-size: 16px;
    }

    .sign-up-label {
        margin-bottom: 20px;
    }

    .bold {
        color: #000000;
    }
</style>