//leaflet no markers on the map issue
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});


import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueI18n from 'vue-i18n'
import VueSocialSharing from 'vue-social-sharing'

Vue.config.productionTip = false

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';


import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'a64fecafe614be21c297',
    wsHost: 'localhost',
    wsPort: '6001',
    forceTLS: false,
    disableStats: true
});


Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);


// filters.js
import Filters from './filters';
Vue.use(Filters);

Vue.filter('reverse', function(value) {
    console.log("filter main.js reverse")
        // slice to make a copy of array, then reverse the copy
    return value.slice().reverse();
});

Vue.filter('hideletters', function(value) {
    console.log("filter main.js hideletter")
    let valSliced = value.slice(0, -3) + "****";
    return valSliced.toString();
})

Vue.filter('stringify', function(value) {
    console.log("filter main.js stringify")
    JSON.stringify(JSON.parse(value), null, 2);
})

Vue.use(VueSocialSharing);

//event bus
export const bus = new Vue();

new Vue({
    router,
    store,
    VueI18n,
    vuetify,
    render: h => h(App),
    async beforeCreate() {
        await store.dispatch("init");
    }
}).$mount('#app')