<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <!-- <div actionBarHidden="true"> -->
                    <section class="page page-checkout-confirm">
                        <div class="page--top-container">
                            <v-icon
                            color="primary"
                            class="icon-logo"
                            >mdi-spa</v-icon>
                            <template v-if="checkoutResult">

                                <h1 class="page--title"
                                >Booking Completed</h1>
                                <p class="page--description">
                                    Thank you for your order! To continue click below

                                </p>
                                <div class="page--main">
                                    <div class="btn-container">
                                        <v-btn
                                        link
                                        to="/"
                                        x-large
                                        block
                                        class="btn btn-browse"
                                        color="primary">

                                            Go To Homepage
                                        </v-btn>
                                        <v-btn
                                        link
                                        text
                                        to="/bookings"
                                        x-large
                                        block
                                        outlined
                                        class="btn btn-browse"
                                        color="primary">
                                            My Bookings
                                        </v-btn>
                                    </div>
                                </div>                               
                            </template>
                            <template v-else>
                                <h1 class="page--title"
                                >Someting went wrong</h1>
                                <div class="page--main">
                                    <p class="page--description">
                                        Please try again and make sure that your data is correct
                                    </p>
                                </div>
                            </template>
                        </div>
                    </section>
                <!-- </div> -->
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

    import {
        mapGetters,
        mapState
    } from 'vuex'

    export default {
        props: {
            checkoutResult: {
                type: Boolean,
                default: true
            }
        },
        components:{
        },
        data() {
            return {

            };
        },
        created() {
        },
        mounted() {
        },
        methods: {


        }
    };
</script>

<style lang="scss">
    .page.page-checkout-confirm {
        align-items: center;
        justify-items: center;
        flex-direction: column;
        .page--top-container {
            text-align: center;
            margin: 0rem auto;
            h1.page--tite {
            }
        }
        .page--main {
            .page--form {
                // min-width: 380px;
                max-width: 800px;
                margin: 0 auto;
                .v-text-field.v-text-field--enclosed {
                    .v-input__slot {
                        background: #ebebeb;
                    }
                    .v-text-field__details {
                        margin-bottom: 0;
                    }
                }
            }
            p.forgot {
                cursor: pointer;
                font-weight: bold;
                text-align: right;
            }

            p.page--text-login {
                cursor: pointer;
                strong {
                    
                    margin-left: 1rem;
                    color: var(--v-primary-base)!important;
                }
            }
        }
    }


</style>
