<template>
    <section class="page page--single page--merchant">
        <v-container>
            <v-row dense>
                <v-col
                md="4"
                cols="12"
                v-if="loaded"
                >

                    <div class="page--main main" >
                        <div class="slider-container">
                            <div class="page--cover anim-cover">

                                <v-img class="card-img slider-img--big"
                                    :lazy-src="require('../assets/_bk-a.jpg')"
                                    v-if="!sliderImg"
                                    :src="getImage" />

                                <v-img class="card-img slider-img--big"
                                    :lazy-src="require('../assets/_bk-a.jpg')"
                                    v-if="sliderImg"
                                    :src="sliderImg" />

                            </div>

                            <div class="scrollmenu slider-container--thubnails">
                                <div 
                                    v-for="(image, index) in getImages" 
                                    class="slider--thubnail card-img-thumb-wrapper" 
                                    :key="'itemIimage-'+index"
                                    @click="updateSlider(image)"
                                    @touch="updateSlider(image)">
                                        <v-img 
                                        class="slider-img--thumbnail card-img-thumb" 
                                        :lazy-src="require('../assets/_bk-a.jpg')"
                                        :src="image" />
                                </div>
                            </div>
                        </div>
                        <!-- End Slider Container -->

                        <div class="main-container">
                            <v-row>
                                <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                xl="12"
                                >
                                    <div class="seller-top--container">
                                        <!-- {{ item}} -->
                                        <h1 class="page--title primary--text">{{item.name}}</h1>
                                        <p class="page--description"><strong>{{ item.street }}</strong>, <br> {{ item.city }}</p>
                                        <p>{{ item.description }}</p>
                                        <p class="open--hours">
                                            <v-chip
                                            outlined
                                            :color="(openToday.open) ? 'green' : 'red'"
                                            >
                                                <v-icon
                                                :color="(openToday.open) ? 'green' : 'red'"
                                                left>
                                                    mdi-{{ openToday.open ? 'check' : 'close' }}
                                                </v-icon>
                                                Today {{ openToday.open ? 'Open from ' + openToday.start + ' to ' + openToday.end : 'Closed' }}
                                            </v-chip>
                                        </p>

                                        <div>
                                            <!-- <div v-if="calculateRatings.length"> -->
                                                <div class="text-left">
                                                    <v-rating 
                                                    class="my-2"
                                                    readonly
                                                    size="18"
                                                    color="teal"
                                                    half-increments
                                                    v-model="item.rating"
                                                    ></v-rating>
                                                </div>
                                        </div>

                                        <v-btn 
                                            x-large
                                            block
                                            outlined
                                            class="btn btn-booking"
                                            color="primary"
                                            @click="addToFavourities()"
                                            v-if="isFavourited"
                                            > 
                                                Remove Favorite
                                                <v-icon class="ml-2">
                                                    mdi-heart
                                                </v-icon>
                                        </v-btn> 

                                        <v-btn 
                                            x-large
                                            block
                                            
                                            class="btn btn-booking"
                                            color="primary"
                                            @click="addToFavourities()"
                                            v-if="!isFavourited"
                                            > 
                                                Add to Favorites
                                                <v-icon class="ml-2">
                                                    mdi-heart
                                                </v-icon>
                                        </v-btn> 
                                    </div>

                                </v-col>
                            </v-row>
                        </div>
                    </div>

                    <!-- <SingleView
                    :itemData="item"
                    /> -->
                </v-col>
                <v-col
                md="8"
                cols="12"
                >
                    <div class="page--main main" >
                        <div class="main-container">
                            <v-row>
                                <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                xl="12"
                                >
                                    <div id="services" class="seller-services--container">
                                        <h2 class="page--subtitle primary--text text-center">Services</h2>

                                            <!-- <CategoriesMenu 
                                            :categories="[]" 
                                            :horizontal="true"
                                            :hideIcons="false"
                                            @explore="filterResults"
                                            ></CategoriesMenu> -->

                                            <ServiceList
                                            :itemServices="item.services"
                                            @bookItem="Book"
                                            ></ServiceList>
 
                                    </div>
                                </v-col>


                                <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                xl="12"
                                >
                                    <div class="seller-map--container">
                                        <h3 class="page--subtitle primary--text text-center">Location</h3>
                                        <v-card class="mx-auto" elevation="8" style="overflow:hidden;">

                                            <v-card-text>
                                            {{ item.street }}, <br> {{ item.city }}, {{ item.country }}
                                            </v-card-text>

                                            <v-row id="mapContainerDetectWidthHeight2">
                                                <LocationPicker
                                                v-if="loaded"
                                                :lat="parseFloat(item.lat)"
                                                :lng="parseFloat(item.lng)"
                                                :disableClick="true"
                                                :parentDiv="'mapContainerDetectWidthHeight2'"
                                                ></LocationPicker>
                                            </v-row>
                                        </v-card>
                                    </div>
                                </v-col> 
                                <!-- End Col -->  

                                <v-col
                                cols="12"
                                sm="12"
                                md="12"
                                xl="12"
                                v-if="ratingsloaded"
                                >
                                    <div class="seller-ratings--container">
                                        <h3 class="page--subtitle primary--text text-center">Reviews</h3>

                                            <v-card
                                                class="mx-auto"
                                                v-for="(item, index) in ratings" 
                                                :key="index"
                                            >
                                                <v-card-text>
                                                {{item.comment}}

                                                <div class="text-right">
                                                    <v-rating
                                                    v-model="item.rating"
                                                    color="teal"
                                                    half-increments
                                                    background-color="grey darken-1"
                                                    empty-icon="$ratingFull"
                                                    readonly
                                                    ></v-rating>
                                                </div>
                                                </v-card-text>
                                            </v-card>
                                        
                                    </div>
                                </v-col> 
                                <!-- End Col -->                                                              
                            </v-row>
                        </div>
                        <!-- End Main Container -->
                    </div>

                    <!-- <SingleView
                    :itemData="item"
                    /> -->
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
    let moment = require("moment");

    import { mapGetters } from 'vuex'
    import ServiceList from"../components/products/ServiceList.vue";

    import CategoriesCtm from "../mixins/Categories";
    import CategoriesMenu from '../components/widgets/CategoriesMenu.vue';
    import mixinShared from '@/mixins/Shared'
    import LocationPicker from "@/components/LocationPicker"
    import { bus } from '../main'

    export default {
        mixins: [mixinShared],
        props: ["singleItem","seller_id"],
        components: {
            //CategoriesMenu,
            ServiceList,
            LocationPicker,
            //CategoriesCtm
        },
        data() {
            return {
                options: {
                    enableRatings : false,
                    showRatingsButtonTop : false,
                },
                item: {},
                sliderImg: "",
                tab: 0,
                selectedIndex: 0,
                images: null,
                isLike: false,
                isHeart: false,
                categorySelected: "all",
                loaded:false,
                ratingsloaded:false,
                ratings:[]
            };
        },

        mounted() {
            //this.sliderImg = this.getImage()
            console.log('this.singleItem', this.singleItem)
            console.log('this.getSingleItem', this.getSingleItem);

            //if the id is set and the seller data was not set over
            var appURL = localStorage.getItem('appURL', "https://api.wellbookings.com");
            var token = localStorage.getItem('token');


            if(this.singleItem === undefined && this.seller_id !== undefined){

                fetch(appURL + '/api/seller/'+this.seller_id, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        'Authorization': 'Bearer ' + token,
                    }
                }).then(response => {

                    console.log(response);

                    if (response.status === 200) {
                        response.json().then(result => {

                            this.item = result.success;
                            console.log('step 2 : result.success', result.success);
                            this.loaded=true;
                            //commit('result.success', result.success);

                        });
                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 398');
                    }

                }).catch((error) => {
                    console.log('[GET SELLER http request error]', error);
                });

            }else if(this.singleItem !== undefined  && this.seller_id === undefined) {
                this.item = this.singleItem
                console.log('step 1 : created singleItem store', this.singleItem);
                this.loaded=true;

            }else {
                //get last seen seller from store
                console.log('created singleItem props', this.item);
                this.item = this.getSingleItem
                this.loaded=true;
            }

            //get all reviews
            fetch(appURL + '/api/client/booking/rating/'+this.seller_id, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'Authorization': 'Bearer ' + token,
                }
            }).then(response => {

                console.log(response);

                if (response.status === 200) {
                    response.json().then(result => {

                        this.ratings = result.success;
                        console.log('step 2 : result.success', result.success);
                        this.ratingsloaded=true;
                        //commit('result.success', result.success);

                    });
                } else if (response.status === 401) {
                    //failed logn
                    console.log('Error connecting to API server 398');
                } else {
                    // unkown error
                    console.log('Unexpected error with API server 398');
                }

            }).catch((error) => {
                console.log('[GET SELLER http request error]', error);
            });

        },
        created() {
            this.images = [{
                    src: "~/assets/images/food/pancake640.jpg"
                },
                {
                    src: "~/assets/images/food/pancake640.jpg"
                },
                {
                    src: "~/assets/images/food/pancake640.jpg"
                },
                {
                    src: "~/assets/images/food/pancake640.jpg"
                }
            ];
        },
        computed: {
            ...mapGetters(["getSingleItem","getFavourities"]),

            openToday() {
                // Check if exist otherwise give an error
                if(this.item.working_hours) {
                    // Parse the string
                    let openHours = {};
                    try {
                        openHours = JSON.parse(this.item.working_hours)
                    } catch (e) {
                        openHours = this.item.working_hours
                    }

                    // Today Moment Date
                    let todayDate = moment()
                    // Moment full date
                    let fullDate = moment(todayDate, "YYYY-MM-DD HH:mm:ss")
                    // Get day DDD in moment 
                    let dayStr = fullDate.format('ddd')

                    //let Offdays = ["Wen","Tue","Sat"]
                    // Check the Off days in array, if true is close days
                    //let filterCloseDay = openHours.Offdays.filter(d => d !== day);
                    let filterCloseDay = openHours.Offdays.filter(d => d === dayStr);
                    // Return value
                    let isClosed = (filterCloseDay.length) ? false : true

                    // New Object with open value
                    const openHoursUpd = {
                        ...openHours,
                        open : isClosed
                    }

                    //console.log("openHoursUpd", openHoursUpd)
                    return openHoursUpd

                }

                return []

            },
            calculateRatings() {
                var services = this.item.services.length

                return services
            },
            isFavourited(){
                if(this.getFavourities === undefined || this.getFavourities === {} || this.getFavourities === null){
                    return false;
                }else{
                    return this.getFavourities.find(fav => fav.id === this.item.id) ? true : false;
                }
            }
        },
        methods: {
            filterResults(data) {
                console.log("filterResults", data)
                this.categorySelected = data
            },
            updateSlider(image) {
                console.log("image upd", image)
                this.sliderImg = image
            },
            onLoaded() {
                // this.animateFrom()
            },
            Book(service) {
                let seller =this.item;
                console.log("book seller", seller)
                console.log("book service", service)
                this.$store.dispatch("updateBookingCart", {service, seller})

                bus.$emit('showMakeBookingDialog',{service, seller});

            },
            close() {
                this.$router.go(-1)
            },
            addToFavourities(){
                //this.singleItem
                //this.$store.dispatch('addRemoveFavourite',this.singleItem);
                this.$store.dispatch('addRemoveFavourite',this.item);
            }
        },
    };
</script>
<style lang="scss" scoped>
    .slider-container {
        .page--cover {
            border-radius: 5px;
            overflow: hidden;
        }
        .slider-container--thubnails {
            // display: flex;
            margin: .5rem auto;
            // padding-bottom: .5rem;
            .slider--thubnail {
                display: inline-block;
                width: calc(25% - 5px);
                background-color: #ebebeb;
                vertical-align: center;
                border-radius: 10px;
                margin-right: 5px;

                img {
                    padding: .25rem;
                    overflow: hidden;
                    border-radius: 50px;
                    margin: .25rem;
                }
            }
        }
    }

    .seller-top--container {
        .page--title {
            font-weight: bold;
        }
    }

    .seller-services--container {
        .page--subtitle {
            font-weight: bold;
        }
        .single-service {
            border-bottom: 1px solid rgba(0,0,0,0.1)
        }
    }
    .v-sheet.v-card {
        border-radius: 15px;
    }

</style>