<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <!-- <div actionBarHidden="true"> -->
                    <section class="page page-login">
                        <div class="page--top-container">
                            <v-icon
                            color="primary"
                            class="icon-logo"
                            >mdi-spa</v-icon>

                            <h1 class="page--title"
                            >
                            {{ isLoggingIn ? 'Login' : 'Register' }}
                            </h1>
                            <p class="page--description">
                                {{ isLoggingIn ? 'Please login to Merchant account' : 'Register to our website' }}
                            </p>

                            <AlertMessage 
                                :messageVisible="messageDetails.visible"
                                :messageText="messageDetails.text"
                                :messageType="messageDetails.type"
                            ></AlertMessage>
                        </div>
                        <div class="page--main">
                            <div class="page--form">
                                <v-container>
                                <!-- <v-img class="logo" src="/images/logo.png" /> -->
                                <v-row dense>

                                    <v-col 
                                    cols="12">

                                            <v-text-field
                                            outlined
                                            height="50"
                                            class="input--big-icon"
                                            v-show="!isLoggingIn && !forgotPass"
                                            id="username"
                                            label="Username"
                                            v-model="user.name"
                                            name="registration-username"
                                            prepend-icon="mdi-account-outline"
                                            type="text"
                                            
                                            ></v-text-field>
                                    </v-col>                                     
                                    <v-col 
                                    cols="12">
                                        <v-text-field
                                        outlined
                                        height="50"
                                        class="input--big-icon"     
                                        v-show="!isLoggingIn || isLoggingIn || forgotPass"
                                        id="email"
                                        label="Email"
                                        name="login-reg-email"
                                        v-model="user.email"
                                        prepend-icon="mdi-email-outline"
                                        type="text"
                                        
                                        ></v-text-field>
                                    </v-col>
                                    <v-col 
                                    cols="12">

                                        <v-text-field
                                            outlined
                                            height="50"
                                            class="input--big-icon"
                                            v-show="!isLoggingIn || isLoggingIn && !forgotPass"                                         
                                            id="password"
                                            label="Password"
                                            v-model="user.password"
                                            name="login-password"
                                            prepend-icon="mdi-lock"
                                            type="password"
                                            
                                        ></v-text-field>
                                        <p 
                                        v-show="isLoggingIn" 
                                        class="forgot"
                                        @click="forgotPassword()"
                                        >{{ !forgotPass ? 'Forgot your password? ' : 'Back to Login'}}</p>
                                    </v-col>
                                    <v-col 
                                    cols="12">

                                        <v-text-field
                                            outlined
                                            height="50"
                                            class="input--big-icon"
                                            v-show="!isLoggingIn && !forgotPass"
                                            id="confirm_password"
                                            label="Repeat Password"
                                            v-model="user.confirm_password"
                                            name="login-conf-password"
                                            prepend-icon="mdi-lock"
                                            type="password"
                                            
                                        ></v-text-field>
                                    </v-col>  
                                    <!-- End Col -->

                                        <v-col 
                                        cols="12">

                                            <v-text-field
                                                outlined
                                                ref="company_name"
                                                height="50"
                                                class="input--big-icon"
                                                v-show="!isLoggingIn && !forgotPass"
                                                id="company_name"
                                                label="Company Name"
                                                v-model="user.company_name"
                                                name="login-company-name"
                                                prepend-icon="mdi-domain"
                                                type="text"
                                                
                                            ></v-text-field>
                                        </v-col>  
                                        <!-- End Col -->         

                                        <v-col 
                                        cols="12">

                                            <v-text-field
                                                outlined
                                                height="50"
                                                class="input--big-icon"
                                                v-show="!isLoggingIn && !forgotPass"
                                                id="company_email"
                                                label="Company Email"
                                                v-model="user.company_email"
                                                name="company-email"
                                                prepend-icon="mdi-email-outline"
                                                type="text"
                                                
                                            ></v-text-field>
                                        </v-col>  
                                        <!-- End Col -->                                                                               

                                        <!-- <div row="6" v-show="!isLoggingIn" class="input-field">
                                            <v-text-field class="input" ref="company_email" :isEnabled="!processing"
                                                label="Companyemail" v-model="user.company_email"
                                                ></v-text-field>
                                            <div class="hr-light"></div>
                                        </div> -->

                                        <v-col 
                                        cols="12">

                                            <v-text-field
                                                outlined
                                                height="50"
                                                class="input--big-icon"
                                                v-show="!isLoggingIn && !forgotPass"
                                                id="company_phone"
                                                label="Company phone"
                                                v-model="user.company_phone"
                                                name="company-phone"
                                                prepend-icon="mdi-phone-outline"
                                                type="number"
                                                ref="company_phone"
                                            ></v-text-field>
                                        </v-col>  
                                        <!-- End Col -->

                                        <v-col 
                                        cols="12">

                                            <v-text-field
                                                outlined
                                                height="50"
                                                class="input--big-icon"
                                                v-show="!isLoggingIn && !forgotPass"
                                                id="company_email"
                                                label="Company Country"
                                                v-model="user.company_country"
                                                name="company-country"
                                                prepend-icon="mdi-domain"
                                                type="text"
                                                ref="company_country"
                                            ></v-text-field>
                                        </v-col>  
                                        <!-- End Col -->

                                        <v-col 
                                        cols="12">

                                            <v-text-field
                                                outlined
                                                height="50"
                                                class="input--big-icon"
                                                v-show="!isLoggingIn && !forgotPass"
                                                id="company_city"
                                                label="Company City"
                                                v-model="user.company_city"
                                                name="company-city"
                                                prepend-icon="mdi-domain"
                                                type="text"
                                                ref="company_city"
                                            ></v-text-field>
                                        </v-col>  
                                        <!-- End Col -->   

                                        <v-col 
                                        cols="12">

                                            <v-text-field
                                                outlined
                                                height="50"
                                                class="input--big-icon"
                                                v-show="!isLoggingIn && !forgotPass"
                                                id="company_street"
                                                label="Company Street"
                                                v-model="user.company_street"
                                                name="company-street"
                                                prepend-icon="mdi-domain"
                                                type="text"
                                                ref="company_street"
                                            ></v-text-field>
                                        </v-col>  
                                        <!-- End Col -->   

                                        <v-col 
                                        cols="12">

                                            <v-text-field
                                                outlined
                                                height="50"
                                                class="input--big-icon"
                                                v-show="!isLoggingIn && !forgotPass"
                                                id="company_zip_code"
                                                label="Company Zip Code"
                                                v-model="user.company_zip_code"
                                                name="company_zip_code"
                                                prepend-icon="mdi-domain"
                                                type="text"
                                                ref="company_zip_code"
                                            ></v-text-field>
                                        </v-col>  
                                        <!-- End Col -->
                                        <v-col 
                                        cols="12">
                                            <v-btn 
                                            @click="submit" 
                                            class="btn btn-primary btn-login btn-register"
                                            :outlined="!isLoggingIn"
                                            x-large
                                            block
                                            color="primary"
                                            >
                                                {{ isLoggingIn ? 'Submit' : 'Sign Up'}}
                                            </v-btn>
                                        </v-col>

                                        <!-- <div v-show="isLoggingIn" @click="forgotPassword()">Forgot your password?</div> -->
                                    </v-row>
                                </v-container>
                            </div>
                            <!-- End Form -->

                            <p class="page--text-login page--text mt-5" @click="toggleForm">
                                {{isLoggingIn ? 'Don’t have a merchant account? ' : 'Back to Login'}}
                                <strong>{{ isLoggingIn ? 'Sign up' : '' }}</strong>
                            </p>

                            <p class="page--text-login page--text mt-5" @click="goToLogin(true )">
                                Are you a user ?<strong>Go to user login page</strong>
                            </p> 

                            <!-- <p class="page--text-login page--text ma-0" @click="changeAPI">
                                    <span>Change the default API server </span>
                                    <span class="bold">Change</span>
                            </p> -->
                        </div>                            
                    </section>
                <!-- </div> -->
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    //import * as http from "http";
    import mixinShared from '@/mixins/Shared'
    import AlertMessage from "@/components/widgets/AlertMessage"

    import {
        mapGetters,
        mapState
    } from 'vuex'

    export default {
        mixins: [mixinShared],
        props: {
            isLoggingIn: {
                type: Boolean,
                default: true
            }
        },
        components:{
            AlertMessage
        },
        data() {
            return {
                processing: false,
                // Message info 
                messageDetails: {
                    visible: false,
                    type: "",
                    text: ""
                },
                // Form                
                user: {
                    name: "",
                    email: "",
                    password: "",
                    confirm_password: "",
                    company_name: "",
                    company_email: "",
                    company_phone: "",
                    company_country: "",
                    company_city: "",
                    company_street: "",
                    company_zip_code: ""                   
                },
                Token:"",
                // Forgot Pass
                forgotPass: false                
            };
        },
        created() {
            console.log('this is a test app : created');
        },
        mounted() {

            console.log('this is a test app : mounted');
            setTimeout(() => {
                this.onStart();
            }, 1000);

        },
        computed: {
            ...mapGetters([
                "isLoggedIn", 
                "isSellerLoggedIn", 
                // "getProfileData",
                // "getSellerProfileData"
            ])
        },           
        methods: {
            forgotPassword() {
                this.isLoggingIn = true
                this.forgotPass = !this.forgotPass
            },            
            onStart(){
            },

            changeAPI(){

            },
            toggleForm() {
                this.isLoggingIn = !this.isLoggingIn
                this.forgotPass = false
            },

            submit() {

                // if (!this.user.email || !this.user.password) {
                //     alert(
                //         "Please provide both an email address and password."
                //     );
                //     return;
                // }

                // this.processing = true;
                // if (this.isLoggingIn) {
                //     this.login();
                // } else {
                //     this.register();
                // }
                this.processing = true;
                if (this.isLoggingIn && !this.forgotPass) {
                    if (!this.user.email || !this.user.password) {
                        let info = {
                            visible: true,
                            text: "Please provide both an email address and password.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)
                        return;
                    }              
                         
                    //console.log('Login')
                    this.login();
                }
                else if( this.isLoggingIn && this.forgotPass ) {
                    if (!this.user.email ) {
                        let info = {
                            visible: true,
                            text: "Please provide an email to reset.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)
                        return;
                    }

                    console.log('Forgot')
                    this.recoverPassword()
                    // } else {
                    //     this.login();
                    // }
                    //this.login();
                } else {
                    this.register();
                }
            },
            goToGettingStarted(){
                this.$router.push({
                    name: 'seller-getting-started'
                });
            },

            login() {
                var appURL = localStorage.getItem('appURL');

                fetch(appURL+'/api/seller/login', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify({
                        email: this.user.email,
                        password: this.user.password
                    })
                }).then(response => {


                    console.log(response);

                    if (response.status === 200) {

                    response.json().then(result => {
                            console.log('result', result);

                            //localStorage.setItem('sellerToken',result.success.token);
                            this.$store.dispatch('setSellerProfile',result.success.user);
                            this.$store.dispatch('setSellerToken',result.success.token);
                            // Error Message Info
                            let info = {
                                visible: true,
                                text: "Welcome, you are logged in!",
                                type:"success"
                            }
                            this.messageBoxCustom(info) 

                            console.log('navigating.....token', result.success.token)

                            console.log('navigating.....token no', result.success.token)
                            //this.$router.go(-1)
                            // this.$router.push({
                            //     name: 'seller-dashboard',
                            //     // params: {
                            //     //     isLoggingIn: loginRegister
                            //     // }
                            // });
                            setTimeout( ()=> { 
                                //this.$router.go(-1)
                                if(result.success.user.active === '1' || result.success.user.seller.services.length > 0){
                                    this.goToMerchantDashboard();
                                }else{

                                    this.goToGettingStarted();
                                }



                            }, 2000);

                    });
                    
                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                        // Error Message Info
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)                          
                    } else if (response.status === 422) {
                        //failed logn
                        //alert('Not Authenticated');
                        // Error Message Info
                        let info = {
                            visible: true,
                            text: "Not Authenticated, invalid login data",
                            type:"error"
                        }
                        this.messageBoxCustom(info)  
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 398');
                        // Error Message Info
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)                          
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                        this.processing = false;
                        //alert(error)
                        // Error Message Info
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)    
                });


            },

            register() {
                if (this.user.password != this.user.confirm_password) {

                    this.processing = false;
                    //alert("Your passwords do not match.");
                    let info = {
                        visible: true,
                        text: "Your passwords do not match.",
                        type:"error"
                    }
                    this.messageBoxCustom(info)

                    return;

                } else if(!this.user.name || !this.user.email){

                    this.processing = false;
                    //alert('all fields are required.');
                    let info = {
                        visible: true,
                        text: "All fields are required.",
                        type:"error"
                    }
                    this.messageBoxCustom(info)

                    return;
                }

                var appURL = localStorage.getItem('appURL');

                //console.log("register func data register", this.user );

                fetch(appURL+'/api/seller/registerwithcompany', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify({
                        name: this.user.name,
                        username: this.user.username,
                        email: this.user.email,
                        password: this.user.password,
                        confirm_password: this.user.confirm_password,


                        company_name: this.user.company_name,
                        company_email: this.user.company_email,
                        company_phone: this.user.company_phone,
                        company_country: this.user.company_country,
                        company_city: this.user.company_city,
                        company_street: this.user.company_street,
                        company_zip_code: this.user.company_zip_code
                    })
                }).then(response => {


                    console.log(response);

                    if (response.status === 200) {

                    response.json().then(result => {
                        console.log('result', result);

                        let info = {
                            visible: true,
                            text: "Registration Completed",
                            type:"success"
                        }
                        this.messageBoxCustom(info)
                        this.login();

                    });
                    
                    } else if (response.status === 401) {
                    //failed logn
                        console.log('Error connecting to API server 398');
                        // Error Message Info
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)                    
                    } else {
                    // unkown error
                        console.log('Unexpected error with API server 398');
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                        this.processing = false;
                        //alert(error)
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)
                });

            },

            recoverPassword() {
                if(!this.user.email){

                    this.processing = false;
                    //alert('all fields are required.');
                    let info = {
                        visible: true,
                        text: "All fields are required.",
                        type:"error"
                    }
                    this.messageBoxCustom(info)

                    return;
                }

                // var appURL = localStorage.setItem('appURL',"https://api.wellbookings.com");
                var appURL = localStorage.getItem('appURL');

                fetch(appURL + '/api/reset-password', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    },
                    body: JSON.stringify({
                        email: this.user.email,
                    })
                }).then(response => {

                    console.log(response);

                    if (response.status === 200) {

                    response.json().then(result => {
                        console.log('result', result);

                        let info = {
                            visible: true,
                            text: "Password Reset Confirm",
                            type:"success"
                        }
                        this.messageBoxCustom(info)  

                        this.login();

                    });
                    
                    } else if (response.status === 401) {
                    //failed logn
                        console.log('Error connecting to API server 398');
                        // Error Message Info
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)                    
                    } else {
                    // unkown error
                        console.log('Unexpected error with API server 398');
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                        this.processing = false;
                        //alert(error)
                        let info = {
                            visible: true,
                            text: "Something went wrong, try again.",
                            type:"error"
                        }
                        this.messageBoxCustom(info)
                });

            },

            focusPassword() {
                // this.$refs.password.nativeView.focus();
            },
            focusConfirmPassword() {
                if (!this.isLoggingIn) {
                    // this.$refs.confirm_password.nativeView.focus();
                }
            },
            goHome(){
                console.log('navigating.....')
                // this.$navigateTo(Home, { clearHistory: true });
            }


        }
    };
</script>

<style scoped>
.row--dense > .col, .row--dense > [class*=col-] {
    padding: 0;
}

    .page {
        align-items: center;
        flex-direction: column;
    }

    .form {
        margin-left: 30px;
        margin-right: 30px;
        flex-grow: 2px;
        vertical-align: middle;
    }

    .logo {
        margin-bottom: 12px;
        height: 90px;
        font-weight: bold;
    }

    .header {
        /* horizontal-align: center; */
        font-size: 25px;
        font-weight: 600px;
        margin-bottom: 70px;
        text-align: center;
        color: #D51A1A;
    }

    .input-field {
        margin-bottom: 25px;
    }

    .input {
        font-size: 18px;
        placeholder-color: #A8A8A8;
    }

    .input:disabled {
        background-color: white;
        opacity: 0.5px;
    }

    .btn-primary {
        margin: 30px 5px 15px 5px;
    }

    .login-label {
        horizontal-align: center;
        color: #A8A8A8;
        font-size: 16px;
    }

    .sign-up-label {
        margin-bottom: 20px;
    }

    .bold {
        color: #000000;
    }
</style>
