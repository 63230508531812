<template>
    <!-- <div>
        <v-container>
            <v-row>
                <v-col cols="12"> -->
                    <section class="page page-account">
                        <div class="page--main">
                            <div class="page--form">
                                <!-- <v-card style="margin-bottom:30px;" v-show="service.id !== undefined" >
                                    <v-container>

                                        <v-row dense v-show="service.id !== undefined" >

                                            <v-col cols="12">
                                                <h2>Set Image</h2>

                                                <v-file-input v-model="service.serviceImage"  accept="image/*" label="File input"
                                                    prepend-icon="mdi-camera" @change="onFileChange" show-size>
                                                </v-file-input>                                            
                                                
                                                <v-btn @click="uploadServiceImage()" class="btn btn-primary btn-login btn-register"
                                                    x-large block color="primary">
                                                    Upload Service photo
                                                </v-btn>

                                            </v-col>

                                        </v-row>
                                    </v-container>
                                </v-card> -->
                                <v-card>
                                    <v-container>
                                        <v-row dense>
                                            <v-col 
                                            cols="12">
                                                <v-text-field
                                                outlined
                                                height="50"
                                                class="input--big-icon"
                                                id="service_name"
                                                label="Service Name"
                                                v-model="service.service_name"
                                                name="service_name"
                                                prepend-icon="mdi-text"
                                                type="text"
                                                
                                                ></v-text-field>
                                            </v-col>   
                                            <!-- Enc Col -->
                                            <v-col 
                                            cols="12">
                                                <!-- <v-text-field
                                                outlined
                                                height="50"
                                                class="input--big-icon"
                                                id="email"
                                                label="Email"
                                                v-model="service.service_name"
                                                name="account-email"
                                                prepend-icon="mdi-email-outline"
                                                type="text"
                                                
                                                ></v-text-field> -->
                                                <!-- <v-select 
                                                :items="categories" 
                                                v-model="selectCat" 
                                                item-text="name" 
                                                label="Category" 
                                                prepend-icon="mdi-spa"
                                                outlined
                                                height="50"
                                                class="input--big-icon"
                                                return-object 
                                                @change="selectedCategory"
                                                ></v-select> -->
                                            </v-col>   
                                            <!-- Enc Col -->
                                            <v-col 
                                            cols="12">
                                                <v-text-field
                                                v-model="service.time_interval" 
                                                type="number"
                                                item-text="Minutes for service" 
                                                label="Timeframe"
                                                hint="How long does it take to perform this service in minutes" 
                                                prepend-icon="mdi-clock-outline"
                                                outlined
                                                height="50"
                                                class="input--big-icon"
                                                ></v-text-field>
                                            </v-col>   
                                            <!-- Enc Col -->                          
                                            <v-col 
                                            cols="12">

                                            </v-col>   
                                            <!-- Enc Col -->     
                                                                
                                            <!-- <v-col 
                                            cols="12">
                                                <v-text-field
                                                outlined
                                                height="50"
                                                class="input--big-icon"
                                                id="Icon"
                                                label="Icon"
                                                v-model="service.service_icon"
                                                name="service-icon"
                                                prepend-icon="mdi-domain"
                                                type="text"
                                                
                                                ></v-text-field>
                                            </v-col>    -->
                                            <!-- Enc Col -->
                                            <v-col 
                                            cols="6">
                                                    <v-text-field
                                                    outlined
                                                    height="50"
                                                    class="input--big-icon"
                                                    id="service_price"
                                                    label="price"
                                                    v-model="service.price"
                                                    name="service-price"
                                                    prepend-icon="mdi-cash"
                                                    type="text"
                                                
                                                ></v-text-field>
                                            </v-col>   
                                            <!-- Enc Col -->                          
                                            <v-col 
                                            cols="6">
                                                    <v-select 
                                                    :items="currencies" 
                                                    v-model="selectCur" 
                                                    item-text="name"
                                                    label="Currency" 
                                                    prepend-icon="mdi-currency-usd"
                                                    outlined
                                                    height="50"
                                                    class="input--big-icon" 
                                                    return-object 
                                                    @change="selectedCurrency"
                                                ></v-select>

                                            </v-col>   
                                            <v-col 
                                            cols="6">
                                                    <v-checkbox
                                                    outlined
                                                    height="50"
                                                    class="input--big-icon"
                                                    id="service_allow_come_to_me_only"
                                                    label="Allow Come to me"
                                                    hint="allow customer to request the service at a specific location" 
                                                    v-model="service.allow_come_to_me"
                                                    name="service-come-to-me-only"
                                                    prepend-icon="mdi-moped"
                                                    type="text"
                                                    ></v-checkbox>
                                            </v-col> 
                                            <v-col 
                                            cols="6">
                                                    <v-checkbox
                                                    outlined
                                                    height="50"
                                                    class="input--big-icon"
                                                    id="service_come_to_me_only"
                                                    label="Come to me Only"
                                                    hint="Make this service only available for come to me" 
                                                    v-model="service.come_to_me_only"
                                                    name="service-come-to-me-only"
                                                    prepend-icon="mdi-moped"
                                                    type="text"
                                                    ></v-checkbox>
                                            </v-col>   
                                            <!-- Enc Col -->                          
                                            <v-col 
                                            cols="6">
                                                    <v-text-field
                                                    outlined
                                                    height="50"
                                                    class="input--big-icon"
                                                    id="service_come_to_me_additional_fee"
                                                    label="Additional fee for come to me"
                                                    hint="travel cost in the same currency as the service" 
                                                    v-model="service.come_to_me_additional_fee"
                                                    name="service-additional-fee"
                                                    prepend-icon="mdi-moped"
                                                    type="text"
                                                
                                                ></v-text-field>

                                            </v-col>                         
                                            <v-col 
                                            cols="6">
                                                    <v-text-field
                                                    outlined
                                                    height="50"
                                                    class="input--big-icon"
                                                    id="service_come_to_me_additional_travel_time"
                                                    label="additional travel time for come to me"
                                                    hint="How long does it take to to travel to the location in minutes" 
                                                    v-model="service.come_to_me_additional_travel_time"
                                                    name="service-additional-travel-time"
                                                    prepend-icon="mdi-moped"
                                                    type="text"
                                                
                                                ></v-text-field>

                                            </v-col>  
                                            <!-- Enc Col -->   
                                            <v-col 
                                            cols="12">
                                                <v-textarea
                                                outlined
                                                height="150"
                                                class="input--big-icon"
                                                id="service_decription"
                                                label="Service Description"
                                                v-model="service.service_decription"
                                                name="account-zip_code"
                                                prepend-icon="mdi-text"
                                                
                                                ></v-textarea>
                                            </v-col>   
                                            <!-- Enc Col -->  

                                            <v-col 
                                            cols="12">
                                                Select the employees who can do this service, Leave Empty if all employees can perform the service.
                                                <v-select
                                                    v-model="service.selected_employees"
                                                    :items="AvailableEmployees"
                                                    chips
                                                    label="Employees"
                                                    item-text="name"
                                                    return-object
                                                    multiple
                                                    outlined
                                                ></v-select>
                                            </v-col>   
                                            <!-- Enc Col -->   

                                            <v-col 
                                            cols="12">

                                                <div class="btn-container">
                                                    <v-btn 
                                                    @click="AddService" 
                                                    class="btn btn-primary btn-submit"
                                                    x-large
                                                    block
                                                    color="primary"
                                                    >
                                                    {{ (!service.id) ? 'Add Service' : 'Save changes' }}
                                                    </v-btn>

                                                    <v-btn 
                                                    v-if="service.id" 
                                                    @click="RemoveService(service.id)" 
                                                    class="btn btn-primary btn-submit"
                                                    x-large
                                                    block
                                                    outlined
                                                    color="primary"
                                                    >
                                                    Remove Service
                                                    </v-btn>

                                                    <!-- <v-btn 
                                                    v-if="!service.id" 
                                                    @click="goBack" 
                                                    class="btn btn-primary btn-back"
                                                    x-large
                                                    block
                                                    outlined
                                                    color="primary"
                                                    >
                                                    Back
                                                    </v-btn> -->
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card>
                            </div>
                        </div>
                    </section>
                <!-- </v-col>
            </v-row>
        </v-container> -->
        <!-- <div>

            <v-select :items="categories" v-model="selectCat" item-text="name" label="Outlined style" outlined 
    return-object @change="selectedCategory"></v-select>

            <v-text-field class="v-text-field" v-model="service.service_name" hint="Name" />
            <v-text-field class="v-text-field" v-model="service.service_icon" hint="Icon" />
            <v-text-field class="v-text-field" v-model="service.service_decription" hint="description" />
            <v-text-field class="v-text-field" v-model="service.interval" hint="Interval" />
           <v-text-field class="v-text-field" v-model="service.currency" hint="Currency" /> 


            <v-select :items="currencies" v-model="selectCur" item-text="name" label="Outlined style" outlined 
    return-object @change="selectedCurrency"></v-select>
            <v-text-field class="v-text-field" v-model="service.price" hint="Price" />

            <v-btn @click="AddService" class="btn btn-primary">Submit
            </v-btn>
            <v-btn v-if="service.id" @click="RemoveService(service.id)" class="btn btn-primary">Cancel Service
            </v-btn>

        </div> -->
    <!-- </div> -->
</template>

<script>
    import {
        mapGetters,
        mapState
    } from 'vuex'
    // /import SellerDashboard from "./SellerDashboard";
    import categories from "../../mixins/Categories";
    import currencies from "../../mixins/Currencies";

    import mixinShared from '@/mixins/Shared'
    //import moment from 'moment'
    let moment = require("moment");

    export default {
        mixins: [mixinShared],
        props: {
            item: {
                type: Object,
                // default () {
                //     return {};
                // }
            }
        },
        data() {
            return {
                service: {
                    service_name: "",
                    service_icon: "",
                    service_decription: "",
                    //category: "",
                    time_interval: "30",
                    currency: "",
                    price: "",
                    image:'',
                    allow_come_to_me:false,
                    come_to_me_only:false,
                    come_to_me_additional_fee:'',
                    come_to_me_additional_travel_time:'',
                    serviceImage:"",
                    selected_employees:[]
                },
                processing: false,
                categories: categories,
                currencies: currencies,
                selectCat:{},
                selectCur:{},
                AvailableEmployees:[],
            };
        },
        mounted() {
            this.arrayTime()
            
            this.service = {
                ...this.item
            };
            let tempCat = this.categories.find((e)=> e.name === this.item.service_name)
            let tempCur = this.currencies.find((e)=> e.name === this.item.currency)
            if(tempCat){

            this.selectedCategory(tempCat);
            this.selectedCurrency(tempCur);
            this.selectCat = tempCat;
            this.selectCur = tempCur;
            }else{

            this.selectedCategory(categories[0]);
            this.selectedCurrency(currencies[0]);
            this.selectCat = categories[0];
            this.selectCur = currencies[0];
            }

            this.AvailableEmployees = this.getSellerProfileData.seller.users;
            console.log('get seller', this.getSellerProfileData.seller);

        },
        computed: {
            ...mapGetters([
                "getCurrentLocation", 
                "isLoggedIn", 
                "isSellerLoggedIn", 
                //"getProfileData",
                "getSellerProfileData",
                "getSellerToken"
            ]),
            getServices() {
                console.log('getServices', this.getSellerProfileData.seller.services);
                return this.getSellerProfileData.seller.services
            }
        },
        methods: {
            // Create Array of 24 time with 30 min range
            // Create Array of 24 time with 30 min range
            uploadServiceImage(){

                const formData = new FormData()
                formData.append('image', this.service.image)
                formData.append('seller_id', this.service.id)

                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');

                fetch(appURL + '/api/seller/service/image', {
                    method: 'POST',
                    headers: {
                        "Authorization": "Bearer " + sellerToken
                    },
                    body: formData
                }).then(response => {

                    console.log(response);


                    if (response.status === 200) {

                        response.json().then(result => {
                            this.$store.dispatch("updateServiceImage", result.success);

                            this.service.image = result.success.image;
                        });

                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                    } else if (response.status === 422) {
                        //failed logn
                        alert('Not Authenticated');
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 398');
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                    this.processing = false;
                    alert(error)
                });
            },

            arrayTime() {
                const items = [];
                new Array(24).fill().forEach((acc, index) => {
                    items.push(moment({
                        hour: index
                    }).format('HH:mm'));
                    items.push(moment({
                        hour: index,
                        minute: 30
                    }).format('HH:mm'));
                })

                this.timeSelect = items;
            },
            
            AddService() {
                var appURL = localStorage.getItem('appURL');
                //var sellerToken = localStorage.getItem('sellerToken');
                let sellerToken

                if(this.getSellerToken) {
                    sellerToken = this.getSellerToken
                } else {
                    sellerToken = localStorage.getItem('sellerToken')
                    //sellerToken = sellerToken.replace(/['"]+/g, '')
                }

                console.log("sellerToken", sellerToken)
                this.processing = true;

                let serviceItem = this.service

                console.log("serviceItem", serviceItem)

                if (this.isSellerLoggedIn) {
                    if (this.service.id) {
                        //update existing

                        fetch(appURL + '/api/seller/service/' + this.service.id +
                            '/update', {
                                method: 'POST',
                                headers: {
                                    "Content-Type": "application/json",
                                    "Accept": "application/json",
                                    "Authorization": "Bearer " + sellerToken
                                },
                                body: JSON.stringify({
                                    ...this.service
                                })
                            }).then(response => {


                            console.log(response);

                            if (response.status === 200) {

                                response.json().then(result => {
                                    console.log('result', result);

                                    this.$store.dispatch('updateService', result.success);

                                    console.log('navigating.....')
                                    this.$emit('done',true);
                                    //this.$router.go(-1)

                                });

                            } else if (response.status === 401) {
                                //failed logn
                                console.log('Error connecting to API server 398');
                            } else if (response.status === 422) {
                                //failed logn
                        alert('Not Login');
                            } else {
                                // unkown error
                                console.log('Unexpected error with API server 398');
                            }

                        }).catch((error) => {
                            console.log('[GPSLocation http request error]', error);
                            this.processing = false;
                            alert(error)
                        });


                    } else {
                        //new


                        fetch(appURL + '/api/seller/service/add', {
                            method: 'POST',
                            headers: {
                                "Content-Type": "application/json",
                                "Accept": "application/json",
                                "Authorization": "Bearer " + sellerToken
                            },
                            body: JSON.stringify({
                                ...this.service
                                //serviceItem
                            })
                        }).then(response => {


                            console.log(response);

                            if (response.status === 200) {

                                response.json().then(result => {
                                    console.log('result', result);

                                    this.$store.dispatch('updateService', result.success);

                                    console.log('navigating.....')
                                    //this.$router.go(-1)
                                    this.$emit('done',true);

                                });

                            } else if (response.status === 401) {
                                //failed logn
                                console.log('Error connecting to API server 398');
                            } else if (response.status === 422) {
                                //failed logn
                        alert('Not Authenticated');
                            } else {
                                // unkown error
                                console.log('Unexpected error with API server 398');
                            }

                        }).catch((error) => {
                            console.log('[GPSLocation http request error]', error);
                            this.processing = false;
                            alert(error)
                        });


                    }

                }
            },
            RemoveService(serviceID) {
                var appURL = localStorage.getItem('appURL');
                var sellerToken = localStorage.getItem('sellerToken');
                
                fetch(appURL + '/api/seller/service/' + serviceID +
                    '/delete', {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + sellerToken
                        }
                    }).then(response => {


                    console.log(response);

                    if (response.status === 200) {

                        response.json().then(result => {
                            console.log('result', result);

                            this.$store.dispatch('removeService', result.success);

                            console.log('navigating.....')
                            //this.$router.go(-1)
                            this.$emit('done',true);

                        });

                    } else if (response.status === 401) {
                        //failed logn
                        console.log('Error connecting to API server 398');
                    } else if (response.status === 422) {
                        //failed logn
                        alert('Not Authenticated');
                    } else {
                        // unkown error
                        console.log('Unexpected error with API server 398');
                    }

                }).catch((error) => {
                    console.log('[GPSLocation http request error]', error);
                    this.processing = false;
                    alert(error)
                });

            },
            selectedCategory(event) {
                console.log('event', event);
                let cattemp = event;
                //this.service.category = cattemp.name;
                this.service.service_icon = cattemp.icon;
                this.key++;
            },
            selectedCurrency(event) {
                console.log('event', event);
                let cattemp = event;
                this.service.currency = cattemp.name;
            },
        },
        watch: {}
    }
</script>

<style scoped>
.v-input--selection-controls__input .v-icon {
    color: #009688!important;
}
</style>