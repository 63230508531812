<template>
    <!-- <v-container 
    fluid
    >
        <v-row dense>
            <v-col cols="12"> -->
    <section class="page page-homepage">
        <div class="search-container home-search home-search-col">
            <div class="search-inputs search-box-container ">
                <v-container>
                    <v-row dense>
                        <v-col 
                            cols="12"
                            md="6"
                            lg="6"
                            >
                            <h1 class="page-title white--text">Search on {{ this.site_name }}</h1>
                            <div class="search-box">
                                <v-row
                                 class="pa-0 pb-0"
                                >
                                    <v-col 
                                        class="pb-0"
                                        cols="12"
                                    >
                                        <v-text-field 
                                            class="pb-0 rt-5" 
                                            filled 
                                            label="search for salon name or service" 
                                            name="search-text" 
                                            v-model="search"
                                            prepend-inner-icon="mdi-magnify" 
                                            type="text" 
                                            hide-details
                                            required 
                                            clearable>
                                        </v-text-field>
                                    </v-col>

                                    <!-- <v-col
                                        cols="2"
                                        sm="2"
                                        md="1"
                                        lg="1"
                                    >
                                        <v-btn 
                                            icon 
                                            color="primary" 
                                            class="btn btn-primary" 
                                            block
                                            tile 
                                            fab
                                            @click="goToLocationPicker()">
                                            <v-icon>
                                                mdi-map-marker-radius-outline
                                            </v-icon>
                                        </v-btn>
                                    </v-col> -->
                                </v-row>
                                <v-row 
                                class="pa-0 mt-0"
                                >
                                    <v-col 
                                    cols="12"
                                    class="pt-0 mt-0"
                                    >
                                        <div class="btn-search-container"> 
                                            <v-row
                                            no-gutters
                                            v-if="!getCurrentLocation.useGeo"
                                            >
                                            <v-col cols="6">
                                                <v-text-field
                                                    label="Country"
                                                    :value="getCurrentLocation.country.label"
                                                    @click="changeLocation()" 
                                                    prepend-inner-icon="mdi-earth" 
                                                    filled
                                                    hide-details
                                                    readonly
                                                ></v-text-field>
                                                </v-col>
                                                <v-col cols="6">
                                                <v-text-field
                                                    label="City"
                                                    :value="getCurrentLocation.city.label"
                                                    @click="changeLocation()" 
                                                    prepend-inner-icon="mdi-city" 
                                                    filled
                                                    hide-details
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            </v-row>
                                            <v-row
                                            no-gutters
                                            v-else
                                            >
                                            <v-col cols="12">
                                                <v-text-field
                                                    label="GEO"
                                                    value="Current Location"
                                                    @click="changeLocation()" 
                                                    filled
                                                    hide-details
                                                    readonly
                                                ></v-text-field>
                                            </v-col>
                                            </v-row>

                                            
                                            <v-btn 
                                                @click="explore(search)" 
                                                class="btn btn-primary btn-submit br-20" 
                                                x-large 
                                                block
                                                color="primary">
                                                Search
                                                <v-icon right class="md-5">
                                                    mdi-magnify
                                                </v-icon>
                                            </v-btn>

                                        </div>
                                    </v-col>
                                    <!-- <v-col cols="2">
                                        <v-btn 
                                            icon 
                                            color="primary" 
                                            class="btn btn-primary" 
                                            style="width: 10px;" 
                                            tile 
                                            fab
                                            @click="goToLocationPicker()">
                                            <v-icon>
                                                mdi-map-marker-radius-outline
                                            </v-icon>
                                        </v-btn>
                                    </v-col> -->
                                    <!-- <v-col cols="12">

                                        <v-btn 
                                        @click="advanced = !advanced" 
                                        text 
                                        small 
                                        block 
                                        color="primary"
                                        class="btn-avanced">
                                            {{advanced?'Simple':'Advanced Search'}}
                                        </v-btn>

                                    </v-col> -->

                                </v-row>


                            </div>
                        </v-col>
                        <v-col 
                            cols="0"
                            md="6"
                            lg="6"
                            >
                        </v-col>

                    </v-row>
                </v-container>
            </div>
        </div>
        <div v-show="isMobile && installButton" 
            class="app-download-container"
        >
            <!-- <v-container> -->

                <v-banner
                    class="banner-app"
                >
                    <v-avatar
                    slot="icon"
                    color="primary"
                    size="40"
                    >
                    <v-icon
                        icon="mdi-tablet-cellphone"
                        color="white"
                    >
                        mdi-tablet-cellphone
                    </v-icon>
                    </v-avatar>

                    We've got an app, try it out

                    <template v-slot:actions>
                    <v-btn
                        text
                        @click="installapp()" 
                        class="btn btn-app"
                        color="primary"
                    >
                        Install
                    </v-btn>
                    </template>
                </v-banner>

                <!-- <div class="install-card">
                    <v-row>
                        <v-col cols="6" class="py-2">
                        <p>We've got an app, try it out</p>

                        </v-col>
                        <v-col cols="6" class="py-2">

                        <v-btn @click="installapp()" class="btn btn-primary">install</v-btn>
                        </v-col>
                    </v-row>
                </div> -->
            <!-- </v-container> -->
        </div>

        <div 
            class="featured-container" 
            v-if="getFavourities != null && getFavourities.length">
            <v-container>
                <h3 class="page--title primary--text">Favourites</h3>
                
                <Anyslider>
                    <template v-slot:content>
                            
                            <v-slide-item
                            cols="12" 
                            sm="12" 
                            md="6" 
                            lg="4" 
                            v-for="fav in getFavourities"
                            :key="fav.id"
                            @click="goToItemDetails(fav)"
                            class="slider-item">

                                <ProductCardFavourite style="width:200px;" :item="fav" :showServices="false"></ProductCardFavourite>

                            </v-slide-item>
                    </template>
                </Anyslider>

            </v-container>
        </div> 

        <div class="categories-container">
            <v-container>
                <v-row dense>
                    <v-col 
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"    
                    >

                        <h2 class="page--title primary--text">Welcome to {{ this.site_name }}</h2>
                        <strong class="page--subtitle">Discover all our services!</strong>
                        <v-row dense class="listmenu">
                            <v-col cols="3" sm="3" md="3" lg="3" v-for="(item, index) in Categories" :key="'category-'+index"
                                class="categories-col">

                                <button class="btn-categories" :class="item.name" @click="explore(item.searchValue)">
                                    <span>
                                        <span class="icon" :class="item.name"></span>
                                        <span class="name">{{item.name}}</span>
                                    </span>
                                </button>
                            </v-col>

                        </v-row>
                    </v-col>
                    <v-col 
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"    
                    >
                        <h3 class="page--title primary--text mb-5">Try it out! It’s FREE and always will be!</h3>
                        <ul>
                            <li>No more phone calls or endless messages</li>
                            <li>Receive SMS confirmation fast</li>
                            <li>Over 200 Salons to choose from</li>
                            <li>Cancel booking anytime (no cancellation fees)</li>
                            <li>Unparalleled customer service</li>
                        </ul>

                    </v-col>
                </v-row>

            </v-container>
        </div>
        <!-- End Categories Container -->

        <div 
            class="featured-container" 
            v-if="getPromoted.length">
            <v-container>
                <h3 class="page--title primary--text">Promoted</h3>
                
                <Anyslider>
                    <template v-slot:content>
                            
                            <v-slide-item
                            cols="12" 
                            sm="12" 
                            md="6" 
                            lg="4" 
                            v-for="fav in getPromoted"
                            :key="fav.id"
                            @click="goToItemDetails(fav)"
                            class="slider-item">

                                <ProductCardFavourite style="width:200px;" :item="fav" :showServices="false"></ProductCardFavourite>

                            </v-slide-item>
                    </template>
                </Anyslider>

            </v-container>
        </div>        

        <div>
            <v-container>
                <h3 class="page--title primary--text">How does it work?</h3>

                    <v-row>
                        <v-col col="3">
                            <v-card
                            class="ma-1 pa-4"
                            >
                            <h3>1. Look for a salon or barbershop</h3>
                            <p>Enter the name of the salon or barbershop in the search and find your favorite!</p>
                            </v-card>
                        </v-col>
                        <v-col col="3">
                            <v-card
                            class="ma-1 pa-4"
                            >
                            <h3>2. Choose services</h3>
                            <p>Bobow has hundreds of services, from spa treatments to haircuts.</p>
                            </v-card>
                        </v-col>
                        <v-col col="3">
                            <v-card
                            class="ma-1 pa-4"
                            >
                            <h3>3. Pick an Employee and time</h3>
                            <p>You will recognize the employee by the photo and you will know his free hours.</p>
                            </v-card>
                        </v-col>
                        <v-col col="3">
                            <v-card
                            class="ma-1 pa-4"
                            >
                            <h3>4. Get confirmation instantly</h3>
                            <p>After you confirm the order, it is sent to your email. you can cancel it, or change the details in the personal account.</p>
                            </v-card>
                        </v-col>
                    </v-row>

            </v-container>
        </div>

        <div class="share-social-container">
            <h4 class="page--title secondary--text">Share the love !</h4>
            <strong class="page--subtitle "></strong>
            <ShareNetwork
                network="facebook"
                url="https://api.wellbookings.com"
                title="Say hi to Wellbookings! A brand new, beauty treatment app"
                description="Get your beauty treatments done from a convinient app"
                quote="Beauty on demand everywhere"
                hashtags="wellbookings,beautyEverywhere"
            >
                <v-btn x-large class="btn btn-share">
                    <v-icon class="mr-2">mdi-share-variant-outline</v-icon>
                    Share
                </v-btn>
            </ShareNetwork>
            <!-- <v-img height="300" src="~@/assets/bk-a.jpg" /> -->
        </div>

        <div class="banner-container">
            <v-container>
                <v-row dense>
                    <v-col 
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"    
                    >
                        <h4 class="page--title primary--text">Do you have a hairdressing or beauty company? Go Online</h4>
                        <p class="page--description my-5">We help your business(and customer base) with wellbookings , our easy-to-use salon software</p>
                        <div class="btn-container">
                            <v-btn 
                                link 
                                to="seller-login" 
                                text 
                                x-large
                                outlined 
                                class="btn btn-browse">
                            Join
                            </v-btn>
                        </div>                 
                    </v-col>  

                    <v-col 
                        cols="12"
                        sm="12"
                        md="6"
                        lg="6"    
                    >
                        <v-img height="300" src="~@/assets/wellbookings_admin_screenshot.png" />
                    </v-col>                    
                </v-row>

            </v-container>
        </div>

    </section>
    <!-- </v-col>
        </v-row>
    </v-container> -->
</template>

<script>
    import {
        mapGetters,
        mapState
    } from 'vuex'

    import mixinShared from '@/mixins/Shared'


    import Categories from "../mixins/Categories";
    import ProductCardFavourite from '../components/products/ProductCardFavourite.vue';
    import Anyslider from '../components/slider/Anyslider.vue';
    // import Calender from "../components/Calender";
    import { bus } from '../main'

    export default {
        mixins: [mixinShared],
        data() {
            return {
                alignment: "",
                justify: "",
                location: {},
                search: '',
                searchLocation: '',
                searchDate: '',
                Categories: Categories,
                advanced: false,
                options: {
                    showCalendar: false,
                },
                deferredPrompt: {},
                installButton: false,
            };
        },
        components: {
            ProductCardFavourite,
            Anyslider
        }, 
        created() {
            

        },
        mounted() {

            window.addEventListener("beforeinstallprompt", e => {
                console.log("App beforeinstallprompt fired");
                // Prevent Chrome 76 and earlier from automatically showing a prompt
                e.preventDefault();
                // Stash the event so it can be triggered later.
                this.deferredPrompt = e;

                this.installButton = true;

            });
        },
        methods: {

            installapp() {
                // Show the prompt
                this.deferredPrompt.prompt();

                // Wait for the user to respond to the prompt
                this.deferredPrompt.userChoice.then(choiceResult => {
                if (choiceResult.outcome === "accepted") {
                    console.log("PWA setup accepted");

                    this.installButton = false;
                } else {
                    console.log("PWA setup rejected");

                    this.installButton = false;
                }
                this.deferredPrompt = null;
                });
            },
            changeLocation() {
                bus.$emit('showLocationDialog',true);
            },
            goToLocationPicker() {

                console.log("goToLocationPicker home.vue");
                this.$router.push({
                    name: 'LocationPicker'
                });

            },
            explore(search) {

                if (this.advanced === true) {
                    console.log("advanced explore", this.searchLocation)
                    this.$store.dispatch("searchByCity", this.searchLocation).then(() => {
                        // this.$store.dispatch("searchLocation", this.searchLocation).then(() => {
                        //this.$router.go(-1)

                        this.$router.push({
                            name: 'explore',
                            params: {
                                searchValue: search
                            }
                        });

                    })

                } else {

                    this.$router.push({
                        name: 'explore',
                        params: {
                            searchValue: search
                        }
                    });


                }
            },
            getLocation(position) {

                //if geo is set
                if (position.coords.latitude) {

                    console.log('[Home - location]', position);
                    this.location.lat = position.coords.latitude;
                    this.location.lng = position.coords.longitude;
                    this.location.speed = position.coords.speed;
                    this.location.timestamp = position.timestamp;

                    this.$store.dispatch("setLocation", this.location);

                } else {
                    console.log('[no geo]');

                    fetch(localStorage.getItem('appURL') + '/api/client/search/whereami', {
                        method: 'GET',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json"
                        }
                    }).then(response => {

                        console.log(response);

                        if (response.status === 200) {
                            response.json().then(result => {

                                console.log('[Home - location]', result.success);
                                this.location.lat = result.success.latitude;
                                this.location.lng = result.success.longitude;
                                this.location.speed = 0;
                                this.location.timestamp = 0;

                                this.$store.dispatch("setLocation", this.location);

                            });
                        } else {
                            // unkown error
                            console.log('[cant get location]');
                        }

                    }).catch((error) => {
                        console.log('[GPSLocation http request error]', error);
                    });

                }

            }

        },
        computed: {
            ...mapGetters(["getCurrentLocation", "getPromoted", "isMobile", "getFavourities"])
        },
        watch: {
            // location(val) {
            //     this.$store.dispatch("setLocation", val);
            // },
        }
    };
</script>

<style lang="scss">
    // .install-card {
    //     padding: 20px;
    //     border: 1px solid #2727274d;
    //     background-color: #ebebeb;
    //     margin: 20px;
    //     color: black;
    //     border-radius: 20px;
    // }
    .search-box {
        // padding: 20px;
        background-color: white;
        border: 1px solid #2727274d;
        border-radius: 20px;
        box-shadow: 10px 10px #8888888f;
        max-width: 600px;
        overflow: hidden;
    }
    .input-search-box {
        border-radius: 50px;
    }
    ul {
    list-style: none;
    }
    
    ul li{
    font-size: 21px;
    padding-left: 25px;
    }

    ul li:before {
    content: '✓';
    padding-right: 10px;
    }

    .page.page-homepage {
        .page--title {
            font-weight: bold;
            //color: #009688;
            text-align: center;
        }

        .page--subtitle {
            text-align: center;
            display: block;
        }

        .share-social-container {
            background-image: url("~@/assets/bk-a.jpg");
            background-size: cover;
            // background-attachment: fixed;
            min-height: 300px;
            padding: 4rem 2rem 2rem 2rem;
            text-align: center;
            box-shadow: inset 0 0 0 2000px rgba(0,150,136,0.6);

            .page--subtitle,
            .page--title {
                color: #fff;
                margin-bottom: 1rem;
            }

            a {
                text-decoration: none;
            }

            .btn.btn-browse {

                // color: #fff !important;
                // margin: 1rem auto;
                // border-color: #fff !important;
            }
        }

        .home-search-col{
            background-image: url("~@/assets/unsplash_model1.jpg");
            background-position: right top;
            padding: 2rem 0;
            background-color: #e9eef4;
            background-size: auto 100%;
            background-repeat: no-repeat;
            background-position: left top;
            background-position: center;
            background-size: cover;
        }
        .home-search {
            //background-image: url("~@/assets/unsplash_model1.jpg");
            //background-position: right top;
            background-color: #e9eef4;
            // background-attachment: fixed;
            padding: 7rem 0;

            .search-box-container {
                padding: .2rem;

                .v-text-field {
                    &.theme--light {
                        .v-input__slot {
                            background:white !important;// #ebebeb !important;
                            margin: 0;
                        }
                    }

                    &.theme--dark {
                        .v-input__slot {
                            background: #333333 !important;
                            margin: 0;
                        }
                    }

                    .v-input__slot {
                        margin: 0;
                    }

                    .v-text-field__details {
                        margin-bottom: 0;
                    }
                }

                .btn-search-container {
                    .btn-avanced {
                        margin: 0 auto 1rem auto;
                        text-align: center;
                    }
                    .btn-submit {
                        border-radius: 0 0 20px 20px;
                        -webkit-border-radius: 0 0 20px 20px;
                        -moz-border-radius: 0 0 20px 20px;
                    }
                }
            }
        }
    }

    .v-application {
        &.theme--dark {
            .categories-container {
                background-color: #2e2e2e;
            }

            .banner-container {
                background-color: #2e2e2e;
            }
        }
    }
    .slider-item {

        display: inline-block;
        color: black;
        background-color: white;
        border-width: 1px;
        border-color: slategray;
        margin: 5px;
        font-size: 12px;
        cursor:pointer;

        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
</style>