<template >
  <v-container>
      <v-row>
          <v-col cols="12">
              <section class="page page-account">

                <div class="page--main">
                  <div class="page--form">
                      <v-container>
                        <v-row dense>
                          <v-col 
                          cols="12">
                            <v-text-field
                            outlined
                            height="50"
                            class="input--big-icon"
                            id="username"
                            label="Username"
                            v-model="user.name"
                            disabled
                            name="account-username"
                            prepend-icon="mdi-account-outline"
                            type="text"
                            
                            ></v-text-field>
                          </v-col>   
                          <!-- Enc Col -->
                          <v-col 
                          cols="12">
                            <v-text-field
                            outlined
                            height="50"
                            class="input--big-icon"
                            id="email"
                            label="Email"
                            v-model="user.email"
                            name="account-email"
                            prepend-icon="mdi-email-outline"
                            type="text"
                            
                            ></v-text-field>
                          </v-col>   
                          <!-- Enc Col -->
                          <v-col 
                          cols="12">
                            <v-text-field
                            outlined
                            height="50"
                            class="input--big-icon"
                            id="phone"
                            label="Phone"
                            v-model="user.phone"
                            name="account-phone"
                            prepend-icon="mdi-phone-outline"
                            type="text"
                            
                            ></v-text-field>
                          </v-col>   
                          <!-- Enc Col -->
                          <v-col 
                          cols="12">
                            <v-text-field
                            outlined
                            height="50"
                            class="input--big-icon"
                            id="street"
                            label="Street"
                            v-model="user.street"
                            name="account-street"
                            prepend-icon="mdi-domain"
                            type="text"
                            
                            ></v-text-field>
                          </v-col>   
                          <!-- Enc Col -->     
                          <v-col 
                          cols="12">
                            <v-text-field
                            outlined
                            height="50"
                            class="input--big-icon"
                            id="city"
                            label="City"
                            v-model="user.city"
                            name="account-city"
                            prepend-icon="mdi-domain"
                            type="text"
                            
                            ></v-text-field>
                          </v-col>   
                          <!-- Enc Col -->                                               
                          <v-col 
                          cols="12">
                            <v-text-field
                            outlined
                            height="50"
                            class="input--big-icon"
                            id="country"
                            label="Country"
                            v-model="user.country"
                            name="account-country"
                            prepend-icon="mdi-domain"
                            type="text"
                            
                            ></v-text-field>
                          </v-col>   
                          <!-- Enc Col -->

                          <v-col 
                          cols="12">
                            <v-text-field
                            outlined
                            height="50"
                            class="input--big-icon"
                            id="zip_code"
                            label="Zip Code"
                            v-model="user.zip_code"
                            name="account-zip_code"
                            prepend-icon="mdi-domain"
                            type="text"
                            
                            ></v-text-field>
                          </v-col>   
                          <!-- Enc Col -->
                                                                                                                                                         
                          <!-- <v-text-field class="textfield" v-model="user.name" label="Name"/>
                          <v-text-field class="textfield" v-model="user.email" label="Email"/>
                          <v-text-field class="textfield" v-model="user.phone" label="Phone"/>
                          <v-text-field class="textfield" v-model="user.country" label="Country"/>
                          <v-text-field class="textfield" v-model="user.city" label="City"/>
                          <v-text-field class="textfield" v-model="user.street" label="Street"/>
                          <v-text-field class="textfield" v-model="user.zip_code" label="Zipcode"/> -->
                          <v-col 
                          cols="12">
                            <v-btn 
                            @click="UpdateUser" 
                            class="btn btn-primary btn-submit"
                            x-large
                            block
                            color="primary"
                            >
                            Submit
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                  </div>
                </div>
              </section>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
  import {
    mapGetters,
    mapState
  } from 'vuex'

  export default {
    data() {
      return {
          user:{},
          processing:false
      };
    },
    mounted() {
        this.user = {...this.getProfileData};
    },
    computed: {
      ...mapGetters([
        "isLoggedIn",
        "isSellerLoggedIn",
        "getProfileData",
        "getSellerProfileData"
      ]),
    },
    methods: {
      UpdateUser() {
        this.processing = true;

        if (this.isLoggedIn) {
          //update existing
          var appURL = localStorage.getItem('appURL');
          var token = localStorage.getItem('token');

          fetch(appURL + '/api/client/user/update', {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
              "Authorization": "Bearer " + token
            },
            body: JSON.stringify({...this.user})
          }).then(response => {
              console.log(response);
              //this.$store.dispatch('setprofile', response)

            if (response.status === 200) {
              response.json().then(result => {

                console.log('result', result.success);

                this.$store.dispatch('setprofile', result.success);

                console.log('navigating.....')
                this.$router.go(-1)
              });
            
            } else if (response.status === 401) {
                //failed logn
                console.log('Error connecting to API server 398');
            } else if (response.status === 422) {
                //failed logn
                alert('Not authorized');
            } else {
              // unkown error
              console.log('Unexpected error with API server 398');
            }

            }).catch((error) => {
                console.log('[GPSLocation http request error]', error);
                this.processing = false;
                alert(error)
            });
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
    // End custom common variables


</style>
